import React, { Component } from 'react';

class FormFilterName extends Component {
    render() {
        return (
            <React.Fragment>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="input-group">
                            <input 
                                type="text" 
                                name="name" 
                                className="form-control" 
                                onChange={this.props.handleChangeFilter} 
                                value={this.props.formFilter.name} 
                                placeholder="Buscador" 
                            />

                            <div className="input-group-append">
                                <button className="btn-shadow btn btn-info" onClick={this.props.handleClickFilter}>
                                    <i className="fas fa-search"></i>
                                </button>
                                            
                                {this.props.showbtnSubmit && (
                                    <button 
                                        className="btn-shadow btn btn-danger" 
                                        onClick={this.props.clearValues}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>     

            </React.Fragment>
        );
    }
}

export default FormFilterName;
