import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;

        this.state = {
            modal: false,
            modeEdit: false,
            ErrorValues:  true,
            id: "",

            formCreate: {
                name: "",
                prefix: "",
                code: "",
                has_process: false,
                has_header: false, 
                has_footer: false,
                is_format: false,
                content_format_id: "",
                header: "",
                footer: "",
            },

            selectedOption: {
                content_format_id: "",
                label: "Formatos"
            },
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues()
        }
    }

    validationForm = () => {
        if (this.state.formCreate.position_create_id != "" &&  
            this.state.formCreate.position_review_id != "" &&
            this.state.formCreate.position_approve_id != "" &&
            this.state.formCreate.name != "" &&
            this.state.formCreate.due_date != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/document_management/document_types/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.props.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            formCreate: {
                name: "",
                prefix: "",
                code: "",
                has_process: false,
                has_header: false, 
                has_footer: false,
                is_format: false,
                content_format_id: "",
                header: "",
                footer: "",
            },

            selectedOption: {
                content_format_id: "",
                label: "Formatos"
            },
        })
    }

    HandleClick = () => {
        if(this.validationForm()){
            if (!this.state.modeEdit)
                fetch(`/document_management/document_types`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                    this.props.updateData(data.register);
                    this.clearValues();
                });
            else {
                fetch(`/document_management/document_types/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                    this.props.updateItem(data.register);
                    this.clearValues();
                });
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeChecked = (name, e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [name]: isChecked
            }
        })
    }

    edit = (document_type) => {
        this.setState({
            modeEdit: true,
            modal: true,
            id: document_type.id,

            formCreate: {
                ...this.state.formCreate,
                name: document_type.name,
                prefix: document_type.prefix,
                code: document_type.code,
                has_process: document_type.has_process,
                content_format_id: document_type.content_format_id,
                header: document_type.header,
                footer: document_type.footer,
                has_header: document_type.has_header, 
                has_footer: document_type.has_footer,
                is_format: document_type.is_format,
            },

            selectedOption: {
                content_format_id: `${document_type.content_format != null ? document_type.content_format.id : ""}`,
                label: `${document_type.content_format != null ? document_type.content_format.name : "Formato"}`, 
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
                formCreate: {
                    ...this.state.formCreate,
                    content_format_id: selectedOption.value
                }
        });
    };

    handleChangeFroala = (name, e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [name]: e
            }
        });
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar tipo de documento" : "Crear tipo de documento"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreate}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        errorValues={this.state.ErrorValues}
                        imageUpload={this.props.imageUpload}
                        handleChangeFroala={this.handleChangeFroala}
                        handleChangeChecked={this.handleChangeChecked}

                        //select values

                        //select Format
                        selectedOption={this.state.selectedOption}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        formats={this.props.formats}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered table-layout-fixed" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Prefijo</th>
                                    <th>Código</th>
                                    <th>¿Pertenece a un proceso?</th>  
                                    <th>Formato Asociado</th>                
                                    {(this.props.estados.delete || this.props.estados.edit) && (
                                        <th className="text-center th-accions">Acciones</th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(document_type => (
                                        <tr key={document_type.id}>
                                            <td>{document_type.name}</td>
                                            <td>{document_type.prefix}</td>
                                            <td>{document_type.code}</td>
                                            <td>{document_type.has_process ? "Si" : "No"}</td> 
                                            <td>{document_type.content_format ? document_type.content_format.name : "" }</td>
                                          {/*   <td>{ReactHtmlParser(document_type.header)}</td>
                                            <td>{ReactHtmlParser(document_type.footer)}</td> */}

                                            {(this.props.estados.edit || this.props.estados.delete) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">

                                                                {this.props.estados.edit && (
                                                                    <button
                                                                        onClick={() => this.edit(document_type)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.delete && (
                                                                    <button
                                                                        onClick={() => this.delete(document_type.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <td colSpan="6" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Index;