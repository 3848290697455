import React, { Component } from 'react';
import Table from '../GeneralComponents/RiskConfigIndex'
import WebpackerReact from 'webpacker-react';

class RiskConfig extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateNewData = (data) => {
        this.setState({
            data: data
        })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = config => {
        this.setState({
            data: this.state.data.map(item => {
            if (config.id === item.id) {
              return { ...item, 
                name: config.name,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`${this.props.urlLoadData}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    updateNewData={this.updateNewData}

                    //header title

                    headerTitleName={this.props.headerTitleName}

                    //urls

                    urlCreate={this.props.urlCreate}
                    urlDelete={this.props.urlDelete}
                    urlUpdate={this.props.urlUpdate}

                    estados={this.props.estados}

                    user={this.props.user}
                />
            </React.Fragment>
        );
    }
}


export default RiskConfig;
WebpackerReact.setup({ RiskConfig });


