import React, { Component } from 'react';
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormCreateModal extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            ErrorValues: true,
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">


                                    <div className="col-md-12 mb-4">
                                        <input
                                            type="hidden"
                                            name="user_owner_id"
                                            value={this.props.selectedOption.user_owner_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.users}
                                            autoFocus={false}
                                            className={`link-form ${this.props.ErrorValues == false && this.props.form.user_owner_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOption}
                                        />
                                    </div>
                                    
                                    <div className="col-md-12">
                                        <textarea 
                                            className={`form form-control style-text ${this.props.ErrorValues == false && this.props.form.name == "" ? "error-class" : ""}`}
                                            name="name"
                                            onChange={this.props.HandleChange}
                                            value={this.props.form.name}
                                            placeholder="Introduzca un título para esta tarjeta..."
                                        >
                                        </textarea>
                                        <span className="span-count-form" style={{ color: (this.props.form.name.length + 0 >= 200 ? "red" : "#3f6ad8") }}>
                                            {200 - this.props.form.name.length}
                                        </span>
                                    </div>

                                    {this.props.form.name.length >= 200 && (
                                        <div className="col-md-12 mt-3">
                                            <div className="alert alert-primary" role="alert">
                                                <b>Si quieres ampliar la información debes hacerlo en la descripción</b>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button 
                                    className="btn-shadow btn btn-primary mr-3" 
                                    onClick={() => this.props.HandleClickCreateTask()}
                                >
                                    {this.state.ErrorValues == false ? "Complete los campos" : "Crear" }
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateModal;