import React from "react";

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          {this.props.modules.filter(section => { return section.show_module == this.props.tenant.app_type || section.show_module == "Todos"}).map(modulex => (
            <div className="col-md-3" style={{marginBottom:"30px"}}>
              <h5 key={modulex.id}>{modulex.name} {/*<input type="checkbox" className="float-right" onChange={(e) => this.props.handleChangeAll(e, this.props.modules)}/>*/}</h5>

              {modulex.section_accions.map(item => (
                <div>
                  <label key={item.id}>
                
                    <input
                      style={{marginRight:"10px"}}
                      type="checkbox"
                      value={item.id}
                      checked={this.props.checkedItems.get(item.id.toString())}
                      onChange={this.props.handleChangeAccions}
                    />  {item.name}
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default CheckboxContainer;
