import React, { Component } from 'react';


class CustomComponent extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showOptions: false,
            dataSelect: this.props.data,
            dataAdd: [],
            data: [],
            arraySelect: [],

            form: {
                name: "",
            }
        }
    }

    componentDidMount() {
        this.getValuesFirst();
        if(!this.props.isMulti){
            this.setState({
                form: {
                    name: this.props.value[0][0] != undefined ? this.props.value[0][0].name : "",
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.getValuesFirst();
        }
    }


    getValuesFirst = () => {


        console.log(this.props.value)
        let values = this.props.data.filter(value => {
            let result = true
            this.props.value.forEach((data) => {
                if (data.value == value.value) {
                    result = false
                }

            })
            return result;
        })
        console.log("hool")
        console.log(values)
        console.log(this.props.value)
        this.setState({
            data: values,
            dataAdd: this.props.value[0]
        }, () => this.props.handleChange(this.state.dataAdd, this.props.question))


    }



    getValues = () => {


        console.log(this.props.value)
        let values = this.props.data.filter(value => {
            let result = true
            this.state.dataAdd.forEach((data) => {
                if (data.value == value.value) {
                    result = false
                }

            })
            return result;
        })
        console.log("hool")
        console.log(values)
        console.log(this.props.value)
        this.setState({
            data: values,

        }, () => this.props.handleChange(this.state.dataAdd, this.props.question))


    }



    removeList = (index) => {

        var arr = this.state.dataAdd
        var el = arr[index].value
        console.log("boreeee")
        console.log(arr)
        console.log(el)
        console.log(arr.filter(item => item.value != el))
        this.setState({
            dataAdd: [...arr.filter(item => item.value != el)]
        }, () => this.getValues())

        /* this.props.handleChange(arr.filter(item => item != null)) */
    }

    updateData = (data) => {
        if (this.state.data.length <= this.props.data.length) {
            this.setState({
                dataAdd: [...this.state.dataAdd, data],
                showOptions: false,
                form: {
                    name: "",
                }

            }, () => this.getValues());

            console.log(data)

            this.props.handleChange(data)
        }
    }

    addAll = () => {
        const array = []

        this.setState({
            dataAdd: [...this.props.data],
        }, () => this.getValues())
    }


    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                name: e.target.value
            }
        })
    }


    selectValue = (user) => {
        this.setState({
            form: {
                ...this.state.form,
                name: user.name
            },

            showOptions: false,
        })

        this.props.handleChange(user, this.props.question)
    }

    render() {
        const styleSpan = {
            background: "#299ef9",
            padding: "2px 10px 5px 10px",
            color: "#fff",
            marginRight: "5px"
        }

        const styleIcon = {
            cursor: "pointer"
        }

        const styleList = {
            fontSize: "17px",
            color: "#299ef9",
            cursor: "pointer"
        }

        const IconList = this.props.classIconList != undefined ? `${this.props.classIconList} mr-2` : "mr-2"

        const IconCancel = {
            fontSize: "18px",
            color: "#1aa9fb",
            cursor: "pointer",
        }

        return (
            <React.Fragment>
                <div className="mt-3 gm-autocomplete-ct">

                    {this.props.isMulti ? (
                        <React.Fragment>

                            <div className="input-custom input-container">
                                {this.state.dataAdd.map((option, index) => (
                                    <span style={styleSpan}>{option.name}<i className="fas fa-times ml-1" onClick={() => this.removeList(index)} style={styleIcon}></i></span>
                                ))}

                                <input
                                    type="text"
                                    name="name"
                                    className="input-copy"
                                    autocomplete="off"
                                    value={this.state.form.name}
                                    onChange={(e) => this.handleChange(e)}
                                    onClick={() => this.setState({ showOptions: true })}
                                    placeholder={this.props.selectCustomAutocomplete.label}
                                />


                                <i className={`gm-autocomplete-icon fas ${this.state.showOptions ? "fa-times" : "fa-sort-down"} float-right`} onClick={() => this.setState({ showOptions: (!this.state.showOptions) })} style={IconCancel}></i>

                                <a  href="javascript:void(0)"
                                    className="link-line loat-right mr-2 gm-autocomplete-addall"
                                    onClick={() => this.addAll()}
                                >
                                    Añadir todos
                                </a>

                            </div>

                            {this.state.showOptions && (
                                <div className="card mt-3 gm-autocomplete">
                                    <div className="card-body p-2">
                                        {this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).length >= 1 ? (
                                            this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).map((option, index) => (
                                                <p key={index} style={styleList} onClick={() => this.updateData(option)}> {/*<i className={`${IconList}`} style={styleIcon}></i>*/}{option.name}</p>
                                            ))
                                        ) : (
                                                <h5 className="text-center">No hay registros con los criterios de búsqueda</h5>
                                        )}
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <input
                                    type="text"
                                    name="name"
                                    className="form form-control"
                                    autocomplete="off"
                                    value={this.state.form.name}
                                    onChange={(e) => this.handleChange(e, this.props.question)}
                                    onClick={() => this.setState({ showOptions: (!this.state.showOptions) })}
                                    placeholder={this.props.selectCustomAutocomplete.label}
                                />

                                {this.state.showOptions && (
                                    <div className="card mt-3">
                                        <div className="card-body p-2">
                                            {this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).length >= 1 ? (
                                                this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).map((option, index) => (
                                                    <p key={index} style={styleList} onClick={() => this.selectValue(option)}> {/*<i className={`${IconList}`} style={styleIcon}></i>*/}{option.name}</p>
                                                ))
                                            ) : (
                                                    <h5 className="text-center">No hay registros con los criterios de búsqueda</h5>
                                                )}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                </div>
            </React.Fragment>
        )

    }
}

export default CustomComponent;


// props 

/*

    isMulti == sirve para saber si es multiple o no
    selectCustomAutocomplete == valores del select
    options == data de registros
    classIconList == la clase del icono a mostrar en la lista

*/