import React, { Component } from 'react';
import Table from '../components/indicators/indicator/Index'
import TableGerencia from '../components/indicators/indicator/IndexGerencial'
import TableSegmentacion from '../components/indicators/indicator/indexSegmentacion'

import WebpackerReact from 'webpacker-react';

class IndicatorIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: false,
            sub_category_indicators_select: []
        }
    }

    componentDidMount() {
        this.loadData();
        this.configSelect();
    }

    configSelect = () => {
        let array = []

        this.props.sub_category_indicators.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            sub_category_indicators_select: array,
        })
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    loadData = () => {
        fetch(this.props.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: true
                });
            });
    }

    setData = (data) => {
        this.setState({
            data: data,
            isLoaded: true,
        })
    }



    render() {

        if (this.props.type == "GER") {
            return (
                <React.Fragment>
                    <TableGerencia
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        estados={this.props.estados}
                        isLoaded={this.state.isLoaded}
                        type={this.props.type}
                        cargos={this.props.cargos}
                        user={this.props.user}
                        category_indicator={this.props.category_indicator}
                        setData={this.setData}
                        sub_category_indicator={this.props.sub_category_indicator}
                        category_indicator_view={this.props.category_indicator_view}
                        sub_category_indicators={this.props.sub_category_indicators}
                        sub_category_indicators_select={this.state.sub_category_indicators_select}
                        url={this.props.url}
                    />
                </React.Fragment>
            );
        } else if (this.props.type == "SEGV") { 
            return (
                <React.Fragment>
                    <TableSegmentacion
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        estados={this.props.estados}
                        isLoaded={this.state.isLoaded}
                        type={this.props.type}
                        cargos={this.props.cargos}
                        user={this.props.user}
                        category_indicator={this.props.category_indicator}
                        setData={this.setData}
                        sub_category_indicator={this.props.sub_category_indicator}
                        category_indicator_view={this.props.category_indicator_view}
                        sub_category_indicators={this.props.sub_category_indicators}
                        sub_category_indicators_select={this.state.sub_category_indicators_select}
                        url={this.props.url}
                    />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Table
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        estados={this.props.estados}
                        data={this.state.data}
                        estados={this.props.estados}
                        isLoaded={this.state.isLoaded}
                        type={this.props.type}
                        isLoaded={this.state.isLoaded}
                        type={this.props.type}
                        cargos={this.props.cargos}
                        user={this.props.user}
                        category_indicator={this.props.category_indicator}
                        setData={this.setData}
                        sub_category_indicator={this.props.sub_category_indicator}
                        category_indicator_view={this.props.category_indicator_view}
                        sub_category_indicators={this.props.sub_category_indicators}
                        sub_category_indicators_select={this.state.sub_category_indicators_select}
                        url={this.props.url}
                    />
                </React.Fragment>
            );
        }
    }
}


export default IndicatorIndex;
WebpackerReact.setup({ IndicatorIndex });