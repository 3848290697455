import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import Preloader from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            id: "",
            form: {
                name: "",
                position_ids: [],
                task_label_ids: [],
                user_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },

            editValuesPosition: [],
            editValuesUsers: [],
            selectLabels: [],
            cargos: [],
            users: [],
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            editValuesPosition: [],
            selectLabels: [],

            form: {
                name: "",
                position_ids: [],
                task_label_ids: [],
                user_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },
        })
    }

    componentDidMount = () => {
        this.configSelect();
    }

    configSelect = () => {
        let arrayCargos = []
        let arrayUsers = []

        this.props.positions.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.props.users.map((item) => (
            arrayUsers.push({label: item.name, value: item.id})
        ))

        this.setState({
            cargos: arrayCargos,
            users: arrayUsers,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteUsers = selectedOptionUsers => {
        let array = []

        selectedOptionUsers.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                user_ids: array
            }
        })
    }

    HandleChange = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    };


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/task/task_boards/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    HandleClick = () => {
        if (!this.state.modeEdit) {

            if(this.validationForm() == true){
                fetch(`/task/task_boards`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false, modeEdit: false })
                    this.props.updateData(data.register)
                    this.props.messageSuccess(data);
                });
            }

        } else {

            fetch(`/task/task_boards/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.setState({ modal: false, modeEdit: false })
                this.props.updateItem(data.register)
                //this.props.messageSuccess(data);
            });
        }
    }

    addLabel = (label, state) => {
        const selectLabels = this.state.selectLabels

        if (!state){
            selectLabels.push(label.id)

            this.setState({
                selectLabels: selectLabels,
                form: {
                    ...this.state.form,
                    task_label_ids: selectLabels,
                },
            })
        }else {
            var arr = selectLabels
            var index = selectLabels.indexOf(parseInt(label.id))
            delete arr[index]

            this.setState({
                selectLabels: arr.filter(item => item != null),
                form: {
                    ...this.state.form,
                    task_label_ids: arr.filter(item => item != null),
                },
            })
        }
    }

    edit = (task_board) => {
        const position = []
        const task_labels = []
        const arrayEdit = []
        const arrayEditUsers = []

        task_board.positions.map((item) => (
            position.push(item.id)
        ))

        task_board.task_labels.map((item) => (
            task_labels.push(item.id)
        ))

        task_board.positions.map((item) => (
            arrayEdit.push({label: item.name, value: item.id})
        ))

        task_board.users.map((item) => (
            arrayEditUsers.push({label: `${item.first_name} ${item.first_last_name}`, value: item.id})
        ))

        this.setState({
            form: {
                name: task_board.name,
                position_ids: position,
                task_label_ids: task_labels,
                user_ids: arrayEditUsers,
            },

            id: task_board.id,
            selectLabels: task_labels,
            editValuesPosition: arrayEdit,
            editValuesUsers: arrayEditUsers,
            modal: true,
            modeEdit: true,
        })
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Tableros de tareas"}
                    subTitle={"Gestión de videos"}
                    titleNew={"Nuevo tablero"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.create}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Editar tablero" : "Nuevo tablero"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar tablero" : "Crear tablero"}
                        labels={this.props.labels}

                        addLabel={this.addLabel}
                        selectLabels={this.state.selectLabels}
                        editValuesPosition={this.state.editValuesPosition}

                        //select config 
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}

                        //select user
                        handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
                        users={this.state.users}
                        selectedOptionUsers={this.state.selectedOptionUsers}
                        editValuesUsers={this.state.editValuesUsers}

                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        {this.props.data.length >= 1 ? (
                            <div className="row">

                                {this.props.data.map(task_board => (
                                    <div className="col-md-4 mb-4" key={task_board.id}>
                                        <div className="card">
                                            <div className="card-header">
                                                {task_board.name}
                                            </div>

                                            <div className="card-body">
                                                <div className="row">

                                                    {/*<div className="col-md-12">
                                                        <p className="mb-0"><b>Cargos</b></p>
                                                        {task_board.positions.map(position => (
                                                            <span key={position.id} style={{ background: "gray" }} className={`badge badge-secondary mt-2 mr-2`}>{position.name}</span>
                                                        ))}
                                                    </div>*/}

                                                    <div className="col-md-12">
                                                        <p className="mb-0"><b>Usuarios</b></p>
                                                        {task_board.users.map(user => (
                                                            <span key={user.id} style={{ background: "gray" }} className={`badge badge-secondary mt-2 mr-2`}>{user.first_name} {user.first_last_name}</span>
                                                        ))}
                                                    </div>

                                                    <div className="col-md-12 mt-3">
                                                        <p className="mb-0"><b>Etiquetas</b></p>
                                                        {task_board.task_labels.map(label => (
                                                            <span key={label.id} style={{ background: label.color }} className={`badge badge-secondary mt-2 mr-2`}>{label.name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            {true && (
                                                <div className="card-footer" style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div>

                                                        {true && (
                                                            <a
                                                                href={`task_boards/${task_board.id}/tasks`}
                                                                data-turbolinks="false"
                                                                className="btn"
                                                            >
                                                                Tareas
                                                            </a>
                                                        )}

                                                        {this.props.estados.edit && (
                                                            <buttoan
                                                                onClick={() => this.edit(task_board)}
                                                                className="btn"
                                                            >
                                                                Editar
                                                            </buttoan>
                                                        )}

                                                        {this.props.estados.delete && (
                                                            <button
                                                                onClick={() => this.delete(task_board.id)}
                                                                className="btn"
                                                            >
                                                                Eliminar
                                                            </button>
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4>No hay boards</h4>
                                    </div>
                                </div>
                            )
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Index;
