import React, { Component } from 'react';
import Show from '../components/settings/ProcesoScore/Show'
import WebpackerReact from 'webpacker-react';

class ProcesoScoreShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            totalValue: 0,
            isLoaded: false,
            loadCalification: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    changeStateCalification = (state) => {
        this.setState({ loadCalification: state })
    }


    loadData = () => {
        fetch(`/settings/get_proceso_child_scores/${this.props.procesoScore.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            totalValue: data.totalValue,
            loadCalification: false,
            isLoaded: true
          });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Show
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    totalValue={this.state.totalValue}
                    procesoScore={this.props.procesoScore}

                    user={this.props.user}
                    users={this.props.users}

                    changeStateCalification={this.changeStateCalification}
                    loadCalification={this.state.loadCalification}
                />
            </React.Fragment>
        );
    }
}


export default ProcesoScoreShow;
WebpackerReact.setup({ ProcesoScoreShow });

