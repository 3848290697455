import React, { Component } from 'react';
import Table from '../components/knowledgeBase/Index'
import WebpackerReact from 'webpacker-react';

class KnowledgeBaseIndex extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = video => {
        this.setState({
            data: this.state.data.map(item => {
            if (video.id === item.id) {
              return { ...item, 
                description: video.description, 
                video_url: video.video_url, 
                type: video.type, 
                module_app_id: video.module_app_id,
                imagen: video.imagen,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/settings/get_knowledge_bases`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    updateDataFilter = (data) => {
        this.setState({
            data: data
        })
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    module_apps={this.props.module_apps}
                    updateDataFilter={this.updateDataFilter}
                    estados={this.props.estados}
                />
            </React.Fragment>
        );
    }
}


export default KnowledgeBaseIndex;
WebpackerReact.setup({ KnowledgeBaseIndex });
