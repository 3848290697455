import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import Loading from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            id: "",

            form: {
                specific_area_id: "",
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                description: "",
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionUsers: {
                users_ids: "",
                label: "Responsable"
            },

            selectedOptionAreas: {
                specific_area_id: "",
                label: "Área Específicas"
            },

            selectedOptionCargo: {
                proceso_id: "",
                label: "Proceso"
            },

            users: [],
            specific_areas: [],
            cargos: [],
        }
    }

    clearValues = () => {
        this.setState({
            ErrorValues: true,
            modeEdit: false,
            
            form: {
                specific_area_id: "",
                proceso_id: "",
                date_of_analysis: "",
                description: "",
                responsable_id: "",
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionAreas: {
                specific_area_id: "",
                label: "Área Específicas"
            },

            selectedOptionUsers: {
                users_ids: "",
                label: "Participantes"
            },

            selectedOptionCargo: {
                proceso_id: "",
                label: "Proceso"
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.date_of_analysis != "" && 
            this.state.form.responsable_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    //selects Config

    componentDidMount = () => {
        this.configSelect();
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                responsable_id: selectedOption.value
            }
        });
    };

    handleChangeAutocompleteCargo = selectedOptionCargo => {
        this.setState({
            selectedOptionCargo,
            form: {
                ...this.state.form,
                proceso_id: selectedOptionCargo.value
            }
        });
    };

    handleChangeAutocompleteAreas = selectedOptionAreas => {
        this.setState({
            selectedOptionAreas,
            form: {
                ...this.state.form,
                specific_area_id: selectedOptionAreas.value
            }
        });
    };

    handleChangeAutocompleteUsers = selectedOptionUsers => {
        let array = []

        selectedOptionUsers.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                users_ids: array
            }
        })
    }

    configSelect = () => {
        let array = []
        let arrayAreas = []
        let arrayCargos = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.specific_areas.map((item) => (
            arrayAreas.push({label: item.name, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
            specific_areas: arrayAreas,
            cargos: arrayCargos
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }    

    HandleClick = () => {
        if(this.validationForm() == true){
            if (this.state.modeEdit == true) {
                fetch(`/cause/cause_analyses/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false})
                    this.props.updateItem(data.register)
                    this.messageSuccess(data);
                });
            }else{
                fetch(`/cause/cause_analyses`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false})
                    this.props.updateData(data.register)
                    this.messageSuccess(data);
                });
            }
        }
    }

    edit = (cause) => {
        this.setState({
            modal: true,
            modeEdit: true, 
            id: cause.id,

            form: {
                proceso_id: cause.proceso_id,
                specific_area_id: cause.specific_area_id,
                date_of_analysis: cause.date_of_analysis,
                description: cause.description,
                responsable_id: cause.responsable_id,
            },

            selectedOption: {
                responsable_id: cause.responsable_id,
                label: `${cause.responsable.first_name} ${cause.responsable.first_last_name}`
            },

            selectedOptionAreas: {
                specific_area_id: cause.specific_area_id,
                label: `${cause.specific_area != undefined ? cause.specific_area.name : "Área Específicas"}`
            },

            selectedOptionCargo: {
                proceso_id: cause.proceso_id,
                label: `${cause.proceso != undefined ? cause.proceso.name : "Proceso"}`
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/cause/cause_analyses/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.tenant.app_type == "Mejora" ? "Investigación de causas" : "Análisis causa – raíz"}`}
                    subTitle={`${this.props.tenant.app_type == "Mejora" ? "Método de análisis" : "Herramientas"}`}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Editar Análisis causa" : "Nuevo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}
                        
                        //select values
                        
                        //select user
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        users={this.state.users}

                        //select proceso
                        handleChangeAutocompleteAreas={this.handleChangeAutocompleteAreas}
                        selectedOptionAreas={this.state.selectedOptionAreas}
                        specific_areas={this.state.specific_areas}

                        //select participantes
                        handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
                        selectedOptionUsers={this.state.selectedOptionUsers}

                        //select cargos

                        handleChangeAutocompleteCargo={this.handleChangeAutocompleteCargo}
                        selectedOptionCargo={this.state.selectedOptionCargo}
                        cargos={this.state.cargos}

                        tenant={this.props.tenant}
                        showFile={false}
                        
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Fecha de análisis</th>
                                            <th>Responsable</th>
                                            <th>Descripción</th>
                                            <th>{this.props.tenant.app_type == "Salud" ? "Área Específica" : "Proceso"}</th>

                                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                <th className="text-center"></th>
                                            )}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.length >= 1 ? (
                                            this.props.data.map(cause => (
                                                <tr key={cause.id}>
                                                    <td>{cause.date_of_analysis}</td>
                                                    <td>{cause.responsable != undefined ? cause.responsable.first_name : ""} {cause.responsable != undefined ? cause.responsable.first_last_name : ""}</td>
                                                    <td>{cause.description}</td>
                                                    <td>{this.props.tenant.app_type == "Salud" ? (cause.specific_area != undefined ? cause.specific_area.name : "") : (cause.proceso != undefined ? cause.proceso.name : "")}</td>
                                                
                                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {this.props.estados.gestionar && (
                                                                            <a
                                                                                href={`/cause/cause_analyses/${cause.id}`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Gestionar
                                                                                </a>
                                                                        )}

                                                                        {this.props.estados.editar && (
                                                                            <button
                                                                                onClick={() => this.edit(cause)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                                </button>
                                                                        )}

                                                                        {this.props.estados.eliminar && (
                                                                            <button
                                                                                onClick={() => this.delete(cause.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                                </button>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;