import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import { ChromePicker } from 'react-color'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Ckaditor from '../Formats/Ckeditor';
import FormCreate from "../DynamicFiledCondition/FormCreate";
import { array } from 'prop-types';

class ModalConfigurationDinamicFile extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showPikerFirstColor: false,
            modeEdit: false,
            ErrorValues: true,

            form: {
                comparation: "",
                value: "",
                color: "",
                question_id: "",
                question_compare_id: "",
                position_ids: [],
                email_content: "",
                send_email: false,
                configuration_dynamic_field_id: this.props.question.configuration_dynamic_field.id,
            },

            formUpdate: {
                comparation: "",
                value: "",
                color: "",
                question_id: "",
                question_compare_id: "",
                position_ids: [],
                email_content: "",
                send_email: false,
            },

            selectedOption: {
                question_id: "",
                label: "Seleccione una pregunta"
            },

            selectedOptionUpdate: {
                question_id: "",
                label: "Seleccione una pregunta"
            },

            selectedOptionPositions: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionQuestionCompare: {
                question_compare_id: "",
                label: "Pregunta a comparar",
            },
            
            dynamic_filed_condition_id: "",
            data: [],
            questions: [],
            editValues: [],
        }
    }

    componentDidMount = () => {
        this.loadDataQuestions()
        this.loadData();
    }

    validationForm = () => {
        if (this.state.form.question_id != "" &&
            this.state.form.comparation != ""  &&
            this.state.form.value != "" &&
            this.state.form.color != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    loadDataQuestions = () => {
        fetch(`/formatos/get_step_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = [];
    
            data.data.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))
    
            this.setState({
                questions: array,
            })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_dynamic_filed_conditions/${this.props.question.configuration_dynamic_field.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
            })
        });
    }


    // Primer color 

    handleClickFirstColor = () => {
        this.setState({ showPikerFirstColor: !this.state.showPikerFirstColor })
    };

    handleCloseFirstColor = () => {
        this.setState({ showPikerFirstColor: false })
    };

    handleChangeFirstColor = (color) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    color: color.hex,
                }
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    color: color.hex,
                }
            })
        }
    }

    handleChangeAutocomplete = selectedOption => {
        if(this.state.modeEdit){
            this.setState({
                selectedOption,
                    formUpdate: {
                    ...this.state.formUpdate,
                    question_id: selectedOption.value
                }
            });  
        }else{
            this.setState({
                selectedOption,
                    form: {
                      ...this.state.form,
                      question_id: selectedOption.value
                  }
            });  
        }
    }

    handleChangeAutocompleteUpdate = selectedOptionUpdate => {
        this.setState({
            selectedOptionUpdate,
                formUpdate: {
                  ...this.state.formUpdate,
                  question_id: selectedOptionUpdate.value
              }
        });  
    }

    handleChangeAutocompleteQuestionCompare = selectedOptionQuestionCompare => {
        if(this.state.modeEdit){
            this.setState({
                selectedOptionQuestionCompare,
                    formUpdate: {
                    ...this.state.formUpdate,
                    question_compare_id: selectedOptionQuestionCompare.value
                }
            });  
        }else{
            this.setState({
                selectedOptionQuestionCompare,
                    form: {
                      ...this.state.form,
                      question_compare_id: selectedOptionQuestionCompare.value
                  }
            });  
        }
    }

    HandleChangeCheckBox = (e) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    send_email: e.target.checked,
                }
            });
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    send_email: e.target.checked,
                }
            });
        }
    }

    handleChangeAutocompletePositions = selectedOptionPosition => {
        let array = []

        selectedOptionPosition.map((item) => (
          array.push(item.value)
        ))

        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    position_ids: array,
                },
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    position_ids: array,
                },
            })
        }
    }


    HandleChangeCkaditor = (e) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    email_content: e,
                },
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    email_content: e,
                },
            })
        }
    }
    

    HandleChange = (e) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    [e.target.name]: e.target.value,
                },
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    [e.target.name]: e.target.value,
                },
            })
        }
    }

    clearValues = () => {
        this.setState({
            form: {
                ...this.state.form,
                comparation: "",
                value: "",
                color: "",
                question_id: "",
            },

            formUpdate: {
                comparation: "",
                value: "",
                color: "",
                question_id: "",
            },

            selectedOption: {
                question_id: "",
                label: "Seleccione una pregunta"
            },

            selectedOptionUpdate: {
                question_id: "",
                label: "Seleccione una pregunta"
            },

            dynamic_filed_condition_id: "",
            modeEdit: false,
            ErrorValues: true,
        })
    }

    edit = (dynamic_filed_condition) => {
        const array = [];
        const arrayIds = [];

        if(dynamic_filed_condition.positions.length >= 1){
            dynamic_filed_condition.positions.map((item) => (
                array.push({ label: item.name, value: item.id }),
                arrayIds.push(item.id)
            ))
        }

        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                comparation: dynamic_filed_condition.comparation,
                value: dynamic_filed_condition.value,
                color: dynamic_filed_condition.color,
                question_id: dynamic_filed_condition.question_id,

                question_compare_id: dynamic_filed_condition.question_compare_id,
                position_ids: arrayIds,
                email_content: dynamic_filed_condition.email_content,
                send_email: dynamic_filed_condition.send_email,
            },

            selectedOptionUpdate: {
                question_id: dynamic_filed_condition.question != null ? dynamic_filed_condition.question.id : "",
                label: `${dynamic_filed_condition.question != null ? dynamic_filed_condition.question.question : "Seleccione una pregunta"}`
            },

            selectedOptionPositions: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionQuestionCompare: {
                question_compare_id: dynamic_filed_condition.question_compare != null ? dynamic_filed_condition.question_compare.id : "",
                label: `${dynamic_filed_condition.question_compare != null ? dynamic_filed_condition.question_compare.question : "Seleccione una pregunta"}`,
            },

            modeEdit: true,
            dynamic_filed_condition_id: dynamic_filed_condition.id,
            editValues: array,
        })
    }

    HandleClick = () => {
        if (!this.state.modeEdit) {

            if(this.validationForm() == true){
                fetch(`/formatos/dynamic_filed_conditions`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.updateData(data.register)
                    this.props.messageSuccess(data);
                });
            }

        } else {

            fetch(`/formatos/dynamic_filed_conditions/${this.state.dynamic_filed_condition_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.updateItem(data.register)
                this.props.messageSuccess(data);
            });
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/dynamic_filed_conditions/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.props.messageSuccess(response);
                    });
            }
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false
        })
    }

    //add update
    updateItem = dynamic_filed_condition => {
        this.setState({
            data: this.state.data.map(item => {
            if (dynamic_filed_condition.id === item.id) {
              return { ...item, 
                comparation: dynamic_filed_condition.comparation,
                value: dynamic_filed_condition.value,
                color: dynamic_filed_condition.color,
                question: dynamic_filed_condition.question,

                positions: dynamic_filed_condition.positions,
                question_compare: dynamic_filed_condition.question_compare,
                send_email: dynamic_filed_condition.send_email,
                email_content: dynamic_filed_condition.email_content,
              }
            }
            return item;
          })
        });
    }

    

    render() {
        
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <ModalBody>
                        <div className="row">

                            <div className="col-md-12 mb-3">
                                <div className="card">
                                    <div className="card-header">
                                        Añadir configuración
                                    </div>
                                    
                                    <FormCreate 
                                        form={this.state.form}
                                        HandleChange={this.HandleChange}
                                        errorValues={this.state.ErrorValues}
                                        handleClickFirstColor={this.handleClickFirstColor}
                                        HandleClick={this.HandleClick}


                                        //select question_compare
                                        selectedOptionQuestionCompare={this.state.selectedOptionQuestionCompare}
                                        handleChangeAutocompleteQuestionCompare={this.handleChangeAutocompleteQuestionCompare}
                                        questions={this.state.questions}

                                        //select positions
                                        selectedOptionPositions={this.state.selectedOptionPositions}
                                        handleChangeAutocompletePositions={this.handleChangeAutocompletePositions}
                                        cargos={this.props.cargos}

                                        //select question
                                        selectedOption={this.state.selectedOption}
                                        handleChangeAutocomplete={this.handleChangeAutocomplete}

                                        //ckaditor
                                        HandleChangeCkaditor={this.HandleChangeCkaditor}

                                        //edit values positions
                                        editValues={this.state.editValues}

                                        //checkbox 
                                        HandleChangeCheckBox={this.HandleChangeCheckBox}

                                        //color piker
                                        showPikerFirstColor={this.state.showPikerFirstColor}
                                        dynamic_filed_condition_id={this.state.dynamic_filed_condition_id}
                                        handleCloseFirstColor={this.handleCloseFirstColor}
                                        handleChangeFirstColor={this.handleChangeFirstColor}
                                        modeEdit={false}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                {this.state.data.length >= 1 ? (
                                    this.state.data.map(dynamic_filed_condition => (
                                        <React.Fragment>
                                            <div className="card mb-3" key={dynamic_filed_condition.id}>
                                                <div className="card-body pb-2">
                                                    {this.state.dynamic_filed_condition_id == dynamic_filed_condition.id ? (
                                                        <React.Fragment>
                                                            <FormCreate 
                                                                form={this.state.formUpdate}
                                                                HandleChange={this.HandleChange}
                                                                errorValues={this.state.ErrorValues}
                                                                handleClickFirstColor={this.handleClickFirstColor}
                                                                HandleClick={this.HandleClick}


                                                                //select question_compare
                                                                selectedOptionQuestionCompare={this.state.selectedOptionQuestionCompare}
                                                                handleChangeAutocompleteQuestionCompare={this.handleChangeAutocompleteQuestionCompare}
                                                                questions={this.state.questions}

                                                                //select positions
                                                                selectedOptionPositions={this.state.selectedOptionPositions}
                                                                handleChangeAutocompletePositions={this.handleChangeAutocompletePositions}
                                                                cargos={this.props.cargos}

                                                                //select question
                                                                selectedOption={this.state.selectedOption}
                                                                handleChangeAutocomplete={this.handleChangeAutocomplete}

                                                                //ckaditor
                                                                HandleChangeCkaditor={this.HandleChangeCkaditor}

                                                                //edit values positions
                                                                editValues={this.state.editValues}

                                                                //checkbox 
                                                                HandleChangeCheckBox={this.HandleChangeCheckBox}

                                                                //color piker
                                                                showPikerFirstColor={this.state.showPikerFirstColor}
                                                                dynamic_filed_condition_id={this.state.dynamic_filed_condition_id}
                                                                handleCloseFirstColor={this.handleCloseFirstColor}
                                                                handleChangeFirstColor={this.handleChangeFirstColor}
                                                                modeEdit={true}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <div className="row">
                                                            <div className="col-md-4">
                                                                    <b>Pregunta</b>
                                                                    <p>{dynamic_filed_condition.question != null ? dynamic_filed_condition.question.question : ""}</p>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <b>Condición</b>
                                                                    <p>{dynamic_filed_condition.comparation}</p>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <b>Valor</b>
                                                                    <p>{dynamic_filed_condition.value}</p>
                                                                </div>

                                                               

                                                                <div className="col-md-2">
                                                                    <button 
                                                                        style={{ backgroundColor: dynamic_filed_condition.color, color: "#ffff" }}
                                                                        className="btn btn-light"
                                                                        
                                                                    >
                                                                        Color
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>

                                                <div className="card-footer">
                                                    {this.state.dynamic_filed_condition_id == dynamic_filed_condition.id ? (
                                                        <React.Fragment>
                                                            <button
                                                                className="btn-shadow btn btn-info mr-2"
                                                                onClick={() => this.HandleClick()}
                                                            >
                                                                Actualizar 
                                                            </button>

                                                            <button
                                                                className="btn-shadow btn btn-danger"
                                                                onClick={() => this.clearValues()}
                                                            >
                                                                Cancelar
                                                            </button>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <button
                                                                className="btn-shadow btn btn-info mr-2"
                                                                onClick={() => this.edit(dynamic_filed_condition)}
                                                            >
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </button>

                                                            <button
                                                                className="btn-shadow btn btn-danger"
                                                                onClick={() => this.delete(dynamic_filed_condition.id)}
                                                            >
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                        </React.Fragment>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalConfigurationDinamicFile;
