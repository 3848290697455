import React, { Component } from 'react';

class layoutHorizontal extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            formUpdate: [],
            formUpdateMonth: [],
            formUpdateHorizontal: [],
            formUpdateHorizontalMonth: [],
            matrix: [],
            index: 500,
            id: "",
            indicator_id: "",
            indexMonth: 500,
            indexHorizontal: 500,
            indexHorizontalMonth: 500,



        }
    }

    componentWillReceiveProps(nextProps) {

        if (this.props !== nextProps) {
            console.log(nextProps)
            console.log("afsafsdafadfdfa")
            this.setState({
                data: nextProps.data,
                matrix: this.setMatrix(nextProps)
            })
        }
    }

    setMatrix = (nextProps) => {

        let matrix = []
        let vector = []
        nextProps.data.map((data) => {
            data.data_indicators[this.props.month].data.data.map((data, index) => {
                vector.push(data)
            })
            matrix.push(vector)
            vector = []
        })
        return matrix
    }


    setHorizontal = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).map(data => {

            let getMonth = data.data_indicators[this.props.month].data.data[index]
            array.push(getMonth)


        })

        return array

    }

    setMonth = (index) => {

        console.log(index)
        let array = []
        console.log(this.state.data[index].data_indicators)
        let getMonth = [...this.state.data[index].data_indicators]
        getMonth.map(data => {

            let value = { real: data.real}
            array.push(value)


        })
        console.log(this.state.data[index].data_indicators)
        console.log(array)
        return array


    }
    setHorizontalMonth = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).map(data => {

            let getMonth = { real: data.data_indicators[index].real,first_variable: data.data_indicators[index].first_variable, second_variable: data.data_indicators[index].second_variable}
            array.push(getMonth)


        })

        return array


    }



    updateFiels = (index, id, indicator_id) => {
        this.setState({
            index: index,
            indexHorizontal: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdate: this.state.matrix[index]
        })
    }

    updateFielsHorizontal = (index, id, indicator_id) => {
        this.setState({
            indexHorizontal: index,
            index: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdateHorizontal: this.setHorizontal(index)
        })
    }

    updateFielsMonth = (index, id, indicator_id) => {
        console.log("indexMOnth")
        this.setState({
            indexMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexHorizontalMonth: 200,
            id: id,
            formUpdateMonth: this.setMonth(index)

        })
    }
    updateFielsHorizontalMonth = (index, id, indicator_id) => {
        this.setState({
            indexHorizontalMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexMonth: 200,
            id: id,
            formUpdateHorizontalMonth: this.setHorizontalMonth(index)

        })
    }




    HandleChangeUpdate = value => (e) => {
        const values = this.state.formUpdate;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }


    HandleChangeUpdateHorizontal = value => (e) => {
        const values = this.state.formUpdateHorizontal;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateMonth = value => (e) => {
        const values = this.state.formUpdateMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonth = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }
    HandleChangeUpdateHorizontalMonthFirstVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].first_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].second_variable);
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonthSecondVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].second_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].first_variable); ;
        this.setState({
            values
        });
    }






    HandleClickUpdate = () => {
        let data = { data: this.state.formUpdate }
        fetch(`/indicator/update_json_new/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }

    HandleClickUpdateHorizontal = () => {
        let data = { data: this.state.formUpdateHorizontal }
        fetch(`/indicator/update_json_horizontal/${this.state.data[0].indicator_id}/${this.state.indexHorizontal}/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }

    HandleClickUpdateMonth = () => {
        let data = { data: this.state.formUpdateMonth }
        fetch(`/indicator/update_json_month/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260,
                    indexMonth: 260

                })
                /*               this.messageSuccess(data); */
            });
    }


    HandleClickUpdateHorizontalMonth = () => {
        let data = { data: this.state.formUpdateHorizontalMonth }
        fetch(`/indicator/update_json_month_horizontal/${this.state.data[0].indicator_id}/${this.state.indexHorizontalMonth}/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260,
                    indexMonth: 260,
                    indexHorizontalMonth: 260

                })
                /*               this.messageSuccess(data); */
            });
    }


    getInput = (indexP, index, data) => {

        if (indexP == this.state.index) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdate[index].real}
                    onChange={this.HandleChangeUpdate(index)}
                    className="form form-control"
                />)
        }
        else if (index == this.state.indexHorizontal) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateHorizontal[indexP].real}
                    onChange={this.HandleChangeUpdateHorizontal(indexP)}
                    className="form form-control"
                />)
        }
        else {
            return (
                <p>{data}</p>
            )

        }
    }

    getInputMonth = (indexP, index, data) => {
        console.log(index)
        console.log(indexP)
        if (indexP == this.state.indexMonth) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateMonth[index].real}
                    onChange={this.HandleChangeUpdateMonth(index)}
                    className="form form-control"
                />)
        }
        else if (index == this.state.indexHorizontalMonth) {
            return (
                <React.Fragment>
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateHorizontalMonth[indexP].real}
                    onChange={this.HandleChangeUpdateHorizontalMonth(indexP)}
                    className="form form-control"
                
                />
                <input
                type="text"
                name="first_variable"
                value={this.state.formUpdateHorizontalMonth[indexP].first_variable}
                onChange={this.HandleChangeUpdateHorizontalMonthFirstVariable(indexP)}
                className="form form-control"
            />
             <input
                type="text"
                name="second_variable"
                value={this.state.formUpdateHorizontalMonth[indexP].second_variable}
                onChange={this.HandleChangeUpdateHorizontalMonthSecondVariable(indexP)}
                className="form form-control"
            />
            </React.Fragment>
                )
        }
        else {
            return (
                <p>{data}</p>
            )

        }
    }



    render() {
        if (this.state.data.length == 0) {
            return (
                <div></div>
            );
        }
        return (

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body table-expanded">

                            <table className="indicator-horizontal-table-days">
                           <tbody>
                                <tr className="day">
                                    <td className="tr-gray">Variable</td>
                                    {this.state.data[0].data_indicators[0].data.data.map((data, index) => (
                                        <td className="td td-info">
                                            <div onClick={() => this.updateFielsHorizontal(index, data.id, data.indicator_id)}>{index + 1}</div>
                                            {index == this.state.indexHorizontal && (
                                                <React.Fragment>
                                                    <i
                                                        className="fas fa-check-circle true-btn ml-1"
                                                        onClick={() => this.HandleClickUpdateHorizontal()}
                                                        style={{ marginRight: "0px" }}
                                                    >
                                                    </i>
                                                    <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontal: 260 })}></i>
                                                </React.Fragment>
                                            )}
                                        </td>
                                    ))}
                                    <td className="indicator-subtotal">Total</td>
                                </tr>
                                {this.state.data.filter((data) => data.total_column === false).map((data, indexP) => (
                                    <tr className="day" key={data.id}>
                                        <td className="tr">
                                            {indexP == this.state.index && (
                                                <React.Fragment>
                                                    <i
                                                        className="fas fa-check-circle true-btn ml-1"
                                                        onClick={() => this.HandleClickUpdate()}
                                                        style={{ marginRight: "0px" }}
                                                    >
                                                    </i>
                                                    <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ index: 260 })}></i>
                                                </React.Fragment>
                                            )}
                                            <div onClick={() => this.updateFiels(indexP, data.id, data.indicator_id)}>{data.name}</div>
                                        </td>
                                        {data.data_indicators[this.props.month].data.data.map((data, index) => (
                                            <td>
                                                {this.getInput(indexP, index, data.real)}


                                            </td>
                                        ))}
                                        <td>{data.data_indicators[this.props.month].real}</td>
                                    </tr>
                                ))}
                                 <tr className="day">
                                    <td className="tr" >{this.state.data.filter((data) => data.total_column === true)[0].name}</td>
                                    
                                    {this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                        <td key={data.id}>
                                        <p>{data.real}</p>
                                        </td>


                                    ))}
                                   
                                    <td className="indicator-subtotal">{this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].real}</td>
                                </tr>
                               {/* <tr className="day">
                                    <td className="tr" >{this.state.data.filter((data) => data.total_column === true)[0].name}</td>
                                    {this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                        <td className="td">
                                            <p>{data.real}</p>


                                        </td>
                                    ))}
                                    <td className="indicator-subtotal">{this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].real}</td>
                                </tr>
                                <tr className="day">
                                    <td className="tr" >{this.state.data.filter((data) => data.total_column === true)[0].name}</td>
                                    {this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                        <td className="td">
                                            <p>{data.accumulated}</p>


                                        </td>
                                    ))}
                                    <td className="indicator-subtotal">{this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].accumulated}</td>
                                </tr>
                                <tr className="day">
                                    <td className="tr" >{this.state.data.filter((data) => data.total_column === true)[0].name}</td>
                                    {this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                        <td className="td">
                                            <p>{data.percentege}</p>


                                        </td>
                                    ))}
                                    <td className="indicator-subtotal">{this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].percentege}</td>
                                </tr>
                                <tr className="day">
                                    <td className="tr" >{this.state.data.filter((data) => data.total_column === true)[0].name}</td>
                                    {this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                        <td className="td">
                                            <p>{data.average}</p>


                                        </td>
                                    ))}
                                    <td className="indicator-subtotal">{this.state.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].average}</td>
                                </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body table-expanded">

                            <table className="indicator-horizontal-table">
                            <tbody>
                                <tr className="day">
                                    <td className="tr-gray">Variable</td>
                                    {this.state.data[0].data_indicators.map((data, index) => (

                                        <td className="td td-info">
                                            <div onClick={() => this.updateFielsHorizontalMonth(index, data.id, data.indicator_id)}>{data.month_text}</div>
                                            {index == this.state.indexHorizontalMonth && (
                                                <React.Fragment>
                                                    <i
                                                        className="fas fa-check-circle true-btn ml-1"
                                                        onClick={() => this.HandleClickUpdateHorizontalMonth()}
                                                        style={{ marginRight: "0px" }}
                                                    >
                                                    </i>
                                                    <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontalMonth: 260 })}></i>
                                                </React.Fragment>
                                            )}
                                        </td>
                                    ))}
                                    <td className="indicator-subtotal">Total</td>
                                </tr>
                                {this.state.data.filter((data) => data.total_column === false).map((data, indexPM) => (
                                    <tr className="day" key={data.id}>
                                        <td className="tr">
                                            {indexPM == this.state.indexMonth && (
                                                <React.Fragment>
                                                    <i
                                                        className="fas fa-check-circle true-btn ml-1"
                                                        onClick={() => this.HandleClickUpdateMonth()}
                                                        style={{ marginRight: "0px" }}
                                                    >
                                                    </i>
                                                    <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexMonth: 260 })}></i>
                                                </React.Fragment>
                                            )}
                                            <div onClick={() => this.updateFielsMonth(indexPM, data.id, data.indicator_id)}>{data.name}</div>
                                        </td>
                                        {data.data_indicators.map((data, indexPMC) => (
                                            <td key={data.id}>
                                                {this.getInputMonth(indexPM, indexPMC, data.real)}

                                            </td>
                                        ))}
                                        <td>{data.accumulated}</td>
                                    </tr>
                                ))}

                                <tr className="day">
                                    <td className="tr" >{this.state.data.filter((data) => data.total_column === true)[0].name}</td>
                                    {this.state.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (
                                        <td key={data.id}> 
                                            <p>{data.real}</p>
                                        </td>

                                     
                                    ))}
                                    <td className="indicator-subtotal">{this.state.data.filter((data) => data.total_column === true)[0].accumulated}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>



        );
    }
}

export default layoutHorizontal;
