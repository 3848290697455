import React, { Component } from 'react';
import Select from "react-select";
import { isMobile } from 'react-device-detect';

class IndexProcesos extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            procesos: [],

            form:{
                proceso_ids: "",
            },

            selectedOption: {
                proceso_id: "",
                label: "Procesos"
            },
        }
    }

    componentDidMount(){
        let array = []

        this.props.procesos.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            procesos: array
        })
    }

    clearValues = () => {
        this.setState({
            form:{
                proceso_ids: "",
            },

            selectedOption: {
                proceso_id: "",
                label: "Procesos"
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
          selectedOption,
          form: {
             ...this.state.form,
             proceso_ids: selectedOption.value
          }
        });

        this.add_proceso(selectedOption.value)
    };

    add_proceso = (proceso) => {
        fetch(`/task/add_procesos/${this.props.task.id}/${proceso}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateDataProcesos(data.register.procesos)
            this.clearValues();
        });
    }

    remove_proceso = (proceso) => {
        fetch(`/task/tasks_procesos_delete/${this.props.task.id}/${proceso}`, {
            method: "delete",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateDataProcesos(data.register.procesos)
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{ width: isMobile ? "" : "313px" }}>
                      <input
                        type="hidden"
                        name="user_id"
                        value={this.state.selectedOption.user_id}
                      />
                      <label>Procesos</label>
                      <Select
                        onChange={this.handleChangeAutocomplete}
                        options={this.state.procesos}
                        autoFocus={false}
                        className={`link-form`}
                        value={this.state.selectedOption}
                      />

                      <hr/>
                    </div>

                    <div className="col-md-12">
                        <React.Fragment>
                            {this.props.taskProcesos.length >= 1 ? (
                                this.props.taskProcesos.map((procesos, index) => (
                                    <div key={procesos.id} className={`card ${index >= 1 ? "mt-2" : "" }`}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <span className="ml-2">{procesos.name}</span>
                                                </div>

                                                <div className="col-md-2">
                                                    <i className="fas fa-trash-alt" style={{ cursor: "pointer" }} onClick={() => this.remove_proceso(procesos.id)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h6>No hay procesos</h6>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IndexProcesos;