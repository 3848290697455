import React, { Component } from 'react';
import Ckaditor from '../Formats/Ckeditor';
import Select from "react-select";
import { ChromePicker } from 'react-color'

class FormCreate extends Component {
    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className={this.props.modeEdit ? "" : "card-body"}>
                        <div className="row">
                            <div className="col-md-4">
                                <input
                                    type="hidden"
                                    name="question_id"
                                    value={this.props.selectedOption.question_id}
                                />

                                <Select
                                    onChange={this.props.handleChangeAutocomplete}
                                    options={this.props.questions}
                                    autoFocus={false}
                                    className={`link-form ${this.props.errorValues == false && this.props.form.question_id == "" ? "error-class" : ""}`}
                                    value={this.props.selectedOption}
                                />
                                                
                                <input type="checkbox" onChange={(e) => this.props.HandleChangeCheckBox(e)} checked={this.props.form.send_email} value={!this.props.form.send_email} id="checkboxState" /> 
                                <label htmlFor="checkboxState" className="ml-1">¿Enviar correo?</label>
                            </div>

                            <div className="col-md-2">
                                <input
                                    type="text"
                                    className={`form form-control ${this.props.errorValues == false && this.props.form.comparation == "" ? "error-class" : ""}`}
                                    name="comparation"
                                    value={this.props.form.comparation}
                                    onChange={this.props.HandleChange}
                                />
                            </div>

                            <div className="col-md-4">
                                <input
                                    type="text"
                                    className={`form form-control ${this.props.errorValues == false && this.props.form.value == "" ? "error-class" : ""}`}
                                    name="value"
                                    value={this.props.form.value}
                                    onChange={this.props.HandleChange}
                                    placeholder={"Valor"}
                                />
                            </div>

                            <div className="col-md-2">
                                <button 
                                    onClick={this.props.handleClickFirstColor} 
                                    style={{ backgroundColor: this.props.form.color, border: `1px solid ${this.props.errorValues == false && this.props.form.color == "" ? "#fa403c" : "#ffff"}` }}
                                    className="btn btn-light"
                                >
                                    Color
                                </button>
                                { this.props.showPikerFirstColor && this.props.dynamic_filed_condition_id == "" ? <div style={ popover }>
                                <div style={ cover } onClick={ this.props.handleCloseFirstColor }/>
                                    <ChromePicker 
                                        color={this.props.form.color} 
                                        onChange={this.props.handleChangeFirstColor} 
                                    />
                                </div> : null }
                            </div>
                                                
                            {this.props.form.send_email && (
                                <React.Fragment>
                                    <div className="col-md-6 mt-3">
                                        <label>Participantes </label>
                                        <input
                                            type="hidden"
                                            name="position_ids"
                                            value={this.props.selectedOptionPositions.position_ids}
                                        />
                                        <Select
                                            onChange={this.props.handleChangeAutocompletePositions}
                                            options={this.props.cargos}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            name="position_ids"
                                            defaultValue={[
                                                this.props.editValues[0],
                                                this.props.editValues[1],
                                                this.props.editValues[2],
                                                this.props.editValues[3],
                                                this.props.editValues[4],
                                                this.props.editValues[5]
                                            ]}
                                        />
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label>Pregunta para comparar</label>
                                        <input
                                            type="hidden"
                                            name="question_compare_id"
                                            value={this.props.selectedOptionQuestionCompare.question_compare_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteQuestionCompare}
                                            options={this.props.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionQuestionCompare}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <Ckaditor
                                            HandleChange={this.props.HandleChangeCkaditor}
                                            formValues={this.props.form.email_content}
                                        />
                                    </div>
                                </React.Fragment>
                            )}

                        </div>
                    </div>

                    {!this.props.modeEdit && (
                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.HandleClick()}
                            >
                                Crear condición
                            </button>
                        </div>
                    )}
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;
