import React, { Component } from 'react';

class TableIndexWeb extends Component {
    render() {
        const sytleFalse = {
            fontSize: "32px",
            color: "#ff0000",
            cursor: "pointer"
        }

        const sytleTrue = {
            fontSize: "32px",
            color: "#589000",
            cursor: "pointer"
        }

        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <p className="mt-3 pl-4 mb-0" >{this.props.dataUsers.length} Registros</p> 
                    <div className="card-body">
                        <table style={{ tableLayout: "fixed" }} className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th style={{ width: "5%" }}></th>
                                    <th>Nombre de usuario</th>
                                    <th>Correo electrónico</th>
                                    <th>Nombre</th>
                                    <th>Cargos</th>
                                    {this.props.estados.uploadFile && (
                                        <th>Adjuntos</th>
                                    )}

                                    {this.props.estados.change_state && (
                                        <th style={{ width: "10%" }}>Activos {this.props.usersTrue}</th>
                                    )}

                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                        <th className="text-center button-options"></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.dataUsers.length >= 1 ? (
                                    this.props.dataUsers.map(user => (
                                        <tr key={user.id}>

                                            <td><img className="rounded-circle" src={user.avatar.includes("undefined") == false ? user.avatar : "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe"} width="40" height="40" alt={`${user.first_name} ${user.first_last_name}`} /></td>
                                            <td>{user.username}</td>
                                            <td>{user.second_email}</td>
                                            <td>{user.first_name} {user.first_last_name}</td>
                                            <td>{user.position != undefined ? user.position.name : "Sin Cargo"}</td>


                                            {this.props.estados.uploadFile && (
                                                <td>
                                                    <p> <span className="link-line" onClick={() => this.props.addFiles(user)}>{user.general_files.length} Archivos adjuntos </span> </p>
                                                </td>
                                            )}
                                            
                                            {this.props.estados.change_state && (
                                                <td className="text-center">{user.user_state ? <i className="fas fa-check" onClick={() => this.props.updateState(user.id, false)} style={sytleTrue}></i> : <i className="fas fa-times" onClick={() => this.props.updateState(user.id, (this.props.usersTrue >= this.props.current_user.countUsers ? false : true))} style={sytleFalse}></i>}</td>
                                            )}

                                            {(this.props.estados.editar || this.props.estados.eliminar || user.is_tenant) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Button group with nested dropdown"
                                                    >
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right">

                                                                {(this.props.estados.editar) && (
                                                                    <button
                                                                        onClick={() => this.props.edit(user)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.eliminar && !user.is_tenant && (

                                                                    <button
                                                                        onClick={() => this.props.delete(user.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>

                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros con el criterio de búsqueda</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;