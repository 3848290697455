import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';



class Answers extends Component {
    render() {
        var data = this.props.data;
        var index = this.props.index;
        return (
            <React.Fragment>
                {
                    data.type == "answer_file" && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                            <p className="m-0">
                                <a href={data.value}>Ver archivo</a>
                            </p>

                        </div>
                    )
                }


                {
                    (data.type != "answer_integer" && data.type != "url" && data.type != "answer_formula" && data.type != "answer_file" && data.type != "answer_datetime" && data.type != "answer_date" && data.type != "answer_text" && data.type != "answer_checkbox" && data.type != "answer_localization") && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                          
                            <p className="m-0" style={{ color: "#909090" }}>  {typeof data.value !== 'object' ? data.value : ""}</p>
                        </div>
                    )
                }
                {
                    ((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency) && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                          
                            <p className="m-0" style={{ color: "#909090" }}> <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.value} /></p>
                        </div>
                    )
                }
                {
                    ((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency != true) && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                            
                            <p className="m-0" style={{ color: "#909090" }}>{typeof data.value !== 'object' ? data.value : ""}</p>
                        </div>
                    )
                }
                {
                    data.type == "answer_date" && (
                        <div className="col-md-3" style={{ padding: "15px" }}>
                          
                            <p className="m-0" style={{ color: "#909090" }}> {data.value != null & data.value != "" ? data.value : ""}</p>
                        </div>
                    )
                }
                {
                    data.type == "answer_text" && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                            {/*<p className="m-0 gm-hover" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", display: this.state.showParrafo == data.id ? "block" : "none" }} > {ReactHtmlParser(data.value)}</p>*/}
                         
                            <p className="m-0" >
                                {ReactHtmlParser(data.value)}
                            </p>
                            {/*<p className="m-0 gm-ellipsis" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", maxWidth: "300px" }} > {ReactHtmlParser(data.value)}</p>*/}
                        </div>
                    )
                }
                {
                    data.type == "url" && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                         
                            <p className="m-0" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                        </div>
                    )
                }
                {
                    (data.type == "answer_checkbox") && (
                        <div className="col-md-12" style={{ padding: "15px" }}>
                           
                            <p className="m-0" style={{ color: "#909090" }}>
                                {
                                    data.value.map((value, index) => (

                                        <span>{index == 0 ? "" : ", "} {value}</span>

                                    ))
                                }
                            </p>
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

export default Answers;