import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormCreateSection extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label>Nombre <small className="validate-label">*</small></label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Categoría</label>
                                        <select
                                            name="section_category_id"
                                            value={this.props.formValues.section_category_id}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                        >
                                            <option value="">Seleccione la categoría</option>
                                            {this.props.dataCategory.map(item => (
                                                <React.Fragment>
                                                    <option value={item.id}>{item.name}</option>
                                                </React.Fragment>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Para que aplicación va el módulo</label>
                                        <select
                                            name="show_module"
                                            value={this.props.formValues.show_module}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                        >
                                            <option value="">Seleccione un tipo</option>
                                            <option value="Mejora">Mejora</option>
                                            <option value="Salud">Salud</option>
                                            <option value="Todos">Todos</option>
                                        </select>
                                    </div>

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateSection;