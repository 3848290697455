import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            show: true
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">

                                    {this.state.show && (
                                        <React.Fragment>
                                            <div className="card">
                                                <div className="card-header">
                                                    Sub Categorías
                                                </div>
                                                <div className="card-body">
                                                    <ul>
                                                        {this.props.format_category.format_sub_categories.map(format_sub_category => (
                                                            <React.Fragment>
                                                                <li>{format_sub_category.name}</li>
                                                                <ul className="mb-2">
                                                                    {format_sub_category.positions.map(cargo => (
                                                                        <li>{cargo.name}</li>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    <div className={`card ${this.state.show ? "mt-3" : ""}`}>
                                        <div className="card-header">
                                            Accesos
                                        </div>
                                        <div className="card-body">
                                            <ul>
                                                {this.props.format_category.positions.map(cargo => (
                                                    <li>{cargo.name}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;