import React, { Component } from 'react';
import Table from '../components/formatos/FormatSubCategory/Index'  
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import Preloader from '../GeneralComponents/LoadingComponen'

class FormatSubCategoryShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false,
        })
    }

    //add update
    updateItem = step => {
        this.setState({
            data: this.state.data.map(item => {
            if (step.id === item.id) {
              return { ...item, 
                name: step.name,
                description: step.description,
                is_private: step.is_private,
                positions: step.positions,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_format_sub_categories/${this.props.format_sub_category.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Sub Categoria: ${this.props.format_sub_category.name}`}
                    subTitle={"ForData"}
                    titleNew={"Nuevo formato"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={() => this.child.current.formatAdd()}
                    showNew={true}
                    showFilter={false}
                />
                
                {!this.state.isLoaded ? (
                    <Table
                        ref={this.child}
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        updateItem={this.updateItem}
                        updateData={this.updateData}

                        format_sub_category={this.props.format_sub_category}
                        permissions={this.props.permissions}
                        formats_permissions_all={this.props.formats_permissions_all}
                        format_sub_categories={this.props.format_sub_categories}
                        categories={this.props.categories}
                    />
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader />
                        </div>
                    </div>
                )}

            </React.Fragment>
        );
    }
}


export default FormatSubCategoryShow;
WebpackerReact.setup({ FormatSubCategoryShow });

