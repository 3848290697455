import React, { Component } from 'react';

class index extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.dataActions.length >= 1 ? (
                    this.props.dataActions.map((accion, index) => (
                        <div className={`card mb-3`} key={accion.id}>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-3 mb-2">
                                        <b>Fecha</b>
                                        <p>{this.props.dateTime(accion.event_date)}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Área específica</b>
                                        <p>{accion.specific_area != undefined ? accion.specific_area.name : ""}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Paciente</b>
                                        <p>{accion.patient_name}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Tipo de Evento</b>
                                        <p>{accion.safety_related_event.name}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Evento relacionado</b>
                                        <p>{accion.related_event != undefined ? accion.related_event.name : ""}</p>
                                    </div>

                                    <div className="col-md-12">
                                        <b>Descripción</b>
                                        <p>{accion.description}</p>
                                    </div>

                                </div>
                            </div>

                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                <div className="card-footer">
                                    {this.props.estados.editar && (
                                        <a
                                            href={`/health/event_notification/${accion.id}/edit`}
                                            className="dropdown-item"
                                            data-turbolinks='false'
                                        >
                                            Editar
                                        </a>
                                    )}

                                    {this.props.estados.eliminar && (
                                        <button
                                            onClick={() => this.props.delete(accion.id)}
                                            className="dropdown-item"
                                        >
                                            Eliminar
                                        </button>
                                    )}

                                    {true && (
                                        <a
                                            href={`/health/event_notification/${accion.id}`}
                                            data-turbolinks="false"
                                            className="dropdown-item"
                                        >
                                            Más información
                                        </a>
                                    )}
                                </div>
                            )}

                        </div>
                    ))

                ) : (
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>No hay registros</h4>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default index;