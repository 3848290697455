import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Ckeditor from '../formatos/Formats/Ckeditor'
import Dropzone from "react-dropzone";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
{/*                                     <div className="col-md-12 mb-3">
                                        <label>Tipo de video</label>
                                        <select 
                                            name="type_module" 
                                            className="form form-control"
                                            onChange={this.props.onChangeForm}
                                            value={this.props.formValues.type_module}
                                        >
                                            <option value="">Seleccione un tipo</option>
                                            <option value="Manual">Manual</option>
                                            <option value="Teoria">Teoria</option>
                                        </select>
                                    </div> */}

                                    {/*<div className="col-md-6">
                                        <label>Módulo</label>
                                        <select 
                                            name="module_app_id" 
                                            className="form form-control"
                                            onChange={this.props.onChangeForm}
                                            value={this.props.formValues.module_app_id}
                                        >
                                            <option value="">Seleccione un tipo</option>
                                            {this.props.module_apps.map(moduleName => (
                                                <option value={moduleName.id}>{moduleName.name}</option>
                                            ))}
                                        </select>
                                    </div>*/}

                                    <div className="col-md-12 mb-3">
                                        <input 
                                            type="text" 
                                            name="video_url" 
                                            value={this.props.formValues.video_url}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                            placeholder="Url del video"
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <Dropzone onDrop={(files) => this.props.handleFile(files)}>
                                            {({getRootProps, getInputProps}) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone',
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{`${(this.props.nameFile != "" ? this.props.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                            <Ckeditor 
                                                HandleChange={this.props.onChangeFormDescription}
                                                formValues={this.props.formValues.description}
                                            />
                                    </div>

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;