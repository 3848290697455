import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ShowTask from './ShowTask'
import IndexLabels from '../tasksLabels/IndexLabels'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class ShowTaskEmail extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            showPopoverLabels: false,
            isLoadedShow: true,
            id: "",
            task: {},
            dataShow: {},
        }
    }

    toogle = (from) => {
        console.log("abriendo el módulos de tareas toogletoogletoogle")
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false, task: {} })
        }
    }

    editState = (id, state) => {
        fetch(`/task/update_state/${id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.props.updateItem(data.task);
        });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                this.props.updateStateLoad(true)
                fetch(`/task/tasks/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                    });
            }
        });
    };

    loadDataShow = (id) => {
        fetch(`/task/get_show_taks/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataShow: data.data,
                    isLoadedShow: false
                });
            });
    }

    editTask = (tarea) => {
        this.setState({
            modal: true,
            task: tarea
        })
    }

    get_color = (state) => {
        if (state == "create") {
            return "rgb(59, 174, 247)"
        } else if (state == "doing") {
            return "rgb(255, 159, 26)"
        } else if (state == "made") {
            return "rgb(97, 189, 79)"
        }
    }

    get_colorDate = (state) => {
        let resta = new Date(state).getTime() - new Date().getTime()
        resta = Math.round((resta / (1000 * 24 * 60 * 60)));

        if (resta > 5) {
            return "green"
        } else if (resta < 5 && resta >= 0) {
            return "orange"
        } else {
            return "red"
        }
    }


    componentDidMount = () => {
        if(this.props.task_id != "" && this.props.task_id != undefined){
            this.editTask(this.props.task_id)
        }
    }


    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <ShowTask 
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear tarea"}

                        //form props
                        task={this.state.task}

                        loadTasks={this.loadDataShow}
                        users={this.props.users}

                        //load show
                        isLoadedShow={false}

                        //current_user
                        user={this.props.user}

                        //load tasks
                        loadData={this.props.loadData}

                        //test load data

                        updateItem={this.props.updateItem}
                        updateData={this.props.updateData}

                        //procesos 

                        procesos={this.props.procesos}
                    />
                )}
            </React.Fragment >
        );
    }
}

export default ShowTaskEmail;

