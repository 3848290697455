import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import FormCreateSubCategories from '../../formatos/FormatCategories/SubModules/FormCreate'

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modalSubCategories: false,
            modeEdit: false,
            modeEditChildren: false,
            modeEditSubCategories: false,
            title: "",
            sub_category_indicator_id: "",
            formato_id: "",
            category_indicator_id: "",

            form: {
                name: "",
                order_number: "",
                format_id: "",
                section_name: "",
                position_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormat: {
                format_id: "",
                label: "Formato"
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                format_category_id: "",
            },

            formUpdatePositionCategory: {
                position: 0,
            },

            formCreateSubCategories: {
                name: "",
                category_indicator_id: "",
                position_ids: [],
            },

            dataProcesosEdit: [],
            cargos: [],
            formatos: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,

            form: {
                name: "",
                order_number: "",
                section_name: "",
                format_id: "",
                position_ids: [],
            },

            formCreateFormat: {
                name: "",
                format_category_id: "",
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true, title: "Nueva categoría" })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    componentDidMount = () => {
        this.defaultValues();
    }

    defaultValues = () => {
        let array = []
        let arrayFormat = []

        this.props.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.formatos.map((item) => (
            arrayFormat.push({ label: item.name, value: item.id })
        ))

        this.setState({
            cargos: array,
            formatos: arrayFormat,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
            ...this.state.form,
            position_ids: array
          }
        })
    }

    validationForm = () => {
        if (this.state.form.name != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClickSubCategory = () => {
        if (!this.state.modeEditSubCategories)
            fetch(`/indicator/sub_category_indicators`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/indicator/category_indicators`);
                    this.clearValuesSubCategory();
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/indicator/sub_category_indicators/${this.state.sub_category_indicator_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                    this.clearValuesSubCategory();
                    window.location.replace(`/indicator/category_indicators`);
                });
        }
    }

    HandleClick = () => {
        if (!this.state.modeEdit)
            fetch(`/indicator/category_indicators`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.props.updateData(data.register)
                    this.setState({ modal: false, title: "" })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/indicator/category_indicators/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                    this.clearValues();
                    this.props.updateItem(data.register)
                });
        }
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    edit = (config) => {
        let arrayPositonEdit = []

        config.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        this.setState({
            id: config.id,
            modeEdit: true,
            dataProcesosEdit: arrayPositonEdit,
            modal: true,

            selectedOptionFormat: {
                format_id: config.have_format ? config.format.id : "",
                label: config.have_format ? config.format.name : "Formato"
            },
            
            form: {
                name: config.name,
                section_name: config.section_name,
                order_number: config.order_number,
                format_id: config.have_format ? config.format.id : ""
            }
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/indicator/category_indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    handleChangeAutocompleteFormat = selectedOptionFormat => {
        this.setState({
            selectedOptionFormat,
                form: {
                    ...this.state.form,
                    format_id: selectedOptionFormat.value
                }
        });
    };

    handleChangeAutocompleteSubCategoryMulti = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: array
            }
        })
    }

    clearValuesSubCategory = () => {
        this.setState({
            dataProcesosEdit: [],

            formCreateSubCategories: {
                name: "",
                category_indicator_id: "",
                position_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },
        })
    }

    subCategories = (from, category) => {
        if (from == "new") {
            this.setState({
                formCreateSubCategories: {
                    ...this.state.formCreateSubCategories,
                    category_indicator_id: category.id,
                },

                modalSubCategories: true,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        } else {
            this.clearValuesSubCategory();
            this.setState({
                modalSubCategories: false,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        }
    }

    HandleChangeSubCategory = (e) => {
        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                [e.target.name]: e.target.value
            }
        })
    }

    deleteSubCategory = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/indicator/sub_category_indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        window.location.replace(`/indicator/category_indicators`);
                        this.messageSuccess(response)
                    });
            }
        });
    };

    editSubCategory = (category_indicator, sub_category_indicator) => {
        let arrayPositonEdit = []
        let arrayIds = []

        sub_category_indicator.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            sub_category_indicator_id: sub_category_indicator.id,
            modeEditSubCategories: true,
            dataProcesosEdit: arrayPositonEdit,
            modalSubCategories: true,

            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                category_indicator_id: category_indicator.id,
                name: sub_category_indicator.name,
                position_ids: arrayIds
            }
        })
    }

    clearValuesPosition = () => {
        this.setState({
            category_indicator_id: "",
            formUpdatePositionCategory: {
                position: 0,
            },
        })
    }

    updateValues = (category_indicator) => {
        this.setState({
            category_indicator_id: category_indicator.id,
            formUpdatePositionCategory: {
                position: category_indicator.position,
            },
        })
    }

    updatePosition = (e) => {
        fetch(`/indicator/update_position/${this.state.category_indicator_id}/${e.target.value}/${this.state.formUpdatePositionCategory.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.clearValuesPosition();
        });
    }

    update_position = (sub_category_indicator_id, e, position) => {
        fetch(`/indicator/update_position_sub_category_indicator/${sub_category_indicator_id}/${e.target.value}/${position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.messageSuccess(data);
            this.setState({ modeEditSubCategories: false, modalSubCategories: false, sub_category_indicator_id: null })
        });
    }

    render() {
        const styleIcons = {
            cursor: "pointer",
            color: "#3f69d8",
        }

        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Categorías`}
                    subTitle={"Crea y gestiona las categorías de tus Indicadores"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar categoría" : "Añadir categoría"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}

                        //select formato

                        selectedOptionFormat={this.state.selectedOptionFormat}
                        handleChangeAutocompleteFormat={this.handleChangeAutocompleteFormat}
                        formatos={this.state.formatos}
                    />
                )}

                {this.state.modalSubCategories && (
                    <FormCreateSubCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalSubCategories}
                        toggle={this.subCategories}
                        title={this.state.modeEditSubCategories ? "Actualizar Sub Módulo" : "Crear Sub Módulo"}

                        //form props
                        formValues={this.state.formCreateSubCategories}
                        submitForm={this.HandleClickSubCategory}
                        onChangeForm={this.HandleChangeSubCategory}
                        nameSubmit={this.state.modeEditSubCategories ? "Actualizar" : "Crear"}

                        modeEdit={this.state.modeEditSubCategories}
                        sub_category_indicator_id={this.state.sub_category_indicator_id}
                        update_position={this.update_position}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocompleteSubCategoryMulti}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Sub categorías</th>
                                    <th>Accesos</th>
                                    <th>Posición</th>
                                 {/*    <th style={{ width: "15%" }}>Nombre estrategico</th>
                                    <th style={{ width: "15%" }}>Numero de orden</th> */}

                                    {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                        <th style={{ width: "60px" }}></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(config => (
                                        <tr key={config.id}>
                                            <td>{config.name}</td>
                                            <td>
                                                <p
                                                    className="link-line-dark"
                                                    onClick={() => this.subCategories("new", config)}
                                                >
                                                    + Nuevo Sub Categorías
                                                </p>
                                                <hr />

                                                <div>
                                                    {config.sub_category_indicators.map(sub_category => (
                                                        <React.Fragment>
                                                            <div className="gm-link-formatos">{sub_category.name} <i className="ml-2 fas fa-pencil-alt" style={styleIcons} onClick={() => this.editSubCategory(config, sub_category)}></i> <i className="fas fa-trash-alt" onClick={() => this.deleteSubCategory(sub_category.id)} style={styleIcons}></i></div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </td>

                                            <td>
                                                <ul>
                                                    {config.positions.map(cargo => (
                                                        <li key={cargo.id}>{cargo.name}</li>
                                                    ))}
                                                </ul>
                                            </td>

                                            <td>
                                                {this.state.category_indicator_id == config.id ? (
                                                    <React.Fragment>
                                                        <select
                                                            className="form form-control"
                                                            value={config.position}
                                                            onChange={(e) => this.updatePosition(e)}
                                                        >
                                                            {this.props.data.map(item => (
                                                                <React.Fragment>
                                                                    <option value={item.position}>{item.position}</option>
                                                                </React.Fragment>
                                                            ))}
                                                        </select>

                                                        <i className="fas fa-times" style={{ cursor: "pointer", color: "red", fontSize: "19px", float: "right" }} onClick={() => this.clearValuesPosition()}></i>
                                                    </React.Fragment>
                                                ) : (
                                                    <p onClick={() => this.updateValues(config)}>{config.position}</p>
                                                )}
                                            </td>

                                            {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">

                                                                       {/*  {true && (
                                                                            <a
                                                                                href={`/indicator/category_indicators/${config.id}/indicators?type=${config.category_type}`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Gestionar
                                                                        </a>
                                                                        )} */}

                                                                        {this.props.estados.editar && (
                                                                            <button
                                                                                onClick={() => this.edit(config)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                        </button>
                                                                        )}

                                                                        {this.props.estados.eliminar && (
                                                                            <button
                                                                                onClick={() => this.delete(config.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                        </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
