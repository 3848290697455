import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Comments extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            data: [],   
            
            formUpdate: {
                comment: "",
            }
        }
    }


    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              comment: e.target.value
            }
        });
    }

    editComment = (comment) => {
        this.setState({
            id: comment.id,
            formUpdate: {
                comment: comment.comment,
            }
        })
        
    }

    HandleClick = () => {
        fetch(`/general_comments`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.props.formValuesComments), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadComments();
            this.props.clearValuesComment()
            this.messageSuccess(data);
        });
    }

    HandleClickUpdate = () => {
        fetch(`/general_comments/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadComments();
            this.setState({ id: ""})
            this.messageSuccess(data);
        });
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si"
        }).then(result => {
          if (result.value) {
            fetch(`/general_comments/${id}`, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadComments();
                this.messageSuccess(response);
              });
          }
        });
    };

    dateTime = (fecha) => {
        var d = new Date(fecha),
          months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear()
    }


    render() {
        return (

            <div className="card-hover-shadow-2x mb-3 card">
                <div className="card-header-tab card-header">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><i className="far fa-comments chat-box-icon"></i>Comentarios</div>
                </div>

                <div className="scroll-area-lg scores">
                    <div className="scrollbar-container">
                        <div className="p-2">
                            <div className="chat-wrapper p-1">
                                <div className="chat-box-wrapper">
                                    <div className="col-md-12">
                                        {this.props.dataCommets.length >= 1 ? (
                                            this.props.dataCommets.map(comment => (
                                                <React.Fragment>

                                                        <div className="row mb-4">
                                                            <div className="col-md-1 pl-0">
                                                                <div className="avatar-icon-wrapper mr-1">
                                                                    <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                                                                    <div className="avatar-icon avatar-icon-lg rounded">
                                                                        <img src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe" alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-8">
                                                                <div className="chat-box">
                                                                    {comment.id == this.state.id ? (
                                                                        <textarea
                                                                            type="text"
                                                                            name="comment"
                                                                            value={this.state.formUpdate.comment}
                                                                            onChange={this.HandleChangeUpdate}
                                                                            className="form form-control text-area-comment"
                                                                            rows={"12"}
                                                                        />
                                                                    ) : (
                                                                       <React.Fragment>
                                                                            {comment.comment}
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                                <small className="opacity-6">
                                                                    <i className="fa fa-calendar-alt mr-1" />
                                                                    {this.dateTime(comment.created_at)}
                                                                    {true && (
                                                                        <React.Fragment>
                                                                            {this.state.id != "" ? (
                                                                                <React.Fragment>
                                                                                    {comment.id == this.state.id &&(
                                                                                        <React.Fragment>
                                                                                            <i 
                                                                                                className="fas fa-check-circle float-right ml-2" 
                                                                                                onClick={() => this.HandleClickUpdate()}
                                                                                                style={
                                                                                                    {
                                                                                                        cursor: "pointer",
                                                                                                        color: "green",
                                                                                                        fontSize: "18px",
                                                                                                    }
                                                                                                }
                                                                                            >

                                                                                            </i> 

                                                                                            <i 
                                                                                                className="fas fa-times-circle float-right"
                                                                                                onClick={() => this.setState({ id: ""})}
                                                                                                style={
                                                                                                    {
                                                                                                        cursor: "pointer",
                                                                                                        color: "red",
                                                                                                        fontSize: "18px",
                                                                                                    }
                                                                                                }
                                                                                            >
                                                                                                
                                                                                            </i>
                                                                                        </React.Fragment>
                                                                                    )} 
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <i className="far fa-trash-alt float-right ml-2" style={{cursor: "pointer"}} onClick={() => this.delete(comment.id)}></i>
                                                                                    <i className="fas fa-pencil-alt float-right" style={{cursor: "pointer"}} onClick={() => this.editComment(comment)}></i>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                                </small>
                                                            </div>
                                                        </div>

                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="no-comments-scores">
                                                <i className="far fa-comments"></i><br/>
                                            <h1>No hay comentarios</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-footer">
                    <input 
                        placeholder="Escribe aqui tu comentario..." 
                        type="text" 
                        className="form-control-sm form-control" 
                        name="comment"
                        value={this.props.formValuesComments.comment}
                        onChange={this.props.HandleChangeComment}
                    />

                    <button className="btn-shadow btn btn-success ml-2" onClick={() => this.HandleClick()}>
                        <i className="fas fa-location-arrow"></i>
                    </button>
                </div>
                
            </div>
        );
    }
}

export default Comments;


