import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label>Nombre <small className="validate-label">*</small></label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="hidden"
                                            name="format_id"
                                            value={this.props.selectedOptionFormat.format_id}
                                        />
                                        <label>Formato</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteFormat}
                                            options={this.props.formatos}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionFormat}
                                        />
                                    </div>

                           {/*          <div className="col-md-6">
                                        <label></label>
                                        <select
                                            name="section_name"
                                            value={this.props.formValues.section_name}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                        >
                                            <option value="">Seleccione una opcion</option>
                                            <option value="Gestión estratégica">Gestión estratégica</option>
                                        </select>
                                    </div> */}

                                  {/*   <div className="col-md-12 mt-3">
                                        <label>Numero de orden</label>
                                        <input
                                            type="number"
                                            name="order_number"
                                            value={this.props.formValues.order_number}
                                            onChange={this.props.onChangeForm}
                                            placeholder="Numero de orden"
                                            className="form form-control"
                                        />
                                    </div> */}
                                    
                                    <div className="col-md-12 mt-3">
                                        <label>Cargos que pueden acceder <small className="validate-label">*</small></label>
                                        <input
                                            type="hidden"
                                            name="position_ids"
                                            value={this.props.selectedOption.position_ids}
                                        />
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            name="position_ids"
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.cargos}
                                            defaultValue={[
                                                this.props.editValuesProcesos[0],
                                                this.props.editValuesProcesos[1],
                                                this.props.editValuesProcesos[2],
                                                this.props.editValuesProcesos[3],
                                                this.props.editValuesProcesos[4],
                                                this.props.editValuesProcesos[5]
                                            ]}
                                        />
                                    </div>


                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                    <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;