import React, { Component } from 'react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Dropzone from "react-dropzone";
import Table from '../components/settings/Tenants/Table'

class TenantIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
        }
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = () => {
        fetch(`/settings/get_info_index`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    updateItem = tenant => {
        this.setState({
            data: this.state.data.map(item => {
              if (tenant.id === item.id) {
                return { ...item, 
                    contract: tenant.contract,
                    plan_id: tenant.plan_id,
                    base_model_id: tenant.base_model_id,
                    base_model: tenant.base_model,
                    plan: tenant.plan,
                    app_type: tenant.app_type,
                    is_base: tenant.is_base,
                }
              }
            return item;
          })
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle 
                    title={"Clientes"} 
                    subTitle={"Todos los clientes de gestión salud"}
                    titleNew={"Nuevo módulo"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <Table
                    data={this.state.data}
                    plans={this.props.plans}
                    base_models={this.props.base_models}
                    updateItem={this.updateItem}
                />

            </React.Fragment>
        );
    }
}


export default TenantIndex;



/*

{this.state.tenant_id == tenant.id ?  <i className="fas fa-times float-right" onClick={() => this.clearValues()}></i> : <i onClick={() => this.edit(tenant)} className="fas fa-pencil-alt float-right"></i>}

*/