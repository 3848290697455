import React from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DougnutChart from '../../../GeneralComponents/Charts/DoughnutChart'
import LineChart from '../../../GeneralComponents/Charts/LineChart'
import ColumnChart from '../../../GeneralComponents/Charts/ColumnChart'
import ChatBox from '../../../GeneralComponents/ChatBox'
import Loading from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import IndexWeb from './TableIndexWeb'
import IndexMobile from './Mobile/index'

class table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      action: {},
    }
  }

  delete = id => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡El registro será eliminado para siempre!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si"
    }).then(result => {
      if (result.value) {
        fetch("/health/event_notification/" + id, {
          method: "delete"
        })
          .then(response => response.json())
          .then(response => {
            this.props.loadInfo();

            Swal.fire(
              "Borrado!",
              "¡El registro fue eliminado con éxito!",
              "success"
            );
          });
      }
    });
  };


  MessageSucces = (name_success, type, error_message) => {
    Swal.fire({
      position: "center",
      type: type,
      html: '<p>' + error_message != undefined ? error_message : "asdasdasd" + '</p>',
      title: name_success,
      showConfirmButton: false,
      timer: 1500
    });
  }

  dateTime = (fecha) => {
    var d = new Date(fecha),
      months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes() );
  }

  date = (fecha) => {
    var d = new Date(fecha),
      months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear();
  }



  getDates = () => {

    let dataX = [];
    let dataY = [];


    this.props.selectedOptionSource.map(source => {
      console.log("lñdsjfañslasfasffad")
      dataY.push(this.props.data.filter(value => value.safety_related_event_id == source.id).length);
      dataX.push(source.name);
    })


    console.log(dataY)
    console.log(dataX)
    console.log("hokfldflkld")

    this.setState({
      data: {
        ...this.state.data,
        labels: dataX
      },
      datasets: {
        ...this.state.data,
        data: dataY
      }
    });
  }

  close = () => {
    this.props.closeFilter(true)
  }

  render() {

    if (this.props.is_loading) {
      return (
        <Loading />
      );
    }

    return (
      <React.Fragment>


        <div className="row">

          <div className="col-md-12">
            <button disabled={this.props.dataActions.length >= 1 ? false : true} onClick={this.props.handleClickFilterChart} className="btn btn-primary mr-3"><i className="fas fa-line-chart"></i> 
              {this.props.dataActions.length >= 1 ? "Indicadores" : "Debe haber al menos un registro"}
            </button>
            <button onClick={this.close} style={{ display: this.props.showChart == true ? "inline-block" : "none" }} className="btn btn-light ml-2">Cerrar</button>

          </div>
        </div>
        <br />
        {this.props.showChart == true && (
          <div className="content main-card mb-3 card">
            <div className="row">

              <div className="col-md-6 pt-4">
                <DougnutChart
                  dataDoughnut={this.props.dataDoughnut}
                />
              </div>

              <div className="col-md-6 pt-4">
                {this.props.dataLine.show ? (
                  <LineChart dataLine={this.props.dataLine} />
                ) : (
                    <div className="no-line-chart col-md-12">
                      <p><i className="metismenu-icon pe-7s-graph1"></i><br />
                        <span>Para ver esta gráfica debes seleccionr un rango de fechas</span></p>
                    </div>
                  )}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6 pt-2">
                <ColumnChart dataColumn={this.props.dataColumn}
                />
              </div>
              <div className="col-md-6 pt-2 pr-5">
                <ChatBox nameModule={this.props.nameModule} usuario={this.props.usuario} />
              </div>
            </div>
          </div>
        )}
        
        {isMobile ? (
          <IndexMobile 
            dataActions={this.props.dataActions} 
            estados={this.props.estados}
            loadInfo={this.props.loadInfo}
            delete={this.delete}
            dateTime={this.dateTime}
            date={this.date}
          />
        ) : (
          <IndexWeb 
            dataActions={this.props.dataActions} 
            estados={this.props.estados}
            loadInfo={this.props.loadInfo}
            delete={this.delete}
            dateTime={this.dateTime}
            date={this.date}
          />
        )}

      </React.Fragment>
    );
  }
}

export default table;
