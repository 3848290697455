import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class UploadFIle extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                file: {}
            },

            nameFile: "",
            sizeFile: "",
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    HandleClick = () => {
        const formData = new FormData();
        formData.append("file", this.state.form.file)

        fetch(`/settings/import_icons`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.closeModal();
            this.props.loadData();
            this.clearValues();
        });
    }

    clearValues = () => {
        this.setState({
            form: {
                file: {}
            },

            nameFile: "",
            sizeFile: "",
        })
    }


    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    file: file
                },
  
                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-12">
                                        <Dropzone onDrop={(files) => this.handleFile(files)}>
                                            {({getRootProps, getInputProps}) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone',
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={() => this.HandleClick()}>{"Subir"}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UploadFIle;
