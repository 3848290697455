import React, { Component } from 'react';
import Table from '../components/formatos/FormatCategories/Index'
import WebpackerReact from 'webpacker-react';

class FormatCategories extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = config => {
        this.setState({
            data: this.state.data.map(item => {
            if (config.id === item.id) {
              return { ...item, 
                name: config.name,
                section_name: config.section_name,
                order_number: config.order_number,
                positions: config.positions
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_format_categories`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    estados={this.props.estados}
                    positions={this.props.positions}
                    permissions={this.props.permissions}
                    formats_permissions_all={this.props.formats_permissions_all}
                    format_sub_categories={this.props.format_sub_categories}
                    icons={this.props.icons}
                    categories={this.props.categories}
                />
            </React.Fragment>
        );
    }
}


export default FormatCategories;
WebpackerReact.setup({ FormatCategories });


