import React, { Component } from 'react';
import Gantt from './Gantt/index';
import './Gantt/dhtmlxgantt_material.css';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            currentZoom: 'Days',
            messages: [],
        }
    }

    handleZoomChange = (zoom) => {
        this.setState({
            currentZoom: zoom
        });
    }

    //tasks functions

    createTask = (item) => {
        const form = {
            name: item.text,
            due_date: item.start_date,
            parent_task_id: item.parent,
            duration: item.duration,
            end_date: item.end_date,
            user_owner_id: this.props.current_user.id,
            module_task_id: this.props.modulo.module_task_id,
        }

        fetch(`/task/create_task`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
            
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
        });
    }

    updateTask = (item) => {
        const form = {
            name: item.text,
            due_date: item.start_date,
            parent_task_id: item.parent,
            duration: item.duration,
            end_date: item.end_date,
            progress: item.progress,
        }

        fetch(`/task/update_task/${item.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
        });
    }


    deleteTask = (item) => {
        fetch(`/task/destroy_task/${item.id}`, {
            method: "delete",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(response => {
            this.props.loadData();
        });
    }


    //links functions

    createLink = (item) => {

        const form = {
            source_task_id: item.source,
            target_task_id: item.target, 
            type_task: item.type,
        }

        fetch(`/task/create_link`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
        });
    }

    updateLink = (item) => {

        const form = {
            source_task_id: item.source,
            target_task_id: item.target, 
            type_task: item.type,
        }
        
        fetch(`/task/update_link/${item.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
        });
    }


    deleteLink = (item) => {
        fetch(`/task/destroy_link/${item.id}`, {
            method: "delete",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(response => {
            this.props.loadData();
        });
    }


    logDataUpdate = (type, action, item, id) => {
        console.log("type", type)
        console.log("action", action)
        console.log("item", item)

        //links 

        if(type == "link" && action == "create"){
            this.createLink(item);
        }else if(type == "link" && action == "update"){
            this.updateLink(item);
        }else if(type == "link" && action == "delete"){
            this.deleteLink(item);
        }   

        //tasks

        if(type == "task" && action == "create"){
            this.createTask(item);
        }else if(type == "task" && action == "update"){
            this.updateTask(item);
        }else if(type == "task" && action == "delete"){
            this.deleteTask(item);
        }
    }

    render() {
        const { currentZoom } = this.state;

        if(this.props.isLoaded){
            return(
                <p>Cargando...</p>
            )
        }

        return (
            <div>
                <div className="gantt-container">
                    <Gantt
                        tasks={this.props.data}
                        zoom={currentZoom}
                        onDataUpdated={this.logDataUpdate}
                    />
                </div>
            </div>
        );
    }
}

export default Index;
