import React, { Component } from 'react';
import FormCreate from '../FormatCategories/FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modalCategories: false,
            modalSubCategories: false,
            modalCategoriesShow: false,
            modeEdit: false,
            modeEditSubCategories: false,
            modeEditFormat: false,
            title: "",
            formato_id: "",
            format_category: {},
            category: {},
            format_sub_category_id: "",
            modulo_ids: [],
            created_format: false,

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },

            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                icon_id: "",
                position_ids: [],
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: this.props.format_sub_category.format_category_id,
                format_sub_category_id: this.props.format_sub_category.id,
            },

            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },

            checkedItems: new Map(),
            oldcheckedItems: new Map(),

            checkboxes: [],
            dataProcesosEdit: [],
            cargos: [],
            sub_categories: [],
            modulos: [],

            categories: [],
            format_sub_categories: [],
        }
    }

    formatAdd = () => {
        this.setState({
            modal: true,
            modeEditFormat: false,
            title: "Añadir formato",
            checkedItems: new Map(),
            category: this.props.format_sub_category.category,


            selectedOptionSubCategory: {
                format_sub_category_id: this.props.format_sub_category.id,
                label: this.props.format_sub_category.name,
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_sub_category_id: this.props.format_sub_category.id,
                format_category_id: this.props.format_sub_category.format_category_id,
            }
        })
    }

    HandleClickFormat = () => {
        if (!this.state.modeEditFormat) {
            fetch(`/formatos/format_categories/${this.props.format_sub_category.format_category_id}/formats`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_sub_categories/${this.props.format_sub_category.id}`);
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });

        } else {

            fetch(`/formatos/format_categories/${this.props.format_sub_category.format_category_id}/formats/${this.state.formato_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_sub_categories/${this.props.format_sub_category.id}`);
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreateFormat: {
                ...this.state.formCreateFormat,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeAccions = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormat.formats_permission_ids

        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAll = (e, modulo) => {
        const checkedItems = this.state.checkedItems;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_ids;
        const defaultValues = this.state.formCreateFormat.formats_permission_ids

        if (isChecked) {

            this.setState(
                {

                    modulo_ids: [...this.state.modulo_ids, modulo.id],
                    modulos: [...this.state.modulos.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(Number(key));
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },

                                })
                        }
                    )
                });




        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos
            var indexModulos = 0

            /* 
                        this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                            modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                                newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                            ))
                        )) */

            this.setState(
                {

                    modulo_ids: arr.filter(item => item != null),
                    modulos: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(key);
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },

                                })
                        }
                    )

                });

        }
    };

    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        this.setState({
            selectedOptionSubCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_sub_category_id: selectedOptionSubCategory.value
            }
        });
    }

    handleChangeAutocompleteFormatCategory = selectedOptionFormatCategory => {
        const array = [];

        selectedOptionFormatCategory.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))


        this.setState({
            selectedOptionFormatCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_category_id: selectedOptionFormatCategory.value
            },

            format_sub_categories: array,
        });
    }

    componentDidMount = () => {
        this.configSelect();
    }

    configSelect = () => {
        const array = [];

        this.props.categories.map((item) => (
            array.push({ label: item.name, format_sub_categories: item.format_sub_categories, value: item.id })
        ))

        this.setState({
            categories: array
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    editForm = (formato, category) => {
        console.log("formato", formato)
        console.log("category", category);
        const arrayPermissions = formato.formats_permissions
        const arrayNewPermissions = []
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.formats_permissions_all.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })


        category.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))


        this.setState({
            modal: true,
            created_format: true,
            modeEditFormat: true,
            category: category,
            title: "Editar formato",
            formato_id: formato.id,
            checkedItems: map,

            selectedOptionSubCategory: {
                format_sub_category_id: formato.format_sub_category != null ? formato.format_sub_category.id : "",
                label: `${formato.format_sub_category != null ? formato.format_sub_category.name : "Sub Categorias"}`
            },

            selectedOptionFormatCategory: {
                format_category_id: category.id,
                label: category.name
            },

            formCreateFormat: {
                name: formato.name,
                formats_permission_ids: arrayNewPermissions,
                format_category_id: category.id,
            },

            format_sub_categories: array,
        })
    }

    deleteFormat = (category_id, id, formato) => {
        Swal.fire({
            title: 'Escribe el nombre del formato para poder eliminarlo',
            input: 'text',
            footer: `<p>El nombre del formato es (${formato.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == formato.name.trim()) {
                    fetch(`/formatos/format_categories/${category_id}/formats/${id}`, {
                        method: "delete",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.messageSuccess(response)
                            window.location.replace(`/formatos/format_sub_categories/${this.props.format_sub_category.id}`);
                        });
                } else {
                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEditFormat ? "Actualizar formato" : "Añadir formato"}
                        nameBnt={this.state.modeEditFormat ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreateFormat}
                        submitForm={this.HandleClickFormat}
                        onChangeForm={this.HandleChange}
                        category={this.state.category}
                        modeEditFormat={this.state.modeEditFormat}

                        //ckeckcontainer

                        checkedItems={this.state.checkedItems}
                        handleChangeAccions={this.handleChangeAccions}
                        checkboxes={this.state.checkboxes}
                        modules={this.props.permissions}
                        handleChangeSelectAll={this.handleChangeSelectAll}
                        modulo_ids={this.state.modulo_ids}

                        //select format category
                        selectedOptionFormatCategory={this.state.selectedOptionFormatCategory}
                        handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCategory}
                        categories={this.state.categories}

                        //select sub categorias
                        handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                        selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                        sub_categories={this.state.sub_categories}
                        format_sub_categories={this.state.format_sub_categories}
                    />
                )}

                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/formatos/format_categories" data-turbolinks="false">Módulos</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{this.props.format_sub_category.name}</li>
                    </ol>
                </nav>

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Formato</th>
                                    <th>Número de registros</th>
                                    <th>Formatos asociados</th>
                                    {true && (
                                        <th className="text-center">Acciones</th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(format => (
                                        <tr key={format.id}>
                                            <td>
                                                <a
                                                    href={(true || format.gestionar) ? `/formatos/format_categories/${this.props.format_sub_category.format_category_id}/formats/${format.id}` : ""}
                                                    className="link-line mr-2"
                                                    data-turbolinks="false"
                                                >
                                                    {format.name}
                                                </a>
                                            </td>

                                            <td>{format.questions_count}</td>
                                            <td>
                                                <ul>
                                                    {format.format_associations.map(format_association => (
                                                        <li key={format_association.id}>
                                                            {format_association.format_select && (
                                                                <a target="_blank" href={`/formatos/format_categories/${format_association.format_select.format_category}/formats/${format_association.format_select.id}`}>
                                                                    {format_association.format_select.name}
                                                                </a>
                                                            )
                                                            }

                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>

                                            {(true) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">

                                                                {true && (
                                                                    <button
                                                                        onClick={() => this.editForm(format, this.props.format_sub_category.category)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {true && (
                                                                    <button
                                                                        onClick={() => this.deleteFormat(this.props.format_sub_category.category.id, format.id, format)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <td colSpan="13" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
