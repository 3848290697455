import React, { Component } from 'react';


class IndicatorSurvey extends Component {
    constructor(props) {
        super(props)
    }


    render() {


        return (
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="card">
                        <div className="card-body">

                            <a style={{ color: "#3f6ad8", textDecoration: "underline", cursor: "pointer" }} type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                Expandir información
                            </a>

                            <div className="collapse" id="collapseExample">
                                <div className="row">
                                    {this.props.answers != undefined && (
                                        <React.Fragment>
                                            <React.Fragment>
                                                {this.props.answers.filter(value => value.is_header).map((data, index) => (

                                                    <div className="col-md-12" style={{ padding: "0 15px 15px 15px" }}>
                                                        <p className="title-show-survey">
                                                            {data.value}
                                                        </p>
                                                    </div>

                                                ))}
                                            </React.Fragment>

                                            <React.Fragment>

                                                {this.props.answers.filter(value => value.show_in_gestion).map((data, index) => (

                                                    <React.Fragment>
                                                        {data.type == "answer_file" && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0">
                                                                    <strong>{data.name}</strong>
                                                                </p>
                                                                <p className="m-0">
                                                                    <a href={data.value}>Ver archivo</a>
                                                                </p>

                                                            </div>
                                                        )
                                                        }


                                                        {(data.type != "answer_integer" && data.type != "url" && data.type != "answer_formula" && data.type != "answer_file" && data.type != "answer_datetime" && data.type != "answer_date" && data.type != "answer_text" && data.type != "answer_checkbox") && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}>  {typeof data.value !== 'object' ? data.value : ""}</p>
                                                            </div>
                                                        )
                                                        }
                                                        {((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency) && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}> <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.value} /></p>
                                                            </div>
                                                        )
                                                        }
                                                        {((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency != true) && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}>{typeof data.value !== 'object' ? data.value : ""}</p>
                                                            </div>
                                                        )
                                                        }
                                                        {data.type == "answer_date" && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}> {data.value != null & data.value != "" ? data.value : ""}</p>
                                                            </div>
                                                        )
                                                        }
                                                        {data.type == "answer_datetime" && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}> {data.value != null & data.value != "" ? data.value : ""}</p>
                                                            </div>
                                                        )
                                                        }
                                                        {data.type == "answer_text" && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                            
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0"  > {typeof data.value !== 'object' ? data.value : ""}</p>
                                                            </div>
                                                        )
                                                        }
                                                        {data.type == "url" && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                                                            </div>
                                                        )
                                                        }
                                                        {(data.type == "answer_checkbox") && (
                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                <p className="m-0" style={{ color: "#909090" }}>
                                                                    {
                                                                        data.value.map((value, index) => (

                                                                            <span>{index == 0 ? "" : ", "} {value}</span>

                                                                        ))
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                        }


                                                        {(((index + 1) % 4) == 0 || this.props.answers.filter(value => value.show_in_gestion).length == (index + 1)) && (
                                                            <div className="col-md-12"><hr className="mt-0 mb-0" /></div>
                                                        )
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                            {(this.props.answers.filter(value => !value.show_in_gestion).length > 0) && (
                                                <div className="col-md-12 mt-3" > <a className="btn btn-primary btn-actions-true" data-toggle="collapse" href={`#collapseExampleNoshow`} role="button" aria-expanded="false" aria-controls={`collapseExampleNoshow`}>
                                                    Ver mas</a>
                                                </div>
                                            )}

                                            <div className="collapse col-md-12 " style={{ padding: "15px" }} id={`collapseExampleNoshow`}>
                                                <div className="row mt-3">
                                                    <React.Fragment>
                                                        {this.props.answers.filter(value => !value.show_in_gestion).map((data, index) => (

                                                            <React.Fragment>
                                                                {data.type == "answer_file" && (
                                                                    <div className="col-md-3" style={{ padding: "15px" }}>
                                                                        <p className="m-0">
                                                                            <strong>{data.name}</strong>
                                                                        </p>
                                                                        <p className="m-0">
                                                                            <a href={data.value}>Ver archivo</a>
                                                                        </p>

                                                                    </div>
                                                                )
                                                                }
                                                                {(data.type != "answer_file" && data.type != "url" && data.type != "answer_datetime" && data.type != "answer_text" && data.type != "answer_checkbox") && (
                                                                    <div className="col-md-3" style={{ padding: "15px" }}>
                                                                        <p className="m-0" ><strong>{data.name}</strong></p>
                                                                        <p className="m-0" style={{ color: "#909090" }}> {data.value}</p>
                                                                    </div>
                                                                )
                                                                }
                                                                {data.type == "answer_datetime" && (
                                                                    <div className="col-md-3" style={{ padding: "15px" }}>
                                                                        <p className="m-0" ><strong>{data.name}</strong></p>
                                                                        <p className="m-0" style={{ color: "#909090" }}> {this.dateTime(data.value)}</p>
                                                                    </div>
                                                                )
                                                                }
                                                                {data.type == "answer_text" && (
                                                                    <div className="col-md-3" style={{ padding: "15px" }}>
                                                                        <p className="m-0 gm-hover" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", display: this.state.showParrafo == data.id ? "block" : "none" }} > {data.value}</p>
                                                                        <p className="m-0" ><strong>{data.name}</strong></p>
                                                                        <p className="m-0 gm-ellipsis" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", maxWidth: "300px" }} > {data.value}</p>
                                                                    </div>
                                                                )
                                                                }
                                                                {data.type == "url" && (
                                                                    <div className="col-md-3" style={{ padding: "15px" }}>
                                                                        <p className="m-0" ><strong>{data.name}</strong></p>
                                                                        <p className="m-0" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                                                                    </div>
                                                                )
                                                                }
                                                                {(data.type == "answer_checkbox") && (
                                                                    <div className="col-md-3" style={{ padding: "15px" }}>
                                                                        <p className="m-0" ><strong>{data.name}</strong></p>
                                                                        <p className="m-0" style={{ color: "#909090" }}>
                                                                            {
                                                                                data.value.map((value, index) => (

                                                                                    <span>{index == 0 ? "" : ", "} {value}</span>

                                                                                ))
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )
                                                                }


                                                                {(((index + 1) % 4) == 0) && (
                                                                    <div className="col-md-12"><hr className="mt-0 mb-0" /></div>
                                                                )
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </React.Fragment>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    )
                }
            }
            
            
            
export default IndicatorSurvey;