import React, { Component } from 'react';
import Table from '../components/health/NumeralParents/Table'
import WebpackerReact from 'webpacker-react';

class NumeralParentsIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add update
    updateItem = parent => {
        this.setState({
            data: this.state.data.map(item => {
            if (parent.id === item.id) {
              return { ...item, 
                patient_safety_standar: parent.patient_safety_standar,
                service_area: parent.service_area,
                implementation_guide: parent.implementation_guide,
                video_url: parent.video_url,
                iso_clause: parent.iso_clause,
                proceso_ids: parent.procesos,
                procesos: parent.procesos,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/health/get_numeral_parents/${this.props.normativity.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: true
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    estados={this.props.estados}
                    normativity={this.props.normativity}
                    procesos={this.props.procesos}
                    areas={this.props.areas}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                />
            </React.Fragment>
        );
    }
}


export default NumeralParentsIndex;
WebpackerReact.setup({ NumeralParentsIndex });
