import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ShowCount extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }

    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr className="show-total">
                                            <th>Módulo</th>
                                            <th>Cantidad</th>
                                        </tr>
                                    </thead>
                                    {this.props.tenant.modules.modulos.map((countModules, index) => (
                                        <tr key={index}>
                                            <td className={`text-left ${countModules.name == "Total" ? 'show-total' : ""}`}>{countModules.name}</td>
                                            <td className={`text-center ${countModules.name == "Total" ? 'show-total' : ""}`}>{countModules.sum}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

export default ShowCount;
