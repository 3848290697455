import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js"; 

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                mode: this.props.modeEdit ? this.props.formValues.mode : "", 
                effect: this.props.modeEdit ? this.props.formValues.effect : "", 
                severity: this.props.modeEdit ? this.props.formValues.severity : "", 
                efect_activity_id: this.props.activity.id, 
            }
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    } 

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };
    
    HandleClick = () => {
        if(this.props.modeEdit){
            fetch(`/failure/failures/${this.props.formValues.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateItem(data.register)
                this.props.clearValues();
                this.messageSuccess(data);
            });
        }else{
            fetch("/failure/failures", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.register)
                this.props.updateShowCrate();
                this.props.loadDataActivity();
                this.props.updateState(false)
                this.props.clearValues();
                this.messageSuccess(data);
            });
        }
    }
   
    render() {
        return (
            <React.Fragment>
                <div style={{ width: "289px" }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">

 

                            <div className="col-md-12">
                            <label>Modo de Fallo</label>
                                <textarea 
                                    name="mode" 
                                    className="form form-control"
                                    value={this.state.form.mode}
                                    onChange={this.HandleChange}
                                    placeholder="Ingrese texto"
                                    rows="3"
                                >

                                </textarea>
                            </div>

                            <div className="col-md-12 mt-3 mb-3">
                            <label>Efecto de Fallo</label>
                                <textarea 
                                    name="effect" 
                                    className="form form-control"
                                    value={this.state.form.effect}
                                    onChange={this.HandleChange}
                                    placeholder="Ingrese texto"
                                    rows="3"
                                >

                                </textarea>
                            </div>

                            <div className="col-md-12">
                                <label>Severidad</label>
                                <select 
                                    name="severity" 
                                    className="form form-control"
                                    value={this.state.form.severity}
                                    onChange={this.HandleChange}
                                >
                                    <option value="">seleccione una opción</option>
                                    <option value="1">1{this.props.type_count == "Salud" ? " - No hay daño al paciente y no complica la atención" : ""}</option>
                                    <option value="2">2{this.props.type_count == "Salud" ? " - Afecta el servicio y la atención al paciente (No daño físico)" : ""}</option>
                                    <option value="3">3{this.props.type_count == "Salud" ? " - Daño al paciente / Afecta su atención correcta y oportuna" : ""}</option>
                                    <option value="4">4{this.props.type_count == "Salud" ? " - Daño grave y prolongación de estancia" : ""}</option>
                                    <option value="5">5{this.props.type_count == "Salud" ? " - Muerte de paciente o daño permanente a órgano o función" : ""}</option>
                                </select>
                            </div>

                            <div className="col-md-12 mt-3">
                                <button
                                    className="btn-shadow btn-block btn btn-light text-center"
                                    onClick={() => this.HandleClick()}
                                >
                                    {this.props.modeEdit ? "Actualizar" : "Guardar"}
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </React.Fragment>
            
        );
    }
}

export default FormCreate;
