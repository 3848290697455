import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Form from './Form';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        const date = new Date();
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                format_id: this.props.format.id,
                date_version: `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth() + 1}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`,
                description: "",
            },
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    clearValues = () => {
        this.setState({
            form: {
                format_id: "",
                date_version: "",
                description: "",
            },
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    HandleClick = () => {
        fetch(`/formatos/format_versions`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.clearValues();
            this.props.toggle();
            this.props.updateStateVersion(data.format.was_edited)
        });
    }


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <Form
                                    form={this.state.form}
                                    HandleChange={this.HandleChange}
                                />
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={() => this.HandleClick()}>Crear</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;