import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomAutocomplete from '../../../GeneralComponents/CustomAutocomplete';
import NumberFormat from 'react-number-format';
import Ckeditor from '../../formatos/Surveys/Ckeditor';
import Dropzone from "react-dropzone";

class ModalSubForm extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            countMax: 0,
            count: 1,

            survey_answer_id: "",
            nameFile: "",

            formUpdateRepy1: {

            },

            selectCustomAutocomplete: {
                name: "",
                label: "Buscar de registro",
            },



        }
    }

    componentDidMount = () => {
        console.log("hola")
        this.setState({
            isLoaded: false,
            formUpdateRepy1: this.props.dataAnswer,
        })
    }

    updateCount = (count) => {
        this.setState({
            count: count
        })
    }

    changevalue = (target) => {
        if (target.dataset.type == "answer_integer" || target.dataset.type == "answer_radio" || target.dataset.type == "answer_select") {
            //this.hasFormule(target.value);
        }
    }

    setValueSelect = (e, survey_answer) => {
        const { target } = e;
        var name = survey_answer.id
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [name]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[name].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);

        })
        /* 
                setTimeout(() => {
                    this.updateRepy()
                }, 1000); */
    }


    HandleChangeRepyRadio = (e, id) => {
        const { target } = e;
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value, ""], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            },
        }, () => {
            this.changevalue(target);

        });
    }


    HandleChangeRepy = (e) => {
        const { target } = e;

        console.log("------------------------ haasdasd")
        console.log(e.target.value)

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);
        });
    }

        HandleChangeCkaditor = (e, question) => {
        let val = []
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [e], type: question.question_type, step: this.state.formUpdateRepy1[question.id].step, is_mandatory: question.is_mandatory, is_active: question.is_active }
            }
        })
    }

    HandleChangeRepyNumber = (e) => {
        const { target } = e;
        const value = e.target.value.replace("$", "").replace(",", "").replace(",", "").replace(",", "").replace(",", "")

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);

        });
    }

    handleChangeCustomAutocompleteMultiple = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    handleChangeCustomAutocomplete = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question]: value
            }
        })
    }

    handleChangeCustomAutocompleteSingleFormat = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question.id]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        })
    }


    handleChangeCustomAutocompleteCargos = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []

            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }

    handleChangeCustomAutocompleteProcesos = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []
            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    getDateTime = (datetime) => {
        let time = new Date(datetime)
        let result = time.getFullYear() + "-" + (time.getMonth() < 10 ? `0${time.getMonth()}` : time.getMonth()) + "-" + (time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()) + "T" + (time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()) + ":" + (time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes())
        return result
    }


    HandleChangeFile = (archivo, id, is_mandatory, is_active, question) => {
        question = question.id;
        archivo.map(file => (
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: { value: [file], type: "answer_file", step: this.state.formUpdateRepy1[question].step, is_mandatory: is_mandatory, is_active: is_active }
                },

                survey_answer_id: id,
                nameFile: file.path
            })
        ));
    }

    updateValues = ()=>{
        const formData = new FormData();
        let dataArray = [];

        Object.keys(this.state.formUpdateRepy1).forEach(key => {


            var rt = this.state.formUpdateRepy1;
            var v1 = this.state.formUpdateRepy1[key].step;
            var v2 = this.state.nexStep;
            dataArray.push(this.state.formUpdateRepy1[key].type)
        });

        Object.keys(this.state.formUpdateRepy1).forEach(key => {
            let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
            let final_array = []
            final_array.push(array)
            formData.append(key, array);
        });


        fetch(`/formatos/update_ansewers/${this.props.surveySubform.id}/${dataArray}/0/${this.props.data[0].step_id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.toggle()
                this.props.loadData();
/*                 if ((this.state.nexStep + 1) == this.state.maxStep) {
                    this.redirectSurvey()
                } else {
                    window.scrollTo(0, 0)
                    this.setState((prevState, props) => ({
                        errorValues: true,
                        nexStep: prevState.nexStep + 1,
                        loading: false,
                        loadingNextStep: false,
                        completed: false
                    }));
                } */
            });
    }


    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="answer_string"
                        className="form form-control imput-options-disabled"
                        data-id={question.id}
                        data-question={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        //disabled={this.props.survey.state}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_integer") {

            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_integer"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}

                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}


                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}
                            placeholder="Total Cotizacion"
                        />

                    ) : (

                            <input
                                type="number"
                                className="form form-control imput-options-disabled"
                                name="answer_integer"
                                data-id={question.id}
                                data-question={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
/*                                 disabled={this.props.survey.state}
 */                                value={this.state.formUpdateRepy1[question.id].value}
                                onChange={this.HandleChangeRepy}
                            />
                        )}
                </React.Fragment>
            )

        } else if (question.question_type == "code_format") {
            console.log("sñladjflfdjfljalñsfjkfkldsajfdlsjflsffaslñdj")
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_user_id") {
            return (
                <React.Fragment>
                    <CustomAutocomplete
                        data={this.state.options}
                        handleChange={question.is_multiple ? this.handleChangeCustomAutocompleteMultiple : this.handleChangeCustomAutocomplete}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti={question.is_multiple}
                        question={question.id}
                        value={this.state.formUpdateRepy1[question.id].value}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "position_id") {
            return (
                <React.Fragment>
                    <CustomAutocomplete
                        data={this.state.cargos}
                        handleChange={this.handleChangeCustomAutocompleteCargos}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti
                        question={question.id}
                        value={this.state.formUpdateRepy1[question.id].value}
                    />

                </React.Fragment>
            )

        } else if (question.question_type == "proceso_id") {
            return (
                <React.Fragment>
                    <CustomAutocomplete
                        data={this.state.procesos}
                        handleChange={this.handleChangeCustomAutocompleteProcesos}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti
                        question={question.id}
                        value={this.state.formUpdateRepy1[question.id].value}
                    />

                </React.Fragment>
            )

        }

        else if (question.question_type == "answer_text") {
            return (
                <React.Fragment>
                    <Ckeditor
                        HandleChange={this.HandleChangeCkaditor}
                        question={question}
                        formValues={this.state.formUpdateRepy1[question.id].value[0]}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_date") {
            return (
                <React.Fragment>
                    <input
                        type="date"
                        className="form form-control imput-options-disabled"
                        name="answer_date"
                        data-id={question.id}
                        data-question={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id] ? this.state.formUpdateRepy1[question.id].value : "" }
                        onChange={this.HandleChangeRepy}
                        disabled={question.is_currency}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_datetime") {
            return (
                <React.Fragment>

                    <input
                        type="datetime-local"
                        className="form form-control imput-options-disabled"
                        name="answer_time"
                        data-id={question.id}
                        data-question={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.getDateTime(this.state.formUpdateRepy1[question.id].value)}
                        onChange={this.HandleChangeRepy}
                        //disabled={this.props.survey.state}
                    />

                </React.Fragment>
            )

        } else if (question.question_type == "answer_file") {
            return (
                <React.Fragment>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="mt-3">
                                <Dropzone
                                    onDrop={(files) => this.HandleChangeFile(files, question.id, question.is_mandatory, question.is_active, question)}
                                    //disabled={this.props.survey.state}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps({
                                                className: 'dropzone',
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{ color: this.state.formUpdateRepy1[question.id].value[0].name != undefined ? "#0094ff" : "#a5a5a5" }}>{`${(this.state.formUpdateRepy1[question.id].value[0].name != undefined ? this.state.formUpdateRepy1[question.id].value[0].name : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                        </div>
                                    )}
                                </Dropzone>

                                {false != null && (
                                    <p className="mb-0 mt-2"><b>Archivo: </b>
                                        <a
                                            href={this.state.formUpdateRepy1[question.id].value[0].url}
                                            target="_blanck"
                                        >
                                            {this.state.formUpdateRepy1[question.id].value[0].name}


                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select") {

            return (
                <select
                    name="answer_select"
                    className="form form-control"
                    onChange={(e) => this.setValueSelect(e, question)}
                    //disabled={this.props.survey.state}
                    data-id={question.id}
                    data-type={question.question_type}
                    data-mandatory={question.is_mandatory}
                    data-active={question.is_active}
                    data-question={question.id}

                    value={this.state.formUpdateRepy1[question.id].value}
                >
                    <option value="">Seleccione una opcion</option>
                    {question.options.map((name, index) => (
                        <option value={name}>{name}</option>
                    ))}

                </select>

            )
        
        } else if (question.question_type == "answer_localization"){
            this.getGeolocalizacion(question)
            return(
                <div>
                <input
                    type="text"
                    disabled={false}
                    value={this.state.formUpdateRepy1[question.id].value.toString()}
                    className={`form form-control mt-4`}
                />
             </div>
            )

        } else if (question.question_type == "answer_autdatetime"){
            return(
                <input
                    type="text"
                    className="form form-control"
                    disabled={true}
                    value={this.state.formUpdateRepy1[question.id].value}
                />
            )

        } else if (question.question_type == "answer_radio") {

            return (
                <div className="row mt-3">
                    {question.options.map((name, index) => (
                        <div className="col-md-12">
                            <input
                                id={name}
                                onChange={(e) => this.HandleChangeRepyRadio(e, question.id)}
                                type="radio"
                                value={name}
                                data-id={question.id}
                                data-question={question.id}

                                data-type={question.question_type}
                                disabled={this.props.survey.state}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                checked={this.state.formUpdateRepy1[question.id].value[0] == name ? true : false}
                                className="mr-2"
                            />
                            <label htmlFor={name}> {name} </label>
                            {name == "Otra.." && (
                                <input
                                    type="text"
                                    className="imput-options remove-select ml-3"
                                    name=""
                                    style={{ width: "50%" }}
                                    value={this.state.formUpdateRepy1[question.id].value[1]}
                                    onChange={(e) => this.HandleChangeRepyRadioInput(e, question)}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )

        } else if (question.question_type == "answer_checkbox") {
            return (
                <div className="row mt-3">
                    {question.options.map((name, index) => (
                        <div className="col-md-12">
                            <input
                                name="answer_checkbox"
                                onChange={(e) => this.HandleChangeRepyArray(e, question)}
                                value={name}
                                data-id={question.id}
                                data-type={question.question_type}
                                disabled={this.props.survey.state}
                                data-question={question.id}

                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                checked={this.state.formUpdateRepy1[question.id].value[0].includes(name)}
                                type="checkbox"
                                className="mr-2"
                            />
                            <label htmlFor={name}> {name}</label>
                        </div>
                    ))}
                </div>
            )
        } else if (question.question_type == "answer_formula") {
            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_formula"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}

                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}

                            disabled={true}
                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}
                            placeholder="Total Cotizacion"
                        />

                    ) : (
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                name="answer_formula"
                                data-id={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                disabled={true}
                                value={this.state.formUpdateRepy1[question.id].value}
                                onChange={this.HandleChangeRepy}
                            />
                        )}
                </React.Fragment>
            )

        }
        else if (question.question_type == "format_id") {
            return (
                <React.Fragment>
                    <FileTypeFormat
                        question={question}
                        formUpdateRepy1={this.state.formUpdateRepy1[question.id]}
                        formatValues={this.state.formatValues[question.id]}
                        survey={this.props.survey}
                        setValueSelect={this.setValueSelect}

                        //autocomplete props

                        handleChange={question.is_multiple ? this.handleChangeCustomAutocompleteFormat : this.handleChangeCustomAutocompleteSingleFormat}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti={question.is_multiple}
                        value={this.state.formUpdateRepy1[question.id].value}
                    />
                </React.Fragment>
            )


        } else if (question.question_type == "dynamic_field") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "email") {
            return (
                <React.Fragment>
                    <input
                        type="email"
                        className="form form-control imput-options-disabled"
                        name="email"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "url") {
            return (
                <React.Fragment>
                    <input
                        type="url"
                        className="form form-control imput-options-disabled"
                        name="url"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_subform") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.getSubform(question.id)}
                            >
                                Nuevo
                            </button>

                            <hr />
                        </div>

                        <div className="col-md-12">
                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                <thead>
                                    <tr>
                                        <th>Prueba</th>
                                        <th>Prueba</th>
                                        <th>Prueba</th>
                                        <th>Prueba</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Hola</td>
                                        <td>Hola</td>
                                        <td>Hola</td>
                                        <td>Hola</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            )

        }
    }


    render() {
        if(this.state.isLoaded){
            return(
                <p>Cargando..</p>
            )
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>
                    <ModalBody style={{ background: "#f8f9fa" }}>
                        
{/*                         <ul className="nav nav-tabs mb-0" id="myTab" role="tablist">
                            {this.props.data.map((step, index) => (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.count == (index + 1) ? "active" : ""}`}
                                        onClick={() => { this.updateCount(index + 1) }}
                                        id="home-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                    >
                                        {step.name == "Formulario sin título" ? "Sección" : step.name}
                                    </a>
                                </li>
                            ))}
                        </ul> */}



                                    {this.props.data.filter(question_filter => question_filter.state_db == true).map((question, index) => (
                                        <div className="card mt-3 border-questions" key={question.id}>
                                            <div className="card-body">
                                                
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <h5>{index + 1}) {question.question}</h5>
                                                        <p>{question.description}</p>

                                                        {question.file && (
                                                            <div className="mb-3">
                                                                <img src={question.file} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                            </div>
                                                        )}

                                                    </div>

                                                    <div className="col-md-12">
                                                        {this.getTypeQuestion(question)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                               
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={() => this.updateValues()}>Guardar</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalSubForm;

