import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalSelectType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount = () => {
        const data = ["Respuesta breve", "Párrafo", "Código", "Campo numérico", "Campo dinámico", "Fecha", "Hora", "Carga de archivos", "Lista desplegable", "Lista de usuarios", "Fórmula", "Lista de procesos", "Lista de cargos", "Opción múltiple", "Formato", "Casillas de verificación", "Geolocalización", "Fecha / Hora (Automática)", "Link", "Subformulario", "Firma"];
        const dataEdit = ["Respuesta breve", "Párrafo", "Campo numérico", "Link", "Opción múltiple", "Casillas de verificación"];
        this.setState({
            data: this.props.modeEdit ? dataEdit : data
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className={`col-md-6`}>
                                    {this.state.data.map((type, index) => (
                                        <p style={{display: index <= 9 ? "block" : "none"}} className={`list-format ${this.props.nameBtn == type ? "border-active" : ""}`} onClick={() => this.props.setName(type)}>{this.props.getIcon(type)} {type}</p>
                                    ))}

                                   
                                </div>
                                <div className={`col-md-6`}>
                                    
                                    {this.state.data.map((type, index) => (
                                        <p style={{display: index >  9 ? "block" : "none"}} className={`list-format ${this.props.nameBtn == type ? "border-active" : ""}`} onClick={() => this.props.setName(type)}>{this.props.getIcon(type)} {type}</p>
                                    ))}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <button className="btn-shadow btn btn-info" onClick={() => this.props.toggle()}>{"Confirmar"}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalSelectType;
