import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components'
import Task from './task'
import { Droppable } from 'react-beautiful-dnd'

const Title = styled.h3`
  padding: 8px;
`
const TaskList = styled.div`
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
`
// style={{ height: "666px", marginBottom: "30px"}}

class column extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-md-4">
          <div className="card card-list card-test-parent">
            <div className="card-header">
              <div className="col-md-12">
                  {this.props.column.title}
              </div>
            </div>

            <div className="card-body p-0 scroll-area-lg scroll-tasks-parent">
              <Droppable droppableId={this.props.column.id} type="TASK">
                {(provided, snapshot) => (
                    <React.Fragment>
                        <TaskList
                          innerRef={provided.innerRef}
                          {...provided.droppableProps}
                          isDraggingOver={snapshot.isDraggingOver}
                        >
                          {this.props.tasks.map((task, index) => (
                            <Task key={task.id} task={task} index={index} />
                          ))}
                          {provided.placeholder}
                        </TaskList>

                    </React.Fragment>
                )}
              </Droppable>
            </div>

          </div>
        </div>


      </React.Fragment>
    )
  }
}

export default column;





/*

        <ul 
                    className="todo-list-wrapper list-group list-group-flush"
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <li className="list-group-item">
                        <div className="todo-indicator " style={{ background: "green" }}></div>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                    <div className="widget-heading task-count">
                                      <span>T723</span>
                                      <div className="widget-heading" style={{ wordBreak: "break-all" }}>{"Cuando el documneto esta en estado creado cuando le doy editar solo me debe permitir editar cargos que pueden acceder"}</div>
                                    </div>
                                </div>

                                <div className="widget-content-right">
                                    <div className="d-inline-block dropdown">
                                        <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                            <i className="fa fa-ellipsis-h"></i>
                                        </button>

                                        <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right" style={{ paddingTop: "0px" }}>
                                            {true && (
                                                <React.Fragment>
                                                    <div className="col-md-12 text-center background-blue-dark-app">
                                                        Acciones de tarea
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            
                                            <button type="button" disabled="" tabIndex="-1" className="dropdown-item">Gestionar</button>
                                            <button type="button" tabIndex="0" className="dropdown-item">Eliminar Tarea</button>
                                          

                                            {true && (
                                                <React.Fragment>
                                                    <div className="col-md-12 text-center  background-blue-dark-app">
                                                        Estados
                                                    </div>

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            Pendientes
                                                        </button>
                                                    )}

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            En proceso
                                                        </button>
                                                    )}

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            Finalizadas
                                                        </button>
                                                    )}

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            Archivar
                                                        </button>
                                                    )}
                                                </React.Fragment>
                                            )}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </li>
                </ul >

<TaskList
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    {this.props.tasks.map((task, index) => (
                      <Task key={task.id} task={task} index={index} />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
*/