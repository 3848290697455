import React, { Component } from 'react';
import FormCreate from '../Formats/FormCreate'
import CustomAutocomplete from '../../../GeneralComponents/CustomAutocomplete'

class FileTypeFormat extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: true,
            data: [],
            dataAdd: [],
            formFormat: {
                name: "",
                question_id: "",
            },

            form: {
                name: "",
            }
        }
    }


    componentDidMount = () => {
        this.getValuesFirst();

        if(!this.props.isMulti){
            this.setState({
                form: {
                    name: this.props.value[0][0] != undefined ? this.props.value[0][0].name : "",
                }
            })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.formatValues !== nextProps.formatValues) {
            this.getValuesFirst(nextProps);
        }
    }

    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data]
        })
    }


    getValuesFirst = (nextProps) => {
        if (nextProps != undefined) {
            const data = nextProps != undefined ? nextProps.formatValues.data : []
            let values = data.filter(value => {
                let result = true
                nextProps.value[0].forEach((data) => {
                    if (data.value == value.value) {
                        result = false
                    }

                })
                return result;
            })

            this.setState({
                data: values,
                dataAdd: nextProps.value[0]
            }, () => this.props.handleChange(this.state.dataAdd, this.props.question))
        }

    }


    getValues = () => {
        const data = this.props.formatValues != undefined ? this.props.formatValues.data : this.state.data

        let values = data.filter(value => {
            let result = true
            this.state.dataAdd.forEach((data) => {
                if (data.value == value.value) {
                    result = false
                }

            })
            return result;
        })

        this.setState({
            data: values,

        }, () => this.props.handleChange(this.state.dataAdd, this.props.question))
    }


    removeList = (index) => {
        var arr = this.state.dataAdd
        var el = arr[index].value
        this.setState({
            dataAdd: [...arr.filter(item => item.value != el)]
        }, () => this.getValues())

        /* this.props.handleChange(arr.filter(item => item != null)) */
    }


    updateDataAdd = (data) => {
        this.setState({
            dataAdd: [...this.state.dataAdd, data],
            showOptions: false,
            form: {
                name: "",
            }

        }, () => this.getValues());

        console.log(data)

        this.props.handleChange(data)
    }

    addAll = () => {
        const data = this.props.formatValues != undefined ? this.props.formatValues.data : this.state.data

        this.setState({
            dataAdd: [...data],
        }, () => this.getValues())
    }


    selectValue = (user) => {
        this.setState({
            form: {
                ...this.state.form,
                name: user.name
            },

            showOptions: false,
        })

        this.props.handleChange(user, this.props.question)
    }


    HandleClickFormat = () => {
        fetch(`/formatos/add_option`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formFormat), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false });
                this.clearValuesFormat();
                alert("asdasdasdasd")
            });
    }

    HandleChange = (e) => {
        this.setState({
            formFormat: {
                ...this.state.formFormat,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangeAutoComplete = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from, question_id) => {
        if (from == "new") {
            this.setState({
                modal: true,
                formFormat: {
                    ...this.state.formFormat,
                    question_id: question_id,
                }
            })
        } else {
            this.setState({ modal: false })
            this.clearValuesFormat();
        }
    }

    clearValuesFormat = () => {
        this.setState({
            formFormat: {
                name: "",
                question_id: "",
            }
        })
    }


    HandleClickFormat = () => {
        fetch(`/formatos/add_option`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formFormat), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false });
                this.clearValuesFormat();
                this.updateData(data.register)
            });
    }

    render() {
        const styleSpan = {
            background: "#299ef9",
            padding: "2px 10px 5px 10px",
            color: "#fff",
            marginRight: "5px"
        }

        const styleIcon = {
            cursor: "pointer"
        }

        const styleList = {
            fontSize: "17px",
            color: "#299ef9",
            cursor: "pointer"
        }

        const IconList = this.props.classIconList != undefined ? `${this.props.classIconList} mr-2` : "mr-2"

        const IconCancel = {
            fontSize: "16px",
            color: "#1aa9fb",
            cursor: "pointer",
        }

        const InputStyle = {
            width: "88%",
            display: "initial",
        }

        return (
            <React.Fragment>

                {(this.state.modal && this.props.question.id == this.state.formFormat.question_id) && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Añadir respuesta"}

                        //form props
                        formValues={this.state.formFormat}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClickFormat}
                    />
                )}


                {this.props.isMulti ? (
                    <React.Fragment>
                        <div>

                            <a
                                onClick={() => this.toogle("new", this.props.question.id)}
                                href="javascript:void(0)"
                                className="link-line float-right mr-2"
                            >
                                Crear opción
                            </a>

                            <a
                                href="javascript:void(0)"
                                className="link-line float-right mr-2"
                                onClick={() => this.addAll()}
                            >
                                Seleccionar todos
                            </a>
                        </div>

                        <div className="input-custom input-container gm-input-custom">
                            <div className="gm-autocomplete-options">
                                {this.state.dataAdd.map((option, index) => (
                                    <div style={styleSpan}>{option.name}<i className="fas fa-times ml-1" onClick={() => this.removeList(index)} style={styleIcon}></i></div>
                                ))}

                                <input
                                    type="text"
                                    name="name"
                                    className="input-copy"
                                    autocomplete="off"
                                    value={this.state.form.name}
                                    onChange={(e) => this.handleChangeAutoComplete(e)}
                                    onClick={() => this.setState({ showOptions: true })}
                                    placeholder={this.props.selectCustomAutocomplete.label}
                                />
                            </div>
                            <div>
                                <i className={`fas ${this.state.showOptions ? "fa-times" : "fa-sort-down"} float-right`} onClick={() => this.setState({ showOptions: (!this.state.showOptions) })} style={IconCancel}></i>


                            </div>

                        </div>

                        {this.state.showOptions && (
                            <div className="card mt-3 gm-autocomplete">
                                <div className="card-body p-2">
                                    {this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).length >= 1 ? (
                                        this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).map((option, index) => (
                                            <p key={index} style={styleList} onClick={() => this.updateDataAdd(option)}>{option.name}</p>
                                        ))
                                    ) : (
                                            <h5 className="text-center">No hay registros con los criterios de búsqueda</h5>
                                        )}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <input
                                type="text"
                                name="name"
                                className="form form-control"
                                autocomplete="off"
                                value={this.state.form.name}
                                onChange={(e) => this.handleChangeAutoComplete(e)}
                                onClick={() => this.setState({ showOptions: (!this.state.showOptions) })}
                                placeholder={this.props.selectCustomAutocomplete.label}
                                style={InputStyle}
                            />

                            <a
                                onClick={() => this.toogle("new", this.props.question.id)}
                                href="javascript:void(0)"
                                className="link-line float-right mr-2"
                            >
                                Crear opción
                            </a>

                            {this.state.showOptions && (
                                <div className="card mt-3">
                                    <div className="card-body p-2">
                                        {this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).length >= 1 ? (
                                            this.state.data.filter(value => value.name != null).filter(value => value.name.toString().toLowerCase().includes(this.state.form.name.toString().toLowerCase())).map((option, index) => (
                                                <p key={index} style={styleList} onClick={() => this.selectValue(option)}> {/*<i className={`${IconList}`} style={styleIcon}></i>*/}{option.name}</p>
                                            ))
                                        ) : (
                                                <h5 className="text-center">No hay registros con los criterios de búsqueda</h5>
                                            )}
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}

                {/*<select
                        name="survey_answer_id"
                        className="form form-control"
                        style={{ width: "95%", display: "initial" }}
                        onChange={(e) => this.props.setValueSelect(e, this.props.question)}
                        disabled={this.props.survey.state}
                        data-id={this.props.question.id}
                        data-mandatory={this.props.question.is_mandatory}
                        data-active={this.props.question.is_active}
                        data-type={this.props.question.question_type}
                        value={this.props.formUpdateRepy1.value[0]}
                    >
                        <option value="">Seleccione una opcion</option>
                        {this.props.formatValues != undefined ? (
                            this.state.data.map((name, index) => (
                                <option value={name.id}>{name.name}</option>
                            ))) : (
                                <option value=""></option>
                            )}
                    </select>


                    <i className="fas fa-plus icon-add-format" onClick={() => this.toogle("new", this.props.question.id)}></i>*/}
            </React.Fragment>
        );
    }
}

export default FileTypeFormat;
