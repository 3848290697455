import React from "react";
import Chart from "react-google-charts";

class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();


    }
    componentDidMount() {
        console.log(this.chartReference); // returns a Chart.js instance reference
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="pie-header-line">
                        <div className="datos">
                            {this.props.dataLine.datag.map((dn, index) => (
                                <div key={index} className={"column-" + dn[0]}>
                                    <p>{dn[0]}</p>
                                    <p>{dn[1]}</p>
                                </div>
                            )
                            )}

                        </div>
                    </div>

                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={this.props.dataLine.datag} options={{
                            title: "TENDENCIA DE EVENTOS",
                            hAxis: {
                                title: 'Mes',
                            }, vAxis: {
                                title: 'Cantidad',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />


                </div>





            </React.Fragment>
        );
    }
}

export default LineChart;
