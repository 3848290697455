import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Ckeditor from '../../formatos/Surveys/Ckeditor'
import CKEditor from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-build-classic';

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const editorConfiguration = {
            language: 'es',
            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        };
        

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    {/*
                                    <textarea
                                        name="comment"
                                        value={this.props.formValues.comment}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        rows="6"
                                        placeholder="Comentario"
                                    /> 
                                    */}

                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={this.props.formValues.comment}
                                        config={ editorConfiguration }
                                        
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            this.props.handleChangeCkaditor(data)
                                        }}
                                    />
                                </div>






                                {this.props.showConfig && (
                                    <React.Fragment>

                                        <div className="col-md-12 mt-3">
                                            <label>Enviar a:</label>
                                            <input
                                                type="hidden"
                                                name="user_ids"
                                                value={this.props.selectedOptionComment.user_ids}
                                            />

                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                autoFocus={false}
                                                className={`link-form`}
                                                classNamePrefix="select"
                                                name="user_ids"
                                                onChange={this.props.handleChangeAutocompleteMulti}
                                                options={this.props.users}
                                                defaultValue={[
                                                    this.props.editValuesUsers[0],
                                                    this.props.editValuesUsers[1],
                                                    this.props.editValuesUsers[2],
                                                    this.props.editValuesUsers[3],
                                                    this.props.editValuesUsers[4],
                                                    this.props.editValuesUsers[5],
                                                    this.props.editValuesUsers[6],
                                                    this.props.editValuesUsers[7],
                                                    this.props.editValuesUsers[8],
                                                    this.props.editValuesUsers[9],
                                                    this.props.editValuesUsers[10],
                                                    this.props.editValuesUsers[11]
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-12 text-right mt-2">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" onChange={(e) => this.props.HandleChangeCheckBox(e)} className="custom-control-input" id={`customSwitch`} checked={this.props.formValues.send_register} />
                                                <label className="custom-control-label" htmlFor={`customSwitch`}>¿Enviar registro?</label>
                                            </div>
                                        </div>

                                        {this.props.formValues.send_register && (
                                            <div className="col-md-12 mt-3 mb-3">
                                                <input
                                                    type="hidden"
                                                    name="format_pdf_id"
                                                    value={this.props.selectedOptionPdfs.format_pdf_id}
                                                />
                                                <label>PDFs disponibles</label>
                                                <Select
                                                    onChange={this.props.handleChangeAutocompletePdfs}
                                                    options={this.props.pdfs}
                                                    autoFocus={false}
                                                    className={`link-form`}
                                                    value={this.props.selectedOptionPdfs}
                                                />
                                            </div>
                                        )}


                                        <div className="col-md-12 text-right mt-2">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" onChange={(e) => this.props.HandleChangeCheckBoxEmail(e)} className="custom-control-input" id={`customSwitchEmails`} checked={this.props.show_external_email} />
                                                <label className="custom-control-label" htmlFor={`customSwitchEmails`}>¿Añadir emails externos?</label>
                                            </div>
                                        </div>

                                        {this.props.show_external_email && (
                                            <div className="col-md-12 mt-3">
                                                <textarea
                                                    name="external_emails"
                                                    value={this.props.formValues.external_emails}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                    rows="6"
                                                    placeholder="Emails externos"
                                                />
                                            </div>
                                        )}

                                    </React.Fragment>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt != undefined ? this.props.nameBnt : "Crear"}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;