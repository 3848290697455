import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;

        this.state = {
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            has_process: false,
            is_clone: false,
            editInputs: false,
            isLoaded: false,
            id: "",

            formCreate: {
                name: "",
                document_type_id: "",
                proceso_id: "",
                code: "",
                attached: {},
                is_attached: false,
                user_create_id: "",
                user_review_id: "",
                user_approve_id: "",
                validity: "",
                due_date: "",
                version: "",
                position_ids: [],
                document_parent_id: "",
                tenant_id: "",
                count: "",
            },

            selectedOptionUserCreate: {
                user_create_id: "",
                label: "Usuario que crea"
            },

            selectedOptionUserReview: {
                user_review_id: "",
                label: "Usuario que revisa"
            },

            selectedOptionUserApprove: {
                user_approve_id: "",
                label: "Usuario que aprueba"
            },

            selectedOptionDocumentType: {
                document_type_id: "",
                label: "Tipo de documento"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionMulti: {
                position_ids: [],
                label: "Cargos que pueden acceder",
            },

            editValues: [],
            nameFile: "",
            sizeFile: 0
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues()
        }
    }

    validationForm = () => {
        if (this.state.has_process) {
            if (this.state.formCreate.user_create_id != "" &&
                this.state.formCreate.user_review_id != "" &&
                this.state.formCreate.user_approve_id != "" &&
                this.state.formCreate.version != "" &&
                this.state.formCreate.proceso_id != "" &&
                this.state.formCreate.name != "" &&
                this.state.formCreate.due_date != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            } else {
                this.setState({ ErrorValues: false })
                return false
            }
        } else {
            if (this.state.formCreate.user_create_id != "" &&
                this.state.formCreate.user_review_id != "" &&
                this.state.formCreate.user_approve_id != "" &&
                this.state.formCreate.version != "" &&
                this.state.formCreate.name != "" &&
                this.state.formCreate.due_date != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            } else {
                this.setState({ ErrorValues: false })
                return false
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/document_management/documents/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,
            is_clone: false,
            editInputs: false,

            formCreate: {
                name: "",
                document_type_id: "",
                proceso_id: "",
                code: "",
                attached: {},
                is_attached: false,
                user_create_id: "",
                user_review_id: "",
                user_approve_id: "",
                validity: "",
                due_date: "",
                version: "",
                position_ids: [],
                document_parent_id: "",
                tenant_id: "",
                count: "",
            },

            selectedOptionUserCreate: {
                user_create_id: "",
                label: "Usuario que crea"
            },

            selectedOptionUserReview: {
                user_review_id: "",
                label: "Usuario que revisa"
            },

            selectedOptionUserApprove: {
                user_approve_id: "",
                label: "Usuario que aprueba"
            },

            selectedOptionDocumentType: {
                document_type_id: "",
                label: "Tipo de documento"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionMulti: {
                position_ids: [],
                label: "Cargos que pueden acceder",
            },

            editValues: [],
            nameFile: "",
            sizeFile: 0
        })
    }

    HandleClick = () => {
        if (this.validationForm()) {
            this.setState({ isLoaded: true })
            const formData = new FormData();
            formData.append("name", this.state.formCreate.name)
            formData.append("document_type_id", this.state.formCreate.document_type_id)
            formData.append("proceso_id", this.state.formCreate.proceso_id)
            formData.append("is_attached", this.state.formCreate.is_attached)
            formData.append("attached", this.state.formCreate.attached)
            formData.append("user_create_id", this.state.formCreate.user_create_id)
            formData.append("user_review_id", this.state.formCreate.user_review_id)
            formData.append("user_approve_id", this.state.formCreate.user_approve_id)
            formData.append("validity", this.state.formCreate.validity)
            formData.append("due_date", this.state.formCreate.due_date)
            formData.append("version", this.state.formCreate.version)
            formData.append("code", this.state.formCreate.code)
            formData.append("count", this.state.formCreate.count)
            formData.append("position_ids", this.state.formCreate.position_ids)
            formData.append("document_parent_id", this.state.formCreate.document_parent_id)

            console.log("this.state.formCreate.position_idsthis.state.formCreate.position_ids", this.state.formCreate.position_ids)
            if (!this.state.modeEdit)
                fetch(`/document_management/documents`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        if (this.props.view == "document_view" || this.props.view == "create_view") {
                            window.location.replace(`/document_management/documents_create`);
                        }

                        //this.props.updateData(data.register);
                        //this.clearValues();
                    });
            else {
                fetch(`/document_management/documents/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        if (this.props.view == "document_view" || this.props.view == "create_view") {
                            window.location.replace(`/document_management/documents_create`);
                        }

                        //this.props.updateItem(data.register);
                        //this.clearValues();
                    });
            }
        }
    }

    HandleClickGenerateVersion = (id) => {
        fetch(`/document_management/generate_version_document/${id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.updateData(data.register);
            });
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    edit = (document) => {

        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modeEdit: true,
            modal: true,
            is_clone: false,
            id: document.id,
            editInputs: document.edit,
            has_process: document.document_type.has_process,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                document_type_id: document.document_type_id,
                proceso_id: document.proceso_id,
                code: document.code,
                is_attached: document.is_attached,
                user_create_id: document.user_create_id,
                user_review_id: document.user_review_id,
                user_approve_id: document.user_approve_id,
                due_date: document.due_date,
                is_last: document.is_last,
                version: document.version,
                position_ids: arrayIds,
                count: document.count,
            },


            selectedOptionUserCreate: {
                user_create_id: `${document.user_create != null ? document.user_create.id : ""}`,
                label: `${document.user_create != null ? `${document.user_create.first_name} ${document.user_create.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionUserReview: {
                user_review_id: `${document.user_review != null ? document.user_review.id : ""}`,
                label: `${document.user_review != null ? `${document.user_review.first_name} ${document.user_review.first_last_name}` : "Cargo que revisa"}`
            },

            selectedOptionUserApprove: {
                user_approve_id: `${document.user_approve != null ? document.user_approve.id : ""}`,
                label: `${document.user_approve != null ? `${document.user_approve.first_name} ${document.user_approve.first_last_name}` : "Cargo que aprueba"}`,
            },

            selectedOptionDocumentType: {
                document_type_id: `${document.document_type != null ? document.document_type.id : ""}`,
                label: `${document.document_type != null ? document.document_type.name : "Tipo de documento"}`,
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }


    handleChangeChecked = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                is_attached: isChecked
            }
        })
    }

    handleChangeFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formCreate: {
                    ...this.state.formCreate,
                    attached: file
                },

                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    handleChangeAutocompleteUserCreate = selectedOptionUserCreate => {
        this.setState({
            selectedOptionUserCreate,
            formCreate: {
                ...this.state.formCreate,
                user_create_id: selectedOptionUserCreate.value
            }
        });
    };

    handleChangeAutocompleteUserReview = selectedOptionUserReview => {
        this.setState({
            selectedOptionUserReview,
            formCreate: {
                ...this.state.formCreate,
                user_review_id: selectedOptionUserReview.value
            }
        });
    };

    handleChangeAutocompleteUserApprove = selectedOptionUserApprove => {
        this.setState({
            selectedOptionUserApprove,
            formCreate: {
                ...this.state.formCreate,
                user_approve_id: selectedOptionUserApprove.value
            }
        });
    };

    handleChangeAutocompleteDocumentType = selectedOptionDocumentType => {
        this.setState({
            selectedOptionDocumentType,
            has_process: selectedOptionDocumentType.has_process,
            formCreate: {
                ...this.state.formCreate,
                document_type_id: selectedOptionDocumentType.value
            }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProceso => {
        this.setState({
            selectedOptionProceso,
            formCreate: {
                ...this.state.formCreate,
                proceso_id: selectedOptionProceso.value
            }
        });
    };

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        selectedOptionMulti.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                position_ids: array
            }
        })
    }

    generateClone = (document) => {

        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modal: true,
            modeEdit: false,
            is_clone: true,
            editInputs: document.edit,
            has_process: document.document_type.has_process,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                document_type_id: document.document_type_id,
                proceso_id: document.proceso_id,
                code: document.code,
                is_attached: document.is_attached,
                user_create_id: document.user_create_id,
                user_review_id: document.user_review_id,
                user_approve_id: document.user_approve_id,
                validity: document.validity,
                due_date: document.due_date,
                version: (Number(document.version) + 1),
                position_ids: arrayIds,
                document_parent_id: document.id,
                is_last: true,
                tenant_id: document.tenant_id,
                count: document.count,
            },

            selectedOptionUserCreate: {
                user_create_id: `${document.user_create != null ? document.user_create.id : ""}`,
                label: `${document.user_create != null ? `${document.user_create.first_name} ${document.user_create.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionUserReview: {
                user_review_id: `${document.user_review != null ? document.user_review.id : ""}`,
                label: `${document.user_review != null ? `${document.user_review.first_name} ${document.user_review.first_last_name}` : "Cargo que revisa"}`
            },

            selectedOptionUserApprove: {
                user_approve_id: `${document.user_approve != null ? document.user_approve.id : ""}`,
                label: `${document.user_approve != null ? `${document.user_approve.first_name} ${document.user_approve.first_last_name}` : "Cargo que aprueba"}`,
            },

            selectedOptionDocumentType: {
                document_type_id: `${document.document_type != null ? document.document_type.id : ""}`,
                label: `${document.document_type != null ? document.document_type.name : "Tipo de documento"}`,
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })

        console.log("arrayarrayarray", array);
    }


    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar documento" : "Crear documento"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}
                        is_clone={this.state.is_clone}

                        //form props
                        formValues={this.state.formCreate}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        errorValues={this.state.ErrorValues}
                        handleChangeChecked={this.handleChangeChecked}
                        isLoaded={this.state.isLoaded}
                        has_process={this.state.has_process}
                        editInputs={this.state.editInputs}

                        //file
                        nameFile={this.state.nameFile}
                        handleChangeFile={this.handleChangeFile}

                        //select values

                        //select UserCreate
                        selectedOptionUserCreate={this.state.selectedOptionUserCreate}
                        handleChangeAutocompleteUserCreate={this.handleChangeAutocompleteUserCreate}

                        //select UserReview
                        selectedOptionUserReview={this.state.selectedOptionUserReview}
                        handleChangeAutocompleteUserReview={this.handleChangeAutocompleteUserReview}

                        //select UserApprove
                        selectedOptionUserApprove={this.state.selectedOptionUserApprove}
                        handleChangeAutocompleteUserApprove={this.handleChangeAutocompleteUserApprove}

                        //select DocumentType
                        selectedOptionDocumentType={this.state.selectedOptionDocumentType}
                        handleChangeAutocompleteDocumentType={this.handleChangeAutocompleteDocumentType}

                        //select Proceso
                        selectedOptionProceso={this.state.selectedOptionProceso}
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}

                        //select multi
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}

                        positions={this.props.positions}
                        document_types={this.props.document_types}
                        procesos={this.props.procesos}
                        users={this.props.users}
                        editValues={this.state.editValues}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Versión</th>
                                    <th>Código</th>
                                    <th>Nombre del documento</th>
                                    <th>Fecha de vencimiento</th>
                                    <th>Tipo de documento</th>
                                    <th>Proceso</th>
                                    <th>Crea</th>
                                    <th>Revisa</th>
                                    <th>Aprueba</th>
                                    {(this.props.estados.delete || this.props.estados.edit) && (
                                        <th className="text-center">Acciones</th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(document => (
                                        <tr key={document.id}>
                                            <td>{document.version}</td>
                                            <td>{document.code}</td>
                                            <td>{document.name}</td>
                                            <td>{document.due_date}</td>
                                            <td>{document.document_type ? document.document_type.name : ""}</td>
                                            <td>{document.proceso ? document.proceso.name : ""}</td>
                                            <td>{document.user_create.first_name}</td>
                                            <td>{document.user_review.first_name}</td>
                                            <td>{document.user_approve.first_name}</td>

                                            {(this.props.estados.edit || this.props.estados.delete) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">

                                                                {(this.props.estados.edit && document.state != "aprobado") &&(
                                                                    <a
                                                                        href={`/document_management/documents/${document.id}`}
                                                                        data-turbolinks="false"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Gestionar
                                                                    </a>
                                                                )}
                                                                {( document.state == "aprobado") &&(
                                                                    <a
                                                                        href={`/document_management/documents/${document.id}`}
                                                                        data-turbolinks="false"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Ver documento
                                                                    </a>
                                                                )}

                                                                {this.props.estados.edit && (
                                                                    <button
                                                                        onClick={() => this.edit(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.generate && (
                                                                    <button
                                                                        onClick={() => this.generateClone(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Generar versión
                                                                    </button>
                                                                )}

                                                                {this.props.estados.delete && (
                                                                    <button
                                                                        onClick={() => this.delete(document.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <td colSpan="13" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    )}
                            </tbody>
                        </table>

                        {this.props.data.length >= 10 && (
                            <div className="row">

                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>

                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        )}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Index;