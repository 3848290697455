import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ModalShow from './NumeralsModal'
import Loading from '../../../GeneralComponents/LoadingComponen'


class NewTable extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataNumerals: [],
            modal: false,
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    openModal = (proceso) => {
        this.setState({
            dataNumerals: proceso.numeral_parents,
            modal: true,
        })
    }

    toogle = () => {
        this.setState({ modal: false, dataNumerals: []})
    }


    getColor = (calification) =>{
        if(calification < 60){
            return "color-red" 
        }else if(calification >= 60 && calification < 80 ){
            return "color-orange" 
        }else if(calification >= 80){
            return "color-green" 
        }
    }



    getScore = (value)=>{
        
        
        if (value.length > 0)
        {
            return <div> <span className={this.getColor(value[0].score)}> { value[0].score }</span> <span>{value[0].date_score}</span></div>;

        }
        else{
            return  <span className="no-data-small">Sin calificación</span>;
        }
    }
   


    render() {

        if (this.props.isLoaded) {
            return (
             <Loading/>
            );
          }

        return (
            <React.Fragment>

                <ModalShow
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}    
                    title={"Numerales Asociados"}
                    dataNumerals={this.state.dataNumerals}
                />
                
                <div className="content main-card mb-3 card procesos-score-index">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th style={{ width: "60%" }}>Nombre</th>
                                
                                <th style={{ width: "20%" }}>Última Calificación</th>
                                <th style={{ width: "10%" }}></th>
                                <th style={{ width: "10%" }}></th>
                                
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(proceso => (
                                    <tr key={proceso.id}>
                                        <td>{proceso.name}</td>
                                        {}
                                <td className="last-score"> {this.getScore(proceso.proceso_scores)}  </td>

                                        <td className="text-center" >
                                            <a href="javascript:void(0);" onClick={() => this.openModal(proceso)} className="link-line">
                                                 {proceso.numeral_parents.length} Requisitos
                                             </a>
                                        </td>
                                        <td className="text-center">
                                        {proceso.numeral_parents.length > 0 ? (
                                       <a
                                            href={`/settings/procesos/${proceso.id}`}
                                            className="link-line"
                                            data-turbolinks="false">
                                              Cumplimiento
                                        </a>
                                        ) : ""}
                                        </td>

                                    </tr>
                            ))
                                ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default NewTable;
