import React, { Component } from 'react';
import Show from '../components/settings/Procesos/Show'
import Turbolinks from "turbolinks";
import WebpackerReact from 'webpacker-react';
import IndexHistory from '../components/tasks/tasksCard/IndexHistory';
import FormFilter from '../components/tasks/tasksCard/FormFilter';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import { data } from 'jquery';

class HistoryTask extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            showFilter: false,
            filtering: false,
            isFiltering: false,
            urlFilter: "",

            formFilter: {
                fechaDesde: "",
                fechaHasta: "",
                name: "",
                state: "",
                description: "",
                gestor_user_id: "",
                user_id: "",
                task_label_id: [],
                name_module: [],
                user_owner_id: "",
                count_task: "",
                state_task: "",
                procesos_ids: [],
            },
        
            //paginate 

            activePage: 1,
            countPage: 9,
            task_total: 0,
        }
    }

    componentDidMount(){
        console.log("this.child.current", this.child.current)
        this.loadData();
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })

    }

    loadDataFilter = () => {
        fetch(this.state.urlFilter, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data.reverse(),
                isLoaded: false
            });
        });
    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                user_owner: task.user_owner,
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
              }
            }
            return item;
          })
        });
    }


    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    handleClickShowFilter = () => {
        this.setState({
            showFilter: (this.state.showFilter == false ? true : false),
            filtering: true
        })
    }

    handleClickCancel = () => {
        this.setState({ showFilter: false, filtering: false })
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_history/${this.props.from}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            task_total: data.task_total,
            isLoaded: false
          });
        });
    }

    // paginate 
    // &filtering=${this.state.filtering}&fechaDesde=${this.state.formFilter.fechaDesde}&fechaHasta=${this.state.formFilter.fechaHasta}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&user_id=${this.state.formFilter.user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber, isLoaded: true });
        fetch(`/task/get_history/${this.props.from}?page=${pageNumber}&filter=${this.state.countPage}&filtering=${this.state.isFiltering}&fechaDesde=${this.state.formFilter.fechaDesde}&fechaHasta=${this.state.formFilter.fechaHasta}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&gestor_user_id=${this.state.formFilter.gestor_user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}&user_id=${this.state.formFilter.user_id}&state=${this.state.formFilter.state}&state_task=${this.state.formFilter.state_task}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({ 
                data: data.data,
                task_total: data.task_total,
                isLoaded: false,
            });
        });
    }

    updateDataFilter = (data) => {
        this.setState({
            data: data.data,
            task_total: data.task_total,
            isLoaded: false
        })
    }

    updateStates = (state_is_filtering, state_is_loaded, url) => {
        this.setState({
            urlFilter: url,
            isFiltering: state_is_filtering,
            isLoaded: state_is_loaded,
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    getStateFilter = (state) => {
        this.setState({
            formFilter: {
                ...state.formFilter
            },
        })
    }
    

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Historial de Tareas"}
                    subTitle={"Gestión de tareas"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={false}
                    showFilter={true}
                    taskHistory={this.props.from}
                />

                {this.state.showFilter && (
                    <FormFilter 
                        handleClickFilter={this.HandleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        handleChangeFilter={this.handleChangeFilter}
                        from={this.props.from}
                        history={true}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateStates={this.updateStates}
                        updateDataFilter={this.updateDataFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                        getStateFilter={this.getStateFilter}
                    />
                )}

                <IndexHistory
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    user={this.props.user} 
                    users={this.props.users}
                    from={this.props.from}
                    estados={this.props.estados}
                    procesos={this.props.procesos}

                    //test data 

                    updateData={this.updateData}
                    updateItem={this.updateItem}

                    //pagination 

                    task_total={this.state.task_total}
                    countPage={this.state.countPage}
                    handlePageChange={this.handlePageChange}
                    activePage={this.state.activePage}
                    handleClickFilter={this.loadDataFilter}
                />

            </React.Fragment>
        );
    }
}


export default HistoryTask;
WebpackerReact.setup({ HistoryTask });

