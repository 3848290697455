import React, { Component } from 'react';
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            ErrorValues: true,
            formValues: {
                name: "",
                due_date: "",
                user_owner_id: "",
                url_source: this.props.url_source != undefined ? this.props.url_source : "",
                task_source_id: this.props.task_source_id != undefined ? this.props.task_source_id : "",
                name_module: this.props.nameModule,
                module_task_id: this.props.numeral_child_score
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            users: []
        }
    }

    clearValues = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                name: "",
                due_date: "",
                user_owner_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
        })
    }

    validationForm = () => {
        if (this.state.formValues.name != "" &&  
            this.state.formValues.due_date != "" && 
            this.state.formValues.user_owner_id != "" 
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch(`/task/tasks`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadTasks();
                this.props.closeTask();
                this.messageSuccess(data)
                this.clearValues();
            });
        }
    }

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: e.target.value,
                name: e.target.name == "name" ? (e.target.value.length + 0 > 100 ? this.state.formValues.name : e.target.value) : this.state.formValues.name,
            }
        });
    }

    componentDidMount(){
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
          selectedOption,
          formValues: {
             ...this.state.formValues,
             user_owner_id: selectedOption.value
          }
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-4" style={{ width: "289px" }}>
                        <input
                            type="hidden"
                            name="user_owner_id"
                            value={this.state.selectedOption.user_owner_id}
                        />

                        <Select
                            onChange={this.handleChangeAutocomplete}
                            options={this.state.users}
                            autoFocus={false}
                            className={`link-form ${this.state.ErrorValues == false && this.state.formValues.user_owner_id == "" ? "error-class" : ""}`}
                            value={this.state.selectedOption}
                        />
                    </div>

                    <div className="col-md-12 mb-4">
                        <input 
                            type="date"
                            name="due_date"
                            onChange={this.HandleChange}
                            value={this.state.formValues.due_date}
                            className={`form form-control ${this.state.ErrorValues == false && this.state.formValues.due_date == "" ? "error-class" : ""}`}
                        />
                    </div>

                    <div className="col-md-12">
                        <textarea 
                            className={`form form-control style-text ${this.state.ErrorValues == false && this.state.formValues.name == "" ? "error-class" : ""}`}
                            name="name"
                            onChange={this.HandleChange}
                            value={this.state.formValues.name}
                            placeholder="Introduzca un título para esta tarjeta..."
                        >

                        </textarea>

                        <span className="span-count-form" style={{ color: (this.state.formValues.name.length + 0 >= 200 ? "red" : "#3f6ad8") }}>
                            {200 - this.state.formValues.name.length}
                        </span>

                    </div>

                    {this.state.formValues.name.length >= 200 && (
                        <div className="col-md-12 mt-3">
                            <div className="alert alert-primary" role="alert">
                                <b>Si quieres ampliar la información debes hacerlo en la descripción</b>
                            </div>
                        </div>
                    )}
                    

                    <div className="col-md-12 text-center">
                        <hr/>

                        <a
                            className="btn-shadow btn btn-success mr-3"
                            style={{ color: "#ffff"}}
                            onClick={() => this.HandleClick()}
                        >
                            Agregar 
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormCreate;