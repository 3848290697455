import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { array } from 'prop-types';

class TableIndexWeb extends Component {

    getColor = (number) => {
        if(number == 0 || number >= 12){
            return ["#FF0000","white"]
        }else if(number >= 6 && number <= 11){
            return ["#FFFF00","black"]
        }else if(number == 5 || number < 5){
            return ["#008000","white"]
        }
    }




    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body" style={{ display: "grid" }}>
                        <div className="content-table">
                            <table className="table-riegos mb-5">
                                <tr>
                                    {this.props.showLinks && (
                                        <td rowspan="2" className="blue-td pl-2 pr-2 risk-table-number" ></td>
                                    )}
                                    <td rowspan="2" className="blue-td text-center" style={{ fontWeight: "bold"}}>N</td>
                                    <td rowspan="2" className="blue-td table-descripcion td-horizontal text-center" style={{ fontWeight: "bold"}}>Descripción del riesgo, problema o peligro</td>
                                    <td rowspan="2" className="blue-td table-actividad td-horizontal text-center" style={{ fontWeight: "bold"}}>Actividad/ Procedimiento</td>
                                    <td rowspan="2" className="blue-td table-area td-horizontal text-center" style={{ fontWeight: "bold"}}>Servicio / Área</td>
                                    <td className="no-bb td-vertical rg-color-riesgo"><div className="vertical">Riesgo</div></td>
                                    <td className="no-bb td-vertical rg-color-riesgo"><div className="vertical">Problema</div></td>
                                    <td className="no-bb td-vertical rg-color-claro"><div className="vertical">Peligro</div></td>
                                    <td className="rg-criterio text-center" style={{ fontWeight: "bold"}}>Criterio</td>
                                    <td className="td-vertical rg-vinotinto"><div className="vertical">Altamente probable</div></td>
                                    <td className="td-vertical rg-red"><div className="vertical">probable</div></td>
                                    <td className="td-vertical rg-orange"><div className="vertical">Ocasional</div></td>
                                    <td className="td-vertical rg-green"><div className="vertical">Remota</div></td>
                                    <td className="td-vertical "><div className="vertical">Improbable</div></td>
                                    <td className="td-vertical gr-gray-dark"><div className="vertical"></div></td>
                                    <td className="td-vertical "><div className="vertical">Insignificante</div></td>
                                    <td className="td-vertical rg-green"><div className="vertical">Baja gravedad</div></td>
                                    <td className="td-vertical rg-orange"><div className="vertical">Grave</div></td>
                                    <td className="td-vertical rg-red"><div className="vertical">Muy grave</div></td>
                                    <td className="td-vertical rg-vinotinto"><div className="vertical">Catastrofico</div></td>
                                    <td className="td-vertical gr-gray-dark"><div className="vertical"></div></td>
                                    <td className="td-vertical" colspan="2"><div className="vertical" style={{ fontWeight: "bold"}}>Score</div></td>
                                    <td className="td-vertical rg-blue-dark"> <div className="vertical">Paciente</div></td>
                                    <td className="td-vertical rg-blue-dark"><div className="vertical">Personal de la salud</div></td>
                                    <td className="td-vertical rg-blue-dark"><div className="vertical">Infraestructura</div></td>
                                    <td className="td-vertical rg-blue-dark"><div className="vertical">Visitante</div></td>
                                </tr>
                                <tr>
                                    <td className="td-gray td-gray no-bt"></td>
                                    <td className="td-gray no-bt"></td>
                                    <td className="td-gray no-bt"></td>
                                    <td className="td-gray no-bt"></td>
                                    <td className="td-gray td-number">5</td>
                                    <td className="td-gray td-number">4</td>
                                    <td className="td-gray td-number">3</td>
                                    <td className="td-gray td-number">2</td>
                                    <td className="td-gray td-number">1</td>
                                    <td className="td-gray td-number"></td>
                                    <td className="td-gray td-number">1</td>
                                    <td className="td-gray td-number">2</td>
                                    <td className="td-gray td-number">3</td>
                                    <td className="td-gray td-number">4</td>
                                    <td className="td-gray td-number">5</td>
                                    <td className="td-gray td-number"></td>
                                    <td colspan="2" className="td-gray"></td>
                                    <td className="td-gray"></td>
                                    <td className="td-gray"></td>
                                    <td className="td-gray"></td>
                                    <td className="td-gray" ></td>
                                </tr>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map((risk, index) => (
                                            <tr key={risk.id}>
                                                    {this.props.showLinks && (
                                                        <td className="text-center pt-2" style={{ width: "10px" }}>
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <a
                                                                                href={`/risk/matrices/${this.props.risk_matrix.id}/risks/${risk.id}`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Gestionar
                                                                            </a>
                                                                        )}

                                                                        {true && (
                                                                            <button
                                                                                onClick={() => this.props.edit(risk)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                            </button>
                                                                        )}

                                                                        {true && (
                                                                            <button
                                                                                onClick={() => this.props.delete(risk.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </button>
                                                                        )}

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                <td className="text-center pt-2">{index + 1}</td>
                                                <td className="text-left pl-2 pt-2"><p>{risk.description}</p></td>
                                                <td className="text-left pl-2">{risk.activity.name}</td>
                                                <td className="text-left pl-2">{risk.specific_area.name}</td>
                                                <td className="text-left pl-2" colSpan="3">{risk.risk_type}</td>
                                                <td className="text-left pl-2" >{risk.criterio ? risk.criterio.name : ""}</td>
                                                <td className="text-center"><b>{risk.probability == 5 ? risk.probability : ""}</b></td>
                                                <td className="text-center"><b>{risk.probability == 4 ? risk.probability : ""}</b></td>
                                                <td className="text-center"><b>{risk.probability == 3 ? risk.probability : ""}</b></td>
                                                <td className="text-center"><b>{risk.probability == 2 ? risk.probability : ""}</b></td>
                                                <td className="text-center"><b>{risk.probability == 1 ? risk.probability : ""}</b></td>
                                                <td className="text-center gr-gray-dark">{risk.probability}</td>
                                                <td className="text-center"><b>{risk.impact == 1 ? risk.impact : ""}</b></td>
                                                <td className="text-center"><b>{risk.impact == 2 ? risk.impact : ""}</b></td>
                                                <td className="text-center"><b>{risk.impact == 3 ? risk.impact : ""}</b></td>
                                                <td className="text-center"><b>{risk.impact == 4 ? risk.impact : ""}</b></td>
                                                <td className="text-center"><b>{risk.impact == 5 ? risk.impact : ""}</b></td>
                                                <td className="text-center gr-gray-dark">{risk.impact}</td>
                                                <td className="text-center" colSpan="2" style={{ backgroundColor: this.getColor(risk.score)[0], color: this.getColor(risk.score)[1] }}>{risk.score}</td>
                                                <td className="text-center">{risk.possible_involvement_array.includes("Paciente") ? <i className="fas fa-check"></i> : ""}</td>
                                                <td className="text-center">{risk.possible_involvement_array.includes("Personal de salud") ? <i className="fas fa-check"></i> : ""}</td>
                                                <td className="text-center">{risk.possible_involvement_array.includes("Infraestructura") ? <i className="fas fa-check"></i> : ""}</td>
                                                <td className="text-center">{risk.possible_involvement_array.includes("Visitantes") ? <i className="fas fa-check"></i> : ""}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan="27" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;