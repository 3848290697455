import React, { Component } from 'react';
import TableVentas from '../components/indicators/indicator/ShowVentas'
import TableReacaudo from '../components/indicators/indicator/showRecaudo'
import TableTickets from '../components/indicators/indicator/showTickets'
import TableGeneralIndicator from '../components/indicators/indicator/showGeneralndicator'
import ShowTableIndicator from '../components/indicators/indicator/showTableIndicator'
import Table from '../components/indicators/indicator/Show'
import TableHorizontal from '../components/indicators/indicator/ShowHorizontal'

import WebpackerReact from 'webpacker-react';

class IndicatorShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            dataTask: [],
            dataNew: [],
            isLoaded: true,
            isLoadedTask: true,
            dataLine: [],
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadDataTask();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    updateStateLoadTask = (state) => {
        this.setState({ isLoadedTask: state })
    }

    loadData = () => {
        fetch(`/indicator/get_indicators_show/${this.props.indicator}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                dataNew: data.dataNew,
                isLoaded: false
            });
        });
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.indicator_all.module_task_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataTask: data.data,
                isLoadedTask: false,
            });
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            dataTask: [...this.state.dataTask, data],
        })
    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem", task)
        this.setState({
            dataTask: this.state.dataTask.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                //user_owner: {document: "1037660678", document_type: "Cédula de"},
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
              }
            }
            return item;
          })
        });
    }

    
    generateQuestions = () => {
        if(this.props.indicator_all.have_survey){
            window.open(`${this.props.domain}/formatos/format_categories/${this.props.indicator_all.survey.format_category_id}/formats/${this.props.indicator_all.survey.format_id}/surveys/${this.props.indicator_all.survey.id}`, '_self');
        }else{
            window.open(`${this.props.domain}/formatos/generate_questions/${this.props.category_indicator.format.id}/${this.props.indicator}`, '_self');
        }
    }

    render() {

        console.log(this.props.indicator_all.indicator_type_module)
        if (this.props.indicator_all.indicator_type != "GER" && this.props.indicator_all.indicator_type != "SEGV"){
            return (
                <React.Fragment>
                    <TableGeneralIndicator
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}

                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        }
        if (this.props.indicator_all.indicator_type == "SEGV" ){
            return (
                <React.Fragment>
                    <ShowTableIndicator
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}
                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        }
        if (this.props.indicator_all.indicator_type == "GER" && this.props.indicator_all.indicator_type_module == "two_variables" ){
            return (
                <React.Fragment>
                    <TableGeneralIndicator
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}
                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        }
        if (this.props.indicator_all.indicator_type == "GER" && this.props.indicator_all.indicator_type_module == "gerencia_ventas"){
            return (
                <React.Fragment>
                    <TableVentas
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}
                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        }
        if (this.props.indicator_all.indicator_type == "GER" && this.props.indicator_all.indicator_type_module == "gerencia_recaudo"){
            return (
                <React.Fragment>
                    <TableReacaudo
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}
                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        }
        if ( this.props.indicator_all.indicator_type == "GER" && this.props.indicator_all.indicator_type_module == "gerencia_tickets"){
            return (
                <React.Fragment>
                    <TableTickets
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}
                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}
                        
                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                        
                    />
                </React.Fragment>
            );
        }
        console.log(this.props.indicator_all.table_type)
        if (this.props.indicator_all.table_type == "Vertical") {
            return (
                <React.Fragment>
                    <Table
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        users={this.props.users}
                        position={this.props.position}
                        
                        // indicadores task

                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        } else {

            return (
                <React.Fragment>
                    <div>entre aqui 2</div>
                    <TableHorizontal
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        dataNew={this.state.dataNew}
                        isLoaded={this.state.isLoaded}
                        position={this.props.position}
                        // indicadores task
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoadTask={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
                        indicator_all={this.props.indicator_all}

                        updateData={this.updateData}
                        updateItem={this.updateItem}
                        task_source={this.props.task_source}
                        category_indicator={this.props.category_indicator}
                        indicator={this.props.indicator}
                        url={this.props.url}
                        generateQuestions={this.generateQuestions}
                    />
                </React.Fragment>
            );
        }



    }
}


export default IndicatorShow;
WebpackerReact.setup({ IndicatorShow });
