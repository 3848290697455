import React, { Component } from 'react';

class FormFilter extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <div className="col-md-12 mb-3">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-4">
                                        <label>Descripción</label>
                                        <input
                                            type="text"
                                            name="description"
                                            value={this.props.formValuesFilter.description}
                                            onChange={this.props.HandleChangeFilter}
                                            placeholder="Descripción"
                                            className={`form form-control`}
                                        />
                                    </div>  

                                    <div className="col-md-4">
                                        <label>Tipo de estrategia</label>
                                        <select
                                            className="form form-control"
                                            name="strategic_objective_id"
                                            value={this.props.formValuesFilter.strategic_objective_id}
                                            onChange={this.props.HandleChangeFilter}
                                        >
                                            <option>Seleccione</option>
                                            
                                                <option value="Estrategias Defensivas">Estrategias Defensivas</option>
                                                <option value="Estrategias Ofensivas">Estrategias Ofensivas</option>
                                                <option value="Estrategias Supervivencias">Estrategias Supervivencias</option>
                                                <option value="Estrategias Adaptativas">Estrategias Adaptativas</option>
                                        
                                        </select>
                                    </div>

                                </div>

                            </div>

                            <div className="card-footer">
                                <button 
                                    type="submit"
                                    className="btn btn-primary mr-2"
                                    onClick={() => this.props.HandleClickFilter()}
                                >
                                    Aplicar
                                </button>

                                <label
                                    className="btn btn-light mb-0"
                                    onClick={() => this.props.changeState(!this.props.showFilter)}
                                >
                                    Cancelar
                                </label>
                            </div>
                        </div>
                    </form> 
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;
