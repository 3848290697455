import React, { Component } from 'react';
import Dropzone from "react-dropzone";
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import { Progress } from 'reactstrap';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import loading from '../../../GeneralComponents/LoadingComponen'
import LoadingComponen from '../../../GeneralComponents/LoadingComponen';




class Filter extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;

    }





    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string" || question.question_type == "answer_text" || question.question_type == "code_format" || question.question_type == "url") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="answer_string"
                        className="form form-control imput-options-disabled mb-1"
                        placeholder={"Texto de respuesta breve"}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                        onChange={this.props.HandleChangeRepy}

                    />
                    <select
                        name="answer_string_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.props.formUpdateRepySelect[question.id] != undefined ? this.props.formUpdateRepySelect[question.id].value : ""}
                        onChange={this.props.HandleChangeRepySelect}
                    >
                    
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>                     
                    </select>
                </React.Fragment>
            )
        }
        else if (question.question_type == "answer_integer" || question.question_type == "answer_formula") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        placeholder={"Texto numerico"}
                        name="answer_integer"
                        /*  onBlur={this.updateRepy} */
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                        onChange={this.props.HandleChangeRepy}
                    />
                      <select
                        name="answer_integer_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.props.formUpdateRepySelect[question.id] != undefined ? this.props.formUpdateRepySelect[question.id].value : ""}
                        onChange={this.props.HandleChangeRepySelect}
                    >
                    
                        <option value="contiene">Igual</option>
                        <option value="diferente">Diferente</option>   
                        <option value="mayor">Mayor</option>  
                        <option value="mayorigual">Mayor e igual</option>  
                        <option value="menor">Menor</option>        
                        <option value="menorigual">Menor e igual</option>
                        <option value="entre">Entre: ejemplo (10 y 20)</option>    
                                                 
                    </select>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select" || question.question_type == "answer_radio" || question.question_type == "answer_checkbox") {

            return (
                <React.Fragment>
                <select
                    name="answer_select"
                    className="form form-control mb-1"
                    data-id={question.id}
                    data-type={question.question_type}
                    data-mandatory={question.is_mandatory}
                    data-active={question.is_active}
                    value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                    onChange={this.props.HandleChangeRepy}
                >
                    <option value="">Seleccione una opcion</option>
                    {question.options.map((name, index) => (
                        <option value={name}>{name}</option>
                    ))}
                </select>
                        <select
                        name="answer_select_type"
                        className="form form-control mb-1"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.props.formUpdateRepySelect[question.id] != undefined ? this.props.formUpdateRepySelect[question.id].value : ""}
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="">Opcion de filtrado</option>
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                    </select>
                    </React.Fragment>
            )
        } else if (question.question_type == "answer_datetime") {
            const format = 'h:mm a';
            const now = moment().hour(0).minute(0);

            return (
                <React.Fragment>
                    
                        <input
                            type="datetime-local"
                            className="form form-control imput-options-disabled width-48"
                            name="answer_time"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            data-date={"desde"}
                            value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                            onChange={this.props.HandleChangeRepyDate1}

                        />
                        <input
                            type="datetime-local"
                            className="form form-control imput-options-disabled width-48"
                            name="answer_time"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            data-date={"hasta"}
                            value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                            onChange={this.props.HandleChangeRepyDate2}

                        />
                    

                </React.Fragment>
            )


        } else if (question.question_type == "answer_date") {
            return (
                <React.Fragment>
                    
                        <input
                            type="date"
                            className="form form-control imput-options-disabled mb-1"
                            name="answer_date"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            data-date={"desde"}
                            value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                            onChange={this.props.HandleChangeRepyDate1}
                        />
                        <input
                            type="date"
                            className="form form-control imput-options-disabled"
                            name="answer_date"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            data-date={"hasta"}
                            value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : ""}
                            onChange={this.props.HandleChangeRepyDate2}
                        />
                   
                </React.Fragment >
            )
        } else if (question.question_type == "answer_user_id") {
            return (
                <React.Fragment>
                    <select
                        name="answer_select"
                        className="form form-control"
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : null}
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opcion</option>
                        {this.props.users.map((name, index) => (
                            <option value={name.id}>{name.name}</option>
                        ))}
                    </select>
                </React.Fragment>
            )

        } else if (question.question_type == "position_id") {
            return (
                <React.Fragment>
                    <select
                        name="position_id"
                        className="form form-control"
                        onChange={(e) => this.setValueSelect(e, question)}
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : null}
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opcion</option>
                        {this.props.positions.map((name, index) => (
                            <option value={name.id}>{name.name}</option>
                        ))}
                    </select>
                </React.Fragment>
            )

        } else if (question.question_type == "proceso_id") {
            return (
                <React.Fragment>
                    <select
                        name="proceso_id"
                        className="form form-control"
                        onChange={(e) => this.setValueSelect(e, question)}
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : null}
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opcion</option>
                        {this.props.procesos.map((name, index) => (
                            <option value={name.id}>{name.name}</option>
                        ))}
                    </select>
                </React.Fragment>
            )

        }
        else if (question.question_type == "format_id") {
            return (
                <React.Fragment>
                    <select
                        name="proceso_id"
                        className="form form-control"
                        onChange={(e) => this.setValueSelect(e, question)}
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={this.props.formUpdateRepy[question.id] != undefined ? this.props.formUpdateRepy[question.id].value : null}
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opcion</option>
                        {this.props.formValues[question.id] != undefined && (
                            this.props.formValues[question.id].data.map((name, index) => (
                                <option value={name.value}>{name.name}</option>
                            )))}
                    </select>
                </React.Fragment>
            )

        }
        else {
            return <p></p>
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getTypeQuestion(this.props.question)}
            </React.Fragment>
        );
    }
}

export default Filter;
