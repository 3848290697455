import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Select from "react-select";
import FroalaEditor from 'react-froala-wysiwyg';

class FormCreate extends Component {

    constructor(props) {
        super(props)
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const config = {
            language: 'es',
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg modal-lg-980 form-label-dark" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-6 mb-3">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.name == "" ? "error-class" : ""}`}
                                    />
                                </div>



                                <div className="col-md-3 mb-3">
                                    <label>Prefijo</label>
                                    <input
                                        type="text"
                                        name="prefix"
                                        value={this.props.formValues.prefix}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <div className={`custom-control custom-switch mb-3`}>
                                        <span className="mb-1" style={{ display: "block", position: "relative", left: "-40px" }}><b>¿Es formato?</b></span>
                                        <input type="checkbox" onChange={(e) => this.props.handleChangeChecked("is_format", e)} className="custom-control-input" id={`format`} value={!this.props.formValues.is_format} checked={this.props.formValues.is_format} />
                                        <label className="custom-control-label" htmlFor={`format`}></label>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div className="custom-control custom-switch">
                                        <span className="mb-1" style={{ display: "block", position: "relative", left: "-40px" }}><b>¿Pertenece a un proceso?</b></span>
                                        <input type="checkbox" onChange={(e) => this.props.handleChangeChecked("has_process", e)} className="custom-control-input" id={`customHeader`} value={!this.props.formValues.has_process} checked={this.props.formValues.has_process} />
                                        <label className="custom-control-label" htmlFor={`customHeader`}></label>
                                    </div>

                                    {/*<input type="checkbox" className="mr-2" onChange={this.props.handleChangeChecked} value={!this.props.formValues.has_process} checked={this.props.formValues.has_process} />*/}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Código</label>
                                    <input
                                        type="text"
                                        name="code"
                                        value={this.props.formValues.code}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                    />
                                </div>

                                <div className="col-md-12 ">
                                    <p>Diseña el código como quieres que se genere cuando creas un documento, donde pongas <b> {`{PRO}`} </b> ira el proceso, donde pongas <b>{`{TIPO}`}</b> ira el prefijo de tipo de documento y donde pongas <b>{`{CONS}`}</b> ira el consecutivo</p>
                                    <p>Ejemplo: si ponemos el código de la siguiente manera <b>{`{PRO}-{TIPO}-{CONS}`} </b> y tenemos un proceso Compras en el cual el prefijo es <b>CO</b>un tipo de documento en el que el prefijo es <b>INS</b> el resultado final sera: <b>CO-INS-1</b></p>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        type="hidden"
                                        name="content_format_id"
                                        value={this.props.selectedOption.content_format_id}
                                    />
                                    <label>Formato </label>
                                    <p>Seleccion el formato creado en FORDATA si no tiene dejalo vacio.</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete}
                                        options={this.props.formats}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOption}
                                    />
                                </div>


                                <div className="col-md-12 mb-4">
                                    <div className={`custom-control custom-switch mb-3`}>
                                        <input type="checkbox" onChange={(e) => this.props.handleChangeChecked("has_header", e)} className="custom-control-input" id={`header`} value={!this.props.formValues.has_header} checked={this.props.formValues.has_header} />
                                        <label className="custom-control-label" htmlFor={`header`}>¿Tiene encabezado?</label>
                                    </div>

                                    {this.props.formValues.has_header && (
                                        <React.Fragment>
                                            <label>Diseña el encabezado </label>
                                            <p>Donde pongas {`{NOMBRE}`} ira el nombre del documento y donde pongas {`{VERSION}`} ira la versión del documento.</p>

                                            <FroalaEditor
                                                model={this.props.formValues.header}
                                                config={config}
                                                onModelChange={(e) => this.props.handleChangeFroala("header", e)}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="col-md-12 mb-3">
                                    <div className={`custom-control custom-switch mb-3`}>
                                        <input type="checkbox" onChange={(e) => this.props.handleChangeChecked("has_footer", e)} className="custom-control-input" id={`footer`} value={!this.props.formValues.has_footer} checked={this.props.formValues.has_footer} />
                                        <label className="custom-control-label" htmlFor={`footer`}>¿Tiene el pie de página?</label>
                                    </div>

                                    {this.props.formValues.has_footer && (
                                        <React.Fragment>
                                            <label>Diseña el pie de página </label>
                                            <p>Donde pongas {`{NOMBRE}`} ira el nombre del documento y donde pongas {`{VERSION}`} ira la versión del documento.</p>
                                            <FroalaEditor
                                                model={this.props.formValues.footer}
                                                config={config}
                                                onModelChange={(e) => this.props.handleChangeFroala("footer", e)}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>

                                {!this.props.errorValues && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info"
                                onClick={this.props.submitForm}
                                style={{ color: "#ffff" }}
                            >
                                {this.props.nameBnt}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;