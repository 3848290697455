import React, { Component } from 'react';
import Dropzone from "react-dropzone";
import ModalShow from './ShowCount'

class Table extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            nameFile: "",
            nameFileUpdate: "",
            id: "",
            tenant_id: "",
            sizeFile: 0,
            isLoaded: true,
            modal: false,
            tenant: {},

            form: {
                contract: {},
                plan_id: "",
                app_type: "",
                base_model_id: "",
            }
        }
    }

    clearValues = () => {
        this.setState({
            form: {
                contract: {},
                plan_id: "",
                app_type: "",
                base_model_id: "",
            },

            nameFile: "",
            nameFileUpdate: "",
            id: "",
            tenant_id: "",
        })
    }

    handleFileUpdate = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    contract: file
                },
  
                nameFileUpdate: file.path,
                sizeFile: file.size
            })
        ));
    };

    handleChangeUpdate = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            },  
        })
    };


    HandleClick = () => {
        const formData = new FormData();
        formData.append("contract", this.state.form.contract)
        formData.append("plan_id", this.state.form.plan_id)
        formData.append("app_type", this.state.form.app_type)
        formData.append("base_model_id", this.state.form.base_model_id)

        fetch(`/settings/update_file/${this.state.tenant_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateItem(data.register)
            this.clearValues();
        });
    }

    update_state = (e, tenant_id) => {
        fetch(`/settings/update_state_tenant/${tenant_id}/${e.target.checked}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateItem(data.register)
        });
    }

    edit = (tenant) => {
        this.setState({
            form: {
                ...this.state.form,
                plan_id: tenant.plan_id,
                app_type: tenant.app_type,
                base_model_id: tenant.base_model_id,
            },

            tenant_id: tenant.id
        })
    }


    toogle = (from, register) => {
        if (from == "new") {
            this.setState({ modal: true, tenant: register })
        } else {
            this.setState({ modal: false, tenant: {} })
        }
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <ModalShow
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        tenant={this.state.tenant}
                        title={"Información de la empresa"}
                    />
                )}
                
                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th style={{ width: "14%"}}>Nombre</th>
                                    <th style={{ width: "6%"}}>Usuarios</th>
                                    <th style={{ width: "6%"}}>Registros</th>
                                    <th style={{ width: "6%"}}>Estado</th>
                                    <th style={{ width: "35%"}}>Contrato</th>
                                    <th style={{ width: "14%" }}>Plan</th>
                                    <th style={{ width: "14%" }}>Base de empresa</th>
                                    <th style={{ width: "10%" }}>Tipo de app</th>
                                    <th style={{ width: "10%" }}>Es Base?</th>
                                    <th style={{ width: "6%" }}>Registros</th>
                                    <td style={{ width: "8%" }}>Acciones</td>
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(tenant => (
                                        <tr key={tenant.id} style={{ backgroundColor: tenant.is_base ? "#d0e6f3" : "" }} >
                                            <td>{tenant.id}</td>
                                            <td className="text-left">{tenant.company_name}</td>
                                            <td className="text-center">{tenant.users}</td>
                                            <td className="text-center">{tenant.records_count}</td>
                                            <td className="text-center">{tenant.state_records}</td>
                                            <td>
                                                {(this.state.tenant_id == tenant.id) ? (
                                                    <Dropzone onDrop={(files) => this.handleFileUpdate(files)}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <div
                                                                {...getRootProps({
                                                                    className: 'dropzone',
                                                                })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>{`${(this.state.tenant_id == tenant.id && this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                ) : (
                                                    <React.Fragment>
                                                        {tenant.contract.url != undefined ? (
                                                            <a target="_blank" href={tenant.contract.url}>{tenant.file_name}</a>
                                                        ) : (
                                                            <p>No hay archivo, edite y añade el contrato</p>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </td>

                                            <td>
                                                {this.state.tenant_id == tenant.id ? (
                                                    <select 
                                                        name="plan_id"
                                                        value={this.state.form.plan_id}
                                                        onChange={this.handleChangeUpdate}
                                                        className="form form-control"
                                                    >
                                                        <option value="">Seleccione un plan</option>
                                                        {this.props.plans.map(plan => (
                                                            <option value={plan.id}>{plan.name}</option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <p>{tenant.plan.name}</p>
                                                )}
                                            </td>


                                            <td>
                                                {this.state.tenant_id == tenant.id ? (
                                                    <select 
                                                        name="base_model_id"
                                                        value={this.state.form.base_model_id}
                                                        onChange={this.handleChangeUpdate}
                                                        className="form form-control"
                                                    >
                                                        <option value="">Seleccione una base</option>
                                                        {this.props.base_models.map(base_model => (
                                                            <option value={base_model.id}>{base_model.name}</option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <p>{tenant.base_model != undefined ? tenant.base_model.name : "" }</p>
                                                )}
                                            </td>

                                            <td className="text-left">
                                                {this.state.tenant_id == tenant.id ? (
                                                    <select 
                                                        name="app_type"
                                                        value={this.state.form.app_type}
                                                        onChange={this.handleChangeUpdate}
                                                        className="form form-control"
                                                    >
                                                        <option value="">Seleccione un tipo</option>
                                                        <option value="Mejora">Mejora</option>
                                                        <option value="Salud">Salud</option>
                                                    </select>
                                                ) : (
                                                    <p>{tenant.app_type}</p>
                                                )}
                                            </td>

                                            <td className="text-center">
                                                <input 
                                                    type="checkbox"
                                                    onChange={(e) => this.update_state(e, tenant.id)}
                                                    value={tenant.is_base}
                                                    checked={tenant.is_base}
                                                />
                                            </td>

                                            <td className="text-center">
                                                <button
                                                    className="btn-shadow btn btn-primary"
                                                    onClick={() => this.toogle("new", tenant)}
                                                >
                                                    Ver
                                                </button>
                                            </td>
                                            <td>
                                                {this.state.tenant_id == tenant.id ? (
                                                    <React.Fragment>
                                                        <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i> 
                                                        <i className="fas fa-times-circle update-false" onClick={() => this.setState({ tenant_id: ""})}></i>
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>
                            
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {true && (  
                                                                    <a
                                                                        href={`/settings/tenants/${tenant.id}/modules`}
                                                                        data-turbolinks="false"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar módulos
                                                                    </a>
                                                                )}

                                                                {true && (  
                                                                    <a
                                                                        data-turbolinks="false"
                                                                        className="dropdown-item"
                                                                        onClick={() => this.edit(tenant)}
                                                                    >
                                                                        Editar
                                                                    </a>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;
