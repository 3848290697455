import React, { Component } from 'react';

class TaskList extends Component {
    render() {
        return (
            <React.Fragment>
                               <div className="card-hover-shadow-2x mb-3 card">
                                    <div className="card-header">Tasks List</div>
                                    <div className="scroll-area-sm">
                                        <div className="scrollbar-container">
                                            <div className="p-2">
                                                <ul className="todo-list-wrapper list-group list-group-flush">
                                                    <li className="list-group-item">
                                                        <div className="todo-indicator bg-focus"></div>
                                                        <div className="widget-content p-0">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left mr-2">
                                                                    <div className="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox1" className="custom-control-input"/><label className="custom-control-label"
                                                                                                                                                                                                       for="exampleCustomCheckbox1">&nbsp;</label>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content-left">
                                                                    <div className="widget-heading">Task with dropdown menu</div>
                                                                    <div className="widget-subheading">
                                                                        <div>By Johnny
                                                                            <div className="badge badge-pill badge-info ml-2">NEW</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content-right widget-content-actions">
                                                                    <div className="d-inline-block dropdown">
                                                                        <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                                                            <i className="fa fa-ellipsis-h"></i>
                                                                        </button>
                                                                        <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right"><h6 tabindex="-1" className="dropdown-header">Header</h6>
                                                                            <button type="button" disabled="" tabindex="-1" className="disabled dropdown-item">Action</button>
                                                                            <button type="button" tabindex="0" className="dropdown-item">Another Action</button>
                                                                            <div tabindex="-1" className="dropdown-divider"></div>
                                                                            <button type="button" tabindex="0" className="dropdown-item">Another Action</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="todo-indicator bg-info"></div>
                                                        <div className="widget-content p-0">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left mr-2">
                                                                    <div className="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox2" className="custom-control-input"/><label className="custom-control-label"
                                                                                                                                                                                                       for="exampleCustomCheckbox2">&nbsp;</label>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content-left mr-3">
                                                                    <div className="widget-content-left">
                                                                        <img width="42" className="rounded" src="assets/images/avatars/1.jpg" alt=""/>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content-left">
                                                                    <div className="widget-heading">Go grocery shopping</div>
                                                                    <div className="widget-subheading">A short description for this todo item</div>
                                                                </div>
                                                                <div className="widget-content-right widget-content-actions">
                                                                    <button className="border-0 btn-transition btn btn-outline-success">
                                                                        <i className="fa fa-check"></i>
                                                                    </button>
                                                                    <button className="border-0 btn-transition btn btn-outline-danger">
                                                                        <i className="fa fa-trash-alt"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block text-right card-footer">
                                        <button className="btn-shadow btn btn-success">Agregar Tarea</button>
                                    </div>
                                </div>
            </React.Fragment>
        );
    }
}

export default TaskList;
