import React, { Component } from 'react';
import IndexImportFile from '../ImportFile/Index';

class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="row">
                        <div className="col-md-10">
                            <p className="mt-3 pl-4 mb-0" >{this.props.data.length} Registros</p>    
                        </div>

                        <div className="col-md-2 mt-3">
                            <IndexImportFile
                                urlUploadFile={"/settings/import_position_file"}
                                urlDowloadTemplate={"/settings/download_position_template.xls"}
                                updateData={this.props.updateNewData}
                            />
                        </div>
                    </div>

                    <div className="card-body">

                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th style={{ width: "23%" }}>Nombre</th>
                                    <th style={{ width: "23%" }}>Descripción</th>
                                    <th>Procesos</th>
                                    {this.props.estados.uploadFile && (
                                        <th>Adjuntos</th>
                                    )}
                                    {(this.props.estados.editar == true || this.props.estados.eliminar == true) && (
                                        <th className="text-center"></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(position => (
                                        <tr key={position.id}>
                                            <td>{position.name}</td>
                                            <td>{position.description}</td>
                                            <td>
                                                <span
                                                    className="link-line"
                                                    onClick={() => position.procesos.length >= 1 ? this.props.toogleShow("new", position) : ""}
                                                >
                                                    {position.procesos.length} procesos
                                                </span>
                                            </td>

                                            {this.props.estados.uploadFile && (
                                                <td>
                                                    <p><span className="link-line" onClick={() => this.props.addFiles(position)}>{position.general_files.length} Archivos adjuntos </span></p>
                                                </td>
                                            )}

                                            <td className="text-right" style={{ width: "10px" }}>
                                                {position.name != "Sistema Básico" && (
                                                    <React.Fragment>
                                                        {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {this.props.estados.editar && (
                                                                            <a
                                                                                href={`/settings/positions/${position.id}/edit`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                            </a>
                                                                        )}

                                                                        {(this.props.estados.eliminar && position.name != "Administrador Sistema") && (
                                                                            <button
                                                                                onClick={() => this.props.delete(position.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </td>

                                        </tr>
                                    ))

                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;
