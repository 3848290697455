import React, { Component } from 'react';

class Index extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.length >= 1 ? (
                    this.props.data.map((proceso, index) => (
                        <div className={`card mb-3`} key={proceso.id}>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-3 mb-2">
                                        <b>Nombre</b>
                                        <p>{proceso.name}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Descripción</b>
                                        <p>{proceso.description}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Tipo de proceso</b>
                                        <p>{proceso.proceso_type}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Adjuntos</b>
                                        <p>{this.props.estados.uploadFile == true ? <span className="link-line" onClick={() => this.props.addFiles(proceso)}>{proceso.general_files.length} Archivos adjuntos </span> : <span className="link-line">Ver archivos </span>   }</p>
                                    </div>

                                </div>
                            </div>

                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                <div className="card-footer">
                                    {this.props.estados.editar && (
                                        <button
                                            onClick={() => this.props.edit(proceso)}
                                            className="dropdown-item"
                                        >
                                            Editar
                                        </button>
                                    )}

                                    {this.props.estados.eliminar && (  
                                        <button
                                            onClick={() => this.props.delete(proceso.id)}
                                            className="dropdown-item"
                                        >
                                            Eliminar
                                        </button>
                                    )}
                                </div>
                            )}

                        </div>
                    ))

                ) : (
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>No hay registros</h4>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;