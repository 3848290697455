import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import { isMobile } from 'react-device-detect';
import IndexMobile from './Mobile/Index';
import IndexWeb from './TableIndexWeb';
import Loading from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            id: "",

            form: {
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                users_ids: []
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionUsers: {
                users_ids: "",
                label: "Responsable"
            },

            selectedOptionProcesos: {
                proceso_id: "",
                label: "Proceso"
            },

            users: [],
            procesos: []
        }
    }

    clearValues = () => {
        this.setState({
            ErrorValues: true,
            modeEdit: false,
            
            form: {
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                users_ids: []
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionProcesos: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionUsers: {
                users_ids: "",
                label: "Participantes"
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.proceso_id != "" &&  
                this.state.form.date_of_analysis != "" && 
                this.state.form.responsable_id != ""
            ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    //selects Config

    componentDidMount = () => {
        this.configSelect();
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                responsable_id: selectedOption.value
            }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProcesos => {
        this.setState({
            selectedOptionProcesos,
            form: {
                ...this.state.form,
                proceso_id: selectedOptionProcesos.value
            }
        });
    };

    handleChangeAutocompleteUsers = selectedOptionUsers => {
        let array = []

        selectedOptionUsers.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                users_ids: array
            }
        })
    }

    configSelect = () => {
        let array = []
        let arrayProcesos = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
            procesos: arrayProcesos
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                date_of_analysis: e.target.value
            }
        })
    }    

    HandleClick = () => {
        if(this.validationForm() == true){
            if (this.state.modeEdit == true) {
                fetch(`/failure/efects/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false})
                    this.props.updateItem(data.register)
                    this.messageSuccess(data);
                });
            }else{
                fetch(`/failure/efects`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false})
                    this.props.updateData(data.register)
                    this.messageSuccess(data);
                });
            }
        }
    }

    edit = (risk_matrice) => {
        this.setState({
            modal: true,
            modeEdit: true, 
            id: risk_matrice.id,

            form: {
                proceso_id: risk_matrice.proceso_id,
                date_of_analysis: risk_matrice.date_of_analysis,
                responsable_id: risk_matrice.responsable_id,
            },

            selectedOption: {
                responsable_id: risk_matrice.responsable_id,
                label: `${risk_matrice.responsable.first_name} ${risk_matrice.responsable.first_last_name}`
            },

            selectedOptionProcesos: {
                proceso_id: risk_matrice.proceso_id,
                label: `${risk_matrice.proceso}`
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/failure/efects/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"AMEF"}
                    subTitle={"Herramientas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Editar amef" : "Nuevo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}
                        
                        //select values
                        
                        //select user
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        users={this.state.users}

                        //select proceso
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}
                        selectedOptionProcesos={this.state.selectedOptionProcesos}
                        procesos={this.state.procesos}

                        //select participantes
                        handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
                        selectedOptionUsers={this.state.selectedOptionUsers}
                        
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        {isMobile ? (
                            <IndexMobile 
                                data={this.props.data} 
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                                addFiles={this.addFiles}
                            />
                        ) : (
                            <IndexWeb 
                                data={this.props.data} 
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                                addFiles={this.addFiles}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;