import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loading from '../../../GeneralComponents/LoadingComponen'

class ShowModal extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            factor: {},
            checkedItems: new Map(),
            maxCount: 0,
            isLoaded: true,
        }
    }

    componentDidMount = () => {
        this.loadDataFactors();
    }
    
    loadDataFactors = () => {
        fetch(`/cause/get_factor_show/${this.props.subClase}/${this.props.cause_analysis.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let map = new Map();

            data.subFactorItems.forEach(element => {
                data.factor.sub_factor_items.forEach(items => {
                  if (element.id == items.id) {
                    map.set(items.id.toString(), true)
                  }
                });
            })    

            this.setState({
                data: data.data,
                factor: data.factor,
                checkedItems: map,
                maxCount: data.factor.sub_factor_items.length,
                isLoaded: false
            });
        });
    }


    handleChange = e => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked),
        }));

        if (isChecked){

            fetch(`/cause/factor_sub_factor/${this.state.factor.id}/${item}/${true}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                this.props.loadData();
                this.setState({
                    maxCount: this.state.maxCount + 1
                })
            });

        }else{

            fetch(`/cause/factor_sub_factor/${this.state.factor.id}/${item}/${false}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                this.props.loadData();
                this.setState({
                    maxCount: this.state.maxCount - 1
                })
            });
        }
    };


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}> {this.state.maxCount > 5 ? "" : <i className="app-menu__icon fa fa-user mr-2"></i>} {this.props.title}</ModalHeader>
                        <ModalBody>

                            <div className="row">
                                <div className="col-md-12">
                                    {!this.state.isLoaded ? (
                                        <React.Fragment>
                                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "31%" }}>{this.props.get_name(this.props.subClase)}</th>
                                                        <th>COMPONENTES</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.data.map(sub_factor => (
                                                        <React.Fragment>
                                                            <tr key={sub_factor.id} rowSpan={sub_factor.count}>
                                                                <td>
                                                                    <a href="/">{sub_factor.code}</a> {sub_factor.title}
                                                                </td>

                                                                {sub_factor.sub_factor_items.map(items => (
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            <td style={{ width: "100%" }}><a href="/">{items.code}</a> {items.description}</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox" 
                                                                                    checked={this.state.checkedItems.get(items.id.toString())}
                                                                                    value={items.id}
                                                                                    onChange={this.handleChange}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {this.state.maxCount > 5 && (
                                                <div className="alert alert-danger" role="alert">
                                                    <b>Solo puedes seleccionar 5</b>
                                                </div>
                                            )}

                                        </React.Fragment>
                                    ) : (
                                        <Loading />
                                    )}

                                </div>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <button 
                                className="btn btn-light mt-2" 
                                onClick={() => this.props.toggle()}
                                disabled={this.state.maxCount > 5}
                            >
                                Cerrar
                            </button>
                        </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ShowModal;

/*
rowSpan={sub_factor.count}
*/