import React, { Component } from 'react';
import Index from '../components/tasks/tasksCard/TaskIndexCard'
import WebpackerReact from 'webpacker-react';

class TaskIndexCard extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 
    

    loadData = () => {
        fetch(this.props.configTask.url_load, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false
        })

    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                //user_owner: {document: "1037660678", document_type: "Cédula de"},
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
              }
            }
            return item;
          })
        });
    }


    render() {
        return (
            <React.Fragment>
                <Index
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    module_name={this.props.module_name}
                    users={this.props.users}
                    user={this.props.user}

                    estados={this.props.estados}

                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    procesos={this.props.procesos}
                    config_task={this.props.configTask}
                    url_host={this.props.url_host}
                />
            </React.Fragment>
        );
    }
}


export default TaskIndexCard;
WebpackerReact.setup({ TaskIndexCard });
