import React, { Component } from 'react';
import IndexTrello from "../../taskTrello/index"
import ReactDOM from 'react-dom'
import TaskList from './TaskList'
import FormCreate from './FormCreate'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import ShowTaskEmail from './ShowTaskEmail'
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showForm: false,

            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
                url_source: this.props.url_source != undefined ? this.props.url_source : "",
                task_source_id: this.props.task_source_id != undefined ? this.props.task_source_id : "",
                module_task_id: this.props.module_task != undefined ? this.props.module_task : "",
                name_module: this.props.moduleName != undefined ? this.props.moduleName : "",
                task_label_ids: this.props.from != "TABLERO" ? [] : this.props.task_board.task_labels,
            },

            showColums: "Pendientes",

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            users: []
        }
    }

    componentDidMount() {
        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                user_owner_id: selectedOption.value
            }
        });
    };

    clearValues = () => {
        this.setState({
            showForm: false,
            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.module_task != undefined ? this.props.module_task : "",
                name_module: this.props.moduleName != undefined ? this.props.moduleName : "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
        })
    }

    HandleChange = (e) => {
        console.log(e.target.name)
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                name: e.target.name == "name" ? (e.target.value.length + 0 > 200 ? this.state.form.name : e.target.value) : this.state.form.name,
                module_task_id: this.props.module_task != undefined ? this.props.module_task : "",
                name_module: this.props.moduleName != undefined ? this.props.moduleName : "",
            }
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    render() {
        const dataFinish = this.props.data.filter(estado => estado.state == "made" || estado.state == (this.props.module_task != undefined ? "filed" : ""))
        return (
            <React.Fragment>
                {(this.props.task_id != "" && this.props.task_id != undefined) && (
                    <ShowTaskEmail
                        user={this.props.user}
                        users={this.props.users}
                        showOptions={true}
                        loadData={this.props.loadData}
                        estados={this.props.estados}
                        updateStateLoad={this.props.updateStateLoad}
                        updateItem={this.props.updateItem}
                        updateData={this.props.updateData}

                        //parametros 

                        task_id={this.props.task_id}
                        procesos={this.props.procesos}
                    />
                )}

                <div className="row" >
                    {isMobile && (
                        <div className="col-md-12 mb-3">
                            <select
                                onChange={(e) => this.setState({ showColums: e.target.value })}
                                className="form form-control"
                                value={this.state.showColums}
                            >
                                <option value="">Seleccione una columna</option>
                                <option value="Pendientes">Pendientes</option>
                                <option value="En proceso">En Proceso</option>
                                <option value="Finalizadas">Finalizadas</option>

                            </select>
                        </div>
                    )}


                 {/*    {isMobile && (
                        <div className="col-md-12">
                            <a href="whatsapp://send?abid=phonenumber&text=Hello%2C%20World!">Send Message</a>
                        </div>
                    )} */}

                    {(isMobile == false || this.state.showColums == "Pendientes") && (
                        <div className={`${isMobile ? "col-12 mb-5" : "col-md-4"}`}>
                            <div className="card card-list card-test-parent">
                                <div className="card-header">
                                    <div className="col-md-12 row p-0">
                                        <div className="col-md-7">
                                            Pendientes
                                        </div>

                                        <div className="col-md-5 pr-0 text-right">
                                            <span className="badge badge-light float-right">{this.props.data.filter(estado => estado.state == "create").length}</span>
                                            {this.props.isLoaded ? (
                                                <div className="loader-files"></div>
                                            ) : (
                                                    <React.Fragment>
                                                        {this.props.estados.crear == true ? <React.Fragment> {this.state.showForm == false ? <i className="fas fa-plus float-right icon-add mr-2" onClick={() => this.setState({ showForm: true })}></i> : <i className="fas fa-times float-right icon-add mr-2" onClick={() => this.setState({ showForm: false })}></i>} </React.Fragment> : ""}
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body p-0 scroll-area-lg scroll-tasks-parent">

                                    {this.state.showForm == true && (
                                        <FormCreate
                                            HandleChange={this.HandleChange}
                                            form={this.state.form}
                                            clearValues={this.clearValues}
                                            loadData={this.props.loadData}
                                            updateStateLoad={this.props.updateStateLoad}

                                            //select autocomplete
                                            handleChangeAutocomplete={this.handleChangeAutocomplete}
                                            selectedOption={this.state.selectedOption}
                                            users={this.state.users}

                                            //test data
                                            updateData={this.props.updateData}
                                        />
                                    )}

                                    {this.props.data.filter(estado => estado.state == "create").length >= 1 ? (
                                        this.props.data.filter(estado => estado.state == "create").map(task => (
                                            <TaskList
                                                task={task}
                                                user={this.props.user}
                                                users={this.props.users}
                                                showOptions={true}
                                                loadData={this.props.loadData}
                                                estados={this.props.estados}
                                                updateStateLoad={this.props.updateStateLoad}
                                                updateItem={this.props.updateItem}
                                                updateData={this.props.updateData}

                                                //parametros 

                                                task_id={this.props.task_id}
                                                procesos={this.props.procesos}
                                                messageSuccess={this.messageSuccess}

                                                isFiltering={this.props.isFiltering}
                                                handleClickFilter={this.props.handleClickFilter}
                                            />
                                        ))
                                    ) : (
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <h6>No hay tareas pendientes</h6>
                                                </div>
                                            </div>
                                        )}


                                </div>
                            </div>
                        </div>
                    )}

                    {(isMobile == false || this.state.showColums == "En proceso") && (
                        <div className={`${isMobile ? "col-12 mb-5" : "col-md-4"}`}>
                            <div className="card card-list card-test-parent">
                                <div className="card-header">
                                    <div className="col-md-12">
                                        En proceso
                                        <span className="badge badge-light float-right">{this.props.data.filter(estado => estado.state == "doing").length}</span>
                                    </div>
                                </div>
                                <div className="card-body p-0 scroll-area-lg scroll-tasks-parent">

                                    {this.props.data.filter(estado => estado.state == "doing").length >= 1 ? (
                                        this.props.data.filter(estado => estado.state == "doing").map(task => (
                                            <TaskList
                                                task={task}
                                                user={this.props.user}
                                                users={this.props.users}
                                                showOptions={true}
                                                loadData={this.props.loadData}
                                                estados={this.props.estados}
                                                updateStateLoad={this.props.updateStateLoad}
                                                updateItem={this.props.updateItem}
                                                updateData={this.props.updateData}

                                                //parametros 

                                                task_id={this.props.task_id}
                                                procesos={this.props.procesos}
                                                messageSuccess={this.messageSuccess}

                                                isFiltering={this.props.isFiltering}
                                                handleClickFilter={this.props.handleClickFilter}
                                            />
                                        ))
                                    ) : (
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <h6>No hay tareas en proceso</h6>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}

                    {(isMobile == false || this.state.showColums == "Finalizadas") && (
                        <div className={`${isMobile ? "col-12 mb-5" : "col-md-4"}`}>
                            <div className="card card-list card-test-parent">
                                <div className="card-header">
                                    <div className="col-md-12">
                                        Finalizadas
                                        <span className="badge badge-light float-right">{dataFinish.length}</span>
                                    </div>
                                </div>
                                <div className="card-body p-0 scroll-area-lg scroll-tasks-parent" >

                                    {dataFinish.length >= 1 ? (
                                        dataFinish.map(task => (
                                            <TaskList
                                                task={task}
                                                user={this.props.user}
                                                users={this.props.users}
                                                showOptions={true}
                                                loadData={this.props.loadData}
                                                estados={this.props.estados}
                                                updateStateLoad={this.props.updateStateLoad}
                                                updateItem={this.props.updateItem}
                                                updateData={this.props.updateData}

                                                //parametros 

                                                task_id={this.props.task_id}
                                                procesos={this.props.procesos}
                                                messageSuccess={this.messageSuccess}

                                                isFiltering={this.props.isFiltering}
                                                handleClickFilter={this.props.handleClickFilter}
                                            />
                                        ))
                                    ) : (
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <h6>No hay tareas finalizadas</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
//asdsasd