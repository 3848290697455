import React, { Component } from 'react';
import Table from '../components/Causes/CauseAnalysis/Index'
import WebpackerReact from 'webpacker-react';

class CauseAnalysisIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
            if (format.id === item.id) {
              return { ...item, 
                name: format.name,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/cause/get_cause_analysis`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    estados={this.props.estados}

                    users={this.props.users}
                    specific_areas={this.props.specific_areas}
                    tenant={this.props.tenant}
                    procesos={this.props.procesos}
                />
            </React.Fragment>
        );
    }
}


export default CauseAnalysisIndex;
WebpackerReact.setup({ CauseAnalysisIndex });
