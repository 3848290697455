import React, { Component } from 'react';
import Show from '../components/settings/Procesos/Show'
import WebpackerReact from 'webpacker-react';
import TaskAndComments from '../GeneralComponents/TaskAndComments'
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import ColumnChart from '../GeneralComponents/Charts/ColumnChartQuestion'
import Map from '../GeneralComponents/MapComponent'

class QuestionShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataTask: [],
            isLoaded: true,
        }
    }

    componentDidMount() {
        this.loadData();

    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    componentDidMount() {
        this.loadDataTask();
    }

    //add items
    updateData = (data) => {
        this.setState({
            dataTask: [...this.state.dataTask, data],
            isLoadedTask: false,
        })
    }

    //add update
    updateItem = task => {
        this.setState({
            dataTask: this.state.dataTask.map(item => {
                if (task.id === item.id) {
                    return {
                        ...item,
                        description: task.description,
                        due_date: task.due_date,
                        name: task.name,
                        state: task.state,
                        //user_owner: {document: "1037660678", document_type: "Cédula de"},
                        general_comments: task.general_comments,
                        general_files: task.general_files,
                        task_labels: task.task_labels,
                        users: task.task_labels,
                    }
                }
                return item;
            })
        });
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.module.module_task_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataTask: data.data,
                    isLoadedTask: false,
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={` Analisís: ${this.props.module.question.question}`}
                    subTitle={"Gesión de respuestas"}
                    titleNew={"Nuevo riesgo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />
                {this.props.module.question_type == "answer_radio" && (
                    <React.Fragment>
                        < div className="col-md-12 card">
                            <div className="col-md-12 pt-3 pb-3">
                                <div className="row">
                                    <div className="col-md-12" style={{ fontSize: "26px", textAlign: "center" }}>{this.props.module.format_name.toUpperCase()}<br /> ({this.props.module.question.question.toUpperCase()})</div>
                                    <div className="col-md-12" ><hr /></div>
                                    <div className="col-md-12 pb-3 text-center" style={{ fontSize: "24px" }}>CANTIDAD DE RESPUESTAS: {this.props.module.count_all}</div>
                                    <div style={{ fontSize: "18px", display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
                                        {this.props.module.array.map(data =>
                                            (
                                                <div className="text-center" style={{ fontSize: "20px", marginRight: "50px" }}>{data.name} <br />{data.value} ({data.porcentaje}%)</div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        < div className="col-md-12 card">
                            <div className="col-md-12 pt-3 pb-3">
                                <ColumnChart values={this.props.module.array_graph} name={this.props.module.question.question}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
                < div className="col-md-12">
                    <div className="col-md-12 pt-3 pb-3" style={{ width: "80%", height: "600px", margin:"auto",  }}>
                        <Map markers={this.props.module.geo_localization}
                        />
                    </div>
                </div>

                {this.props.module.question_type == "answer_radio" && (
                <TaskAndComments
                    modulo={this.props.module}
                    user={this.props.user}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    dataTask={this.state.dataTask}
                    name_module_task={this.props.name_module_task}
                    nameComment={this.props.name_module_task}
                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    updateStateLoad={this.updateStateLoad}
                />
                )}
            </React.Fragment>
        );
    }
}


export default QuestionShow;
WebpackerReact.setup({ QuestionShow });
