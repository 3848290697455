import React, { Component } from 'react';

class Items extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: this.props.icons,
            icon_id: "",
            form: {
                name: "",
            }
        }
    }
    
    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    selectIcon = (icon) => {
        this.props.setIconForm(icon.id)
        this.setState({ icon_id: icon.id })
    }
    
    componentDidMount = () => {
        this.setState({
            icon_id: this.props.icon_id
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <input 
                            type="text" 
                            name="name"
                            className="form form-control"
                            value={this.state.form.name}
                            onChange={this.HandleChange}
                            placeholder="Buscar icono"
                        />

                        {this.state.data.filter(value => value.name != null).filter(value => value.name.toLowerCase().includes(this.state.form.name.toLowerCase())).length >= 1 ? (
                            this.state.data.filter(value => value.name != null).filter(value => value.name.toLowerCase().includes(this.state.form.name.toLowerCase())).map((icon, index) => (
                                <div key={icon.id} className={`col-md-2 pl-0 mt-3`}>
                                    <div onClick={() => this.selectIcon(icon)} className={`card ${this.state.icon_id == icon.id ? "select-icon" : ""}`}>
                                        <div className="card-body p-1 text-center">
                                            <i style={{fontSize: "30px"}}className={`${icon.html_name} icon-list` }></i>
                                            <p className="mb-0 mt-1 icon-name">{icon.name == null ? "Baiso" : icon.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-md-12 mt-4 p-0">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="text-center">No hay registros con los criterios de búsqueda</h5>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Items;
