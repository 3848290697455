import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../../../GeneralComponents/LoadingComponen'

class IndexSegmentacion extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            id: "",

            form: {
                name: "",
                total_type: "moneda",
                table_type: "diario",
                variable_1: "",
                variable_2: "",
                actual_year: "",
                initial_variables: "",
                indicator_type: this.props.type,

                analyzed_position_id: "",
                reviewed_position_id: "",
                approved_position_id: "",
                sub_category_indicator_id: this.props.sub_category_indicator != null ? this.props.sub_category_indicator.id : "",
                category_indicator_id: this.props.category_indicator.id,
                description: "",
            },

            selectedOptionPosition1: {
                analyzed_position_id: "",
                label: "Integra"
            },

            selectedOptionSubCategory: {
                sub_category_indicator_id: "",
                label: "Sub categoría"
            },

            selectedOptionPosition2: {
                reviewed_position_id: "",
                label: "Analiza"
            },

            selectedOptionPosition3: {
                approved_position_id: "",
                label: "Aprueba"
            },

            position: [],
        }
    }


    //selects 

    handleChangeAutocomplete1 = selectedOptionPosition1 => {
        this.setState({
          selectedOptionPosition1,
            form: {
                ...this.state.form,
                analyzed_position_id: selectedOptionPosition1.value
            }
        });

    };

    handleChangeAutocomplete2 = selectedOptionPosition2 => {
        this.setState({
          selectedOptionPosition2,
            form: {
                ...this.state.form,
                reviewed_position_id: selectedOptionPosition2.value
            }
        });

    };

    handleChangeAutocomplete3 = selectedOptionPosition3 => {
        this.setState({
          selectedOptionPosition3,
            form: {
                ...this.state.form,
                approved_position_id: selectedOptionPosition3.value
            }
        });

    };

    componentDidMount = () => {
        this.positionSelect();
    }

    positionSelect = () => {
        let array = [];

        this.props.cargos.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            position: array
        })
    }

    validationForm = () => {
        if (this.state.form.name != "" &&  
            this.state.form.actual_year != "" && 
            this.state.form.initial_variables != (this.props.type == "SEGV" && this.state.modeEdit == false ? "" : "*") && 

            this.state.form.variable_1 != (this.props.type != "SEGV" ? "" : "*") && 
            this.state.form.variable_2 != (this.props.type != "SEGV" ? "" : "*") && 

            
            this.state.form.analyzed_position_id != "" && 
            this.state.form.reviewed_position_id != "" && 
            this.state.form.approved_position_id != ""
        ) {
          this.setState({ ErrorValues: true })
          return true
        }else{
          this.setState({ ErrorValues: false })
          return false
          
        }
    }    

    //   this.state.modeEdit == false ? this.state.form.password != "" : "",
    //   this.state.modeEdit == false ? this.state.form.password_confirmation != "" : "",

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                variable_1: "",
                variable_2: "",
                actual_year: "",
                total_type: "moneda",
                table_type: "diario",
                initial_variables: "",
                indicator_type: this.props.type,

                analyzed_position_id: "",
                reviewed_position_id: "",
                approved_position_id: "",
                sub_category_indicator_id: this.props.sub_category_indicator != null ? this.props.sub_category_indicator.id : "",
                category_indicator_id: this.props.category_indicator.id,
                description: "",
            },

            selectedOptionPosition1: {
                analyzed_position_id: "",
                label: ""
            },

            selectedOptionSubCategory: {
                sub_category_indicator_id: "",
                label: "Sub categoría"
            },

            selectedOptionPosition2: {
                reviewed_position_id: "",
                label: ""
            },

            selectedOptionPosition3: {
                approved_position_id: "",
                label: ""
            },


            modeEdit: false,
            ErrorValues: true,
            id: "",
        })
    }

    edit = (indicator) => {
        this.setState({
            form: {
                ...this.state.form,
                name: indicator.name,
                total_type: indicator.total_type,
                variable_1: indicator.variable_1,
                variable_2: indicator.variable_2,
                table_type: indicator.table_type,
                actual_year: indicator.actual_year,

                analyzed_position_id: indicator.analyzed_position != null ? indicator.analyzed_position.id : "",
                approved_position_id: indicator.approved_position != null ?indicator.approved_position.id : "",
                reviewed_position_id: indicator.reviewed_position != null ? indicator.reviewed_position.id : "",
                description: indicator.description,
            },

            selectedOptionPosition1: {
                analyzed_position_id: indicator.analyzed_position != null ? indicator.analyzed_position.id : "",
                label: `${indicator.analyzed_position != null ? indicator.analyzed_position.name : ""}`
            },

            selectedOptionPosition2: {
                reviewed_position_id: indicator.approved_position != null ?indicator.approved_position.id : "",
                label: `${indicator.approved_position != null ?indicator.approved_position.name : ""}`
            },

            selectedOptionPosition3: {
                approved_position_id: indicator.reviewed_position != null ? indicator.reviewed_position.id : "",
                label: `${indicator.reviewed_position != null ? indicator.reviewed_position.name : ""}`
            },

            id: indicator.id,
            modal: true,
            modeEdit: true,
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    getNameTitle = (name) => {
        const indicadores = "Indicadores"

        if (name == "SACLI") {
            return `${this.props.category_indicator.name}: ${indicadores} Clinicos`
        } else if (name == "SAADM") {
            return `${this.props.category_indicator.name}: ${indicadores} Administrativos`
        } else if (name == "SAOP") {
            return `${this.props.category_indicator.name}: ${indicadores} Operativos`
        } else if (name == "SAMEJ") {
            return `${this.props.category_indicator.name}: ${indicadores} Mejora`
        }else if(name == "SEGV"){
            return `${this.props.category_indicator.name}: ${indicadores} de Segmentación de ventas`
        }else if(name == "PDT"){
            return `${this.props.category_indicator.name}: ${indicadores} de productividad`
        }
        
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleClick = () => {
        if (this.validationForm() == true){
            this.props.updateStateLoad(false)
            if (this.state.modeEdit == false)
                fetch(`/indicator/category_indicators/${this.props.category_indicator.id}/indicators`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.props.loadData();
                        this.clearValues();
                        this.setState({ modal: false })
                        this.props.updateStateLoad(true)
                        this.messageSuccess(data);
                    });
            else {
                fetch(`/indicator/category_indicators/${this.props.category_indicator.id}/indicators/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.props.loadData();
                        this.clearValues();
                        this.setState({ modal: false })
                        this.props.updateStateLoad(true)
                        this.messageSuccess(data);
                    });
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/indicator/category_indicators/${this.props.category_indicator.id}/indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    getMonthText = (fecha) => {
        let months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
        return months[fecha]
    }

    showIndicator = (value) => {
        let month = new Date().getMonth();
        
    

        let cumplimiento = ["Sin información", 0 , this.getMonthText(month)];

        value.indicator_data_tables.filter(data=>data.total_column == false).map(data=>{
            if (data.percentage > cumplimiento[1])
            {
                cumplimiento = [`${data.name} tiene el mayor % de ${value.name} al mes de `, data.percentage , this.getMonthText(month)];
            }
        })

      
        return cumplimiento

    }
    numberToCurrency = (amount) => {
        amount = amount ? amount : 0
        var thousandsSeparator = ","
        var currencyNum = "";
        var amountString = amount.toString();
        var digits = amountString.split("");

        var countDigits = digits.length;
        var revDigits = digits.reverse();

        for (var i = 0; i < countDigits; i++) {
            if ((i % 3 == 0) && (i != 0)) {
                currencyNum += thousandsSeparator + revDigits[i];
            } else {
                currencyNum += digits[i];
            }
        };

        var revCurrency = currencyNum.split("").reverse().join("");

        var finalCurrency = "$" + revCurrency;

        return finalCurrency;
    }

    openIndicator = (value) => {
        window.location.href = `/indicator/category_indicators/${this.props.category_indicator.id}/indicators/${value}`;
    }


    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        this.setState({
            selectedOptionSubCategory,
                form: {
                    ...this.state.form,
                    sub_category_indicator_id: selectedOptionSubCategory.value
                }
        });
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.category_indicator.name}`}
                    subTitle={`Gestión de Indicadores`}
                    titleNew={"Nuevo indicador"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}
                    title={this.state.modeEdit ? "Editar Indicador" : "Agregar nuevo Indicador"}
                    modeEdit={this.state.modeEdit}

                    //form props
                    formValues={this.state.form}
                    errorValues={this.state.ErrorValues}
                    onChangeForm={this.HandleChange}
                    submitForm={this.HandleClick}
                    nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                    isLoaded={this.props.isLoaded}

                    //type
                    type={this.props.type}

                    //select1

                    selectedOptionPosition1={this.state.selectedOptionPosition1}
                    handleChangeAutocomplete1={this.handleChangeAutocomplete1}

                    //select2

                    selectedOptionPosition2={this.state.selectedOptionPosition2}
                    handleChangeAutocomplete2={this.handleChangeAutocomplete2}

                    //select3

                    selectedOptionPosition3={this.state.selectedOptionPosition3}
                    handleChangeAutocomplete3={this.handleChangeAutocomplete3}

                    //select sub category
                    selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                    handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                    sub_category_indicators_select={this.props.sub_category_indicators_select}

                    position={this.state.position}
                />

                {this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="row">
                            {this.props.category_indicator_view && (
                                <div className="col-md-12 mb-3">
                                    <div className="row">
                                        {this.props.sub_category_indicators.map((sub_category_indicator, index) => (
                                            <div className={`col-md-2 text-center ${index == 0 ? "pr-0" : ""}`} key={sub_category_indicator.id}>
                                                <div className="card">
                                                    <a 
                                                        href={`/indicator/sub_category_indicators/${sub_category_indicator.id}`}
                                                        target="_blank"
                                                        data-turbolinks="false"
                                                    >
                                                        {sub_category_indicator.name}
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {this.props.data.length >= 1 ? (
                            <div className="row indicators-wp">
                                {this.props.data.map(indicator => (
                                    <div className="col-md-4 mb-4">
                                        <div className="card">
                                            <div className="card-body card-ventas-gerencia">



                                                <p className="card-ventas-name">{indicator.name}</p>
                                                <hr />
                                                <p className="card-ventas-value"> {this.showIndicator(indicator) != undefined ? this.showIndicator(indicator)[1] : ""}%</p>
                                                <p className="card-ventas-desc">{this.showIndicator(indicator) != undefined ? this.showIndicator(indicator)[0] : ""} <br /><span>{this.showIndicator(indicator) != undefined ? this.showIndicator(indicator)[2] : ""}</span></p>
                                               {/*  <p>{indicator.description}</p> */}
                                            </div>

                                            <div className="card-footer">

                                                {this.props.estados.editar && (
                                                    <a
                                                        className="btn"
                                                        onClick={() => this.edit(indicator)}
                                                    >
                                                        <i className="fas fa-pen"></i>
                                                    </a>
                                                )}

                                                {this.props.estados.eliminar && (
                                                    <a
                                                        className="btn"
                                                        onClick={() => this.delete(indicator.id)}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </a>
                                                )}

                                                {this.props.estados.gestionar && (
                                                    <div className="text-right" style={{ width: "100%" }}>
                                                        <button className="btn btn-primary" onClick={() => this.openIndicator(indicator.id)}> Ver Indicador</button>
                                                    </div>
                                                )}  

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        ) : (
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4>No hay indicadores</h4>
                                    </div>
                                </div>
                            )}
                    </React.Fragment>
                ) : (
                        <div className="col-md-12 text-center">
                            <Preloader />
                        </div>
                    )}
            </React.Fragment>
        );
    }
}

export default IndexSegmentacion;