import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label>Fecha de análisis <small className="validate-label">*</small></label>
                                        <input
                                            type="date"
                                            name="date_of_analysis"
                                            value={this.props.formValues.date_of_analysis}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.date_of_analysis == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Proceso <small className="validate-label">*</small></label>
                                        <input
                                            type="hidden"
                                            name="proceso_id"
                                            value={this.props.selectedOptionProcesos.proceso_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteProceso}
                                            options={this.props.procesos}
                                            autoFocus={false}
                                            className={`link-form ${this.props.errorValues == false && this.props.formValues.proceso_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOptionProcesos}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Responsable <small className="validate-label">*</small></label>
                                        <input
                                            type="hidden"
                                            name="responsable_id"
                                            value={this.props.selectedOption.responsable_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.users}
                                            autoFocus={false}
                                            className={`link-form ${this.props.errorValues == false && this.props.formValues.responsable_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOption}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Participantes <small className="validate-label">*</small></label>
                                        <input
                                            type="hidden"
                                            name="users_ids"
                                            value={this.props.selectedOptionUsers.users_ids}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteUsers}
                                            options={this.props.users}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            name="users_ids"
                                        />
                                    </div>

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;