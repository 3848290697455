import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LineChartIndicator from "../../../GeneralComponents/Charts/LineChartIndicator"
import LineChartIndicatorMonth from "../../../GeneralComponents/Charts/LineChartIndicatorMonth"
import LayoutVertical from './layouts/layoutVertical'
import LayoutHorizontal from './layouts/layoutHorizontal'


class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formValues: {
                start_month: new Date().getMonth()
            },

            formUpdate: {
                real: 0,
                target: 0,
            },

            idIndex: 260,
            id: "",
            postion: 0,
            dataLine: [],
            dataLineMonth: [],
            typeLayout: "false",
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: parseInt(e.target.value)

            },
        });
        this.props.loadData();
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }

    updateFiels = (dia, index, idRegister) => {
        this.setState({
            formUpdate: {
                real: dia.real,
                target: dia.target,
            },

            idIndex: index,
            id: idRegister,
            postion: index,
        })
    }

    componentWillReceiveProps(nextProps) {

        if (this.props !== nextProps) {
            this.dataLine(nextProps.data)
            this.dataLineMonth(nextProps.data)
            console.log(nextProps.data)
            console.log("extProps.data")

            this.setState({
                typeLayout: "true",
            })
        }
    }


    HandleClickUpdate = () => {
        fetch(`/indicator/update_json/${this.state.id}/0/${this.state.formValues.start_month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({
                    idIndex: 260,
                })
                /*               this.messageSuccess(data); */
            });
    }

    dataLine = (nextProps) => {

        let target = nextProps.target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, '%'], [0, 0, "", target]]


        let value = this.state.formValues.start_month


        nextProps.data[value].data.data.map((data, index) => {

            let data_percent = data.percent + "%"
            let data_percent_num = data.percent
            if (data.percent == 0) {
                data_percent = ""
            }
            if (!data.state) {
                data_percent_num = null
            }

            array.push([index + 1, data_percent_num, data_percent, target])



        })


        this.setState((state, props) => ({
            dataLine: array
        }));
    }

    dataLineMonth = (nextProps) => {

        let target = nextProps.target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, '%']]


        let value = this.state.formValues.start_month


        nextProps.data.map((data, index) => {

            let data_percent = data.total_percent + "%"
            let data_percent_num = data.total_percent
            if (data.target == 0) {
                data_percent = ""
                data_percent_num = null
            }
            /*                     if ( !data.state )
                                {
                                    data_percent_num = null
                                } */

            array.push([data.month_text, data_percent_num, data_percent, target])



        })


        this.setState((state, props) => ({
            dataLineMonth: array
        }));
    }

    whishIs = (value) => {
        console.log("aquiii")
        console.log(value)
        if ("Vertical" == "Vertical") {
            console.log("dsfasfdsfas")
            return (<LayoutVertical data={this.props.dataNew} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />);

        }
        else {
            return <LayoutHorizontal data={this.props.dataNew} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />;
        }

    }
    render() {
        const value = this.state.formValues.start_month;

        return (
            <React.Fragment>

                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/indicator/category_indicators/${this.props.category_indicator.id}/indicators?type=${this.props.indicator_all.indicator_type}`}
                                data-turbolinks="false"
                            >
                                Indicadores
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);" >
                                {this.props.data.name}
                            </a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    {this.props.dataNew != undefined ? (
                        <div className="col-md-12">

                            <LayoutVertical data={this.props.dataNew} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />

                        </div>
                    ) : (
                            <div></div>
                        )}

                </div>
                <div className="row mb-5">



                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Periodo</h5>
                                            </div>

                                            <div className="col-md-6 text-right">
                                                <select
                                                    name="start_month"
                                                    value={this.state.formValues.start_month}
                                                    onChange={this.HandleChange}
                                                    className="form form-control"
                                                >
                                                    <option value="">Seleccione un mes</option>
                                                    <option value="0">Enero</option>
                                                    <option value="1">Febrero</option>
                                                    <option value="2">Marzo</option>
                                                    <option value="3">Abril</option>
                                                    <option value="4">Mayo</option>
                                                    <option value="5">Junio</option>
                                                    <option value="6">Julio</option>
                                                    <option value="7">Agosto</option>
                                                    <option value="8">Septiembre</option>
                                                    <option value="9">Octubre</option>
                                                    <option value="10">Noviembre</option>
                                                    <option value="11">Diciembre</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                            <div className="col-md-12 item-target">
                                                <div className="row">
                                                    <div className="col-md-6"> <h5>Target</h5></div>

                                                    <div className="col-md-6">{this.props.data.target}%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <LineChartIndicator data={this.state.dataLine} />
                                    </div>
                                </div>
                                <br />
                                <div className="card">
                                    <div className="card-body">
                                        <LineChartIndicatorMonth data={this.state.dataLineMonth} />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default Show;
