import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'

class Table extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;

        this.state = {
            modal: false,
            ErrorValues: true,
            ShowFormModule: true,
            isLoadedModuleAccion: false,

            dataSectionAccions: [],

            id: "",

            form: {
                name: "",
            },

            formUpdate: {
                name: "",
            },

            formSectionAccion: {
                name: "",
                section_app_id: ""
            },

        }

    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
            }
        })
    }

    clearValuesSectionAccion = () => {
        this.setState({
            formSectionAccion: {
                ...this.state.formSectionAccion,
                name: "",
            }
        })
    }
    

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        }else{
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
              ...this.state.form,
              [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true, ShowFormModule: true})
            this.clearValues();
        }else{
            this.setState({ modal: false})
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch("/settings/section_apps", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateData(data.register)
                this.setState({ modal: false})
                this.messageSuccess(data);
            });
        }
    }


    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si"
        }).then(result => {
          if (result.value) {
            fetch("/settings/section_apps/" + id, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadData()
                this.messageSuccess(response)
              });
          }
        });
    };

    //Update section

    edit = (rol) => {
        this.setState({
            id: rol.id,
            formUpdate: {
                name: rol.name,
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    HandleClickUpdate = () => {
        fetch(`/settings/section_apps/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.clearValues();
            this.setState({ id: ""})
            this.messageSuccess(data);
        });
    }

    // acciones de modulo 

    addActions = (section) => {
        this.loadDataModuleAccion(section.id)
        this.setState({
            ShowFormModule: false,
            modal: true,
            formSectionAccion: {
                name: "",
                section_app_id: section.id
            }
        })
    }

    HandleClickSectionAccions = () => {
        fetch("/settings/section_accion_apps", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formSectionAccion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadDataModuleAccion(this.state.formSectionAccion.section_app_id);
            this.clearValuesSectionAccion();
            this.props.loadData();
            this.messageSuccess(data);
        });
    }

    HandleChangeSectionAccions = (e) => {
        this.setState({
            formSectionAccion: {
              ...this.state.formSectionAccion,
              [e.target.name]: e.target.value
            }
        });
    }

    loadDataModuleAccion = (id) => {
        fetch(`/settings/get_section_accion_apps/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataSectionAccions: data.data,
            isLoadedModuleAccion: true
          });
        });
    }


    render() {
        return (
            <React.Fragment>

                <HeaderTitle 
                    title={"Módulos Administrativos"} 
                    subTitle={"Gestión de Módulos"}
                    titleNew={"Nuevo módulo"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />

                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}
                    title={this.state.ShowFormModule == true ? "Agregar nuevo módulo" : "Agregar Acciones del módulo"}

                    //form props create module
                    formValues={this.state.ShowFormModule == true ? this.state.form : this.state.formSectionAccion }
                    onChangeForm={this.state.ShowFormModule == true ? this.HandleChange : this.HandleChangeSectionAccions}
                    submitForm={this.state.ShowFormModule == true ? this.HandleClick : this.HandleClickSectionAccions}

                    errorValues={this.state.ErrorValues}
                    ShowFormModule={this.state.ShowFormModule}

                    //form props create section_accion

                    dataSectionAccions={this.state.dataSectionAccions}
                    messageSuccess={this.messageSuccess}
                    loadSectionAccions={this.loadDataModuleAccion}
                    loadSection={this.props.loadData}
                    

                />

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Acciones del módulos</th>
                                <th className="text-center">Acciones</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(section => (
                                    <tr key={section.id}>
                                        <td>
                                            {section.id == this.state.id ? (
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.state.formUpdate.name}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{section.name}</p>
                                            )}
                                        </td>

                                        <td className="">
                                            {section.section_accion_apps.length >= 1 ? (
                                                <React.Fragment>
                                                    <p><span className="link-line" onClick={() => this.addActions(section)}> Crear acción </span></p>
                                                        {section.section_accion_apps.map((section_accion, index) => (
                                                            <span className={`span-section-actions mr-2`}>{section_accion.name}</span>
                                                        ))}
                                                </React.Fragment>
                                            ) : (
                                                <p>No hay Acciones para {section.name} <span className="link-line" onClick={() => this.addActions(section)}> Crear acción </span></p>
                                            )}
                                        </td>

                                        <td className="text-right" style={{ width: "10px" }}>
                                            {this.state.id != "" ? (
                                                <React.Fragment>
                                                    {section.id == this.state.id &&(
                                                        <React.Fragment>
                                                            <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                            <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                        </React.Fragment>
                                                    )} 
                                                </React.Fragment>
                                            ) : (
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <div className="btn-group" role="group">
                                                        <button
                                                            className="btn-shadow btn btn-info"
                                                            id="btnGroupDrop1"
                                                            type="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="fas fa-bars"></i>
                                                        </button>
                        
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            {true && (
                                                                <button
                                                                    onClick={() => this.edit(section)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </button>
                                                            )}
                        
                                                            {true && (  
                                                                <button
                                                                    onClick={() => this.delete(section.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </td>
                                    </tr>
                            ))
                                ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros con el criterio de búsqueda</h4>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;