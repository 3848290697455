import React from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CheckboxContainer from "./CheckboxContainer";
import Select from "react-select";
import InputProcess from "./inputProcess"
import { isMobile } from 'react-device-detect';

class FormCreate extends React.Component {
  constructor(props) {
    super(props);
    this.token = document.querySelector("[name='csrf-token']").content;
    this.state = {
      title: "Nuevo rol",
      modal: false,
      ErrorValues: true,
      backdrop: "static",
      modeEdit: this.props.editMode,
      id: (this.props.editMode == true ? this.props.position.id : ""),
      form: {
        name: (this.props.editMode == true ? this.props.position.name : ""),
        description: (this.props.editMode == true ? this.props.position.description : ""),
        id: (this.props.editMode == true ? this.props.position.id : ""),
        section_accion_ids: [],
        proceso_ids: [],
      },

      checkedItems: new Map(),
      checkboxes: [],
      modules: [],

      selectedOptionProcesos: {
        proceso_ids: "",
        label: "Procesos"
      },

      editValues: [],
      dataProcesos: [],
      data: [],
    };

  }


  handleChangeAccions = e => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));

  };

  closeModal() {
    this.setState({
      modalIsOpen: false,
      checkedItems: new Map()
    });

  }

  section_category = () => {
    fetch(`/settings/get_positions_section_category`, {
        method: 'GET', // or 'PUT'
        headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
        }
    })
    .then(response => response.json())
    .then(data => {
      if (this.props.editMode == true) {
        let map = new Map();

        this.props.section_accions.forEach(element => {
          this.props.position.section_accions.forEach(element_accion => {
            if (element.id == element_accion.id) {
              map.set(element_accion.id.toString(), true)
            }
          });
        })

        this.setState({
          data: data.data,
          checkedItems: map
        });

        this.selectValuesUpdate();
      }else{
        this.setState({
          data: data.data,
        });
      }
    });
  }

  componentDidMount = () => {
    this.selectValuesCreate();
    this.section_category();
  }

  selectValuesCreate = () => {
    let arrayProcesos = []

    this.props.procesos.map((item) => (
      arrayProcesos.push({ label: `${item.name}`, value: item.id })
    ))

    this.setState({
      dataProcesos: arrayProcesos
    })
  }


  validationForm = () => {
    if (this.state.form.name != "") {
        this.setState({ ErrorValues: true })
        return true
    }else{
        this.setState({ ErrorValues: false })
        return false
    } 
  }


  selectValuesUpdate = () => {
    console.log("ñaosfjld")
    let arrayPositionEdit = []
    let arrayPositionIds = []

    this.props.position.procesos.map((item) => (
      arrayPositionEdit.push({ label: item.name, value: item.id }),
      arrayPositionIds.push(item.id)
    ))

      this.setState({
        editValues: arrayPositionEdit,
      })

    this.setState({
      form: {
        ...this.state.form,
        proceso_ids: arrayPositionIds
      }
    })

    console.log(this.state.editValues[0])
    console.log(this.state.editValues[1])
  }

  handleChangeAutocomplete = selectedOption => {
    let array = []

    selectedOption.map((item) => (
      array.push(item.value)
    ))

    this.setState({
      form: {
        ...this.state.form,
        proceso_ids: array
      }
    })
  };


  openModal(name) {
    if (name == "edit") {
      this.setState({
        modeEdit: false
      });
    } else {

      this.setState({
        title: "Nuevo rol",
        modeEdit: false,
        form: {
          name: "",
          description: "",
          section_accion_ids: [],
        }
      });
    }

    this.setState({
      modalIsOpen: true
    });
  }

  messageSuccess = (response) => {
    Swal.fire({
      position: "center",
      type: `${response.type}`,
      title: `${response.success}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  HandleClick = e => {
    if (this.state.modeEdit == true) {

      let section_accion_ids = [];
      this.state.checkedItems.forEach((value, key) => {
        if (value == true) {
          section_accion_ids.push(key);
        }
      });
      let form = {};
      form = this.state.form
      form.section_accion_ids = section_accion_ids;


      fetch("/settings/positions/" + this.state.id, {
        method: "PATCH", // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(response => {
          this.messageSuccess(response);
          window.location.href = "/settings/positions";
        });


    } else {

      if(this.validationForm() == true){

      let section_accion_ids = [];
      this.state.checkedItems.forEach((value, key) => {
        if (value == true) {
          section_accion_ids.push(key);
        }
      });
      let form = {};
      form = this.state.form
      form.section_accion_ids = section_accion_ids;

      fetch("/settings/positions", {
        method: "POST", // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {

          this.messageSuccess(data);

          window.location.href = "/settings/positions";

          this.setState({
            modal: false,
            form: {
              name: "",
              description: "",
            },

            checkedItems: new Map()
          });
        });
      }
    }
  };

  handleChangeAll = (e) => {
    const arrayTrue = new Array();

    if (e.target.checked) {

      console.log("true")
      
      const map = new Map();

      this.props.section_accions.forEach(element => {
        map.set(element.id.toString(), true)
        arrayTrue.push(element.id)
      })

      this.setState({
        checkedItems: map,
        form: {
          ...this.state.form,
          section_accion_ids: arrayTrue
        }
      })

    }else{
      const map = new Map();

      console.log("false")

      this.props.section_accions.forEach(element => {
        map.set(element.id.toString(), false)
      })

      this.setState({
        checkedItems: map,
        form: {
          ...this.state.form,
          section_accion_ids: []
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content main-card mb-3 card">
          <div className="row card-body">

            <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
              <label>Nombre <small className="validate-label">*</small></label>
              <input
                type="text"
                name="name"
                value={this.state.form.name}
                onChange={this.handleChange}
                className={`form form-control ${this.state.ErrorValues == false && this.state.form.name == "" ? "error-class" : ""}`}
                autoComplete="off"
                placeholder="Nombre"
              />
            </div>

            <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
              <label>Description<small className="validate-label">*</small></label>
              <input
                type="text"
                name="description"
                value={this.state.form.description}
                onChange={this.handleChange}
                className="form form-control"
                autoComplete="off"
                placeholder="Descripción"
              />
            </div>

            {this.state.editValues.length > 0  ? (
              <InputProcess 
                procesoIds={this.state.selectedOptionProcesos.proceso_ids} 
                handleChangeAutocomplete={this.handleChangeAutocomplete} 
                editValues={this.state.editValues} 
                dataProcesos={this.state.dataProcesos} 
              />
            ) : (
                <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
                  <input
                    type="hidden"
                    name="proceso_ids"
                    value={this.state.selectedOptionProcesos.proceso_ids}
                  />
                  <label>Procesos <small className="validate-label">*</small></label>
                  <Select
                    onChange={this.handleChangeAutocomplete}
                    isMulti
                    defaultValue={this.state.editValues}
                    closeMenuOnSelect={false}
                    name="proceso_ids"
                    options={this.state.dataProcesos}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />

                </div>
            )}

            <div className="col-md-12 text-right mt-2">
              <div className="custom-control custom-switch">
                  <input type="checkbox" onChange={this.handleChangeAll} className="custom-control-input" id={`customSwitch`}/>
                  <label className="custom-control-label" htmlFor={`customSwitch`}>Añadir todos</label>
              </div>
            </div>

          </div>

          <div className="card-footer">
              <a className="btn-transition btn btn-outline-light" href="/settings/positions" data-turbolinks="false">Volver</a>
              <button className="btn-shadow  btn btn-info ml-3" onClick={this.HandleClick}>{this.state.modeEdit == true ? "Actualizar cargo" : "Crear cargo"}</button>
          </div>
        </div>


        {this.state.data.map(category => (
          <React.Fragment>
            {category.sections.filter(section => { return section.show_module == this.props.tenant.app_type || section.show_module == "Todos"}).length >= 1 && (
              <div key={category.id} className="content main-card mb-3">
                <div className="card">
                  <div className="card-header header-position">
                      {category.name}
                  </div>

                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-md-12 mt-4">
                          <CheckboxContainer
                            checkedItems={this.state.checkedItems}
                            handleChangeAccions={this.handleChangeAccions}
                            checkboxes={this.state.checkboxes}
                            modules={category.sections}
                            tenant={this.props.tenant}
                          />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </React.Fragment>
        ))}


      </React.Fragment>
    );
  }
}


export default FormCreate;