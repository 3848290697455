import React from "react";
import FormCreate from "../components/health/NotificationSafety/FormCreate";
import WebpackerReact from 'webpacker-react';


class NotificationSafetyNew extends React.Component {
  
  render() {
  
    return (
         <FormCreate usuario={this.props.usuario} editMode={this.props.editMode} areas={this.props.areas} events={this.props.events} sources={this.props.sources} notification_event={this.props.notification_event} />
    );
  }
  
}

export default NotificationSafetyNew;

WebpackerReact.setup({ NotificationSafetyNew });
