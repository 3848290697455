import React, { Component } from 'react';
import Show from '../components/health/NotificationEvents/Show'

class NotificationEventShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: false,
        }
    }

    componentDidMount(){
        this.loadTasks();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false
        })

    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                //user_owner: {document: "1037660678", document_type: "Cédula de"},
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
              }
            }
            return item;
          })
        });
    }

    loadTasks = () => {
        fetch(`/task/get_module_task/${this.props.event_notification.module_task_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Show
                    updateStateLoad={this.updateStateLoad}
                    isLoaded={this.state.isLoaded}
                    event_notification={this.props.event_notification}

                    data={this.state.data}
                    loadTasks={this.loadTasks}
                    user={this.props.user} 
                    users={this.props.users} 
                    estados={this.props.estados}

                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    procesos={this.props.procesos}
                    task_source={this.props.task_source}
                />
            </React.Fragment>
        );
    }
}


export default NotificationEventShow;

