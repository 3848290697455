import React, { Component } from 'react';
import FormCreate from './FormCreate';
import HeaderTitle from '../../GeneralComponents/HeaderTitle'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import FormFilter from './FormFilter'
import ShowVideo from './ShowVideo';
import Preloader from '../../GeneralComponents/LoadingComponen'

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalShow: false,
            modeEdit: false,
            isLoaded: true,
            showFilter: false,

            id: "",
            nameFile: "",
            form: {
                description: "",
                video_url: "",
                type_module: "",
                module_app_id: "",
                imagen: {},
            },

            formFilter: {
                description: "",
                module_app_id: "",
            },

            formYoutube: {
                name: "",
            },

            video: {},
            nameModuleApp: "",
            dataYoutube: [],
        }
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    setModuleApp = (module_app) => {

        this.setState({
            nameModuleApp: module_app.name,
            form: {
                ...this.state.form,
                module_app_id: module_app.id,
            },

            formFilter: {
                ...this.state.formFilter,
                module_app_id: module_app.id,
            },
        });
    }

    componentDidMount = () => {
        this.setModuleApp(this.props.module_apps[0])
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeFilter = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeformYoutube = (e) => {
        this.setState({
            formYoutube: {
                ...this.state.formYoutube,
                name: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleShowVideo = (video) => {
        this.setState({ modalShow: !this.state.modalShow, video: (video != undefined ? video : {}) })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    HandleChangeFormDescription = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                description: e,
            },
        })
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            nameFile: "",

            form: {
                ...this.state.form,
                description: "",
                video_url: "",
                type_module: "",
                imagen: {},
            },


            formFilter: {
                ...this.state.formFilter,
                description: "",
            },

            formYoutube: {
                name: "",
            },

            dataYoutube: [],
        })
    }

    HandleClickFilter = () => {
        fetch("/settings/search_knowledge_bases", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formFilter), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateDataFilter(data.data)
            });
    }

    HandleClick = () => {
        const formData = new FormData();
        formData.append("description", this.state.form.description)
        formData.append("video_url", this.state.form.video_url)
        formData.append("type_module", this.state.form.type_module)
        formData.append("module_app_id", this.state.form.module_app_id)
        formData.append("imagen", this.state.form.imagen)

        if (!this.state.modeEdit) {
            fetch("/settings/knowledge_bases", {
                method: 'POST', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.props.updateData(data.register)
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        } else {
            fetch(`/settings/knowledge_bases/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                    this.props.updateItem(data.register)
                });
        }
    }


    HandleClickSearchYoutube = () => {
        fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=2&q=${this.state.formYoutube.name}&channelId=UCjH00gAtYjZoSvcxQfvYYww&key=AIzaSyCeks4asba3f7cSqpc1lagBVem4CY4QPXo`, {
            method: 'GET', // or 'PUT'
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log(data.items)
                this.setState({
                    dataYoutube: data.items
                })

            });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch("/settings/knowledge_bases/" + id, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    HandleChangeVideoSelect = (e) => {
        const value = e.target.value != "on" ? e.target.value : ""

        if (e.target.checked) {
            this.setState({
                form: {
                    ...this.state.form,
                    video_url: e.target.value,
                },
            })

        } else {

            this.setState({
                form: {
                    ...this.state.form,
                    video_url: "",
                },
            })
        }
    }

    edit = (video) => {
        this.setState({
            form: {
                description: video.description,
                video_url: video.video_url,
                type_module: video.type_module,
                module_app_id: video.module_app_id,
                imagen: video.imagen,
            },

            nameModuleApp: video.module_app.name,
            modal: true,
            modeEdit: true,
            id: video.id,
        })
    }

    handleClickShowFilter = (state) => {
        this.setState({
            showFilter: state != undefined ? state : true,
        })

        if (!state) {
            this.props.loadData();
            this.setState({
                formFilter: {
                    ...this.state.formFilter,
                    description: "",
                },
            })
        }
    }

    //files 

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    imagen: file
                },

                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Tutoriales"}
                    subTitle={"Gestión del conocimiento"}
                    titleNew={"Nuevo video"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickFilter={this.handleClickShowFilter}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.create}
                    showFilter={true}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? `Editar video de ${this.state.nameModuleApp.toLowerCase()}` : `Crear video para ${this.state.nameModuleApp.toLowerCase()}`}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                        onChangeFormDescription={this.HandleChangeFormDescription}

                        //YOUTUBE
                        HandleClickSearchYoutube={this.HandleClickSearchYoutube}
                        HandleChangeformYoutube={this.HandleChangeformYoutube}
                        dataYoutube={this.state.dataYoutube}
                        formYoutube={this.state.formYoutube}
                        HandleChangeVideoSelect={this.HandleChangeVideoSelect}
                        module_apps={this.props.module_apps}

                        //files 
                        handleFile={this.handleFile}
                        nameFile={this.state.nameFile}
                    />
                )}

                {this.state.showFilter && (
                    <FormFilter
                        formValues={this.state.formFilter}
                        handleClickCancel={this.handleClickShowFilter}
                        handleChange={this.HandleChangeFilter}
                        HandleClickFilter={this.HandleClickFilter}
                    />
                )}

                {this.state.modalShow && (
                    <ShowVideo
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalShow}
                        toggle={this.toogleShowVideo}
                        video={this.state.video}
                        title={"Video"}
                    />
                )}

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {this.props.module_apps.map((module_app, index) => (
                        <li className="nav-item" key={module_app.id}>
                            <a className={`nav-link ${index == 0 ? "active" : ""}`} onClick={() => this.setModuleApp(module_app)} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{module_app.name}</a>
                        </li>
                    ))}
                </ul>

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        {this.props.data.filter(video_filter => video_filter.module_app_id == this.state.form.module_app_id).length >= 1 ? (
                            <div className="row">

                                {this.props.data.filter(video_filter => video_filter.module_app_id == this.state.form.module_app_id).map(video => (
                                    <div className="col-md-3 mb-4" key={video.id}>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12 mb-2" style={{
                                                        backgroundImage: `url(${video.imagen.url})`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center center",
                                                        width: "100%",
                                                        height: "200px",
                                                        cursor: "pointer"
                                                    }}
                                                        onClick={() => this.toogleShowVideo(video)}

                                                    >
                                                    </div>

                                                    <div className="col-md-12" style={{ height: "100px", minHeight: "100px", overflow: "hidden", paddingTop: "10px" }}>
                                                        {ReactHtmlParser(video.description)}
                                                    </div>
                                                </div>
                                            </div>

                                            {(this.props.estados.edit || this.props.estados.delete) && (

                                                <div className="card-footer" style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div >
                                                        <button className="btn btn-primary"
                                                            onClick={() => this.toogleShowVideo(video)} >Ver</button>

                                                    </div>
                                                    <div>
                                                        {this.props.estados.edit && (
                                                            <buttoan
                                                                onClick={() => this.edit(video)}
                                                                className="btn"
                                                            >
                                                                Editar
                                                        </buttoan>
                                                        )}

                                                        {this.props.estados.delete && (
                                                            <button
                                                                onClick={() => this.delete(video.id)}
                                                                className="btn"
                                                            >
                                                                Eliminar
                                                        </button>
                                                        )}
                                                    </div>
                                                </div>

                                            )}

                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4>No hay videos</h4>
                                    </div>
                                </div>
                            )
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Index;