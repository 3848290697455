import React, { Component } from 'react';
import Show from '../components/formatos/Formats/Show'  
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"

class FormatsShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            countMax: 0,
            count: 1,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateCount = (count) => {
        this.setState({
            count: count
        })
    }

    reduceCountMax = () => {
        this.setState({
            countMax: this.state.countMax - 1
        })
    }

    sumCountMax = () => {
        this.setState({
            countMax: this.state.countMax + 1
        })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false,
        })
    }

    reduceState = () => {
        this.setState({
            count: this.state.count - 1,
        })
    }

    sumState = () => {
        this.setState({
            count: this.state.count + 1,
        })
    }

    //add update
    updateItem = step => {
        this.setState({
            data: this.state.data.map(item => {
            if (step.id === item.id) {
              return { ...item, 
                name: step.name,
                description: step.description,
                is_private: step.is_private,
                positions: step.positions,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_steps/${this.props.formats.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            countMax: data.data.length,
            isLoaded: false
          });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.formats.name}`}
                    subTitle={"ForData"}
                    titleNew={"Nuevo formato"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <Show
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    format={this.props.formats}
                    format_category={this.props.format_category}
                    countMax={this.state.countMax} 
                    count={this.state.count}

                    reduceState={this.reduceState}
                    sumState={this.sumState}
                    formatos={this.props.formatos}

                    reduceCountMax={this.reduceCountMax}
                    sumCountMax={this.sumCountMax}
                    updateCount={this.updateCount}

                    configuration_format={this.props.configuration_format}
                    positions={this.props.positions}
                    steps_questions={this.props.steps_questions}

                    format_resposabilities={this.props.format_resposabilities} 
                    questions_users={this.props.questions_users} 
                    all_formats={this.props.all_formats}
                    format_associations={this.props.format_associations}

                />
            </React.Fragment>
        );
    }
}


export default FormatsShow;
WebpackerReact.setup({ FormatsShow });

