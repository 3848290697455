import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-12 mb-3">
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    {/*<div className="col-md-12 mb-3">
                                        <label>Cargos </label>
                                        <input
                                            type="hidden"
                                            name="position_ids"
                                            value={this.props.selectedOption.position_ids}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.cargos}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            name="position_ids"
                                            defaultValue={[
                                                this.props.editValuesPosition[0],
                                                this.props.editValuesPosition[1],
                                                this.props.editValuesPosition[2],
                                                this.props.editValuesPosition[3],
                                                this.props.editValuesPosition[4],
                                                this.props.editValuesPosition[5]
                                            ]}
                                        />
                                    </div>*/}

                                    <div className="col-md-12 mb-3">
                                        <label>Usuarios </label>
                                        <input
                                            type="hidden"
                                            name="user_ids"
                                            value={this.props.selectedOptionUsers.user_ids}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteUsers}
                                            options={this.props.users}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            name="user_ids"
                                            defaultValue={[
                                                this.props.editValuesUsers[0],
                                                this.props.editValuesUsers[1],
                                                this.props.editValuesUsers[2],
                                                this.props.editValuesUsers[3],
                                                this.props.editValuesUsers[4],
                                                this.props.editValuesUsers[5]
                                            ]}
                                        />
                                    </div>


                                    <div className="col-md-12">
                                        {this.props.labels.map(label => (
                                            <span key={label.id} onClick={() => this.props.addLabel(label, this.props.selectLabels.includes(label.id))} style={{ background: label.color, cursor: "pointer" }} className={`badge badge-secondary mt-2 mr-2`}>{label.name} {this.props.selectLabels.includes(label.id) ? <i className="fas fa-check"></i> : null }</span>
                                        ))}
                                    </div>


                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;