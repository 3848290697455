import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label>Título</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={this.props.formValues.title}
                                            onChange={this.props.onChangeForm}
                                            placeholder="Título"
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.title == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Fecha</label>
                                        <input
                                            type="date"
                                            name="date"
                                            value={this.props.formValues.date}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.date == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Responsable <small className="validate-label">*</small></label>
                                        <input
                                            type="hidden"
                                            name="responsable_id"
                                            value={this.props.selectedOption.responsable_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.users}
                                            autoFocus={false}
                                            className={`link-form ${this.props.errorValues == false && this.props.formValues.responsable_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOption}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Descripción <small className="validate-label">*</small></label>
                                        <textarea
                                            name="description"
                                            value={this.props.formValues.description}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control`}
                                            rows="4"
                                        />
                                    </div>


                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;