import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import Map from '../../../GeneralComponents/MapComponent'

class ModalShowInfo extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            {this.props.question.type == "answer_text" && (
                                <div className="col-md-12" style={{ wordBreak: "break-all" }}>
                                    {ReactHtmlParser(this.props.question.value)}
                                </div>
                            )}
                            {this.props.question.type == "answer_localization" && (
                                <div className="col-md-12" style={{ height: "500px" , position:"relative"}}>
                                    <Map markers={[{ lat: this.props.question.value.split(",")[0], lng: this.props.question.value.split(",")[1] }]} />
                                </div>
                            )}
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalShowInfo;
