import React, { Component } from 'react';
import Index from '../components/tasks/taskConfiguration/Index'
import WebpackerReact from 'webpacker-react';

class ConfigurationTask extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataLabels: [],
            isLoadedLabels: true,

            //pagination

            activePage: 1,
            countPage: 10,
            labels_count: 0,

            //filter labels 

            formFilterLabel: {
                name: "",
            }
        }
    }

    componentDidMount(){
        this.loadDataLabels();
    }

    updateStateLoadLabels = (state) => {
        this.setState({ isLoadedLabels: state })
    } 

    //add items
    updateDataLabels = (data) => {
        this.setState({
            dataLabels: [...this.state.dataLabels, data],
        })
    }

    //add update
    updateItemLabel = label => {
        this.setState({
            dataLabels: this.state.dataLabels.map(item => {
            if (label.id === item.id) {
              return { ...item, 
                name: label.name,
                color: label.color,
              }
            }
            return item;
          })
        });
    }

    loadDataLabels = () => {
        fetch(`/task/get_labels`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataLabels: data.data,
            labels_count: data.task_labels_total,
            isLoadedLabels: false
          });
        });
    }

    // paginate 

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        fetch(`/task/get_labels?page=${pageNumber}&filter=${this.state.countPage}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({ dataLabels: data.data });
        });
         
    }

    //filter labels

    clearValuesLabel = () => {
        this.setState({
            formFilterLabel: {
                name: ""
            },
            showbtnSubmit: false,
        })
        
        this.loadDataLabels();
    }

    handleChangeFilterLabel = e => {
        this.setState({
            formFilterLabel: {
                ...this.state.formFilterLabel,
                [e.target.name]: e.target.value
            }
        });
    };

    handleClickFilterLabel = () => {
        fetch(`/task/get_labels?name=${this.state.formFilterLabel.name}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataLabels: data.data,
                showbtnSubmit: true
            });
        });
    };

    

    render() {
        return (
            <React.Fragment>
                <Index
                    //labels
                    isLoadedLabels={this.state.isLoadedLabels}
                    updateDataLabels={this.updateDataLabels}
                    dataLabels={this.state.dataLabels}
                    loadDataLabels={this.loadDataLabels}
                    updateStateLoadLabels={this.updateStateLoadLabels}
                    updateItemLabel={this.updateItemLabel}

                    //users 

                    users={this.props.users}
                    user={this.props.user}

                    //pagination

                    activePage={this.state.activePage}
                    countPage={this.state.countPage}
                    handlePageChange={this.handlePageChange}
                    labels_count={this.state.labels_count}

                    //filter labels

                    clearValuesLabel={this.clearValuesLabel}
                    handleChangeFilterLabel={this.handleChangeFilterLabel}
                    handleClickFilterLabel={this.handleClickFilterLabel}
                    showbtnSubmit={this.state.showbtnSubmit}
                    formFilterLabel={this.state.formFilterLabel}

                />
            </React.Fragment>
        );
    }
}


export default ConfigurationTask;
WebpackerReact.setup({ ConfigurationTask });

