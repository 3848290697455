import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from '../../formatos/Formats/FormCreate'

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            submitBtnFile: false,
            isLoadedFiles: false,
            id: "",

            form: {
                name: "",
                description: "",
            },

            formUpdate: {
                name: "",
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: "",
            },

            formUpdate: {
                name: "",
            },
            
            ErrorValues: true,
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        }else{
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
              ...this.state.form,
              [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true})
            this.clearValues();
        }else{
            this.setState({ modal: false})
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch("/dofa/company_objetives", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.register);
                this.clearValues();
                this.setState({ modal: false})
                this.messageSuccess(data);
            });
        }
    }


    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si"
        }).then(result => {
          if (result.value) {
            fetch(`/dofa/company_objetives/${id}`, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadData()
                this.messageSuccess(response)
              });
          }
        });
    };

    //Update section

    edit = (rol) => {
        this.setState({
            id: rol.id,
            formUpdate: {
                name: rol.name,
                module: rol.module
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    HandleClickUpdate = () => {
        fetch(`/dofa/company_objetives/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateItem(data.register)
            this.clearValues();
            this.setState({ id: ""})
            this.messageSuccess(data);
        });
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle 
                    title={"Objetivos"} 
                    subTitle={"Gestión de Objetivos"}
                    titleNew={"Nuevo Objetivo"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}    
                        title={"Agregar Objetivo"}

                        //form props
                        formValues={this.state.form}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                {(this.props.estados.editar || this.props.estados.eliminar) &&(
                                    <th className="text-center"  style={{ width: "8%" }}></th>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(procesos => (
                                    <tr key={procesos.id}>
                                        <td>
                                            {procesos.id == this.state.id ? (
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.state.formUpdate.name}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{procesos.name}</p>
                                            )}
                                        </td>

                                        {(this.props.estados.editar || this.props.estados.eliminar) &&(
                                            <td className="text-right" style={{ width: "10px" }}>
                                                {this.state.id != "" ? (
                                                    <React.Fragment>
                                                        {procesos.id == this.state.id &&(
                                                            <React.Fragment>
                                                                <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                            </React.Fragment>
                                                        )} 
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>
                            
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {this.props.estados.editar && (
                                                                    <button
                                                                        onClick={() => this.edit(procesos)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}
                            
                                                                {this.props.estados.eliminar && (  
                                                                    <button
                                                                        onClick={() => this.delete(procesos.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                            ))
                                ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
