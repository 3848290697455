import React, { Component } from "react";
import { isMobile } from 'react-device-detect';

class Filter extends Component {
  constructor(props){
    super(props)
    this.state = {
      show_btn: false
    }
  }

  handleSubmit = (e) => {
      e.preventDefault();
      this.setState({
        show_btn: true
      })
  }

  close = () =>{
    this.props.closeFilter(true)
    this.setState({
      show_btn: false
    })
  }

  render() {
    return (
      <div className="content main-card mb-3 card">
        <div className="col-md-12 mt-3">
              <form onSubmit={this.handleSubmit}>
                <div className="row">

                  <div className={`col-md-3 ${isMobile ? "mb-3" : ""}`}>
                    <label>Nombre de Usuario</label>
                    <input
                      className="form form-control"
                      type="text"
                      name="username"
                      placeholder="Nombre"
                      onChange={this.props.onChangeFilter}
                      value={this.props.formValuesFilter.username}
                    />
                  </div>

                  <div className={`col-md-3 ${isMobile ? "mb-3" : ""}`}>
                    <label>Correo Electronico</label>
                    <input
                      className="form form-control"
                      type="text"
                      name="second_name"
                      placeholder="Email"
                      onChange={this.props.onChangeFilter}
                      value={this.props.formValuesFilter.second_name}
                    />
                  </div>

                  <div className={`col-md-3 ${isMobile ? "mb-3" : ""}`}>
                    <label>Nombre</label>
                    <input
                      className="form form-control"
                      type="number"
                      name="first_name"
                      placeholder="Número de documento"
                      onChange={this.props.onChangeFilter}
                      value={this.props.formValuesFilter.first_name}
                    />
                  </div>

                  <div className="col-md-3">
                    <label>Cargos</label>
                    <select 
                      name="position_id" 
                      className="form form-control" 
                      value={this.props.formValuesFilter.position_id}  
                      onChange={this.props.onChangeFilter}
                    >
                        <option value="">Cargo</option>
                        {this.props.rols.map(item => (
                            <React.Fragment>
                                <option value={item.id}>{item.name}</option>
                            </React.Fragment>
                          ))
                        }
                    </select>
                  </div>

                  <div className="col-md-3 mt-3">
                    <label>Estado</label>
                    <select 
                      name="user_state" 
                      className="form form-control" 
                      value={this.props.formValuesFilter.user_state}  
                      onChange={this.props.onChangeFilter}
                    >
                        <option value="">Seleccione un estado</option>
                        <option value="true">Activo</option>
                        <option value="false">Inactivo</option>
                    </select>
                  </div>

              
                <div className="col-md-12 mt-4">
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <button onClick={this.props.onClickFilter} className="btn-shadow btn btn-info float-right mr-2">Aplicar</button>
                        <label onClick={this.props.cancelFilter} className="btn-shadow btn btn-danger float-right mr-2">Cerrar filtros</label>
                      </div>
                    </div>
                </div>

                </div>
              </form>
        </div>
      </div>
    );
  }
}
export default Filter;
