import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateQuestion from './FormCreateQuestion';
import { isMobile } from 'react-device-detect';
import Ckaditor from './Ckeditor';
import ReactHtmlParser from 'react-html-parser';
import Select from "react-select";
import Dropzone from "react-dropzone";
import SelectPosition from './SelecPosition'
import ModalConfigurationDate from './ModalConfigurationDate'
import ModalConfigurationDinamicFile from './ModalConfigurationDinamicFile'
import ModalResponsabilidades from '../FormatResponsabilities/FormCreate';
import FormCreateVersions from '../FormatVersions/FormCreate'
import ModalSelectType from '../Formats/ModalSelectType'
import ModalShowVersions from '../FormatVersions/Index'
import IndexFormatAssociations from '../FormatAssociations/Index'
import IndexFormatPdf from '../FormatPdf/Index'
import IndexExelReport from '../FormatExcelReports/Index'
import IndexQuestionAssociations from '../QuestionAssociation/Index';
import IndexIndicators from '../FormatIndicators/Index'
import Preloader from '../../../GeneralComponents/Preloaders';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

class Show extends Component {
    constructor(props) {
        super(props)
        const date = new Date();
        this.fecha = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modalSelectType: false,
            showTitle: false,
            showMessage: false,
            showMessageError: false,
            showQuestions: true,
            ErrorValues: true,
            ErrorValuesCreateQuestion: true,
            ErrorValuesOptions: true,
            has_taks: true,
            modalVersionCreate: false,
            modalShowVersions: false,
            has_comments: true,
            modeEditFile: false,
            showInfo: false,
            modal: false,
            modalConfigurationDinamicFile: false,
            modalResponsabilidades: false,
            data_step: {},
            was_edited: this.props.format.was_edited,
            step_position_id: "",
            config: {
                language: 'es',
                colorsHEXInput: true,
                autoFocus: true,
                toolbartop: true,
                linkAlwaysBlank: true,
                fontFamilySelection: true,
                fontSizeSelection: true,
                paragraphFormatSelection: true,
                htmlExecuteScripts: true,
                iframe: true,
                tabSpaces: 4,
                pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
                toolbarButtons: {
                    moreText: {
                        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                        // Alignment of the group in the toolbar.
                        align: 'left',

                        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                        buttonsVisible: 11
                    },


                    moreParagraph: {
                        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                        align: 'left',
                        buttonsVisible: 11
                    },


                }
            },

            nameBtn: "",
            question_id: "",
            question_config_id: "",
            step_id: "",
            stepId: "",
            generalId: "",
            countMax: 0,
            question_dinamic: {},

            formAddOptions: {
                name: ""
            },

            formCreateSeccion: {
                name: "Sección",
                format_id: "",
            },

            formUpdatePositions: {
                position_ids: [],
            },

            selectedOption: {
                format_relation_id: "",
                label: "Formato"
            },

            selectedOptionCargo: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionQuestion: {
                question_format_id: "",
                label: "Pregunta"
            },

            formUpdateStep: {
                name: "",
                description: "",
                is_private: false,
                position_ids: [],
            },

            question_info: {},

            formQuestion: {
                question: "",
                question_type: "",
                answer_formula: "",
                format_relation_id: "",
                question_format_id: "",
                code_format: "",
                step_id: "",
                description: "",
                decimal_count: 2,
                decimal_count_show: 0,
                is_multiple: false,
                is_currency: false,
                options: [],
            },

            formUpdateQuestion: {
                question: "",
                position: "",
                question_type: "",
                answer_formula: "",
                format_relation_id: "",
                code_format: "",
                description: "",
                decimal_count: 2,
                decimal_count_show: 0,
                is_multiple: false,
                is_currency: false,
                question_format_id: "",
                options: [],
            },

            formUpdateStepPosition: {
                position: "",
            },

            formUpdateFile: {
                file: {},
            },

            configuration_format: {
                has_taks: this.props.configuration_format.has_taks,
                has_comments: this.props.configuration_format.has_comments,
                has_cause_analisis: this.props.configuration_format.has_cause_analisis,
                active_external_link: this.props.configuration_format.active_external_link,
                send_notification: this.props.configuration_format.send_notification,
                email_content: this.props.configuration_format.email_content,
                columns_format_number: this.props.configuration_format.columns_format_number,
                positions: this.props.configuration_format.positions,
            },

            formResponsabilidades: {
                format_id: this.props.format.id,
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            formResponsabilidadesUpdate: {
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            modeEditResponsabilidades: false,
            showTable: false,
            format_responsability_id: "",

            url_img: this.props.format.imagen_url,
            nameFile: "",

            selectValues: [],
            formatos: [],
            questions: [],
            cargos: [],
            editValues: [],
            editValuesCargos: [],
            editValuesPosition: [],
            step_questions: [],
            all_formats: [],
            format_associations: [],

            format_resposabilities: this.props.format_resposabilities,
            loader_create_question: false,

            nameFile: "",
            sizeFile: "",
        }
    }

    clearValues = () => {
        this.setState({
            showTitle: false,
            showQuestions: true,
            showPopover: false,
            ErrorValues: true,
            ErrorValuesOptions: true,

            selectedOption: {
                format_relation_id: "",
                label: "Formato"
            },

            selectedOptionQuestion: {
                question_format_id: "",
                label: "Pregunta"
            },

            formQuestion: {
                question: "",
                question_type: "",
                answer_formula: "",
                format_relation_id: "",
                decimal_count_show: 0,
                code_format: "",
                question_format_id: "",
                step_id: "",
                description: "",
                is_multiple: false,
                is_currency: false,
                options: [],
            },

            formUpdateStep: {
                name: "",
                description: "",
                is_private: false,
                position_ids: [],
            },

            formUpdatePositions: {
                position_ids: [],
            },

            formAddOptions: {
                name: ""
            },

            formUpdateQuestion: {
                question: "",
                position: "",
                file: {},
                decimal_count_show: 0,
                question_type: "",
                answer_formula: "",
                format_relation_id: "",
                code_format: "",
                description: "",
                question_format_id: "",
                is_multiple: false,
                is_currency: false,
                options: [],
            },

            question_id: "",

            nameBtn: "",
            step_id: "",
            stepId: "",

            nameFile: "",
            sizeFile: "",


            selectValues: [],
        })
    }

    componentDidMount = () => {
        this.setDefaultValues()
    }

    setDefaultValues = () => {
        let array = [];
        let arrayCargos = [];
        let arrayPositonEdit = [];
        let arrayAssociation = [];
        let arrayFormatAssociation = [];

        this.props.positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.formatos.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.configuration_format.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        this.props.all_formats.map((item) => (
            arrayAssociation.push({ label: item.name, value: item.id })
        ))

        this.props.format_associations.map((item) => {
            arrayFormatAssociation.push({ label: item.name, value: item.id })
        })

        this.setState({
            formatos: array,
            cargos: arrayCargos,
            editValues: arrayPositonEdit,
            all_formats: arrayAssociation,
            format_associations: arrayFormatAssociation,
        })

    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    handleChangeStateQuestion = (e) => {
        if (this.state.question_id != "") {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    is_multiple: e.target.checked,
                }
            })
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    is_multiple: e.target.checked,
                }
            })
        }
    }

    handleChangeStateQuestionNumber = (e) => {
        if (this.state.question_id != "") {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    is_currency: e.target.checked,
                    answer_formula: (!e.target.checked && this.state.formUpdateQuestion.question_type == "answer_date" ? "" : this.state.formUpdateQuestion.answer_formula)
                }
            })
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    is_currency: e.target.checked,
                    answer_formula: (!e.target.checked && this.state.formQuestion.question_type == "answer_date" ? "" : this.state.formQuestion.answer_formula)
                }
            })
        }
    }

    //select 

    handleChangeAutocompleteFormatAssociation = selectedOption => {
        if (this.state.question_id != "") {
            this.setState({
                selectedOption,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        } else {
            this.setState({
                selectedOption,
                formQuestion: {
                    ...this.state.formQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        }
    };



    handleChangeAutocomplete = selectedOption => {
        if (this.state.question_id != "") {
            this.setState({
                selectedOption,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        } else {
            this.setState({
                selectedOption,
                formQuestion: {
                    ...this.state.formQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        }

        this.LoadQuestion(selectedOption.value)
    };

    handleChangeAutocompleteQuestion = selectedOptionQuestion => {
        if (this.state.formUpdateQuestion.question_format_id != "") {
            this.setState({
                selectedOptionQuestion,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    question_format_id: selectedOptionQuestion.value
                }
            });
        } else {
            this.setState({
                selectedOptionQuestion,
                formQuestion: {
                    ...this.state.formQuestion,
                    question_format_id: selectedOptionQuestion.value
                }
            });
        }
    };

    validationForm = () => {
        if (this.state.formQuestion.question != "" &&
            this.state.formQuestion.question_type != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    validationFormQuestions = () => {
        console.log("status")
        if (this.state.formQuestion.question_type == "answer_formula") {

            if (this.state.formQuestion.answer_formula != "") {
                this.setState({ ErrorValuesCreateQuestion: true })
                return true
            } else {
                this.setState({ ErrorValuesCreateQuestion: false })
                return false
            }

        } else if (this.state.formQuestion.question_type == "code_format") {
            console.log("status")
            let status = false
            if (this.state.formQuestion.code_format.includes('{cons}') && this.state.formQuestion.code_format.includes('{año}')) {
                status = true
            }
            console.log(status)

            if (this.state.formQuestion.code_format != "" && status) {
                this.setState({ ErrorValuesCreateQuestion: true })
                return true
            } else {
                console.log("qui")
                this.setState({ ErrorValuesCreateQuestion: false })
                return false
            }

        } else if (this.state.formQuestion.question_type == "format_id") {

            if (this.state.formQuestion.format_id != "" &&
                this.state.formQuestion.question_format_id != ""

            ) {
                this.setState({ ErrorValuesCreateQuestion: true })
                return true
            } else {
                this.setState({ ErrorValuesCreateQuestion: false })
                return false
            }

        } else {
            return true
        }
    }

    updateSeccion = () => {
        fetch(`/formatos/steps/${this.state.step_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateStep), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateItem(data.step);
                this.updateStateVersion(true)
                this.clearValues();
            });
    }

    updateStepQuestion = (e, question_id, last_step_id) => {
        fetch(`/formatos/update_step_question/${e.target.value}/${question_id}/${last_step_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
            });
    }

    updateStepPosition = (e) => {
        console.log(e.target.value)
        console.log(this.state.formUpdateStepPosition.position)

        fetch(`/formatos/update_step_position/${this.state.step_position_id}/${this.props.format.id}/${e.target.value}/${this.state.formUpdateStepPosition.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({
                    step_position_id: "",
                    formUpdateStepPosition: {
                        position: "",
                    }
                })
            });
    }

    createQuestion = () => {

        if (this.validationForm() == true && this.validationFormQuestions() == true) {
            this.setState({
                loader_create_question: true
            })
            fetch(`/formatos/questions`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formQuestion), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data) {
                        this.props.loadData()
                        this.updateStateVersion(true)
                        this.clearValues();
                        this.setState({ loader_create_question: false, showMessage: true })
                        setTimeout(() => {
                            this.setState({ showMessage: false })
                        }, 2000);
                    } else {
                        this.clearValues();
                        this.setState({ loader_create_question: false, showMessageError: true })
                        setTimeout(() => {
                            this.setState({ showMessageError: false })
                        }, 2000);
                    }
                });
        }
    }

    LoadQuestion = (format_id) => {
        fetch(`/formatos/get_questions/${format_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {

                let array = [];
                console.log(data)
                data.register.map((item) => (
                    array.push({ label: item.question, value: item.id })
                ))

                this.setState({
                    questions: array
                })

            });
    }

    deleteStep = (step) => {
        Swal.fire({
            title: 'Escribe el nombre de la sección para poder eliminarla',
            input: 'text',
            footer: `<p>El nombre de la sección es (${step.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == step.name.trim()) {
                    fetch(`/formatos/delete_step/${step.id}/${this.props.format.id}`, {
                        method: "delete",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.props.updateCount(response.steps);
                            this.props.reduceCountMax();
                            this.updateStateVersion(true)
                            this.props.loadData();
                        });
                } else {
                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };


    removeImagen = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "La imagen será eliminada para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/remove_img/${this.props.format.id}`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.setState({
                            url_img: null,
                        })
                    });
            }
        });
    }

    deleteQuestion = (question) => {
        Swal.fire({
            title: 'Escribe el nombre de la pregunta para poder eliminarla',
            input: 'text',
            footer: `<p>El nombre de la pregunta es (${question.question}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == question.question.trim()) {
                    fetch(`/formatos/questions/${question.id}`, {
                        method: "delete",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.props.loadData()
                            this.clearValues();
                            this.updateStateVersion(true)
                        });
                } else {
                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    add_step = () => {
        fetch(`/formatos/add_step/${this.props.format.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.step)
                this.updateStateVersion(true)
                this.props.sumState();
                this.props.sumCountMax()
            });
    }

    updateValue = (e) => {
        console.log(e.target.value)
    }

    getChangeName = (name) => {

        if (name == "Respuesta breve") {
            return "answer_string"

        } else if (name == "Párrafo") {
            return "answer_text"

        } else if (name == "Fecha") {
            return "answer_date"

        } else if (name == "Fecha Hora") {
            return "answer_datetime"

        } else if (name == "Hora") {
            return "answer_hour"
        }
        else if (name == "Carga de archivos") {
            return "answer_file"

        } else if (name == "Lista desplegable") {
            return "answer_select"

        } else if (name == "Opción múltiple") {
            return "answer_radio"

        } else if (name == "Casillas de verificación") {
            return "answer_checkbox"

        } else if (name == "Lista de usuarios") {
            return "answer_user_id"

        } else if (name == "Lista de procesos") {
            return "proceso_id"

        } else if (name == "Lista de áreas específicas") {
            return "specific_area_id"

        } else if (name == "Fórmula") {
            return "answer_formula"

        } else if (name == "Formato") {
            return "format_id"

        } else if (name == "Lista de cargos") {
            return "position_id"

        } else if (name == "Lista de eventos relacionados") {
            return "related_event_id"

        } else if (name == "Código") {
            return "code_format"

        } else if (name == "Campo numérico") {
            return "answer_integer"

        } else if (name == "Campo dinámico") {
            return "dynamic_field"

        } else if (name == "Link") {
            return "url"

        } else if (name == "Asociación entre campos") {
            return "answer_association"

        } else if (name == "Email") {
            return "email"

        } else if (name == "Geolocalización") {
            return "answer_localization"

        } else if (name == "Fecha / Hora (Automática)") {
            return "answer_autdatetime"

        } else if (name == "Subformulario") {
            return "answer_subform"

        } else if (name == "Firma") {
            return "answer_signature"
        }
    }

    getReturnName = (name) => {
        if (name == "answer_string") {
            return "Respuesta breve"

        } else if (name == "answer_text") {
            return "Párrafo"

        } else if (name == "answer_date") {
            return "Fecha"

        } else if (name == "answer_datetime") {
            return "Hora Fecha"

        } else if (name == "answer_hour") {
            return "Hora"
        }
        else if (name == "answer_file") {
            return "Carga de archivos"

        } else if (name == "answer_select") {
            return "Lista desplegable"

        } else if (name == "answer_radio") {
            return "Opción múltiple"

        } else if (name == "answer_checkbox") {
            return "Casillas de verificación"

        } else if (name == "answer_integer") {
            return "Campo numérico"

        } else if (name == "answer_user_id") {
            return "Lista de usuarios"

        } else if (name == "dynamic_field") {
            return "Campo dinámico"

        } else if (name == "proceso_id") {
            return "Lista de procesos"

        } else if (name == "specific_area_id") {
            return "Lista de áreas específicas"

        } else if (name == "position_id") {
            return "Lista de cargos"

        } else if (name == "answer_formula") {
            return "Fórmula"

        } else if (name == "format_id") {
            return "Formato"

        } else if (name == "code_format") {
            return "Código"

        } else if (name == "related_event_id") {
            return "Lista de eventos relacionados"

        } else if (name == "url") {
            return "Link"

        } else if (name == "answer_association") {
            return "Asociación entre campos"

        } else if (name == "email") {
            return "Email"

        } else if (name == "answer_localization") {
            return "Geolocalización"

        } else if (name == "answer_autdatetime") {
            return "Fecha / Hora (Automática)"

        } else if (name == "answer_subform") {
            return "Subformulario"

        } else if (name == "answer_signature") {
            return "Firma"
        }
    }

    setName = (name) => {
        this.setState({
            showPopover: false,
            ErrorValues: true,
            nameBtn: name,

            formQuestion: {
                ...this.state.formQuestion,
                question_type: this.getChangeName(name),
            },

            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                question_type: this.getChangeName(name),
                //options: this.state.selectValues,
            }
        })
    }

    HandleChangeFormOptions = (e) => {
        this.setState({
            formAddOptions: {
                name: e.target.value
            }
        });
    }

    HandleChangeQuestion = (e, step) => {
        this.setState({
            formQuestion: {
                ...this.state.formQuestion,
                [e.target.name]: e.target.value,
                step_id: step.id
            }
        });
    }

    HandleChangeFormula = (e) => {
        this.setState({
            formQuestion: {
                ...this.state.formQuestion,
                [e.target.name]: e.target.value,
            }
        });
    }

    AddNameToQuestion = (name) => {
        this.setState({
            formQuestion: {
                ...this.state.formQuestion,
                question_type: name,
            }
        });
    }

    HandleChangeUpdateStep = (e) => {
        this.setState({
            formUpdateStep: {
                ...this.state.formUpdateStep,
                [e.target.name]: e.target.value,
            }
        })
    }

    HandleChangeUpdateStepDescription = (e) => {
        this.setState({
            formUpdateStep: {
                ...this.state.formUpdateStep,
                description: e,
            }
        })
    }

    HandleChangeUpdateConfiguration = (e) => {
        this.setState({
            configuration_format: {
                ...this.state.configuration_format,
                email_content: e,
            }
        })
    }

    HandleChangeUpdateConfigurationText = (e) => {
        this.setState({
            configuration_format: {
                ...this.state.configuration_format,
                [e.target.name]: e.target.value,
            }
        })
    }


    HandleChangeOptions = (e, selectValuesIndex) => {
        const input = e.target.value
        this.setState({
            selectValues: this.state.selectValues.map((item, index) => {
                if (selectValuesIndex === index) {
                    return input
                }
                return item;
            })
        });
    }


    getIcon = (name) => {
        if (name == "Respuesta breve") {
            return <i className="fas fa-align-center"></i>
        } else if (name == "Párrafo") {
            return <i className="fas fa-align-left"></i>
        } else if (name == "Fecha") {
            return <i className="fas fa-calendar-day"></i>
        } else if (name == "Hora Fecha") {
            return <i className="fas fa-clock"></i>
        } else if (name == "Hora") {
            return <i className="fas fa-clock"></i>
        }
        else if (name == "Carga de archivos") {
            return <i className="fas fa-cloud-upload-alt"></i>
        } else if (name == "Lista desplegable") {
            return <i className="fas fa-chevron-circle-down"></i>
        } else if (name == "Opción múltiple") {
            return <i className="far fa-dot-circle"></i>
        } else if (name == "Casillas de verificación") {
            return <i className="fas fa-check-square"></i>
        } else if (name == "Lista de usuarios") {
            return <i className="fas fa-users"></i>
        } else if (name == "Lista de procesos") {
            return <i className="fas fa-users"></i>
        } else if (name == "Lista de áreas específicas") {
            return <i className="fas fa-users"></i>
        } else if (name == "Lista de cargos") {
            return <i className="fas fa-users"></i>
        } else if (name == "Fórmula") {
            return <i className="fas fa-square-root-alt"></i>
        } else if (name == "Lista de eventos relacionados") {
            return <i className="fas fa-users"></i>
        } else if (name == "Formato") {
            return <i className="fas fa-database"></i>
        } else if (name == "Campo numérico") {
            return <i className="fas fa-list-ol"></i>
        } else if (name == "Código") {
            return <i className="far fa-list-alt"></i>
        } else if (name == "Campo dinámico") {
            return <i className="fas fa-list-ol"></i>
        } else if (name == "Link") {
            return <i className="fas fa-link"></i>
        } else if (name == "Email") {
            return <i className="fas fa-envelope"></i>
        } else if (name == "Asociación entre campos") {
            return <i className="fas fa-envelope"></i>
        } else if (name == "Geolocalización") {
            return <i className="fas fa-map-marker-alt"></i>
        } else if (name == "Fecha / Hora (Automática)") {
            return <i className="fas fa-clock"></i>
        } else if (name == "Subformulario") {
            return <i className="fas fa-copy"></i>
        } else if (name == "Firma") {
            return <i className="fas fa-file-signature"></i>
        }
    }

    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    style={{ width: "65%" }}
                    placeholder={"Texto de respuesta breve"}
                />

            )

        } else if (question.question_type == "answer_integer") {
            return (
                <input
                    type="number"
                    className="form form-control imput-options-disabled"
                    disabled
                    style={{ width: "65%" }}
                    placeholder={"Texto numerico"}
                />
            )

        } else if (question.question_type == "answer_hour") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    style={{ width: "65%" }}
                    placeholder={"Hora"}
                />
            )

        }
        else if (question.question_type == "url") {
            return (
                <input
                    type="number"
                    className="form form-control imput-options-disabled"
                    disabled
                    style={{ width: "65%" }}
                    placeholder={"Url"}
                />
            )

        } else if (question.question_type == "email") {
            return (
                <input
                    type="number"
                    className="form form-control imput-options-disabled"
                    disabled
                    style={{ width: "65%" }}
                    placeholder={"Email"}
                />
            )

        } else if (question.question_type == "answer_user_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Seleccion de usuarios</option>
                </select>
            )

        } else if (question.question_type == "dynamic_field") {
            return (
                <button
                    className="btn-shadow btn btn-info"
                    onClick={() => this.toogleDinamicFile("new", question)}
                >
                    <i className="fas fa-gear"></i> Configurar campo
                </button>
            )

        } else if (question.question_type == "proceso_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Seleccion de procesos</option>
                </select>
            )

        } else if (question.question_type == "specific_area_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Seleccion de áreas específicas</option>
                </select>
            )

        } else if (question.question_type == "position_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Seleccion de cargos</option>
                </select>
            )

        } else if (question.question_type == "related_event_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Seleccion de eventos relacionados</option>
                </select>
            )

        } else if (question.question_type == "answer_formula") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.answer_formula}
                />
            )
        } else if (question.question_type == "code_format") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.code_format}
                />
            )

        } else if (question.question_type == "answer_subform") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.format_relation != undefined ? question.format_relation.name : ""}
                />
            )

        } else if (question.question_type == "format_id") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.format_relation != undefined ? question.format_relation.name : ""}
                />
            )

        } else if (question.question_type == "answer_text") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    placeholder={"Texto de respuesta largo"}
                />
            )

        } else if (question.question_type == "answer_date") {
            return (
                <button
                    className="btn bnt-light"
                    style={{ border: "1px solid" }}
                    disabled
                >
                    <i className="far fa-calendar-alt"></i> {this.fecha}
                </button>
            )

        } else if (question.question_type == "answer_datetime") {
            return (
                <button
                    className="btn bnt-light"
                    style={{ border: "1px solid" }}
                    disabled
                >
                    <i className="fas fa-clock"></i> {this.fecha}
                </button>
            )

        } else if (question.question_type == "answer_file") {
            return (
                <button
                    className="btn bnt-light"
                    style={{ border: "1px solid" }}
                    disabled
                >
                    <i className="fas fa-upload mr-2"></i> Agregar archivo
                </button>
            )

        } else if (question.question_type == "answer_localization") {
            return (
                <div className="col-md-8 pl-0">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-map-marker-alt mr-2"></i> Geolocalización
                    </button>
                </div>
            )

        } else if (question.question_type == "answer_autdatetime") {
            return (
                <div className="col-md-8">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-clock mr-2"></i> Fecha / Hora (Automática)
                    </button>
                </div>
            )

        } else if (question.question_type == "answer_association") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-3">
                            <b>Formato</b>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={question.format_relation ? question.format_relation.name : ""}
                            />
                        </div>

                        <div className="col-md-3">
                            <b>Pregunta</b>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={question.question_format ? question.question_format.question : ""}
                            />
                        </div>

                        <div className="col-md-3">
                            <b>Formula</b>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={question.answer_formula}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select") {

            return (
                <React.Fragment>
                    {question.options.map((name, index) => (
                        <p className={`${index == 0 ? "mt-3" : ""}`}>{index + 1}. {name}</p>
                    ))}
                </React.Fragment>
            )

        } else if (question.question_type == "answer_radio") {

            return (
                <React.Fragment>
                    {question.options.map((name, index) => (
                        <p className={`${index == 0 ? "mt-3" : ""}`}><i className="far fa-dot-circle"></i> {name}</p>
                    ))}

                </React.Fragment>
            )

        } else if (question.question_type == "answer_checkbox") {

            return (
                <React.Fragment>
                    {question.options.map((name, index) => (
                        <p className={`${index == 0 ? "mt-3" : ""}`}><i className="fas fa-check-square"></i> {name}</p>
                    ))}
                </React.Fragment>
            )
        } else if (question.question_type == "answer_signature") {

            return (
                <React.Fragment>
                    <div className="col-md-8 pl-0">
                        <button
                            disabled="disabled"
                            className="btn btn-light"
                        >
                            <i className="fas fa-file-signature mr-2"></i> Firma
                        </button>
                    </div>
                </React.Fragment>
            )
        }
    }

    removeArray = (index) => {
        var arr = this.state.selectValues
        delete arr[index]
        this.setState({
            selectValues: arr.filter(item => item != null)
        })
    }

    removeArrayUpdateQuestion = (index) => {
        var arr = this.state.formUpdateQuestion.options
        delete arr[index]
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                options: arr.filter(item => item != null),
            },
        })
    }

    addArray = () => {
        if (this.validationFormOptions() == true) {
            const array = this.state.selectValues
            array.push(this.state.formAddOptions.name)

            if (this.state.question_id != "") {
                this.setState({
                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        options: array,
                    },

                    formAddOptions: {
                        name: ""
                    },

                    selectValues: array
                })
            } else {
                this.setState({
                    formQuestion: {
                        ...this.state.formQuestion,
                        options: array,
                    },

                    formAddOptions: {
                        name: ""
                    },

                    selectValues: array
                })
            }
        }
    }

    addArrayFormUpdate = () => {
        const array = this.state.formUpdateQuestion.options
        array.push(this.state.formAddOptions.name)

        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                options: array,
            },

            formAddOptions: {
                name: ""
            },
        })
    }

    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                [e.target.name]: e.target.value,
            },
        })
    }

    validationFormOptions = () => {
        if (this.state.formAddOptions.name != "") {
            this.setState({ ErrorValuesOptions: true })
            return true
        } else {
            this.setState({ ErrorValuesOptions: false })
            return false
        }
    }

    addAnother = () => {
        if (this.state.question_id != "") {
            const array = this.state.formUpdateQuestion.options
            array.push("Otra..")

            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    options: array,
                },

                formAddOptions: {
                    name: ""
                },

                selectValues: array
            })
        } else {

            const array = this.state.formQuestion.options
            array.push("Otra..")

            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    options: array,
                },

                formAddOptions: {
                    name: ""
                },

                selectValues: array
            })
        }
    }


    getOptions = (options, add_array, removeArray, handleChangeOptions) => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <input
                            type="text"
                            name="name"
                            onChange={(e) => this.HandleChangeFormOptions(e)}
                            className={`form form-control ${this.state.ErrorValuesOptions == false && this.state.formAddOptions.name == "" ? "error-class" : ""}`}
                            value={this.state.formAddOptions.name}
                            placeholder={`Opción ${options.length == 0 ? "1" : options.length + 1}`}
                        />

                        <a
                            className="link-add"
                            onClick={() => add_array()}
                        >
                            Crear
                        </a>

                        <a
                            className="link-remove"
                            onClick={() => this.clearValues()}
                        >
                            Cancelar
                        </a>
                    </div>

                    <div className={`col-md-12 mt-3`}>
                        <div className="row">
                            {options.map((name, index) => (
                                <React.Fragment>
                                    <div className={`col-md-11 ${index > 0 ? "mt-3" : ""}`} key={index}>
                                        <input
                                            type="text"
                                            className="form form-control imput-options"
                                            value={this.state.formUpdateQuestion.options[index]}
                                            placeholder={name}
                                            onChange={(e) => handleChangeOptions(e, index)}
                                            disabled={name != "Otra.." ? false : true}
                                        />
                                    </div>

                                    <div className={`col-md-1 text-center ${index > 0 ? "mt-3" : ""}`}>
                                        <i className="fas fa-times icon-remove-option" onClick={() => removeArray(index)}></i>
                                    </div>
                                </React.Fragment>
                            ))}

                            {!options.includes("Otra..") && (
                                <button
                                    className="btn btn-add-another mt-3"
                                    onClick={() => this.addAnother()}
                                    style={{ color: "#299ef9" }}
                                >
                                    Agregar Otros
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }

    getInputs = (name, array) => {
        if (name == "Respuesta breve") {
            return (
                <div className="col-md-8">
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        style={{ width: "65%" }}
                        placeholder={"Texto de respuesta breve"}
                    />
                </div>
            )

        } else if (name == "Párrafo") {
            return (
                <React.Fragment>
                    <div className="col-md-7">
                        <input
                            type="text"
                            className="form form-control imput-options-disabled"
                            disabled
                            placeholder={"Texto de respuesta largo"}
                        />
                    </div>

                    {/*  <div className="col-md-4 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e)} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                            <label className="custom-control-label" htmlFor={`customCheckboxState`}>¿Es texto enriquecido?</label>
                        </div>
                    </div> */}
                </React.Fragment>
            )

        } else if (name == "Link") {
            return (
                <div className="col-md-8">
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        placeholder={"Url"}
                    />
                </div>
            )

        } else if (name == "Email") {
            return (
                <div className="col-md-8">
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        placeholder={"Email"}
                    />
                </div>
            )

        } else if (name == "Campo numérico") {
            return (
                <React.Fragment>
                    <div className="row" style={{ paddingLeft: "15px" }}>
                        <div className="col-md-7">
                            <input
                                type="number"
                                style={{ marginTop: "29px" }}
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={"Texto numerico"}
                            />
                        </div>

                        <div className="col-md-2">
                            <label>Decimales para calcular</label>
                            <input
                                type="number"
                                className={`form form-control`}
                                name="decimal_count"
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count : this.state.formQuestion.decimal_count}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={"Decimales"}
                            />
                        </div>

                        <div className="col-md-3">
                            <label>Decimales para mostrar</label>
                            <input
                                type="number"
                                className={`form form-control`}
                                name="decimal_count_show"
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count_show : this.state.formQuestion.decimal_count_show}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={""}
                            />
                        </div>
                    </div>

                    <div className="col-md-3 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                            <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>¿Es moneda?</label>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (name == "Geolocalización") {
            return (
                <div className="col-md-8">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-map-marker-alt mr-2"></i> Geolocalización
                    </button>
                </div>
            )

        } else if (name == "Fecha / Hora (Automática)") {
            return (
                <div className="col-md-8">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-clock mr-2"></i> Fecha / Hora (Automática)
                    </button>
                </div>
            )

        } else if (name == "Hora") {
            return (
                <div className="col-md-8">
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        style={{ width: "65%" }}
                        placeholder={"Hora"}
                    />
                </div>
            )

        }
        else if (name == "Campo dinámico") {
            return (
                <div className="col-md-8">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        Configurar campo
                    </button>
                </div>
            )

        } else if (name == "Fórmula") {
            return (
                <React.Fragment>
                    <div className="row" style={{ paddingLeft: "15px" }}>
                        

                        <div className="col-md-5">
                            <label>Decimales para mostrar</label>
                            <input
                                type="number"
                                className={`form form-control`}
                                name="decimal_count_show"
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count_show : this.state.formQuestion.decimal_count_show}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={""}
                            />
                        </div>

                        <div className="col-md-4">
                            <label>Decimales para calcular</label>
                            <input
                                type="number"
                                className={`form form-control`}
                                name="decimal_count"
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count : this.state.formQuestion.decimal_count}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={"Decimales"}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                            <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>¿Es moneda?</label>
                        </div>
                    </div>
                    <div className="col-md-8">
                            <input
                                type="text"
                                className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.answer_formula == "" ? "error-class" : ""}`}
                                name="answer_formula"
                                style={{ marginTop: "29px" }}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={"Copia la formula matematica"}
                            />
                        </div>

                    <div className="col-md-12">
                        <br />
                        <p>Ejemplo 1: {`{p.S1_P1} + {p.S1_P2}`} suma entre la pregunta 1 de la sección 1 y la pregunta 2 de la sección 1  </p>
                        <p>Ejemplo 2: {`{p.S1_P1} * {p.S2_P1}`} multiplicación entre la pregunta 1 de la sección 1 y pregunta 1 de la sección 2  </p>
                        <p>Cada pregunta de la cual depende la formula se conforma así {`{p.S1_P1}`}</p>
                        <p>Debe ir entre llaves {`{}`}</p>
                        <p>siempre debe ir {`{p.}`}</p>
                        <p>Luego la S y el número de la sección{`{S1}`}</p>
                        <p>Luego un guion bajo{`{ _ }`}</p>
                        <p>Luego la P y el número de la pregunta{`{P1}`}</p>

                    </div>
                </React.Fragment>
            )

        } else if (name == "Código") {
            return (
                <div className="col-md-12">
                    <input
                        type="text"
                        className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false ? "error-class" : ""}`}
                        style={{ width: "65%" }}
                        name="code_format"
                        value={this.state.question_id != "" ? this.state.formUpdateQuestion.code_format : this.state.formQuestion.code_format}
                        onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                        placeholder={"Código"}
                    />
                    <span style={{ color: "red", display: this.state.ErrorValuesCreateQuestion == false ? "block" : "none" }}>El formato del código es invalido</span>
                    <br />
                    <p>Debes ingresar {`{cons}`} donde quieras poner el consecutivo y {`{año}`} donde quieras los dos últimos dígitos del año Ej: {`{cons}-FMTO-{año}`}  {`1-FMTO-20`} </p>
                </div>
            )

        } else if (name == "Subformulario") {
            return (
                <React.Fragment>
                    <div className="col-md-6">
                        <input
                            type="hidden"
                            name="format_id"
                            value={this.state.selectedOption.format_relation_id}
                        />
                        <label htmlFor="">Formato</label>
                        <Select
                            onChange={this.handleChangeAutocomplete}
                            options={this.state.formatos}
                            autoFocus={false}
                            className={`link-form ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                            value={this.state.selectedOption}
                        />
                    </div>

                    {/*this.state.questions.length >= 1 && (
                        <div className="col-md-6">
                            <input
                                type="hidden"
                                name="question_format_id"
                                value={this.state.selectedOptionQuestion.question_format_id}
                            />
                            <label htmlFor="">Campo para mostrar</label>
                            <Select
                                onChange={this.handleChangeAutocompleteQuestion}
                                options={this.state.questions}
                                autoFocus={false}
                                className={`link- ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.question_format_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOptionQuestion}
                            />
                        </div>
                    )*/}
                </React.Fragment>
            )

        } else if (name == "Formato") {
            return (
                <React.Fragment>
                    <div className="col-md-6">
                        <input
                            type="hidden"
                            name="format_id"
                            value={this.state.selectedOption.format_relation_id}
                        />
                        <label htmlFor="">Formato</label>
                        <Select
                            onChange={this.handleChangeAutocomplete}
                            options={this.state.formatos}
                            autoFocus={false}
                            className={`link-form ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                            value={this.state.selectedOption}
                        />
                    </div>

                    {this.state.questions.length >= 1 && (
                        <div className="col-md-6">
                            <input
                                type="hidden"
                                name="question_format_id"
                                value={this.state.selectedOptionQuestion.question_format_id}
                            />
                            <label htmlFor="">Campo para mostrar</label>
                            <Select
                                onChange={this.handleChangeAutocompleteQuestion}
                                options={this.state.questions}
                                autoFocus={false}
                                className={`link- ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.question_format_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOptionQuestion}
                            />
                        </div>
                    )}

                    <div className="col-md-12 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e)} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                            <label className="custom-control-label" htmlFor={`customCheckboxState`}>¿Es multiple?</label>
                        </div>
                    </div>

                </React.Fragment>
            )

        } else if (name == "Asociación entre campos") {
            return (
                <React.Fragment>
                    <div className="col-md-4 mt-3">
                        <input
                            type="hidden"
                            name="format_id"
                            value={this.state.selectedOption.format_relation_id}
                        />
                        <label htmlFor="">Formato</label>
                        <Select
                            onChange={this.handleChangeAutocomplete}
                            options={this.state.all_formats}
                            autoFocus={false}
                            className={`link-form ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                            value={this.state.selectedOption}
                        />
                    </div>
                    {this.state.questions.length >= 1 && (
                        <div className="col-md-4 mt-3">
                            <input
                                type="hidden"
                                name="question_format_id"
                                value={this.state.selectedOptionQuestion.question_format_id}
                            />
                            <label htmlFor="">Pregunta</label>
                            <Select
                                onChange={this.handleChangeAutocompleteQuestion}
                                options={this.state.questions}
                                autoFocus={false}
                                className={`link- ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.question_format_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOptionQuestion}
                            />
                        </div>
                    )}

                    <div className="col-md-4 mt-3">
                        <label htmlFor="">Formula</label>
                        <select
                            className="form form-control"
                            name="answer_formula"
                            value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                            onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="Suma">Suma</option>
                            <option value="Maximo">Máximo</option>
                            <option value="Minimo">Mínimo</option>
                            <option value="Contador">Cantidad de registros</option>
                            <option value="Valor">Mismo Valor(Ingra el mismo valor del campo que agregaste)</option>
                            {/*  <option value="Promedio">Promedio</option> */}
                        </select>
                    </div>



                    {/*<div className="col-md-12 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e)} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                            <label className="custom-control-label" htmlFor={`customCheckboxState`}>¿Es multiple?</label>
                        </div>
                    </div>*/}

                </React.Fragment>
            )

        } else if (name == "Lista de usuarios") {
            return (
                <React.Fragment>
                    <div className="col-md-8">
                        <select
                            className="form form-control imput-options-disabled"
                            disabled
                        >
                            <option value="">Seleccion de usuarios</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e)} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                            <label className="custom-control-label" htmlFor={`customCheckboxState`}>¿Es multiple?</label>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (name == "Lista de procesos") {
            return (
                <div className="col-md-8">
                    <select
                        className="form form-control imput-options-disabled"
                        disabled
                    >
                        <option value="">Seleccion de procesos</option>
                    </select>
                </div>
            )

        } else if (name == "Lista de áreas específicas") {
            return (
                <div className="col-md-8">
                    <select
                        className="form form-control imput-options-disabled"
                        disabled
                    >
                        <option value="">Seleccion de áreas específicas</option>
                    </select>
                </div>
            )

        } else if (name == "Lista de cargos") {
            return (
                <div className="col-md-8">
                    <select
                        className="form form-control imput-options-disabled"
                        disabled
                    >
                        <option value="">Seleccion de cargos</option>
                    </select>
                </div>
            )

        } else if (name == "Lista de eventos relacionados") {
            return (
                <div className="col-md-8">
                    <select
                        className="form form-control imput-options-disabled"
                        disabled
                    >
                        <option value="">Seleccion de eventos relacionados</option>
                    </select>
                </div>
            )

        } else if (name == "Fecha") {
            return (
                <div className="col-md-12">
                    <div className="row mb-3">

                        {(this.state.formQuestion.is_currency || this.state.formUpdateQuestion.is_currency) && (
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.answer_formula == "" ? "error-class" : ""}`}
                                    name="answer_formula"
                                    style={{ width: "65%" }}
                                    value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                                    onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                    placeholder={"Copia la formula matematica"}
                                />
                            </div>
                        )}

                        <div className="col-md-3">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                                <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>¿Es formula?</label>
                            </div>
                        </div>
                    </div>

                    <button
                        className="btn bnt-light"
                        style={{ border: "1px solid" }}
                        disabled
                    >
                        <i className="far fa-calendar-alt"></i> {this.fecha}
                    </button>
                </div>
            )

        } else if (name == "Hora") {
            return (
                <div className="col-md-12">
                    <button
                        className="btn bnt-light"
                        style={{ border: "1px solid" }}
                        disabled
                    >
                        <i className="fas fa-clock"></i> {this.fecha}
                    </button>
                </div>
            )

        } else if (name == "Carga de archivos") {
            return (
                <div className="col-md-12">
                    <button
                        className="btn bnt-light"
                        style={{ border: "1px solid" }}
                        disabled
                    >
                        <i className="fas fa-upload mr-2"></i> Agregar archivo
                    </button>
                </div>
            )

        } else if (name == "Casillas de verificación") {
            return (
                <React.Fragment>
                    <div className="col-md-8">
                        {this.getOptions(this.state.selectValues, this.addArray, this.removeArray, this.HandleChangeOptions)}
                    </div>
                </React.Fragment>
            )

        } else if (name == "Opción múltiple") {
            return (
                <React.Fragment>
                    <div className="col-md-8">
                        {this.getOptions(this.state.selectValues, this.addArray, this.removeArray, this.HandleChangeOptions)}
                    </div>
                </React.Fragment>
            )

        } else if (name == "Lista desplegable") {
            return (
                <React.Fragment>
                    <div className="col-md-8">
                        {this.getOptions(this.state.selectValues, this.addArray, this.removeArray, this.HandleChangeOptions)}
                    </div>
                </React.Fragment>
            )
        } else if (name == "Firma") {
            return (
                <React.Fragment>
                    <div className="col-md-8">
                        <button
                            disabled="disabled"
                            className="btn btn-light"
                        >
                            <i className="fas fa-file-signature mr-2"></i> Firma
                        </button>
                    </div>
                </React.Fragment>
            )
        }
    }

    HandleChangeUpdateSelect = (e) => {
        fetch(`/formatos/update_select/${this.props.configuration_format.id}/${e.target.value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
                this.setState({
                    configuration_format: {
                        ...this.state.configuration_format,
                        has_taks: data.configuration_format.has_taks,
                        has_comments: data.configuration_format.has_comments,
                        send_notification: data.configuration_format.send_notification,
                        active_external_link: data.configuration_format.active_external_link,
                        has_cause_analisis: data.configuration_format.has_cause_analisis,
                        columns_format_number: data.configuration_format.columns_format_number,
                    }
                })
        });
    }


    //questions options 

    editQuestion = (question, step) => {
        this.setState({
            formUpdateQuestion: {
                question: question.question,
                position: question.position,
                file: question.file,
                decimal_count_show: question.decimal_count_show,
                question_type: question.question_type,
                answer_formula: question.answer_formula,
                code_format: question.code_format,
                is_multiple: question.is_multiple,
                is_currency: question.is_currency,
                description: question.description,
                decimal_count: question.decimal_count,
                question_format_id: question.question_format_id,
                format_relation_id: question.format_relation_id,
                options: question.options,
            },

            selectedOption: {
                format_relation_id: question.format_relation_id,
                label: `${question.format_relation != null ? question.format_relation.name : "Seleccione el formato"}`
            },

            selectedOptionQuestion: {
                question_format_id: question.question_format_id,
                label: `${question.question_format != null ? question.question_format.question : "Seleccione el campo"}`
            },

            stepId: step,
            question_id: question.id,
            nameBtn: this.getReturnName(question.question_type)
        })

        if (question.question_format != null) {
            this.LoadQuestion(question.format_id)
        }
    }

    HandleChangeUpdateQuestion = (e) => {
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                [e.target.name]: e.target.value,
            },
        })
    }

    handleFileQuestionImagen = (archivo) => {
        console.log("archivoarchivoarchivoarchivo", archivo);
        archivo.map(file => (
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    file: file
                },
  
                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    handleClickUpdate = (question_id, type_position) => {
        fetch(`/formatos/update_position/${question_id}/${type_position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.clearValues();
            });
    }

    updatePositionQuestion = (e, step_id) => {
        fetch(`/formatos/update_position/${this.state.question_id}/${step_id}/${e.target.value}/${this.state.formUpdateQuestion.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.updateStateVersion(true)
                this.clearValues();
            });
    }

    //CREAR PREGUNTA
    HadleClickUpdateQuestion = () => {

        /*
        console.log("this.state.formUpdateQuestion.options", Array.from(this.state.formUpdateQuestion.options));
        const formData = new FormData();
        formData.append("question", this.state.formUpdateQuestion.question)
        formData.append("position", this.state.formUpdateQuestion.position)
        formData.append("file", this.state.formUpdateQuestion.file)
        formData.append("question_type", this.state.formUpdateQuestion.question_type)
        formData.append("answer_formula", this.state.formUpdateQuestion.answer_formula)
        formData.append("code_format", this.state.formUpdateQuestion.code_format)
        formData.append("is_multiple", this.state.formUpdateQuestion.is_multiple)
        formData.append("is_currency", this.state.formUpdateQuestion.is_currency)
        formData.append("description", this.state.formUpdateQuestion.description)
        formData.append("decimal_count", this.state.formUpdateQuestion.decimal_count)
        formData.append("question_format_id", this.state.formUpdateQuestion.question_format_id)
        formData.append("format_relation_id", this.state.formUpdateQuestion.format_relation_id)
        formData.append("options", Array.from(this.state.formUpdateQuestion.options))
        formData.append("decimal_count_show", this.state.formUpdateQuestion.decimal_count_show)

        fetch(`/formatos/questions/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.updateStateVersion(true);
            this.clearValues();
        });

        */

        fetch(`/formatos/questions/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateQuestion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.updateStateVersion(true);
            this.clearValues();
        });
    }

    HandleChangeUpdateOptions = (e, selectValuesIndex) => {
        const input = e.target.value
        console.log("dddddd")
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                options: this.state.formUpdateQuestion.options.map((item, index) => {
                    if (selectValuesIndex === index) {
                        return input
                    }
                    return item;
                })
            }
        });
    }


    updateId = (id) => {
        this.setState({
            step_id: id,
            showPopover: true,
        })
    }

    clearId = () => {
        this.setState({
            showPopover: false
        })
    }

    updateState = (id, state, countryside) => {
        fetch(`/formatos/update_state/${id}/${state}/${countryside}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
            });
    }

    updateStateFormat = (state, countryside) => {
        fetch(`/formatos/update_state_format/${this.props.format.id}/${state}/${countryside}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    configuration_format: {
                        ...this.state.configuration_format,
                        has_taks: data.configuration_format.has_taks,
                        has_comments: data.configuration_format.has_comments,
                        send_notification: data.configuration_format.send_notification,
                        active_external_link: data.configuration_format.active_external_link,
                        has_cause_analisis: data.configuration_format.has_cause_analisis,
                    }
                })
            });
    }

    handleChangeAutocompleteCargo = selectedOption => {
        let array = []

        if (selectedOption != null) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))

            this.setState({
                configuration_format: {
                    ...this.state.configuration_format,
                    position_ids: array
                }
            })

        } else {

            this.setState({
                configuration_format: {
                    ...this.state.configuration_format,
                    position_ids: []
                }
            })
        }
    }

    handleChangeAutocompletePosition = selectedOption => {
        let array = []

        if (selectedOption != null) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))

            this.setState({
                formUpdatePositions: {
                    ...this.state.formUpdatePositions,
                    position_ids: array
                }
            })

        } else {

            this.setState({
                formUpdatePositions: {
                    ...this.state.formUpdatePositions,
                    position_ids: []
                }
            })
        }
    }

    updatePositions = (question_id) => {
        fetch(`/formatos/update_question_positions/${question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdatePositions), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.setState({
                    editValuesPosition: [],
                    question_config_id: "",

                    formUpdatePositions: {
                        position_ids: []
                    },

                    selectedOptionCargo: {
                        position_ids: "",
                        label: "Cargos"
                    },
                })
            });
    }


    configQuestion = (question) => {
        const array = [];
        const arrayIds = [];

        if (question.positions.length >= 1) {

            question.positions.map((item) => (
                array.push({ label: item.name, value: item.id }),
                arrayIds.push(item.id)
            ))

            this.setState({
                editValuesPosition: array,
                formUpdatePositions: {
                    position_ids: arrayIds,
                }
            })
        } else {
            this.setState({
                editValuesPosition: array,
                formUpdatePositions: {
                    position_ids: []
                }
            })
        }

        if (this.state.question_config_id != "") {
            this.setState({
                question_config_id: "",
            })
        } else {
            this.setState({
                question_config_id: question.id,
            })
        }
    }


    updateStateStep = (e) => {
        this.setState({
            formUpdateStep: {
                ...this.state.formUpdateStep,
                is_private: e.target.checked,
            }
        })
    }

    handleChangeAutocompleteCargoStep = selectedOption => {
        let array = []

        if (selectedOption != null) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))

            this.setState({
                formUpdateStep: {
                    ...this.state.formUpdateStep,
                    position_ids: array
                }
            })

        } else {

            this.setState({
                formUpdateStep: {
                    ...this.state.formUpdateStep,
                    position_ids: []
                }
            })
        }

    }

    editStep = (step) => {
        const array = []

        step.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            showTitle: !this.state.showTitle,
            step_id: step.id,
            formUpdateStep: {
                name: step.name,
                description: step.description,
                is_private: step.is_private,
            },

            editValuesCargos: array,
        })
    }

    handleClickUpdateConfiguration = () => {
        fetch(`/formatos/configuration_formats/${this.props.configuration_format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.configuration_format), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                const arrayPositonEdit = []

                this.messageSuccess(data)

                data.positions.map((item) => (
                    arrayPositonEdit.push({ label: item.name, value: item.id })
                ))

                this.setState({
                    showInfo: false,
                    editValues: arrayPositonEdit,
                    configuration_format: {
                        ...this.state.configuration_format,
                        positions: data.positions,
                        email_content: data.configuration_format.email_content,
                    }
                })
            });
    }


    //añadir archivo 

    HandleClickFile = () => {
        const formData = new FormData();
        formData.append("file", this.state.formUpdateFile.file)

        fetch(`/formatos/update_file_format/${this.props.format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    formUpdateFile: {
                        file: {},
                    },

                    url_img: data.imagen,
                    nameFile: "",
                    modeEditFile: false,
                })
            });
    }


    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formUpdateFile: {
                    ...this.state.formUpdateFile,
                    file: file
                },

                nameFile: file.path,
            })
        ));
    };

    cancelUpload = () => {
        this.setState({
            formUpdateFile: {
                ...this.state.formUpdateFile,
                file: ""
            },

            nameFile: "",
            modeEditFile: false,
        })
    }

    editFIle = () => {
        this.setState({
            modeEditFile: true,
        })
    }

    getIconsPosition = (question) => {
        const size = {
            fontSize: "26px",
            cursor: "pointer"
        }

        if (question.position == 1) {
            return <i className="fas fa-angle-down" onClick={() => this.handleClickUpdate(question.id, "down")} style={size}></i>
        } else if (question.position == question.last_position) {
            return <i className="fas fa-angle-up" onClick={() => this.handleClickUpdate(question.id, "up")} style={size}></i>
        } else {
            return (
                <React.Fragment>
                    <i className="fas fa-angle-down mr-3" onClick={() => this.handleClickUpdate(question.id, "down")} style={size}></i>
                    <i className="fas fa-angle-up" onClick={() => this.handleClickUpdate(question.id, "up")} style={size}></i>
                </React.Fragment>
            )
        }
    }

    toogle = (from, question, questions) => {
        if (from == "new") {
            this.setState({ modal: true, question_info: question, step_questions: questions })
        } else {
            this.setState({ modal: false, question_info: {}, step_questions: [] })
            this.props.loadData()
        }
    }

    toogleSelectType = (from, step_id) => {
        if (from == "new") {
            this.setState({ modalSelectType: true, step_id: step_id })
        } else {
            this.setState({ modalSelectType: false })
        }
    }

    toogleResponsabilidades = (from) => {
        if (from == "new") {
            this.setState({ modalResponsabilidades: true, modeEditResponsabilidades: false })
        } else {
            this.setState({ modalResponsabilidades: false, modeEditResponsabilidades: false })
        }
    }

    toogleDinamicFile = (from, question) => {
        if (from == "new") {
            this.setState({ modalConfigurationDinamicFile: true, question_dinamic: question })
        } else {
            this.setState({ modalConfigurationDinamicFile: false, question_dinamic: {} })
            this.props.loadData()

        }
    }


    toogleVersions = (from) => {
        if (from == "new") {
            this.setState({ modalVersionCreate: true })
        } else {
            this.setState({ modalVersionCreate: false })
        }
    }

    toogleShowVersions = (from) => {
        if (from == "new") {
            this.setState({ modalShowVersions: true })
        } else {
            this.setState({ modalShowVersions: false })
        }
    }

    updateStateVersion = (state) => {
        this.setState({ was_edited: state })
    }

    //responsablilidades

    HandleChangeResponsabilidades = (e) => {
        if (this.state.modeEditResponsabilidades) {
            this.setState({
                formResponsabilidadesUpdate: {
                    ...this.state.formResponsabilidadesUpdate,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({
                formResponsabilidades: {
                    ...this.state.formResponsabilidades,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    clearValuesResposabilidades = () => {
        this.setState({
            formResponsabilidades: {
                ...this.state.formResponsabilidades,
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            formResponsabilidadesUpdate: {
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            format_responsability_id: "",
        })
    }


    HandleChangeCkaditor = (e) => {
        if (this.state.modeEditResponsabilidades) {
            this.setState({
                formResponsabilidadesUpdate: {
                    ...this.state.formResponsabilidadesUpdate,
                    content_email: e
                }
            });
        } else {
            this.setState({
                formResponsabilidades: {
                    ...this.state.formResponsabilidades,
                    content_email: e
                }
            });
        }
    }

    //add items
    updateDataResponsabilidades = (data) => {
        this.setState({
            format_resposabilities: [...this.state.format_resposabilities, data],
            modalResponsabilidades: false,
            showTable: true,
        })
    }

    //add update
    updateItemResponsabilidades = data => {
        this.setState({
            format_resposabilities: this.state.format_resposabilities.map(item => {
                if (data.id === item.id) {
                    return {
                        ...item,
                        question: data.question,
                        type_responsabilty: data.type_responsabilty,
                        content_email: data.content_email,
                    }
                }
                return item;
            })
        });
    }

    HandleClickResponsabilidades = () => {
        if (this.state.modeEditResponsabilidades) {
            fetch(`/formatos/format_responsabilities/${this.state.format_responsability_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formResponsabilidadesUpdate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItemResponsabilidades(data.register)
                    this.clearValuesResposabilidades();
                    this.setState({ modalResponsabilidades: false })
                });
        } else {
            fetch(`/formatos/format_responsabilities`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formResponsabilidades), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateDataResponsabilidades(data.register)
                    this.clearValuesResposabilidades();
                    this.setState({ modalResponsabilidades: false })
                });
        }
    }

    loadDataResposabilidades = () => {
        fetch(`/formatos/get_format_resposabilities/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_resposabilities: data.data,
                });
            });
    }

    deleteResponsabilidades = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_responsabilities/${id}`, {
                    method: "DELETE",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.loadDataResposabilidades()
                    });
            }
        });
    };

    editarResponsabilidades = (responsabilidad) => {
        console.log("editResponsabilidadeseditResponsabilidades")
        this.setState({
            modalResponsabilidades: true,
            modeEditResponsabilidades: true,
            format_responsability_id: responsabilidad.id,

            formResponsabilidadesUpdate: {
                question_id: responsabilidad.question_id,
                type_responsabilty: responsabilidad.type_responsabilty,
                content_email: responsabilidad.content_email,
            }
        })
    }


    setStepPosition = (step) => {
        this.setState({
            step_position_id: step.id,
            formUpdateStepPosition: {
                position: step.step_position,
            }
        })
    }


    handleChangeFileQuestion = (archivo, question_id) => {
        const formData = new FormData();
        formData.append("file", archivo[0])

        fetch(`/formatos/update_imagen_question/${question_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.clearValues();
            this.props.loadData();
            this.messageSuccess(data);
        });
    }



    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <ModalConfigurationDate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Configuración del campo tipo fecha"}
                        question={this.state.question_info}
                        positions={this.props.positions}
                        steps_questions={this.props.steps_questions}
                    />
                )}

                {this.state.modalConfigurationDinamicFile && (
                    <ModalConfigurationDinamicFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalConfigurationDinamicFile}
                        toggle={this.toogleDinamicFile}
                        title={"Configuración del campo dinámico"}
                        question={this.state.question_dinamic}
                        messageSuccess={this.messageSuccess}
                        format={this.props.format}
                        cargos={this.state.cargos}
                    />
                )}

                {this.state.modalResponsabilidades && (
                    <ModalResponsabilidades
                        backdrop={"static"}
                        modal={this.state.modalResponsabilidades}
                        toggle={this.toogleResponsabilidades}
                        title={this.state.modeEditResponsabilidades ? "Actualizar responsabilidad" : "Crear responsabilidad"}
                        nameBnt={this.state.modeEditResponsabilidades ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.modeEditResponsabilidades ? this.state.formResponsabilidadesUpdate : this.state.formResponsabilidades}
                        onChangeForm={this.HandleChangeResponsabilidades}
                        submitForm={this.HandleClickResponsabilidades}
                        questions_users={this.props.questions_users}
                        HandleChangeCkaditor={this.HandleChangeCkaditor}
                        modeEdit={this.state.modeEditResponsabilidades}
                    />
                )}

                {this.state.modalVersionCreate && (
                    <FormCreateVersions
                        backdrop={"static"}
                        modal={this.state.modalVersionCreate}
                        toggle={this.toogleVersions}
                        title={"Crear versión"}
                        format={this.props.format}
                        updateStateVersion={this.updateStateVersion}
                    />
                )}

                {this.state.modalSelectType && (
                    <ModalSelectType
                        backdrop={"static"}
                        modal={this.state.modalSelectType}
                        toggle={this.toogleSelectType}
                        title={"Seleccione el tipo"}

                        setName={this.setName}
                        getIcon={this.getIcon}
                        formQuestion={this.state.formQuestion}
                        nameBtn={this.state.nameBtn}
                        modeEdit={false}
                    />
                )}

                {this.state.modalShowVersions && (
                    <ModalShowVersions
                        backdrop={"static"}
                        modal={this.state.modalShowVersions}
                        toggle={this.toogleShowVersions}
                        title={"Control de cambios"}
                        format={this.props.format}
                        messageSuccess={this.messageSuccess}
                        updateStateVersion={this.updateStateVersion}
                    />
                )}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories`}
                                data-turbolinks="false"
                            >
                                {"Volver"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=ACTIVE`}
                                data-turbolinks="false"
                            >
                                {"Registros"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            {this.props.format.name}
                        </li>
                    </ol>
                </nav>



                < div className="row">
                    <div className={`format-fields col-md-8 ${isMobile ? "p-0" : ""}`}>



                        {isMobile && (
                            <div className="mb-3">
                                <a
                                    onClick={() => this.add_step()}
                                    className="btn-shadow btn btn-info btn-block"
                                    style={{ color: "#ffff" }}
                                >
                                    <i className="fas fa-plus"></i> Crear Formulario
                                </a>
                            </div>
                        )}

                        <ul className="nav nav-tabs mb-0" id="myTab" role="tablist">
                            {this.props.data.map((step, index) => (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.props.count == (index + 1) ? "active" : ""}`}
                                        onClick={() => { this.clearValues(); this.props.updateCount(index + 1) }}
                                        id="home-tab"
                                        data-toggle="tab"
                                        //href={`#home${step.id}`}
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                    >
                                        {step.name == "Formulario sin título" ? "Sección" : step.name}

                                        {(this.state.step_position_id == step.id) ? (
                                            <React.Fragment>
                                                <select
                                                    value={step.step_position}
                                                    onChange={(e) => this.updateStepPosition(e, step.id)}
                                                >
                                                    {this.props.data.map((item, index) => (
                                                        <React.Fragment>
                                                            <option value={item.step_position}>{index + 1}</option>
                                                        </React.Fragment>
                                                    ))}
                                                </select>

                                                <i className="fas fa-times ml-1" style={{ color: "#ff0000", cursor: "pointer", fontSize: 16 }} onClick={() => this.setState({ step_position_id: "" })}></i>
                                            </React.Fragment>
                                        ) : (
                                                <React.Fragment>
                                                    <span className="ml-1">{index + 1}/{this.props.data.length}</span>
                                                    <i onClick={() => this.setStepPosition(step)} class="fas fa-exchange-alt ml-1"></i>
                                                </React.Fragment>
                                            )}
                                    </a>
                                </li>
                            ))}

                        </ul>


                        <div className="tab-content" id="myTabContent">
                            {this.props.data.map((step, index) => (
                                <div className={`tab-pane fade show ${this.props.count == (index + 1) ? "active" : ""}`} id={`home${step.id}`} role="tabpanel" aria-labelledby="home-tab">
                                    <div className={`card mt-4`} key={step.id}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    {(this.state.showTitle && this.state.step_id == step.id) ? (
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="text"
                                                                        className="form form-control imput-title"
                                                                        name="name"
                                                                        value={this.state.formUpdateStep.name}
                                                                        onChange={this.HandleChangeUpdateStep}
                                                                    />
                                                                </div>

                                                                <div className="col-md-12 mt-3">
                                                                    <label htmlFor="">Descripción</label>
                                                                    <Ckaditor
                                                                        HandleChange={this.HandleChangeUpdateStepDescription}
                                                                        formValues={this.state.formUpdateStep.description}
                                                                    />
                                                                </div>

                                                                <div className="col-md-7 mt-3">
                                                                    <label>Cargos que pueden acceder <small className="validate-label">*</small></label>
                                                                    <input
                                                                        type="hidden"
                                                                        name="position_ids"
                                                                        value={this.state.selectedOptionCargo.position_ids}
                                                                    />
                                                                    <Select
                                                                        isMulti
                                                                        closeMenuOnSelect={false}
                                                                        autoFocus={false}
                                                                        className={`link-form`}
                                                                        classNamePrefix="select"
                                                                        name="position_ids"
                                                                        onChange={this.handleChangeAutocompleteCargoStep}
                                                                        options={this.state.cargos}
                                                                        defaultValue={[
                                                                            this.state.editValuesCargos[0],
                                                                            this.state.editValuesCargos[1],
                                                                            this.state.editValuesCargos[2],
                                                                            this.state.editValuesCargos[3],
                                                                            this.state.editValuesCargos[4],
                                                                            this.state.editValuesCargos[5]
                                                                        ]}
                                                                    />
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="custom-control custom-switch mt-5">
                                                                        <input type="checkbox" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestion`} checked={this.state.formUpdateStep.is_private} />
                                                                        <label className="custom-control-label" htmlFor={`customGestion`}>¿Es privado?</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment>
                                                                <h4 onClick={() => this.setState({ showTitle: !this.state.showTitle })}>{step.name}</h4>
                                                                {ReactHtmlParser(step.description)}
                                                            </React.Fragment>
                                                        )}
                                                </div>

                                                <div className="col-md-2">
                                                    {(this.state.showTitle && this.state.step_id == step.id) ? (
                                                        <React.Fragment>
                                                            <i className="fas fa-times icon-show-format" onClick={() => this.setState({ showTitle: !this.state.showTitle, step_id: "" })}></i>
                                                            <i className="fas fa-check icon-show-format mr-3" onClick={() => this.updateSeccion()}></i>
                                                        </React.Fragment>
                                                    ) : (
                                                            <i className="fas fa-pencil-alt icon-show-format" onClick={() => this.editStep(step)}></i>
                                                        )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-footer text-right">
                                            <a
                                                className="btn-actions"
                                                onClick={() => this.deleteStep(step)}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </a>
                                        </div>
                                    </div>

                                    {this.state.showMessage && (
                                        <div class="alert alert-success mt-2" role="alert">
                                            <b>¡La pregunta fue creada con éxito!</b>
                                        </div>
                                    )}
                                    {this.state.showMessageError && (
                                        <div class="alert alert-danger mt-2" role="alert">
                                            <b>¡Ocurrio un error al crear la pregunta!</b>
                                        </div>
                                    )}

                                    <div className={`card mb-4 mt-4`}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className={`col-md-7 ${isMobile ? "mb-3" : ""}`}>
                                                    <input
                                                        type="text"
                                                        className={`form form-control ${this.state.ErrorValues == false && this.state.formQuestion.question == "" ? "error-class" : ""}`}
                                                        name="question"
                                                        value={this.state.formQuestion.question}
                                                        onChange={(e) => this.HandleChangeQuestion(e, step)}
                                                        placeholder={"Pregunta"}
                                                    />

                                                    {(this.state.ErrorValues == false) && (
                                                        <div className="alert alert-danger mt-3" role="alert">
                                                            <ul className="mb-0 pl-2">
                                                                <li className={`${this.state.formQuestion.question != "" ? "color-password" : ""}`}>{"La pregunta no debe de estar vacía"}</li>
                                                                <li className={`${this.state.formQuestion.question_type != "" ? "color-password" : ""}`}>{"Debes de seleccionar un tipo de pregunta"}</li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-5" style={{ display: "flex" }}>
                                                    <button
                                                        type="button"
                                                        className={`btn-shadow btn btn-primary btn-block text-left`}
                                                        style={{ width: "88%", height: "33px" }}
                                                        onClick={() => this.toogleSelectType("new", step.id)}
                                                    >
                                                        {(this.state.nameBtn != "") ? (
                                                            <React.Fragment>
                                                                {this.getIcon(this.state.nameBtn)} {this.state.nameBtn}
                                                            </React.Fragment>
                                                        ) : (
                                                                <React.Fragment>
                                                                    <i className="fas fa-info-circle"></i>  {"Seleccione un tipo"}
                                                                </React.Fragment>
                                                            )}
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn-shadow btn btn-info btn-block text-left mt-0"
                                                        style={{ width: "15%", marginLeft: "10px", height: "33px" }}
                                                        onClick={() => this.createQuestion()}
                                                    >
                                                        {this.state.loader_create_question ? (
                                                            <Preloader />
                                                        ) : (
                                                                <i className="fas fa-check"></i>

                                                            )}
                                                    </button>
                                                    {/*<FormCreateQuestion
                                                        id={step.id}
                                                        registerId={this.state.step_id}
                                                        nameBtn={this.state.nameBtn}
                                                        setName={this.setName}
                                                        updateId={this.updateId}
                                                        clearId={this.clearId}
                                                        showPopover={this.state.showPopover}
                                                        getIcon={this.getIcon}
                                                        handleClick={this.createQuestion}
                                                        modeEdit={false}
                                                    />*/}
                                                </div>

                                                <div className="col-md-7 mt-3">
                                                    <textarea
                                                        type="text"
                                                        className={`form form-control`}
                                                        name="description"
                                                        value={this.state.formQuestion.description}
                                                        onChange={(e) => this.HandleChangeQuestion(e, step)}
                                                        style={{ resize: "none" }}
                                                        placeholder={"Descripción"}
                                                    />
                                                </div>

                                                {(this.state.nameBtn != "" && this.state.step_id == step.id) && (
                                                    <div className="col-md-12 mt-4">
                                                        <div className="row">
                                                            {this.getInputs(this.state.nameBtn)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {step.questions.filter(question_filter => question_filter.state_db == true).map((question, index) => (
                                        <div className="card mt-3 border-questions" key={question.id}>
                                            <div className="card-body">
                                                {this.state.question_id == question.id ? (
                                                    <React.Fragment>
                                                        <div className="row">

                                                            <div className={`col-md-7`}>
                                                                <input
                                                                    type="text"
                                                                    name="question"
                                                                    className="form form-control"
                                                                    value={this.state.formUpdateQuestion.question}
                                                                    onChange={(e) => this.HandleChangeUpdateQuestion(e)}
                                                                />
                                                            </div>

                                                            {true && (
                                                                <div className={`col-md-5 ${isMobile ? "mt-3" : ""}`} style={{ display: "flex" }}>
                                                                    <FormCreateQuestion
                                                                        id={question.id}
                                                                        registerId={this.state.question_id}
                                                                        nameBtn={this.state.nameBtn}
                                                                        setName={this.setName}
                                                                        updateId={this.updateId}
                                                                        clearId={this.clearId}
                                                                        showPopover={this.state.showPopover}
                                                                        getIcon={this.getIcon}
                                                                        handleClick={this.HadleClickUpdateQuestion}
                                                                        modeEdit={true}
                                                                    />
                                                                </div>
                                                            )}

                                                            <div className="col-md-12">
                                                                <div className="col-md-7 mt-3 pl-0">
                                                                    <textarea
                                                                        type="text"
                                                                        className={`form form-control`}
                                                                        name="description"
                                                                        value={this.state.formUpdateQuestion.description}
                                                                        onChange={(e) => this.HandleChangeUpdateQuestion(e)}
                                                                        style={{ resize: "none" }}
                                                                        placeholder={"Descripción"}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 mt-3">
                                                                <Dropzone onDrop={(files) => this.handleChangeFileQuestion(files, question.id)}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div
                                                                            {...getRootProps({
                                                                                className: 'dropzone',
                                                                            })}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <p>{"Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo"}</p>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>

                                                            <div className="col-md-2 mt-3">
                                                                <label>Sección</label>
                                                                <select
                                                                    className="form form-control"
                                                                    //value={question.position}
                                                                    onChange={(e) => this.updateStepQuestion(e, question.id, step.id)}
                                                                >
                                                                    <option value="" disabled selected>{step.step_position + 1}</option>
                                                                    {this.props.data.map((item, index) => (
                                                                        <React.Fragment>
                                                                            <option value={item.id}>{index + 1}</option>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="col-md-2 mt-3">
                                                                <label>Posición</label>
                                                                <select
                                                                    className="form form-control"
                                                                    value={question.position}
                                                                    onChange={(e) => this.updatePositionQuestion(e, step.id)}
                                                                >
                                                                    {step.questions.map(item => (
                                                                        <React.Fragment>
                                                                            <option value={item.position}>{item.position}</option>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            {/*<div className="col-md-8 mt-3">
                                                                <Dropzone onDrop={(files) => this.handleFileQuestionImagen(files)}>
                                                                    {({getRootProps, getInputProps}) => (
                                                                        <div
                                                                            {...getRootProps({
                                                                                className: 'dropzone',
                                                                            })}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>*/}

                                                            <div className="col-md-12 pl-0 mt-3">
                                                                <div className="">
                                                                    {(question.question_type == "answer_radio" || question.question_type == "answer_select" || question.question_type == "answer_checkbox") ? (
                                                                        <React.Fragment>
                                                                            {(this.state.nameBtn == "Lista desplegable" || this.state.nameBtn == "Opción múltiple" || this.state.nameBtn == "Casillas de verificación") ? (
                                                                                <React.Fragment>
                                                                                    {this.getOptions(question.options, this.addArrayFormUpdate, this.removeArrayUpdateQuestion, this.HandleChangeUpdateOptions)}
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                    <React.Fragment>
                                                                                        {this.getInputs(this.state.nameBtn)}
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <React.Fragment>
                                                                                {this.getInputs(this.state.nameBtn)}
                                                                            </React.Fragment>
                                                                        )}
                                                                </div>

                                                            </div>

                                                            {(this.state.nameBtn != "" && this.state.step_id == step.id) && (
                                                                <div className="col-md-12 mt-4" >
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            {this.getInputs(this.state.nameBtn)}
                                                                        </div>

                                                                        <div className="col-md-2">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                        <React.Fragment>
                                                            <div className="row">


                                                                <div className="col-md-10">
                                                                    <h5>{question.position}) {question.question}</h5>
                                                                    <p>{question.description}</p>
                                                                    {question.file && (
                                                                        <div className="mb-3">
                                                                            <img src={question.file} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                {false && (
                                                                    <div className="col-md-2 pr-2">
                                                                        {this.getIconsPosition(question)}
                                                                    </div>
                                                                )}

                                                                <div className="col-md-12">
                                                                    {this.getTypeQuestion(question)}
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    )}
                                            </div>

                                            <div className="card-footer">
                                                {this.state.question_id == question.id ? (
                                                    <React.Fragment>
                                                        <i className="fas fa-times icon-show-format" onClick={() => this.clearValues()}></i>
                                                    </React.Fragment>
                                                ) : (
                                                        <React.Fragment>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-12 p-0">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <a
                                                                                    className="btn btn-actions-true"
                                                                                    onClick={() => this.editQuestion(question, step.id)}
                                                                                >
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </a>

                                                                                <a
                                                                                    className="btn-actions"
                                                                                    onClick={() => this.deleteQuestion(question)}
                                                                                >
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </a>
                                                                            </div>

                                                                            <div className="col-md-4 text-right">
                                                                                {question.configuration_question_date != null && (
                                                                                    <a
                                                                                        href="javascript:void(0)"
                                                                                        className="link-line"
                                                                                        onClick={() => this.toogle("new", question, step.questions)}
                                                                                    >
                                                                                        Configurar semaforo
                                                                                    </a>
                                                                                )}
                                                                            </div>

                                                                            <div className="col-md-4 text-right pl-0">
                                                                                <a className="link-line ml-2" onClick={() => this.configQuestion(question)}>
                                                                                    Configuración de pregunta
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {(this.state.question_config_id == question.id) && (
                                                                        <div className="col-md-12">
                                                                            <hr className="mb-1" />
                                                                            <div className="row">
                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.show_filter, "show_filter")} className="custom-control-input" id={`customShowFilter${question.id}`} checked={question.show_filter} />
                                                                                        <label className="custom-control-label" htmlFor={`customShowFilter${question.id}`}>¿Mostrar en filtro?</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.show_table, "show_table")} className="custom-control-input" id={`customShowTable${question.id}`} checked={question.show_table} />
                                                                                        <label className="custom-control-label" htmlFor={`customShowTable${question.id}`}>¿Mostrar en la tabla?</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.show_in_gestion, "show_in_gestion")} className="custom-control-input" id={`customGestion${question.id}`} checked={question.show_in_gestion} />
                                                                                        <label className="custom-control-label" htmlFor={`customGestion${question.id}`}>¿Mostrar en gestion?</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_header, "show_header")} className="custom-control-input" id={`customHeader${question.id}`} checked={question.is_header} />
                                                                                        <label className="custom-control-label" htmlFor={`customHeader${question.id}`}>¿Mostrar en encabezado?</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_mandatory, "mandatory")} className="custom-control-input" id={`customSwitch${question.id}`} checked={question.is_mandatory} />
                                                                                        <label className="custom-control-label" htmlFor={`customSwitch${question.id}`}>¿Obligatorio?</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_active, "active")} className="custom-control-input" id={`customActive${question.id}`} checked={question.is_active} />
                                                                                        <label className="custom-control-label" htmlFor={`customActive${question.id}`}>¿Visible?</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 pt-3">
                                                                                    <div className="custom-control custom-switch">
                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_private, "private")} className="custom-control-input" id={`customPrivate${question.id}`} checked={question.is_private} />
                                                                                        <label className="custom-control-label" htmlFor={`customPrivate${question.id}`}>Es privado?</label>
                                                                                    </div>
                                                                                </div>

                                                                                {question.is_private && (
                                                                                    <div className="col-md-4 pt-3">
                                                                                        <input
                                                                                            type="hidden"
                                                                                            name="position_ids"
                                                                                            value={this.state.selectedOptionCargo.position_ids}
                                                                                        />
                                                                                        <Select
                                                                                            isMulti
                                                                                            closeMenuOnSelect={false}
                                                                                            autoFocus={false}
                                                                                            className={`link-form`}
                                                                                            classNamePrefix="select"
                                                                                            name="position_ids"
                                                                                            onChange={this.handleChangeAutocompletePosition}
                                                                                            options={this.state.cargos}
                                                                                            defaultValue={[
                                                                                                this.state.editValuesPosition[0],
                                                                                                this.state.editValuesPosition[1],
                                                                                                this.state.editValuesPosition[2],
                                                                                                this.state.editValuesPosition[3],
                                                                                                this.state.editValuesPosition[4],
                                                                                                this.state.editValuesPosition[5]
                                                                                            ]}
                                                                                        />
                                                                                    </div>
                                                                                )}


                                                                                {question.is_private && (
                                                                                    <div className="col-md-4 pt-3">
                                                                                        <button className="btn-shadow btn btn-info" onClick={() => this.updatePositions(question.id)}>
                                                                                            Actualizar cargos
                                                                                        </button>
                                                                                    </div>
                                                                                )}


                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4 format-configuration">

                        <div className="mb-2">
                            <a href="javascript:void()" onClick={() => this.add_step()} className="btn btn-primary btn-shadow mr-2">
                                Agregar Sección <i className="fas fa-plus"></i>
                            </a>

                            <a href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/update_values`} className="btn btn-primary btn-shadow mr-2" data-turbolinks="false">
                                Personalizar Gestión
                            </a>

                        </div>

                        <div className="card mb-3">
                            <div className="card-header">
                                Imagen de portada
                            </div>
                            <div className="card-body">
                                {(this.state.url_img == null || this.state.modeEditFile) ? (
                                    <React.Fragment>
                                        <Dropzone onDrop={(files) => this.handleFile(files)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone',
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </React.Fragment>
                                ) : (
                                        <React.Fragment>
                                            <img src={this.state.url_img} style={{ maxWidth: "200px" }} />
                                        </React.Fragment>
                                    )}
                            </div>


                            <div className="card-footer" style={{ display: "flex", justifyContent: "space-between" }}>
                                {this.state.nameFile != "" ? (
                                    <React.Fragment>
                                        <button
                                            className="btn-shadow btn btn-info mr-2"
                                            onClick={() => this.HandleClickFile()}
                                        >
                                            {this.state.modeEditFile ? "Actualizar imagen" : "Subir imagen"}
                                        </button>

                                        <button
                                            className="btn btn-light"
                                            onClick={() => this.cancelUpload()}
                                        >
                                            Cancelar
                                        </button>
                                    </React.Fragment>
                                ) : (
                                        <React.Fragment>
                                            {this.state.modeEditFile ? (
                                                <React.Fragment>
                                                    <button
                                                        className="btn-shadow btn btn-info mr-2"
                                                        onClick={() => this.HandleClickFile()}
                                                    >
                                                        Actualizar imagen
                                                    </button>

                                                    <button
                                                        className="btn btn-light"
                                                        onClick={() => this.cancelUpload()}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </React.Fragment>
                                            ) : (
                                                    <React.Fragment>
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => this.editFIle()}
                                                        >
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </button>

                                                        {this.state.url_img != null && (
                                                            <button
                                                                className="btn-shadow btn btn-danger ml-3"
                                                                onClick={() => this.removeImagen()}
                                                            >
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                        </React.Fragment>
                                    )}
                            </div>

                        </div>

                        <div className="card mb-3">
                            <div className="card-header" style={{ display: "flow-root", paddingTop: 12 }}>
                                Configuración general

                                <a className="float-right btn" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    <i className="fas fa-angle-down"></i>
                                </a>
                            </div>

                            <div className="card-body p-3">
                                <div className="row">

                                    <div className="collapse w-100" id="collapseExample">
                                        <div className="col-md-12">
                                            <div className="row mt-3">

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_taks, "show_task")} className="custom-control-input" id={`customShowTask`} checked={this.state.configuration_format.has_taks} />
                                                        <label className="custom-control-label" htmlFor={`customShowTask`}>¿Mostrar tareas?</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_comments, "show_coment")} className="custom-control-input" id={`customShowCommets`} checked={this.state.configuration_format.has_comments} />
                                                        <label className="custom-control-label" htmlFor={`customShowCommets`}>¿Mostrar comentarios?</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_cause_analisis, "cause_analisis")} className="custom-control-input" id={`customHasCauseAnalisis`} checked={this.state.configuration_format.has_cause_analisis} />
                                                        <label className="custom-control-label" htmlFor={`customHasCauseAnalisis`}>¿Tiene Método de Análisis ?</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.active_external_link, "active_external_link")} className="custom-control-input" id={`customActiveExternalLink`} checked={this.state.configuration_format.active_external_link} />
                                                        <label className="custom-control-label" htmlFor={`customActiveExternalLink`}>Desactivar ingreso de registros externos</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <hr />
                                                </div>

                                                <div className="col-md-12">
                                                    <label>Columnas del formulario</label>
                                                    <select 
                                                        value={this.state.configuration_format.columns_format_number}
                                                        onChange={this.HandleChangeUpdateSelect}
                                                        className="form form-control"
                                                        name="columns_format_number"
                                                    >   
                                                        <option value="1">1 columna </option>
                                                        <option value="2">2 columnas </option>
                                                    </select>
                                                </div>



                                                <div className="col-md-12 mt-3">
                                                    <hr />
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.send_notification, "send_notification")} className="custom-control-input" id={`customShowNotifications`} checked={this.state.configuration_format.send_notification} />
                                                        <label className="custom-control-label" htmlFor={`customShowNotifications`}>¿Enviar notificación?</label>
                                                        <p className="font-12 pt-1">Envía una notificación cada que generen un registro en este formato abajo puedes configurar el mensaje</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            {this.state.showInfo ? (
                                                                <React.Fragment>
                                                                    {this.state.configuration_format.send_notification && (
                                                                        <React.Fragment>
                                                                            <div className="mb-3">
                                                                                {/*                                                                                     <span className="font-12 mb-2">{"Debes poner {titulo_formato} donde quieras que este el titulo del formato"}</span> 
 */}                                                                            <label>Cargos que pueden acceder <small className="validate-label">*</small></label>
                                                                                <SelectPosition
                                                                                    handleChangeAutocomplete={this.handleChangeAutocompleteCargo}
                                                                                    selectedOptionCargo={this.state.selectedOptionCargo}
                                                                                    cargos={this.state.cargos}
                                                                                    editValues={this.state.editValues}
                                                                                />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}

                                                                    <Ckaditor
                                                                        HandleChange={this.HandleChangeUpdateConfiguration}
                                                                        formValues={this.state.configuration_format.email_content}
                                                                    />
                                                                </React.Fragment>
                                                            ) : (
                                                                    <React.Fragment>
                                                                         <b>Configuración del Email</b>
                                                                        <hr/>
                                                                        <b>Cargos</b>
                                                                        <ul>
                                                                            {this.state.configuration_format.positions.map((cargo, index) => (
                                                                                <React.Fragment>
                                                                                    <li>{cargo.name}</li>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </ul>

                                                               

                                                                        <div className="pb-2"><b>Contenido del email</b></div>
                                                                        <p style={{ fontSize: "12px" }}>(Debajo del mensaje siempre aparecera el link para ingresar al listado)</p>
                                                                        {/*                                                                                 <span className="font-12">{"Debes poner {titulo_formato} donde quieras que este el titulo del formato"}</span>
 */}                                                                            <div className="send-format-email mt-2">
                                                                            {ReactHtmlParser(this.state.configuration_format.email_content)}
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}

                                                        </div>

                                                        <div className="card-footer">
                                                            {this.state.showInfo ? (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="btn btn-shadow btn-info"
                                                                        onClick={() => this.handleClickUpdateConfiguration()}
                                                                    >
                                                                        Actualizar
                                                                        </button>

                                                                    <button
                                                                        className="btn btn-shadow btn-danger ml-2"
                                                                        onClick={() => this.setState({ showInfo: false })}
                                                                    >
                                                                        <i className="fas fa-times"></i>
                                                                    </button>
                                                                </React.Fragment>
                                                            ) : (
                                                                    <React.Fragment>
                                                                        <button
                                                                            className="btn btn-shadow btn-light"
                                                                            onClick={() => this.setState({ showInfo: true })}
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </button>
                                                                    </React.Fragment>
                                                                )}

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header" style={{ display: "flow-root", paddingTop: 12 }}>
                                Responsabilidades

                                <button
                                    className="btn-shadow btn btn-info float-right"
                                    onClick={() => this.toogleResponsabilidades("new")}
                                >
                                    Crear
                                </button>

                                <a className="float-right mr-3" data-toggle="collapse" href="#collapseExampleResponsabilidades" role="button" aria-expanded="false" aria-controls="collapseExampleResponsabilidades">
                                    <i className="fas fa-angle-down"></i>
                                </a>
                            </div>

                            <div className="card-body p-3">
                                <div className="row">

                                    <div className={`collapse ${this.state.showTable ? "show" : ""} w-100`} id="collapseExampleResponsabilidades">
                                        <div className="col-md-12 mt-3">



                                            {this.state.format_resposabilities.length >= 1 ? (
                                                this.state.format_resposabilities.map(formato => (
                                                    <div className="mb-3" key={formato.id} >

                                                        <div><p>Pregunta: {formato.question}</p></div>
                                                        {/*                                                                 <div><p>Responsabilidad: {formato.type_responsabilty}</p></div> */}
                                                        <div><p>Email:</p></div>
                                                        <div><p className="send-format-email mt-2">{ReactHtmlParser(formato.content_email)}</p></div>
                                                        <div>{true && (
                                                            <a
                                                                onClick={() => this.editarResponsabilidades(formato)}
                                                                className="mr-2"
                                                            >
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </a>
                                                        )}

                                                            {true && (
                                                                <a
                                                                    onClick={() => this.deleteResponsabilidades(formato.id)}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                        <hr />
                                                    </div>

                                                ))
                                            ) : (

                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>

                                                )}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <IndexFormatAssociations
                            format={this.props.format}
                            formatos={this.state.formatos}
                            steps_questions={this.props.steps_questions}
                        />

                        <IndexQuestionAssociations
                            format={this.props.format}
                            formatos={this.state.format_associations}
                            steps_questions={this.props.steps_questions}
                        />

                        <IndexFormatPdf
                            format={this.props.format}
                            format_category={this.props.format_category}
                        />

                        <IndexExelReport
                            format={this.props.format}
                            format_category={this.props.format_category}
                        />

                        <IndexIndicators
                            format={this.props.format}
                            format_category={this.props.format_category}
                        />

                        <div className="card mb-3">
                            <div className="card-header">
                                Control de cambios
                            </div>
                            <div className="card-body">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <a href="javascript:void()" onClick={() => this.toogleShowVersions("new")} className="btn btn-primary btn-shadow mr-2">
                                        Control de cambios
                                </a>

                                    {this.state.was_edited && (
                                        <a href="javascript:void()" onClick={() => this.toogleVersions("new")} className="btn btn-primary btn-shadow">
                                            ¿Nueva versión?
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Show;

