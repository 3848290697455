import React, { Component } from 'react';

class Index extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.length >= 1 ? (
                    this.props.data.map((position, index) => (
                        <div className={`card mb-3`} key={position.id}>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-3 mb-2">
                                        <b>Nombre</b>
                                        <p>{position.name}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Descripción</b>
                                        <p>{position.description}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Procesos</b>
                                        <p>      
                                            <span
                                                className="link-line"
                                                onClick={() => position.procesos.length >= 1 ? this.props.toogleShow("new", position) : ""}
                                            >
                                                {position.procesos.length} procesos
                                            </span>
                                        </p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Adjuntos</b>
                                        <p>{this.props.estados.uploadFile == true ? <span className="link-line" onClick={() => this.props.addFiles(position)}>{position.general_files.length} Archivos adjuntos </span> : <span className="link-line">Ver archivos </span>   }</p>
                                    </div>

                                </div>
                            </div>

                            {position.name != "Sistema Básico" && (
                                <React.Fragment>
                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                        <div className="card-footer">
                                            {this.props.estados.editar && (
                                                <a
                                                    href={`/settings/positions/${position.id}/edit`}
                                                    data-turbolinks="false"
                                                    className="dropdown-item"
                                                >
                                                    Editar
                                                </a>
                                            )}

                                            {(this.props.estados.eliminar  && position.name != "Administrador Sistema")  && (
                                                <button
                                                    onClick={() => this.props.delete(position.id)}
                                                    className="dropdown-item"
                                                >
                                                    Eliminar
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}

                        </div>
                    ))

                ) : (
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>No hay registros</h4>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;