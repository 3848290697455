    import React, { Component } from 'react';
import IndexTrello from "../../taskTrello/index"
import ReactDOM from 'react-dom'
import TaskList from './TaskList'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import Pagination from "react-js-pagination";

class IndexHistory extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
    }

    componentDidMount(){
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {!this.props.isLoaded ? (
                        <React.Fragment>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map((task, index) => (
                                    <div className={`col-md-4 ${index > 2 ? "mt-3" : ""}`}>
                                        <div className="card">
                                            <div className="card-body p-2">
                                                <TaskList 
                                                    task={task} 
                                                    user={this.props.user} 
                                                    users={this.props.users} 
                                                    showOptions={true} 
                                                    loadData={this.props.loadData}
                                                    estados={this.props.estados}
                                                    updateStateLoad={this.props.updateStateLoad}
                                                    updateItem={this.props.updateItem}
                                                    procesos={this.props.procesos}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h6>No hay tareas</h6>
                                        </div>
                                    </div>
                                </div>
                            )} 
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Preloader />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )} 

                        <div className="col-md-12 mt-4">
                            <div className="row">
                                <div className={`col-md-${isMobile ? "1" : "7"}`}>
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.task_total}
                                    </p>
                                </div>

                                <div className="col-md-2 text-right">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.task_total}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        </div>
                </div>
                
            </React.Fragment>
        );
    }
}

export default IndexHistory;
//asdsasd