import React, { Component } from 'react';
import Select from "react-select";

class SelecPosition extends Component {
    render() {
        return (
            <React.Fragment>
                <input
                    type="hidden"
                    name="position_ids"
                    value={this.props.selectedOptionCargo.position_ids}
                />
                <Select
                    isMulti
                    closeMenuOnSelect={false}
                    autoFocus={false}
                    className={`link-form`}
                    classNamePrefix="select"
                    name="position_ids"
                    onChange={this.props.handleChangeAutocomplete}
                    options={this.props.cargos}
                    defaultValue={[
                        this.props.editValues[0],
                        this.props.editValues[1],
                        this.props.editValues[2],
                        this.props.editValues[3],
                        this.props.editValues[4],
                        this.props.editValues[5]
                    ]}
                />
            </React.Fragment>
        );
    }
}

export default SelecPosition;
