import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../GeneralComponents/HeaderTitle'

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            custom_page_id: "",

            form: {
                name: "",
                url: "",
                content: "", 
            },

            formUrl: {
                url: "",
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    HandleChangeCkaditor = (e) => {
        this.setState({
            form:{
                ...this.state.form,
                content: e
            }
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            custom_page_id: "",

            form: {
                ...this.state.form,
                name: "",
                content: "", 
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/custom_pages/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.loadData()
                    //this.messageSuccess(response)
                });
            }
        });
    };

    HandleClick = () => {
        if (this.state.modeEdit) {
            fetch(`/settings/custom_pages/${this.state.custom_page_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateItem(data.register)
                this.clearValues();
                this.setState({ modal: false })
            });
        } else {
            fetch(`/settings/custom_pages`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.register)
                this.clearValues();
                this.setState({ modal: false })
            });
        }
    }


    edit = (custom_page) => {
        this.setState({
            modal: true,
            modeEdit: true,
            custom_page_id: custom_page.id,

            form: {
                ...this.state.form,
                name: custom_page.name,
                url: custom_page.url,
                content: custom_page.content,
            },
        })
    }

    copyLink = (url) => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(url)
    }


    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar página" : "Crear página"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.form}

                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        HandleChangeCkaditor={this.HandleChangeCkaditor}
                    />
                )}

                <HeaderTitle
                    title={"Creación de páginas"}
                    subTitle={"Gestión de tareas"}
                    titleNew={"Nueva página"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Contenido</th>
                                    <th>Url</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(custom_page => (
                                        <tr key={custom_page.id}>
                                            <td>{custom_page.name}</td>
                                            <td>{custom_page.content}</td>
                                            <td>{custom_page.url}</td>

                                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {this.props.estados.gestionar && (
                                                                    <a
                                                                        href={`/settings/custom_pages/${this.props.empresa.name}/${custom_page.url.trim()}`}
                                                                        data-turbolinks="false"
                                                                        target="_blank"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Gestionar
                                                                    </a>
                                                                )}

                                                                {true && (
                                                                    <button
                                                                        onClick={() => this.copyLink(`${this.props.url}/${this.props.empresa.name}/${custom_page.url.trim()}`)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Copiar link
                                                                    </button>
                                                                )}

                                                                {this.props.estados.editar && (
                                                                    <button
                                                                        onClick={() => this.edit(custom_page)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.eliminar && (
                                                                    <button
                                                                        onClick={() => this.delete(custom_page.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
