import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Ckaditor from '../formatos/Formats/Ckeditor';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        placeholder="Nombre"
                                    />
                                </div>

                                <div className="col-md-12 mt-3 mb-3">
                                    <label>Url</label>
                                    <input
                                        type="text"
                                        name="url"
                                        value={this.props.formValues.url}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Url"
                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label htmlFor="">Contenido de la página</label>
                                    <textarea
                                        name="content"
                                        value={this.props.formValues.content}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        rows="4"
                                    />
                                    {/*<Ckaditor
                                        HandleChange={this.props.HandleChangeCkaditor}
                                        formValues={this.props.formValues.content}
                                    />*/}
                                </div>
                                
                                
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;