import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import Filter from './Filter'
import Pagination from "react-js-pagination";
import UploadFile from './UploadFile'
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { array } from 'prop-types';
import Ckaditor from '../Formats/Ckeditor';
import ReactDOM from 'react-dom'
import ModalQr from './ModalQr'

class Index extends Component {
    constructor(props) {
        super(props);

        this.token = document.querySelector("[name='csrf-token']").content;
        this.url = `${this.props.domain}/formatos/generate_questions/${this.props.format.id}`
        this.state = {
            formUpdateRepy: {

            },
            formUpdateRepySelect: {

            },

            formatValues: {

            },

            formUpdateWidth: {
                column_width: "",
            },

            showFilter: false,
            question_id: "",
            modal: false,
            showPopover: false,
            sort: false,
            showIcons: false,
            selectItems: [],
            checked: false,
            modalQr: false,
            options: [],

            answer_id: "",

            formUpdateAnswer: {
                answer_type: "",
                value: "",
            },

        }
    }

    HandleChangeRepy = (e) => {
        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: e.target.value + "-" + (this.state.formUpdateRepySelect[e.target.dataset.id] != undefined ? this.state.formUpdateRepySelect[e.target.dataset.id] : "contiene")
            }
        });
    }


    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                [e.target.name]: e.target.value,
            }
        });
    }

    selectValue = (answer) => {
        this.loadOptions(answer.survey_id, answer.question_id)
        this.setState({
            answer_id: answer.survey_id,
            question_id: answer.question_id,

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: answer.type,
                value: answer.value,
            }
        })
    }

    loadOptions = (survey_id, question_id) => {
        fetch(`/formatos/get_options_surveys/${survey_id}/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    options: data.register,
                })
            });
    }

    cancelValues = () => {
        this.setState({
            answer_id: "",
            question_id: "",
            options: [],

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: "",
                value: "",
            }
        })
    }


    HandleChangeCkaditor = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                value: e
            }
        })
    }

    updateValue = () => {
        fetch(`/formatos/update_survey_values/${this.state.answer_id}/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateAnswer), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (this.state.showFilter) {

                    this.props.handlePageChange_filter(this.props.activePage_filter);
                    this.setState({
                        show_count: this.state.show_count - 1
                    })
                }
                else {
                    this.props.handlePageChange(this.props.activePage);
                }
                this.cancelValues();
            });
    }


    HandleChangeRepySelect = (e) => {
        var value = this.state.formUpdateRepy[e.target.dataset.id].split("-")[0];
        this.setState({
            formUpdateRepySelect: {
                ...this.state.formUpdateRepySelect,
                [e.target.dataset.id]: e.target.value
            },

            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: value + "-" + e.target.value
            }
        });
    }

    HandleChangeWidth = (e) => {
        this.setState({
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    setValues = (question) => {
        this.setState({
            question_id: question.id,
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                column_width: question.column_width,
            }
        })
    }

    HandleChangeRepyDate1 = (e) => {

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${e.target.value},${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[1] : null}`
            }
        });
    }
    HandleChangeRepyDate2 = (e) => {

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[0] : null},${e.target.value}`
            }
        });
    }



    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    componentWillReceiveProps = (nexProps) => {
        this.loadValues(nexProps)
        let filterPareameters = nexProps.filters

        /*        nexProps.dataQuestionnvm.map(value=>{
                   filterPareameters[value.id] = ""
               }); */

        this.setState({
            formUpdateRepy: filterPareameters,
            show_count: nexProps.isFilter ? nexProps.survey_total_filter : nexProps.survey_total
        })
    }


    componentDidMount = () => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: '338057327568708',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v8.0'
            });
        };
    }

    onClickFacebook = () => {
        FB.ui({
            method: 'feed',
            link: this.url
        }, function (response) { });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.handlePageChange(this.props.activePage);
                        this.messageSuccess(response)
                    });
            }
        });
    };


    getWidth = (value) => {
        console.log(isMobile)
        console.log(value)
        if (value > 4 || isMobile) {

            return (((value * 300) + 300).toString()) + "px";

        } else {
            return "100%";
        }

    }



    generateQuestions = () => {
        window.open(`${this.props.domain}/formatos/generate_questions/${this.props.format.id}`, '_self');
    }

    dateDate = (fecha) => {
        console.log(fecha)
        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));
        console.log(d)
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear();

    }
    dateTime = (fecha) => {

        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));

        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());


    }
    showFilter = () => {
        this.setState({
            showFilter: true
        })

    }
    cancelFilter = () => {
        this.props.cleanFilters();
        this.props.loadData();
        this.setState({
            showFilter: false

        })

    }

    sortby = (question, order) => {
        console.log(question)
        console.log(order)
        var order_value = order ? "ASC" : "DESC"
        fetch(`/formatos/sort_list/${this.props.format.id}/${question}/${order_value}`, {
            method: "PATCH",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                this.props.loadData();
                this.setState({
                    sort: !this.state.sort
                })

            });
    }

    queryExcel = (data) => {
        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');
        console.log("------ob-------")
        console.log(data)
        return query
    }


    updateWidth = () => {
        fetch(`/formatos/update_width/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateWidth), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    question_id: "",
                    formUpdateWidth: {
                        column_width: "",
                    }
                })

                this.props.loadData()
            });
    }


    getFormatsValues = (datas) => {
        fetch(`/formatos/get_formats_values/${datas}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [datas]: data
                    }
                })
            });
    }

    loadValues = (nexProps) => {
        nexProps.dataQuestion.filter(value => value.question_type == "format_id").map((question, index) => {
            this.getFormatsValues(question.id)
        })
    }

    copyLink = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(this.url)
        this.setState({
            modalQr: false,
        })
    }

    addAllValues = (e) => {
        if (e.target.checked) {
            const array = []

            this.props.dataAnswers.map((survey) => (
                array.push(survey.survey)
            ))

            this.setState({
                selectItems: array,
                checked: e.target.checked,
            })
        } else {
            this.setState({
                selectItems: [],
                checked: e.target.checked,
            })
        }
    }


    addItem = (survey, state) => {
        const selectItems = this.state.selectItems

        if (!state) {
            selectItems.push(survey.survey)

            this.setState({
                selectItems: selectItems,
            })
        } else {
            var arr = selectItems
            var index = selectItems.indexOf(parseInt(survey.survey))
            delete arr[index]

            this.setState({
                selectItems: arr.filter(item => item != null),
            })
        }
    }

    deleteAll = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Los registros serán eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/delete_surveys/${this.state.selectItems}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ selectItems: [], checked: false });
                        this.props.handlePageChange(this.props.activePage);
                        this.messageSuccess(response);
                    });
            }
        });
    };

    updateView = () => {
        fetch(`/formatos/update_suveys_view/${this.state.selectItems}/${this.props.view}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.setState({ selectItems: [], checked: false })
            });
    }

    setTitle = () => {
        if (this.props.view === "ACTIVE") {
            return "Desactivar"
        } else if (this.props.view === "INACTIVE") {
            return "Activar"
        }
    }


    toogleModalQr = (from) => {
        if (from == "new") {
            this.setState({ modalQr: true })
        } else {
            this.setState({ modalQr: false })
        }
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={`${this.props.format.name}`}
                    titleNew={"Crear"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    showNew={this.props.dataQuestion.length >= 1 && this.props.state_records != "inactive"}
                    onClickNew={this.generateQuestions}
                    showFilter={this.props.dataQuestion.length >= 1 ? true : false}
                    onClickFilter={this.showFilter}
                />

                {this.state.modal && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Subir archivo"}
                        format={this.props.format}
                        format_category={this.props.format_category}
                        loadData={this.props.loadData}
                    />
                )}

                {this.state.modalQr && (
                    <ModalQr
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalQr}
                        toggle={this.toogleModalQr}
                        title={"Código QR"}
                        url={this.url}
                        copyLink={this.copyLink}
                    />
                )}

                {!this.props.isLoaded ? (
                    this.props.dataQuestion.length >= 1 ? (
                        <React.Fragment>
                            <div className="mb-2" style={{ display: this.state.showFilter ? "block" : "none" }} >
                                <div className="col-md-12 card">
                                    <div className="row pt-2 pb-3">


                                        {this.props.dataQuestion.length >= 1 ? (
                                            this.props.dataQuestion.filter(value => value.show_filter).map(question => (
                                                (question.question_type != "answer_file" && question.question_type != "dynamic_field" && question.question_type != "answer_autdatetime") && (
                                                    <div className={question.question_type == "answer_date" || question.question_type == "answer_time" ? "col-md-3 mb-3 filter-inputs-wp" : "col-md-3 mb-3 filter-inputs-wp"}>
                                                        <div className="filter-inputs">
                                                            <label htmlFor="">{question.question} {question.question_type == "answer_date" ? "(Desde-Hasta)" : ""}</label>
                                                            <Filter question={question}
                                                                users={this.props.users}
                                                                procesos={this.props.procesos}
                                                                positions={this.props.positions}
                                                                HandleChangeRepy={this.HandleChangeRepy}
                                                                HandleChangeRepySelect={this.HandleChangeRepySelect}
                                                                formUpdateRepy={this.state.formUpdateRepy}
                                                                formUpdateRepySelect={this.state.formUpdateRepySelect}
                                                                HandleChangeRepyDate1={this.HandleChangeRepyDate1}
                                                                HandleChangeRepyDate2={this.HandleChangeRepyDate2}
                                                                formValues={this.state.formatValues}
                                                            /></div>
                                                    </div>
                                                )

                                            ))
                                        ) : (<div></div>
                                            )}
                                        <div className="col-md-12" > <hr /></div>
                                        <div className="col-md-12" ><button className="btn btn-primary" onClick={() => this.props.loadDataFilter(this.state.formUpdateRepy)}>Filtrar</button>< button className="btn btn-danger ml-3" onClick={() => this.cancelFilter()}>X</button></div>

                                    </div>

                                </div>
                            </div>

                            <div className="content main-card mb-3 card mh-400" >
                                <div className="card-body" style={{ display: "grid", justifyContent: "center" }}>
                                    <div className="row mb-2">
                                        <div className="col-md-4">
                                            {this.state.selectItems.length >= 1 ? (
                                                <React.Fragment>



                                                    {(this.props.view != "ALL" && this.props.view != null) && (
                                                        <button
                                                            className="btn-shadow btn btn-info"
                                                            onClick={() => this.updateView()}
                                                        >
                                                            {this.setTitle()} {this.state.selectItems.length} registros
                                                        </button>
                                                    )}

                                                    {this.props.estados.delete && (
                                                        <button
                                                            className="btn-shadow btn btn-danger ml-2"
                                                            onClick={() => this.deleteAll()}
                                                        >
                                                            Eliminar {this.state.selectItems.length} registros
                                                        </button>
                                                    )}

                                                </React.Fragment>
                                            ) : (
                                                    <React.Fragment>
                                                        {this.state.show_count} registros
                                                </React.Fragment>
                                                )}
                                        </div>


                                        <div className="col-md-8 text-right">
                                            {this.state.showIcons ? <a style={{ color: "#ffff" }} onClick={() => this.setState({ showIcons: !this.state.showIcons })} className="btn btn-primary mobile"> <i className="far fa-window-close"></i></a> : <a style={{ color: "#ffff" }} className="btn btn-primary mobile" onClick={() => this.setState({ showIcons: !this.state.showIcons })}> <i className="fas fa-text-width"></i></a>}

                                            <a
                                                className="btn btn-primary mobile"
                                                style={{ color: "#ffff", marginLeft: "10px" }}
                                                target="_blank"
                                                onClick={() => this.toogleModalQr("new")}
                                            >
                                                <i className="far fa-copy"></i><span className="label">Link / Código QR</span>
                                            </a>


                                            {false && (
                                                <a
                                                    className="btn btn-info ml-2"
                                                    target="_blank"
                                                    href={`https://twitter.com/intent/tweet?original_referer=${this.url}&ref_src=twsrc%5Etfw&text=${this.props.format.name}&tw_p=tweetbutton&url=${this.url}`}
                                                >
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            )}

                                            {false && (
                                                <a
                                                    className="btn btn-primary ml-2"
                                                    style={{ color: "#ffff" }}
                                                    onClick={() => this.onClickFacebook()}
                                                >
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            )}

                                            <div className="btn-group ml-2" role="group" aria-label="Button group with nested dropdown">
                                                <div className="btn-group" role="group">
                                                    <button
                                                        className="btn-shadow btn btn-info mobile options"
                                                        id="btnGroupDrop1"
                                                        type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span className="label">Opciones</span> <i className="ml-2 fas fa-bars"></i>
                                                    </button>

                                                    <div className="dropdown-menu dropdown-menu-right dp-pdf">

                                                        {true && (
                                                            <button
                                                                onClick={() => this.toogle("new")}
                                                                className="dropdown-item"
                                                            >
                                                                Importar registros desde excel
                                                                    </button>
                                                        )}

                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="false"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/download_file.xls`}
                                                            >
                                                                Descargar plantilla
                                                                    </a>
                                                        )}

                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="false"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/generate_exel.xls?${this.queryExcel(this.state.formUpdateRepy)}`}
                                                            >
                                                                Exportar registros a excel
                                                                    </a>
                                                        )}
                                                        {/*                                                         {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="false"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/get_custom_file.xls`}
                                                            >
                                                                Generar informe
                                                                    </a>
                                                        )} */}

                                                        {this.props.format_exels.map(exel => (
                                                            <a
                                                                className="dropdown-item"
                                                                key={exel.id}
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/get_custom_file.xls?exel_id=${exel.id}`}

                                                            >
                                                                {exel.name}
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="btn-group ml-2" role="group" aria-label="Button group with nested dropdown">
                                                <div className="btn-group mobile" role="group">
                                                    <button
                                                        className="btn-shadow btn btn-info mobile"
                                                        id="btnGroupDrop1"
                                                        type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span className="label">Vistas<i className="ml-2 fas fa-bars"></i></span><i className="ml-2 far fa-eye"></i>
                                                    </button>

                                                    <div className="dropdown-menu dropdown-menu-right dp-pdf">
                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="false"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=ALL`}
                                                            >
                                                                Ver todos
                                                            </a>
                                                        )}

                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="false"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=ACTIVE`}
                                                            >
                                                                Activos
                                                            </a>
                                                        )}

                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="false"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=INACTIVE`}
                                                            >
                                                                Inactivos
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.format_indicators.length >= 1 && (
                                                <a
                                                    className="btn-shadow btn btn-info ml-2"
                                                    data-turbolinks="false"
                                                    href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/format_indicators`}
                                                >
                                                    Indicadores <i className="ml-2 fas fa-bars"></i>
                                                </a>
                                            )}



                                        </div>
                                    </div>
                                    {!this.props.isLoaded ? (
                                        <div className="content-table" id="for-data" >

                                            <table className="table table-hover table-striped table-fd" id="sampleTable" style={{ width: this.props.dataQuestion.length > 4 ? this.props.tableWidth : "100%", tableLayout: this.props.dataQuestion.length > 4 ? "initial" : "fixed" }}>
                                                {(this.props.dataQuestion.length >= 1) && (
                                                    <thead>

                                                        <tr>
                                                            <td width="30px" className="td-header">
                                                                <input type="checkbox" onChange={(e) => this.addAllValues(e)} checked={this.state.checked} />
                                                            </td>
                                                            <td width="30px" className="td-header"></td>


                                                            {this.props.dataQuestion.length >= 1 ? (
                                                                this.props.dataQuestion.filter(value => value.show_table).map(question => (

                                                                    <td className="td-header" style={{ width: this.props.dataQuestion.length > 4 ? question.column_width + "px" : "" }}>

                                                                        <div className="gm-sort">
                                                                            <span className="" style={{ maxWidth: "80%" }}>  <a href={`/formatos/questions/${question.id}`} target="_blank" data-turbolinks="false">{question.question}</a></span>
                                                                            <div className="ml-2">
                                                                                <a className="sort-up" href="javascript:void(0)" onClick={() => this.sortby(question.id, this.state.sort)}>
                                                                                    <i style={{ position: "relative", top: "-3px", fontSize: "12px" }} className="fas fa-sort"></i>
                                                                                </a>

                                                                                {this.state.showIcons && (
                                                                                    <React.Fragment>
                                                                                        <i style={{ position: "relative", top: "-3px", color: "#ffff", cursor: "pointer" }} id={`PopoverLegacy${question.id}`} className="fas fa-text-width ml-2" onClick={() => this.setValues(question)}></i>
                                                                                        <UncontrolledPopover isOpen={this.state.question_id == question.id} placement="top" target={`PopoverLegacy${question.id}`}>
                                                                                            <PopoverHeader>{"Cambiar tamaño"}<a className="close-popover" onClick={() => this.setState({ question_id: "", formUpdateWidth: { column_width: "" } })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                                            <PopoverBody>
                                                                                                <div style={{ width: "289px" }}>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="column_width"
                                                                                                        value={this.state.formUpdateWidth.column_width}
                                                                                                        onChange={this.HandleChangeWidth}
                                                                                                        className={`form form-control`}
                                                                                                        placeholder="Tamaño"
                                                                                                    />

                                                                                                    <div className="mt-3">
                                                                                                        <button
                                                                                                            className="btn-shadow btn btn-info btn-block"
                                                                                                            onClick={() => this.updateWidth()}
                                                                                                        >
                                                                                                            Actualizar valor
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </PopoverBody>
                                                                                        </UncontrolledPopover>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </td>


                                                                ))
                                                            ) : (<div></div>
                                                                )}
                                                        </tr>
                                                    </thead>
                                                )}

                                                < tbody >
                                                    {
                                                        this.props.dataAnswers.length >= 1 ? (
                                                            this.props.dataAnswers.map(survey => (
                                                                <tr key={survey.survey} style={{ background: this.state.selectItems.includes(survey.survey) ? "#1aa9fb2b" : "" }}>
                                                                    <td style={{ width: "30px" }}>
                                                                        <input type="checkbox" style={{ position: "relative", top: "4px" }} value={survey.survey} onChange={() => this.addItem(survey, this.state.selectItems.includes(survey.survey))} checked={this.state.selectItems.includes(survey.survey)} />
                                                                    </td>
                                                                    <td className="text-center" style={{ width: "30px" }}>
                                                                        {true && (
                                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                                <div className="btn-group" role="group">
                                                                                    <button
                                                                                        className="btn-shadow btn btn-info"
                                                                                        id="btnGroupDrop1"
                                                                                        type="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i className="fas fa-bars"></i>
                                                                                    </button>

                                                                                    <div className="dropdown-menu dropdown-menu-right">

                                                                                        {true && (
                                                                                            <a
                                                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${survey.format}/gestion/${survey.survey}`}
                                                                                                data-turbolinks="false"
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                Gestionar
                                                                                            </a>
                                                                                        )}

                                                                                        {this.props.estados.edit && (
                                                                                            <a
                                                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys/${survey.survey}`}
                                                                                                data-turbolinks="false"
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                Editar
                                                                                            </a>
                                                                                        )}



                                                                                        {false && (
                                                                                            <a
                                                                                                href={`/formatos/formats/${survey.format}/share_questions/${survey.survey}`}
                                                                                                data-turbolinks="false"
                                                                                                target="_blank"
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                Compartir cuestionario
                                                                                            </a>
                                                                                        )}




                                                                                        {this.props.estados.delete && (
                                                                                            <a
                                                                                                onClick={() => this.delete(survey.survey)}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                Eliminar
                                                                                            </a>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>


                                                                    {survey.answers.filter(value => value.show_table).map(answer => (
                                                                        <td className="td-fordata-info" style={{ background: (answer.type == "answer_date" || answer.type == "dynamic_field") && answer.color != "white" && this.props.view != "INACTIVE" ? answer.color : "", color: (answer.type == "answer_date" || answer.type == "dynamic_field") && answer.color != "white" && this.props.view != "INACTIVE" ? "white" : "gray" }}>

                                                                            {(answer.type == "na") && (
                                                                                <span>{answer.value != null ? answer.value : "null"}</span>

                                                                            )}

                                                                            {(answer.type != "answer_integer" && answer.type != "url" && answer.type != "answer_formula" && answer.type != "answer_file" && answer.type != "answer_checkbox" && answer.type != "answer_datetime" && answer.type != "na" && answer.type != "answer_date" && answer.type != "answer_text" && answer.type != "answer_radio" && answer.type != "answer_select" && answer.type != "answer_string" && answer.type != "answer_hour" && answer.type != "answer_signature") && (
                                                                                <span>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                            )}

                                                                            {answer.type == "answer_string" && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_string" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                                style={{ width: "90%", display: "inherit" }}
                                                                                            />
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <span onClick={() => this.selectValue(answer)}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}

                                                                            {answer.type == "answer_hour" && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_hour" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                                style={{ width: "90%", display: "inherit" }}
                                                                                            />
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <span onClick={() => this.selectValue(answer)}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}



                                                                            {answer.type == "answer_select" && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_select" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <select
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                            >
                                                                                                <option value="">Seleccione un valor</option>
                                                                                                {this.state.options.map((value, index) => (
                                                                                                    <option value={value}>{value}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <span onClick={() => this.selectValue(answer)}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}

                                                                            {answer.type == "answer_file" && (
                                                                                <a
                                                                                    href={answer.value}
                                                                                    target="_blanck"
                                                                                >
                                                                                    {answer.value != null && answer.value != "" ? "Ver Archivo" : ""}

                                                                                </a>
                                                                            )}
                                                                            {(answer.type == "answer_radio") && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_radio" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <select
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                            >
                                                                                                <option value="">Seleccione un valor</option>
                                                                                                {this.state.options.map((value, index) => (
                                                                                                    <option value={value}>{value}</option>
                                                                                                ))}
                                                                                            </select>

                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <span onClick={() => this.selectValue(answer)}>{answer.value.replace(",", "")} </span>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}

                                                                            {(answer.type == "answer_checkbox") && (

                                                                                answer.value.map((value, index) => (

                                                                                    <span>{index == 0 ? "" : ", "} {value}</span>

                                                                                ))
                                                                            )}
                                                                            {answer.type == "answer_datetime" && (
                                                                                <span>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                                            )}

                                                                            {answer.type == "answer_signature" && answer.value && (
                                                                                <img style={{ width: "150px", height: "50px" }} src={answer.value} />
                                                                            )}

                                                                            {answer.type == "answer_date" && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_date" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <input
                                                                                                type="date"
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                                style={{ width: "90%", display: "inherit" }}
                                                                                            />
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <span onClick={() => this.selectValue(answer)}>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}

                                                                            {answer.type == "answer_text" && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_text" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <Ckaditor
                                                                                                HandleChange={this.HandleChangeCkaditor}
                                                                                                formValues={this.state.formUpdateAnswer.value}
                                                                                            />
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <div className="gm-ellipsis" onClick={() => this.selectValue(answer)} style={{ maxWidth: (answer.column_width - 20) + "px", maxHeight: "60px" }}>{ReactHtmlParser(answer.value)}</div>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}

                                                                            {((answer.type == "answer_integer" || answer.type == "answer_formula") && answer.is_currency) && (

                                                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={answer.value} />

                                                                            )}
                                                                            {((answer.type == "answer_integer" || answer.type == "answer_formula") && answer.is_currency != true) && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_integer" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <input
                                                                                                type="number"
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                                style={{ width: "90%", display: "inherit" }}
                                                                                            />
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <span onClick={() => this.selectValue(answer)}>{answer.value}</span>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                            {(answer.type == "url") && (
                                                                                <React.Fragment>
                                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "url" && this.props.estados.edit) ? (
                                                                                        <React.Fragment>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="value"
                                                                                                className="form form-control"
                                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                                value={this.state.formUpdateAnswer.value}
                                                                                                style={{ width: "90%", display: "inherit" }}
                                                                                            />
                                                                                            <div className="mt-2 mb-1">
                                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                            <React.Fragment>
                                                                                                <a href={answer.value} target="_blank">{answer.value}</a> <i className="fas fa-pencil-alt" onClick={() => this.selectValue(answer)}></i>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}


                                                                        </td>
                                                                    ))}

                                                                </tr>
                                                            ))

                                                        ) : (
                                                                <tr>
                                                                    <td className="text-center" colSpan={this.props.dataQuestion.length + 2}>
                                                                        <div className="text-center mt-4 mb-4" >
                                                                            <h4>No hay registros</h4>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                            <div className="col-md-12 text-center">
                                                <Preloader />
                                            </div>
                                        )
                                    }

                                </div>

                                {!this.props.isFilter ? (
                                    <div className="col-md-12 text-right">
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage}
                                            itemsCountPerPage={this.props.countPage}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.survey_total}
                                            pageRangeDisplayed={this.props.countPage}
                                            onChange={this.props.handlePageChange}
                                        />
                                    </div>
                                ) : (
                                    <div className="col-md-12 text-right">
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage_filter}
                                            itemsCountPerPage={this.props.countPage_filter}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.survey_total_filter}
                                            pageRangeDisplayed={this.props.countPage_filter}
                                            onChange={this.props.handlePageChange_filter}
                                        />
                                    </div>
                                )}


                            </div>
                        </React.Fragment >
                    ) : (

                            <div className="no-fordata"><i className="far fa-list-alt"></i> <br />
                                ¡Aún no has completado tu Formato! <br /> crea almenos una entrada</div>

                        )
                ) : (
                        <div className="col-md-12 text-center">
                            <Preloader />
                        </div>
                    )}
            </React.Fragment >
        );
    }
}

export default Index;