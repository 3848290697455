import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import FormCreateFiles from '../../../GeneralComponents/FormConfigurationFile'
import Loading from '../../../GeneralComponents/LoadingComponen'
import FormFilterName from '../../../GeneralComponents/FormFilterName';
import { isMobile } from 'react-device-detect';
import IndexMobile from './Mobile/Index'
import IndexWeb from './TableIndexWeb'

class Table extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            modaFiles: false,
            isLoadedFiles: false,
            id: "",

            form: {
                name: "",
                description: "",
                proceso_type: "",
                prefix: "",
            },

            formUpdate: {
                name: "",
                description: "",
                prefix: "",
            },

            module_id: "",
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            form: {
                name: "",
                description: "",
                proceso_type: "",
                prefix: "",
            }
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    HandleClick = () => {
        if (this.validationForm() == true && this.state.modeEdit == true) {
            fetch(`/settings/procesos/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.props.loadData();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        } else {
            fetch("/settings/procesos", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.props.loadData();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/procesos/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    //Update section

    edit = (proceso) => {
        this.setState({
            id: proceso.id,
            modal: true,
            modeEdit: true,
            form: {
                name: proceso.name,
                description: proceso.description,
                proceso_type: proceso.proceso_type
            }
        })
    }

    //files 

    addFiles = (position) => {
        this.setState({
            module_id: position.id,
            modaFiles: true,
        })
    }

    toogleFiles = (from) => {
        if (from == "new") {
            this.setState({ modaFiles: true })
            this.clearValues();
        } else {
            this.setState({ modaFiles: false })
        }
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={"Proceso"}
                    subTitle={"Gestión de Procesos"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {/* FormCreateFiles */}

                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props

                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}
                        title={"Agregar Archivos"}
                        delete_file={this.props.estados.deleteFile}
                        edit_file={this.props.estados.editFile}
                        loadDataTable={this.props.loadData}
                        nameModule={"Procesos"}
                        module_id={this.state.module_id}
                    />
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Actualizar proceso" : "Agregar nuevo proceso"}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.form}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        tenant={this.props.tenant}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <FormFilterName

                            handleChangeFilter={this.props.handleChangeFilter}
                            handleClickFilter={this.props.handleClickFilter}
                            showbtnSubmit={this.props.showbtnSubmit}
                            clearValues={this.props.clearValues}
                            formFilter={this.props.formFilter}
                        />

                        {isMobile ? (
                            <IndexMobile
                                data={this.props.data}
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                                addFiles={this.addFiles}
                                updateNewData={this.props.updateNewData}
                            />
                        ) : (
                            <IndexWeb
                                data={this.props.data}
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                                addFiles={this.addFiles}
                                updateNewData={this.props.updateNewData}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Table;