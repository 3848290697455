import React, { Component } from 'react';

class Form extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-md-12">
                    <label>Fecha de la versión </label>
                    <input
                        type="date"
                        name="date_version"
                        value={this.props.form.date_version}
                        onChange={this.props.HandleChange}
                        className={`form form-control`}
                    />
                </div>

                <div className="col-md-12 mt-3">
                    <label>Descripción del cambio </label>
                    <textarea
                        name="description"
                        rows="5"
                        value={this.props.form.description}
                        onChange={this.props.HandleChange}
                        className={`form form-control`}
                        placeholder="Descripción del cambio"
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default Form;
