import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ShowVideo extends Component {

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12" style={{ height: "22rem" }}>
                                    <iframe src={"https://www.youtube.com/embed/" + this.props.video.video_url} style={{ width: "100%", height: "100%" }} allowfullscreen></iframe>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ShowVideo;
