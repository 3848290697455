import React, { Component } from 'react';
import Preloader from '../../../../GeneralComponents/LoadingComponen'
import FormCreate from './FormCreate'
import FormCreateCkeckList from './FormCreateCkeckList'
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import { isMobile } from 'react-device-detect';
import FormFilterName from '../../../../GeneralComponents/FormFilterName';

class IndexLabel extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalCkeckList: false,
            modeEdit: false,
            id: "",
            label: {},
            form: {
                name: "",
                color: "",
            }
        }
    }

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            form: {
                name: "",
                color: "",
            }
        })
    }

    clearValuesChecklist = () => {
        this.setState({
            label_id: "",
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleChecklist = (from, label) => {
        if (from == "new") {
            this.setState({ modalCkeckList: true, label: label })
        } else {
            this.setState({ modalCkeckList: false, label: "" })
            this.props.loadDataLabels()
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }


    HandleClick = () => {
        if(!this.state.modeEdit)
            fetch(`/task/task_labels`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.props.updateDataLabels(data.register)
                this.clearValues();
            });
        else{
            fetch(`/task/task_labels/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.props.updateItemLabel(data.register)
                this.clearValues();
            });
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                //this.props.updateStateLoadLabels(true)
                fetch(`/task/task_labels/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.loadDataLabels()
                    this.props.messageSuccess(response)
                });
            }
        });
    };

    edit = (label) => {
        this.setState({
            modeEdit: true,
            modal: true,
            id: label.id,
            form: {
                name: label.name,
                color: label.color,
            }
        })
    }

    

    handleChangeComplete = (color) => {
        this.setState({ 
            form: {
                ...this.state.form,
                color: color.hex 
            }
        });
    };

    render() {
        return (
            <React.Fragment>

                <FormFilterName
                    handleChangeFilter={this.props.handleChangeFilterLabel}
                    handleClickFilter={this.props.handleClickFilterLabel}
                    showbtnSubmit={this.props.showbtnSubmit}
                    clearValues={this.props.clearValuesLabel}
                    formFilter={this.props.formFilterLabel}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar etiqueta" : "Añadir etiqueta"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Añadir"}
                        
                        //colors 
                        onChangeFormColor={this.handleChangeComplete}
                    />
                )}

                {this.state.modalCkeckList && (
                    <FormCreateCkeckList
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCkeckList}
                        toggle={this.toogleChecklist}
                        title={"Crear Checklist"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        label={this.state.label}

                        //users 

                        users={this.props.users}
                        user={this.props.user}
                        loadDataLabels={this.props.loadDataLabels}
                        
                    />
                )}

                {!this.props.isLoadedLabels ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-header">
                                <div className="row w-100">
                                    <div className="col-md-9">
                                        Etiqueta
                                    </div>

                                    <div className="col-md-3 pr-0 text-right">
                                        <button
                                            className="btn-shadow btn btn-info"
                                            onClick={() => this.toogle("new")}
                                        >
                                            Añadir etiqueta
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "25%" }}>Nombre</th>
                                            <th>Checklists</th>
                                            <th style={{ width: "8%" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.dataLabels.length >= 1 ? (
                                            this.props.dataLabels.map(label => (
                                                <tr key={label.id}>
                                                    <td>
                                                        <p className="items-colors" style={{ backgroundColor: label.color }}>{label.name}</p>
                                                    </td>

                                                    <td>
                                                        <p
                                                        
                                                        >
                                                        <span
                                                        className="link-line-dark"
                                                        onClick={() => this.toogleChecklist("new", label)}
                                                        > + Checklists</span>
                                                        </p>
                                                            <hr/>
                                                        <div className="col-md-12 pl-0 pb-2">
                                                            {label.check_lists.map((check_list, index) => (
                                                            <span>{index > 0 ? "," : "" } {check_list.name}</span>
                                                            ))}
                                                        </div>
                                                    </td>

                                                    <td className="text-right" style={{ width: "10px" }}>
                                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button
                                                                    className="btn-shadow btn btn-info"
                                                                    id="btnGroupDrop1"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="fas fa-bars"></i>
                                                                </button>

                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                    {true && (
                                                                        <button
                                                                            onClick={() => this.edit(label)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </button>
                                                                    )}

                                                                    {true && (
                                                                        <button
                                                                            onClick={() => this.delete(label.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                </tr>
                                            ))
                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-md-12 mt-4">
                                <div className="row">
                                    <div className={`col-md-${isMobile ? "1" : "7"}`}>
                                        <p>
                                            Mostrando {this.props.dataLabels.length} de {this.props.labels_count}
                                        </p>
                                    </div>

                                    <div className="col-md-2 text-right">
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage}
                                            itemsCountPerPage={this.props.countPage}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.labels_count}
                                            pageRangeDisplayed={this.props.countPage}
                                            onChange={this.props.handlePageChange}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader/>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default IndexLabel;

