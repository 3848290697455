import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from "./FormCreate";
import FormUploadFIle from './UploadFIle';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalFile: false,
            modeEdit: false,
            id: "",

            form: {
                name: "",
                html_name: "",
            },

            formUpdate: {
                name: "",
                html_name: "",
            },
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleFile = () => {
        this.setState({ modalFile: !this.state.modalFile })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",

            form: {
                name: "",
                html_name: "",
            },

            formUpdate: {
                name: "",
                html_name: "",
            },
        })
    }

    HandleClick = () => {
        if (this.state.modeEdit) {
            fetch(`/settings/icons/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateItem(data.register);
                this.setState({ modal: false})
                this.clearValues();
                this.messageSuccess(data);
            });
        }else{
            fetch("/settings/icons", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateData(data.register);
                this.setState({ modal: false})
                this.messageSuccess(data);
            });
        }
    }
    

    HandleChange = (e) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    [e.target.name]: e.target.value
                }
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/icons/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    edit = (icon) => {
        this.setState({
            modal: true,
            modeEdit: true,
            id: icon.id,

            formUpdate: {
                name: icon.name,
                html_name: icon.html_name,
            },
        })
    }

    closeModal = () => {
        this.setState({ modalFile: false })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Ingreso de iconos"}
                    subTitle={"Gestión de iconos"}
                    titleNew={"Nuevo icono"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    onClickFilter={""}
                    showNew={true}
                    showFilter={false}
                />

                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10">
                                <h5 className="mb-0">Subir archivo</h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button
                                    className="btn-shadow  btn btn-info"
                                    onClick={() => this.toogleFile()}
                                >
                                    Subir
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar icon" : "Agregar icon"}

                        //form props
                        formValues={this.state.modeEdit ? this.state.formUpdate : this.state.form}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                    />
                )}

                {this.state.modalFile && (
                    <FormUploadFIle
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalFile}
                        toggle={this.toogleFile}
                        title={"Subir archivo"}

                        closeModal={this.closeModal}
                        loadData={this.props.loadData}
                    />
                )}

                {this.props.data.length >= 1 ? (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    {this.props.data.map((icon, index) => (
                                        <div key={icon.id} className={`col-md-2 ${index >= 6 ? "mt-3" : ""}`}>
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <i className={`${icon.html_name} icon-list` }></i>
                                                    <p className="mb-0 mt-3 icon-name">{icon.name == null ? "Baiso" : icon.name}</p>
                                                </div>

                                                <div className="card-footer">
                                                    <i className="fas fa-pencil-alt icon-edit" onClick={() => this.edit(icon)}></i>
                                                    <i className="fas fa-trash-alt icon-delete"  onClick={() => this.delete(icon.id)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="content main-card mb-3 card">
                        <div className="card-body text-center">
                            <h5>No hay registros</h5>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;
