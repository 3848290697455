import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                cause: this.props.modeEdit ? this.props.formValues.cause : "", 
                control: this.props.modeEdit ? this.props.formValues.control : "", 
                ocurrency_score_first: this.props.modeEdit ? this.props.formValues.ocurrency_score_first : "", 
                detectability_score_first: this.props.modeEdit ? this.props.formValues.detectability_score_first : "", 
                failure_id: this.props.id, 
            }
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    } 
    
    HandleClick = () => {
        if(this.props.modeEdit){
            fetch(`/failure/causes/${this.props.formValues.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.props.clearValues();
                this.messageSuccess(data);
            });
        }else{  
            fetch("/failure/causes", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.props.clearValues();
                this.props.loadDataActivity();
                this.messageSuccess(data);
            });
        }
    }
   
    render() {
        return (
            <React.Fragment>
                <div style={{ width: "289px" }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">

                            <div className="col-md-12">
                            <label htmlFor="">Causa</label>
                                <textarea 
                                    name="cause" 
                                    className="form form-control"
                                    value={this.state.form.cause}
                                    onChange={this.HandleChange}
                                    placeholder="Ingrese texto"
                                    rows="3"
                                >

                                </textarea>
                            </div>
                            <div className="col-md-12 mt-3">
                                <label>Ocurrencia</label>
                                <select 
                                    name="ocurrency_score_first" 
                                    className="form form-control"
                                    value={this.state.form.ocurrency_score_first}
                                    onChange={this.HandleChange}
                                >
                                    <option value="">seleccione una opción</option>
                                    <option value="1">1{this.props.type_count  == "" || this.props.type_count  == undefined || this.props.type_count  == "Salud" ? " - Dificilmente ocurre / Probablemente 1 vez al año" : ""}</option>
                                    <option value="2">2{this.props.type_count  == "" || this.props.type_count  == undefined || this.props.type_count  == "Salud"  ? " - Ocurre muy pocas veces / Probablemente 1 vez cada 6 meses" : ""}</option>
                                    <option value="3">3{this.props.type_count  == "" || this.props.type_count  == undefined || this.props.type_count  == "Salud" ? " - Ocurre algunas veces / Probablemente 1 vez a la semana" : ""}</option>
                                    <option value="4">4{this.props.type_count  == "" || this.props.type_count  == undefined  || this.props.type_count  == "Salud" ? " - Ocurre una vez al día" : ""}</option>
                                    <option value="5">5{this.props.type_count == "" || this.props.type_count  == undefined  || this.props.type_count  == "Salud" ? " - Ocurre de manera diaria en diferentes momentos" : ""}</option>
                                </select>
                            </div>

                            <div className="col-md-12 mt-3">
                                <label htmlFor="">Control</label>
                                <textarea 
                                    name="control" 
                                    className="form form-control"
                                    value={this.state.form.control}
                                    onChange={this.HandleChange}
                                    placeholder="Ingrese texto"
                                    rows="3"
                                >

                                </textarea>
                            </div>
                            <div className="col-md-12 mt-3">
                                <label>Detectabilidad</label>
                                <select 
                                    name="detectability_score_first" 
                                    className="form form-control"
                                    value={this.state.form.detectability_score_first}
                                    onChange={this.HandleChange}
                                >
                                    <option value="">seleccione una opción</option>
                                    <option value="1">1{this.props.type_count == ""|| this.props.type_count  == undefined || this.props.type_count  == "Salud"  ? " - Detectable siempre / Más de dos barreras muy bien aplicadas" : ""}</option>
                                    <option value="2">2{this.props.type_count == "" || this.props.type_count  == undefined || this.props.type_count  == "Salud" ? " - Casi siempre detectable / Dos barreras de seguridad bien aplicadas" : ""}</option>
                                    <option value="3">3{this.props.type_count == "" || this.props.type_count  == undefined || this.props.type_count  == "Salud" ? " - Normalmente detectable / Existe una barrera de seguridad bien aplicada" : ""}</option>
                                    <option value="4">4{this.props.type_count == "" || this.props.type_count  == undefined  || this.props.type_count  == "Salud" ? " - Muy díficil de detectar /Existe una barrera que no se aplica regularmente" : ""}</option>
                                    <option value="5">5{this.props.type_count== ""  || this.props.type_count  == undefined  || this.props.type_count  == "Salud" ? " - No es detectable, no existen barreras de seguridad" : ""}</option>
                                </select>
                            </div>
                            
                            <div className="col-md-12 mt-3">
                                <button
                                    className="btn-shadow btn-block btn btn-light text-center"
                                    onClick={() => this.HandleClick()}
                                >
                                    {this.props.modeEdit ? "Actualizar" : "Guardar"}
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </React.Fragment>
            
        );
    }
}

export default FormCreate;
