import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";

class Index extends Component {
    constructor(props) {
        super(props);
        const date = new Date();
        this.token = document.querySelector("[name='csrf-token']").content;
        this.fecha = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
        this.state = {
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            id: "",

            formCreate: {
                name: "",
                proceso_id: "",
                responsable_user_id: "",
                external_document_parent_id: "",
                emisor: "",
                attached: {},
                due_date: "",
                creation_date: this.fecha,
                position_ids: [],
            },

            selectedOptionUser: {
                responsable_user_id: "",
                label: "responsable"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionMulti: {
                position_ids: [],
                label: "Cargos que pueden acceder",
            },

            editValues: [],
            nameFile: "",
            sizeFile: 0
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues()
        }
    }

    validationForm = () => {
        if (this.state.formCreate.responsable_user_id != "" &&
            this.state.formCreate.name != "" &&
            this.state.formCreate.proceso_id != "" &&
            this.state.formCreate.due_date != "" &&
            this.state.formCreate.creation_date != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/document_management/external_documents/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            formCreate: {
                name: "",
                proceso_id: "",
                responsable_user_id: "",
                external_document_parent_id: "",
                emisor: "",
                attached: {},
                due_date: "",
                creation_date: "",
                position_ids: [],
            },

            selectedOptionUser: {
                responsable_user_id: "",
                label: "Usuario que crea"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },


            editValues: [],
            nameFile: "",
            sizeFile: 0
        })
    }

    HandleClick = () => {
        if (this.validationForm()) {
            this.setState({ isLoaded: true })
            const formData = new FormData();
            formData.append("name", this.state.formCreate.name)
            formData.append("proceso_id", this.state.formCreate.proceso_id)
            formData.append("responsable_user_id", this.state.formCreate.responsable_user_id)
            formData.append("external_document_parent_id", this.state.formCreate.external_document_parent_id)
            formData.append("emisor", this.state.formCreate.emisor)
            formData.append("attached", this.state.formCreate.attached)
            formData.append("due_date", this.state.formCreate.due_date)
            formData.append("creation_date", this.state.formCreate.creation_date)
            formData.append("position_ids", this.state.formCreate.position_ids)


            if (!this.state.modeEdit)
                fetch(`/document_management/external_documents`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        this.props.loadData();
                        this.clearValues();
                    });
            else {
                fetch(`/document_management/external_documents/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        this.props.updateItem(data.register);
                        this.clearValues();
                    });
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    edit = (document) => {
        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))


        this.setState({
            modeEdit: true,
            modal: true,
            id: document.id,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                proceso_id: document.proceso.id,
                responsable_user_id: document.responsable_user.id,
                emisor: document.emisor,
                attached: {},
                due_date: document.due_date,
                creation_date: document.creation_date,
                external_document_parent_id: document.external_document_parent_id,
                position_ids: arrayIds,
            },


            selectedOptionUser: {
                responsable_user_id: `${document.responsable_user != null ? document.responsable_user.id : ""}`,
                label: `${document.responsable_user != null ? `${document.responsable_user.first_name} ${document.responsable_user.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }

    generateClone = (document) => {

        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modal: true,
            modeEdit: false,
            is_clone: true,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                proceso_id: document.proceso.id,
                responsable_user_id: document.responsable_user.id,
                emisor: document.emisor,
                attached: {},
                due_date: document.due_date,
                creation_date: document.creation_date,  
                external_document_parent_id: document.id,
                position_ids: arrayIds,
            },

            selectedOptionUser: {
                responsable_user_id: `${document.responsable_user != null ? document.responsable_user.id : ""}`,
                label: `${document.responsable_user != null ? `${document.responsable_user.first_name} ${document.responsable_user.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }



    handleChangeChecked = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                is_attached: isChecked
            }
        })
    }

    handleChangeFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formCreate: {
                    ...this.state.formCreate,
                    attached: file
                },

                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    handleChangeAutocompleteUser = selectedOptionUser => {
        this.setState({
            selectedOptionUser,
                formCreate: {
                    ...this.state.formCreate,
                    responsable_user_id: selectedOptionUser.value
                }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProceso => {
        this.setState({
            selectedOptionProceso,
                formCreate: {
                    ...this.state.formCreate,
                    proceso_id: selectedOptionProceso.value
                }
        });
    };

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        selectedOptionMulti.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                position_ids: array
            }
        })
    }

    get_colorDate = (state) => {
        let resta = new Date(state).getTime() - new Date().getTime()
        resta = Math.round((resta / (1000 * 24 * 60 * 60)));
        console.log(resta, "kasñdfjlas")
        resta = resta + 1
        if (resta >= 5) {
            return "green"
        } else if (resta < 5 && resta >= 0) {
            return "orange"
        } else {
            return "red"
        }
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar documento externo" : "Crear documento externo"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreate}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        errorValues={this.state.ErrorValues}
                        handleChangeChecked={this.handleChangeChecked}
                        isLoaded={this.state.isLoaded}

                        //file
                        nameFile={this.state.nameFile}
                        handleChangeFile={this.handleChangeFile}

                        //select values

                        //select UserCreate
                        selectedOptionUser={this.state.selectedOptionUser}
                        handleChangeAutocompleteUser={this.handleChangeAutocompleteUser}

                        //select Proceso
                        selectedOptionProceso={this.state.selectedOptionProceso}
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}

                        //select multi
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}


                        procesos={this.props.procesos}
                        users={this.props.users}
                        positions={this.props.positions}
                        editValues={this.state.editValues}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered table-layou-fixed" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de vencimiento</th>
                                    <th>Proceso</th>
                                    <th>Responsable</th>
                                    <th>Emisor</th>
                                    <th>Documento</th>
                                    {(this.props.estados.delete || this.props.estados.edit) && (
                                        <th className="text-center w-9" st>Acciones</th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(document => (
                                        <tr key={document.id}>
                                            <td>{document.name}</td>
                                            <td>{document.creation_date}</td>
                                            <td style={{ background: this.props.state == "vigente" ? this.get_colorDate(document.due_date) : "", color: this.props.state == "vigente" ? "#fff" : "" }}>{document.due_date}</td>
                                            <td>{document.proceso ? document.proceso.name : ""}</td>
                                            <td>{document.responsable_user.first_name} {document.responsable_user.first_last_name}</td>
                                            <td>{document.emisor}</td>
                                            <td><a  target="_blank" href={document.attached.url ? document.attached.url : ""}>{document.attached.url ? "Ver Documento" : ""}</a></td>

                                            {(this.props.estados.edit || this.props.estados.delete) && (
                                                <td className="text-right">
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {this.props.estados.edit && (
                                                                    <button
                                                                        onClick={() => this.edit(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.delete && (
                                                                    <button
                                                                        onClick={() => this.delete(document.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.generate && (
                                                                    <button
                                                                        onClick={() => this.generateClone(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Generar versión
                                                                    </button>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <td colSpan="7" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    )}
                            </tbody>
                        </table>

                        {this.props.data.length >= 10 && (
                            <div className="row">

                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>

                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        )}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Index;