import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import ModalQr from '../../formatos/Surveys/ModalQr'

class SurveyIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.url = `${this.props.domain}/formatos/generate_questions_surveys/${this.props.format.id}/${this.props.survey.id}/parent`
        this.state = {
            formUpdateRepy: {

            },
            formUpdateRepySelect: {

            },

            formatValues: {

            },

            formUpdateWidth: {
                column_width: "",
            },

            showFilter: false,
            question_id: "",
            modal: false,
            showPopover: false,
            sort: false,
            showIcons: false,
            selectItems: [],
            checked: false,
            modalQr: false,
            options: [],

            answer_id: "",

            formUpdateAnswer: {
                answer_type: "",
                value: "",
            }
        }
    }
    HandleChangeRepy = (e) => {
        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: e.target.value + "-" + (this.state.formUpdateRepySelect[e.target.dataset.id] != undefined ? this.state.formUpdateRepySelect[e.target.dataset.id] : "contiene")
            }
        });
    }


    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                [e.target.name]: e.target.value,
            }
        });
    }

    selectValue = (answer) => {
        this.loadOptions(answer.survey_id, answer.question_id)
        this.setState({
            answer_id: answer.survey_id,
            question_id: answer.question_id,

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: answer.type,
                value: answer.value,
            }
        })
    }

    loadOptions = (survey_id, question_id) => {
        fetch(`/formatos/get_options_surveys/${survey_id}/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                console.log(data, "asfafsd")
                this.setState({
                    options: data.register,
                })
            });
    }

    cancelValues = () => {
        this.setState({
            answer_id: "",
            question_id: "",
            options: [],

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: "",
                value: "",
            }
        })
    }


    HandleChangeCkaditor = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                value: e
            }
        })
    }

    updateValue = () => {
        fetch(`/formatos/update_survey_values/${this.state.answer_id}/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateAnswer), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log(data, "asfafsd")
                this.cancelValues();
                this.props.loadData(this.props.format_association);

            });
    }


    HandleChangeRepySelect = (e) => {
        var value = this.state.formUpdateRepy[e.target.dataset.id].split("-")[0];
        this.setState({
            formUpdateRepySelect: {
                ...this.state.formUpdateRepySelect,
                [e.target.dataset.id]: e.target.value
            },

            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: value + "-" + e.target.value
            }
        });
    }

    HandleChangeWidth = (e) => {
        this.setState({
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    setValues = (question) => {
        this.setState({
            question_id: question.id,
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                column_width: question.column_width,
            }
        })
    }

    HandleChangeRepyDate1 = (e) => {

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${e.target.value},${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[1] : null}`
            }
        });
    }
    HandleChangeRepyDate2 = (e) => {

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[0] : null},${e.target.value}`
            }
        });
    }

    getWidth = (value) => {
        console.log(isMobile)
        console.log(value)
        if (value > 4 || isMobile) {

            return (((value * 300) + 300).toString()) + "px";

        } else {
            return "100%";
        }

    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.formato.id}/surveys/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData(this.props.format_association)
                        //this.props.messageSuccess(response)
                    });
            }
        });
    };

    getWidth = (value) => {
        console.log(isMobile)
        console.log(value)
        if (value > 4 || isMobile) {

            return (((value * 300) + 300).toString()) + "px";

        } else {
            return "100%";
        }
    }

    toogleModalQr = (from) => {
        if (from == "new") {
            this.setState({ modalQr: true })
        } else {
            this.setState({ modalQr: false })
        }
    }


    copyLink = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(this.url)
        this.setState({
            modalQr: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modalQr && (
                    <ModalQr
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalQr}
                        toggle={this.toogleModalQr}
                        title={"Código QR"}
                        url={this.url}
                        copyLink={this.copyLink}
                    />
                )}


                <div className="card mt-4">
                    <div className="card-header" style={{ display: "flow-root", paddingTop: 12 }}>
                        {this.props.format.name} ({this.props.survey_total} Registro{this.props.survey_total == 1 ? "" : "s" })

                        <a
                            className="btn-shadow btn btn-info float-right"
                            href={`/formatos/generate_questions_surveys/${this.props.format.id}/${this.props.survey.id}/parent`}
                            data-turbolinks="false"
                        >
                            Crear
                        </a>

                        <a
                            className="btn btn-primary float-right mr-2"
                            style={{ color: "#ffff", marginLeft: "10px" }}
                            target="_blank"
                            onClick={() => this.toogleModalQr("new")}
                        >
                            <i className="far fa-copy"></i> Link / Código QR
                        </a>

                        {(!this.props.formato.has_taks || !this.props.formato.has_comments) && (
                            <a
                                className="btn-shadow btn btn-info float-right mr-3"
                                style={{ color: "#ffff" }}
                                onClick={() => this.props.cancelSelect()}
                            >
                                Volver
                            </a>
                        )}
                    </div>

                    <div className="card-body" style={{ display: "grid", justifyContent: "center" }}>
                       
                        <div className="content-table">
                            <table className="table table-hover table-striped table-fd" style={{ width: this.props.dataQuestionnvm.length > 4 ? this.props.tableWidth : "100%", tableLayout: this.props.dataQuestionnvm.length > 4 ? "initial" : "fixed" }}>
                                {(this.props.dataQdataAnswersnvm.length >= 1) && (
                                    <thead>

                                        <tr>
                                            <td width="30px" className="td-header"></td>
                                            {this.props.dataQuestionnvm.length >= 1 ? (
                                                this.props.dataQuestionnvm.filter(value => value.show_table).map(question => (

                                                    <td style={{ width: this.props.dataQuestionnvm.length > 4 ? question.column_width + "px" : "" }} className="td-header">
                                                        {/* <a href={`/formatos/questions/${question.id}`} target="_blank" data-turbolinks="false">{question.question}</a> */}
                                                        <div className="gm-sort">
                                                            <span className="gm-ellipsis" style={{ maxWidth: "80%" }}>{question.question}</span>
                                                            <div className="ml-2"> <a className="sort-up hidden" href="javascript:void(0)" onClick={() => this.sortby(question.id, this.state.sort)}>  <i style={{ position: "relative", top: "-3px" }} className="fas fa-sort"></i> </a>{/* <a href="javascript:void(0)" onClick={()=>this.sortby(question.id, "DESC" )}> <i className="fas fa-sort-down"></i></a> */}</div>
                                                        </div>
                                                    </td>


                                                ))
                                            ) : (<div></div>
                                                )}
                                        </tr>
                                    </thead>
                                )}

                                < tbody >
                                    {
                                        this.props.dataQdataAnswersnvm.length >= 1 ? (
                                            this.props.dataQdataAnswersnvm.map(survey => (
                                                <tr key={survey.survey}>
                                                    <td className="text-center" style={{ width: "20px" }}>
                                                        {true && (
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right">

                                                                        {true && (
                                                                            <a
                                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${survey.format}/gestion/${survey.survey}`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Gestionar
                                                            </a>
                                                                        )}

                                                                        {true && (
                                                                            <a
                                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${survey.format}/surveys/${survey.survey}?type=parent`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                            </a>
                                                                        )}



                                                                        {false && (
                                                                            <a
                                                                                href={`/formatos/formats/${survey.format}/share_questions/${survey.survey}`}
                                                                                data-turbolinks="false"
                                                                                target="_blank"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Compartir cuestionario
                                                            </a>
                                                                        )}




                                                                        {true && (
                                                                            <a
                                                                                onClick={() => this.delete(survey.survey)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                            </a>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    {survey.answers.filter(value => value.show_table).map(answer => (
                                                        <td className="td-fordata-info" style={{ background: (answer.type == "answer_date" || answer.type == "dynamic_field") && answer.color != "white" && this.props.view != "INACTIVE" ? answer.color : "", color: (answer.type == "answer_date" || answer.type == "dynamic_field") && answer.color != "white" && this.props.view != "INACTIVE" ? "white" : "gray" }}>

                                                            {(answer.type == "na") && (
                                                                <span>{answer.value != null ? answer.value : "null"}</span>

                                                            )}

                                                            {(answer.type != "answer_integer" && answer.type != "url" && answer.type != "answer_formula" && answer.type != "answer_file" && answer.type != "answer_checkbox" && answer.type != "answer_datetime" && answer.type != "na" && answer.type != "answer_date" && answer.type != "answer_text" && answer.type != "answer_radio" && answer.type != "answer_select" && answer.type != "answer_string" && answer.type != "answer_signature" && answer.type != "answer_hour") && (
                                                                <span>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                            )}

                                                            {answer.type == "answer_signature" && answer.value && (
                                                                <img style={{ width: "150px", height: "50px" }} src={answer.value} />
                                                            )}

                                                            {answer.type == "answer_string" && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_string") ? (
                                                                        <React.Fragment>
                                                                            <input
                                                                                type="text"
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                                style={{ width: "90%", display: "inherit" }}
                                                                            />
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <span onClick={() => this.selectValue(answer)}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                        )}
                                                                </React.Fragment>
                                                            )}

                                                            {answer.type == "answer_hour" && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_hour") ? (
                                                                        <React.Fragment>
                                                                            <input
                                                                                type="text"
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                                style={{ width: "90%", display: "inherit" }}
                                                                            />
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <span onClick={() => this.selectValue(answer)}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                        )}
                                                                </React.Fragment>
                                                            )}

                                                            {answer.type == "answer_select" && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_select") ? (
                                                                        <React.Fragment>
                                                                            <select
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                            >
                                                                                <option value="">Seleccione un valor</option>
                                                                                {this.state.options.map((value, index) => (
                                                                                    <option value={value}>{value}</option>
                                                                                ))}
                                                                            </select>
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <span onClick={() => this.selectValue(answer)}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                        )}
                                                                </React.Fragment>
                                                            )}

                                                            {answer.type == "answer_file" && (
                                                                <a
                                                                    href={answer.value}
                                                                    target="_blanck"
                                                                >
                                                                    {answer.value != null && answer.value != "" ? "Ver Archivo" : ""}

                                                                </a>
                                                            )}
                                                            {(answer.type == "answer_radio") && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_radio") ? (
                                                                        <React.Fragment>
                                                                            <select
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                            >
                                                                                <option value="">Seleccione un valor</option>
                                                                                {this.state.options.map((value, index) => (
                                                                                    <option value={value}>{value}</option>
                                                                                ))}
                                                                            </select>

                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <span onClick={() => this.selectValue(answer)}>{answer.value.replace(",", "")} </span>
                                                                        )}
                                                                </React.Fragment>
                                                            )}

                                                            {(answer.type == "answer_checkbox") && (

                                                                answer.value.map((value, index) => (

                                                                    <span>{index == 0 ? "" : ", "} {value}</span>

                                                                ))
                                                            )}
                                                            {answer.type == "answer_datetime" && (
                                                                <span>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                            )}

                                                            {answer.type == "answer_date" && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_date") ? (
                                                                        <React.Fragment>
                                                                            <input
                                                                                type="date"
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                                style={{ width: "90%", display: "inherit" }}
                                                                            />
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <span onClick={() => this.selectValue(answer)}>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                                        )}
                                                                </React.Fragment>
                                                            )}

                                                            {answer.type == "answer_text" && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_text") ? (
                                                                        <React.Fragment>
                                                                            <Ckaditor
                                                                                HandleChange={this.HandleChangeCkaditor}
                                                                                formValues={this.state.formUpdateAnswer.value}
                                                                            />
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <div className="gm-ellipsis" onClick={() => this.selectValue(answer)} style={{ maxWidth: (answer.column_width - 20) + "px", maxHeight: "60px" }}>{ReactHtmlParser(answer.value)}</div>
                                                                        )}
                                                                </React.Fragment>
                                                            )}

                                                            {((answer.type == "answer_integer" || answer.type == "answer_formula") && answer.is_currency) && (

                                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={answer.value} />

                                                            )}
                                                            {((answer.type == "answer_integer" || answer.type == "answer_formula") && answer.is_currency != true) && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_integer") ? (
                                                                        <React.Fragment>
                                                                            <input
                                                                                type="number"
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                                style={{ width: "90%", display: "inherit" }}
                                                                            />
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <span onClick={() => this.selectValue(answer)}>{answer.value}</span>
                                                                        )}
                                                                </React.Fragment>
                                                            )}
                                                            {(answer.type == "url") && (
                                                                <React.Fragment>
                                                                    {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "url") ? (
                                                                        <React.Fragment>
                                                                            <input
                                                                                type="text"
                                                                                name="value"
                                                                                className="form form-control"
                                                                                onChange={this.HandleChangeUpdateAnswer}
                                                                                value={this.state.formUpdateAnswer.value}
                                                                                style={{ width: "90%", display: "inherit" }}
                                                                            />
                                                                            <div className="mt-2 mb-1">
                                                                                <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <React.Fragment>
                                                                                <a href={answer.value} target="_blank">{answer.value}</a> <i className="fas fa-pencil-alt" onClick={() => this.selectValue(answer)}></i>
                                                                            </React.Fragment>
                                                                        )}
                                                                </React.Fragment>
                                                            )}


                                                        </td>
                                                    ))}

                                                </tr>
                                            ))

                                        ) : (
                                                <tr>
                                                    <td className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SurveyIndex;
