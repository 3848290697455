import React from "react";
import Chart from "react-google-charts";

class ChartNormativity extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ display: "inline-flex",width:'100%' }}>
        <Chart
          width={'100%'}
          height={300}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={this.props.califications_date}
          options={{
            title: "Historico",
            chartArea: { width: "70%" },
            hAxis: {
              title: "Fecha",
              minValue: 0
            },
            vAxis: {
              title: "Nota de cumplimiento",
              minValue: 0,
              maxValue:10
            },
          }}
          
        />
      </div>
    );
  }
}

export default ChartNormativity;
