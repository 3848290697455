import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Ckeditor from '../../formatos/Formats/Ckeditor'

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    show = (value) => {
        return this.props.title == "Agregar Subnumeral" ? "block" : "none"
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-6" style={{ display: this.show() }}>
                                    <label>Título <small className="validate-label">*</small></label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}

                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Seguridad del paciente <small className="validate-label">*</small></label>
                                    <input
                                        type="text"
                                        name="patient_safety_standar"
                                        value={this.props.formValues.patient_safety_standar}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.patient_safety_standar == "" ? "error-class" : ""}`}
                                        placeholder="Seguridad del paciente "

                                    />
                                </div>

                                <div className="col-md-6 mt-3" style={{ display: this.show() }}>
                                    <label>Elemento medible <small className="validate-label">*</small></label>
                                    <input
                                        type="text"
                                        name="measurable_element"
                                        value={this.props.formValues.measurable_element}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.measurable_element == "" ? "error-class" : ""}`}

                                    />
                                </div>

                                <div className={`col-md-6 ${this.props.title == "Agregar Subnumeral" ? "mt-3" : ""}`}>
                                    <label>Área/servicio <small className="validate-label">*</small></label>
                                    <select
                                        name="service_area"
                                        className="form form-control"
                                        value={this.props.formValues.service_area}
                                        onChange={this.props.onChangeForm}
                                    >
                                        <option value="">Seleccione</option>
                                        {this.props.areas.map((item) => (
                                            <option value={item.name}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label>Cláusula ISO <small className="validate-label">*</small></label>
                                    <input
                                        type="text"
                                        name="iso_clause"
                                        value={this.props.formValues.iso_clause}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.iso_clause == "" ? "error-class" : ""}`}

                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label>Guía de implementación <small className="validate-label">*</small></label>
                                    <Ckeditor
                                        HandleChange={this.props.HandleChangeInplementionGuide}
                                        formValues={this.props.formValues.implementation_guide}
                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label>Url del video <small className="validate-label">*</small></label>
                                    <input
                                        type="text"
                                        name="video_url"
                                        value={this.props.formValues.video_url}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Ejemplo: https://www.youtube.com/embed/ApXoWvfEYVU"

                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <input
                                        type="hidden"
                                        name="proceso_ids"
                                        value={this.props.selectedOptionProcesos.proceso_ids}
                                    />
                                    <label>Procesos </label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="proceso_ids"
                                        defaultValue={[
                                            this.props.editValuesProcesos[0],
                                            this.props.editValuesProcesos[1],
                                            this.props.editValuesProcesos[2],
                                            this.props.editValuesProcesos[3],
                                            this.props.editValuesProcesos[4],
                                            this.props.editValuesProcesos[5]
                                        ]}
                                        options={this.props.procesos}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>



                                {this.props.errorValues == false && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.title == "Agregar Numeral" ? "Crear" : "Actualizar"}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;