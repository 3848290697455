import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import PositionIndex from '../packComponents/PositionIndex'
import ProcesoIndex from '../packComponents/ProcesoIndex'
import ProcesoShow from '../packComponents/ProcesoShow'
import TenantIndex from '../packComponents/TenantIndex'
import TenantShow from '../packComponents/TenantShow'
import SectionIndex from '../packComponents/SectionIndex'
import SectionAppIndex from '../packComponents/SectionAppIndex'

import NotificationEventIndex from '../packComponents/NotificationEventIndex'
import NotificationEventShow from '../packComponents/NotificationEventShow'
import NotificationEventNew from '../packComponents/NotificationEventNew'






class Root extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        if (this.props.module == "position_index") {
            return (
                <React.Fragment>
                    <PositionIndex
                        procesos={this.props.procesos}
                        estados={this.props.estados}
                    />
                </React.Fragment>
            );
        } else if (this.props.module == "procesos_index") {
            return (
                <React.Fragment>
                    <ProcesoIndex
                        estados={this.props.estados}
                        tenant={this.props.tenant}
                    />
                </React.Fragment>
            );
        } else if (this.props.module == "procesos_show") {
            return (
                <React.Fragment>
                    <ProcesoShow
                        proceso={this.props.proceso}
                        users={this.props.users}
                        usuario={this.props.usuario}
                    />
                </React.Fragment>
            );
        } else if (this.props.module == "tenant_index") {
            return (
                <React.Fragment>
                    <TenantIndex
                        plans={this.props.plans}
                        base_models={this.props.base_models}
                    />
                </React.Fragment>
            );
        } else if (this.props.module == "tenant_show") {
            return (
                <React.Fragment>
                    <TenantShow
                        user={this.props.user}
                        tenant={this.props.tenant}
                        estados={this.props.estados}
                    />
                </React.Fragment>
            );
        }
        else if (this.props.module == "section_index") {
            return (
                <React.Fragment>
                    <SectionIndex

                    />
                </React.Fragment>
            );
        }
        else if (this.props.module == "section_app_index") {
            return (
                <React.Fragment>
                    <SectionAppIndex

                    />
                </React.Fragment>
            );
        }




        else if (this.props.module == "event_notification_index") {
            return (
                <React.Fragment>
                    <NotificationEventIndex
                        usuario={this.props.usuario}
                        areas={this.props.areas}
                        events={this.props.events}
                        sources={this.props.sources}
                        nameModule={this.props.nameModule}
                        estados={this.props.estados}
                    />
                </React.Fragment>
            );
        }

        else if (this.props.module == "event_notification_show") {
            return (
                <React.Fragment>
                    <NotificationEventShow
                        event_notification={this.props.event_notification}
                        user={this.props.user}
                        users={this.props.users}
                        estados={this.props.estados}
                        procesos={this.props.procesos}
                        task_source={this.props.task_source}
                    />
                </React.Fragment>
            );
        }

        else if (this.props.module == "event_notification_new") {
            return (
                <React.Fragment>
                    <NotificationEventNew
                        usuario={this.props.usuario}
                        editMode={this.props.editMode}
                        areas={this.props.areas}
                        events={this.props.events}
                        sources={this.props.sources}
                        notification_event={this.props.notification_event}
                    />
                </React.Fragment>
            );
        }







    }
}


export default Root;
WebpackerReact.setup({ Root });