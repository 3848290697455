import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TaskList from './TaskList'
import FormCreate from './FormCreate'
import Preloader from '../../../GeneralComponents/LoadingComponen'

class TaskIndexCard extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            showCard: false,
            isLoadedShow: true,
            isLoadedCommets: true,
            isLoadedFiles: true,
            id: "",

            form: {
                due_date: "",
                module_task_id: this.props.config_task.show_info ? this.props.config_task.module_task_id : "",
                name: "",
                task_source_id: this.props.config_task.show_info ? this.props.config_task.task_source.id : "",
                url_source: this.props.config_task.show_info ? this.props.config_task.url : "",
                user_owner_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            users: []
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({

            form: {
                ...this.state.form,
                name: "",
                due_date: "",
                user_owner_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            showCard: false,
        })
    }

    componentDidMount(){
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
          selectedOption,
          form: {
             ...this.state.form,
             user_owner_id: selectedOption.value
          }
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                name: e.target.name == "name" ? (e.target.value.length + 0 > 100 ? this.state.form.name : e.target.value) : this.state.form.name,
            }
        });
    }

    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-8">
                                <h6>
                                    {this.props.estados.crear == true ? "Nueva Tarea" : "Todas las tareas"} 
                                </h6>
                            </div>
                            <div className="col-md-4">
                                {this.props.estados.crear == true ? <React.Fragment> {this.state.showCard == false ? <i className="fas fa-plus float-right icon-add-card" onClick={() => this.setState({ showCard: true })}></i> : <i className="fas fa-times float-right icon-add-card" onClick={() => this.setState({ showCard: false })}></i>} </React.Fragment> : ""}
                            </div>
                            <div className="col-md-12"><hr/></div>
                        </div>
                    </div>
                </div>
                    
                    {this.state.showCard &&(
                        <FormCreate
                            HandleChange={this.HandleChange}
                            form={this.state.form}
                            clearValues={this.clearValues}
                            loadData={this.props.loadData}
                            updateStateLoad={this.props.updateStateLoad}
                            //select autocomplete
                            handleChangeAutocomplete={this.handleChangeAutocomplete}
                            selectedOption={this.state.selectedOption}
                            users={this.state.users}

                            updateData={this.props.updateData}
                        />
                    )}


                    {this.props.isLoaded ? (
                       <div className="col-md-12 text-center mt-4">
                            <Preloader />
                       </div>
                    ) : (
                        <React.Fragment>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(task => (
                                    <div className="mt-3" key={task.id}>
                                        <TaskList 
                                            task={task} 
                                            user={this.props.user} 
                                            users={this.props.users}
                                            showOptions={false} 
                                            loadData={this.props.loadData}
                                            estados={this.props.estados}
                                            updateStateLoad={this.props.updateStateLoad}

                                            updateItem={this.props.updateItem}
                                            procesos={this.props.procesos}
                                            messageSuccess={this.messageSuccess}
                                            url_host={this.props.url_host}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="col-md-12 mt-2 p-0">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h6>No hay tareas generales</h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}

            </React.Fragment>
        );
    }
}

export default TaskIndexCard;