import React, { Component } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class FormCreateQuestion extends Component {
    render() {
        return (
            <React.Fragment>
                <button
                    id={`PopoverLegacy${this.props.id}`}
                    type="button"
                    className={`btn-shadow btn btn-primary btn-block text-left`}
                    style={{ width: "88%", height: "33px" }}
                    onClick={() => this.props.updateId(this.props.id)}
                >
                    {(this.props.nameBtn != "" && this.props.registerId == this.props.id) ? (
                        <React.Fragment>
                            {this.props.getIcon(this.props.nameBtn)} {this.props.nameBtn}
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <i className="fas fa-info-circle"></i>  {"Seleccione un tipo"}
                            </React.Fragment>
                        )}
                </button>

                <button
                    type="button"
                    className="btn-shadow btn btn-info btn-block text-left mt-0"
                    style={{ width: "15%", marginLeft: "10px", height: "33px" }}
                    onClick={() => this.props.handleClick()}
                >
                    <i className="fas fa-check"></i>
                </button>

                <UncontrolledPopover isOpen={this.props.showPopover && this.props.registerId == this.props.id} trigger="legacy" placement="bottom" target={`PopoverLegacy${this.props.id}`}>
                    <a className="close-popover" onClick={() => this.props.clearId()} style={{ padding: "10px" }}> <i className="fas fa-times"></i></a>
                    <PopoverBody>
                        <div className="row">
                            <div className="col-md-12" style={{ width: "301px" }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className={`list-format`} onClick={() => this.props.setName("Respuesta breve")}><i className="fas fa-align-center"></i> Respuesta breve</p>
                                    </div>

                                    <div className="col-md-12">
                                        <p className={`list-format`} onClick={() => this.props.setName("Párrafo")}><i className="fas fa-align-left"></i> Párrafo</p>
                                    </div>

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Código")}><i className="fas fa-align-left"></i> Cçodigo</p>
                                        </div>
                                    )}

                                    <div className="col-md-12">
                                        <p className={`list-format`} onClick={() => this.props.setName("Campo numérico")}><i className="fas fa-list-ol"></i> Campo numérico</p>
                                    </div>

                                    <div className="col-md-12">
                                        <p className={`list-format`} onClick={() => this.props.setName("Link")}><i className="fas fa-link"></i> Link</p>
                                    </div>

                                    {false && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Email")}><i className="fas fa-envelope"></i> Email</p>
                                        </div>
                                    )}

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Campo dinámico")}><i className="fas fa-list-ol"></i> Campo dinámico</p>
                                        </div>
                                    )}


                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Fecha")}><i className="fas fa-calendar-day"></i> Fecha</p>
                                        </div>
                                    )}


                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Hora")}><i className="fas fa-calendar-day"></i> Hora</p>
                                        </div>
                                    )}

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Carga de archivos")}><i className="fas fa-cloud-upload-alt"></i> Carga de archivos</p>
                                        </div>
                                    )}

                                    <div className="col-md-12">
                                        <p className={`list-format`} onClick={() => this.props.setName("Lista desplegable")}><i className="fas fa-chevron-circle-down"></i> Lista desplegable</p>
                                    </div>

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Lista de usuarios")}><i className="fas fa-users"></i> Lista de usuarios</p>
                                        </div>
                                    )}

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Fórmula")}><i className="fas fa-square-root-alt"></i> Fórmula</p>
                                        </div>
                                    )}

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Lista de procesos")}><i className="fas fa-cogs"></i> Lista de procesos</p>
                                        </div>
                                    )}

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Lista de cargos")}><i className="fas fa-briefcase"></i> Lista de cargos</p>
                                        </div>
                                    )}

                                    <div className="col-md-12">
                                        <p className={`list-format`} onClick={() => this.props.setName("Opción múltiple")}><i className="far fa-dot-circle"></i> Opción múltiple</p>
                                    </div>

                                    {!this.props.modeEdit && (
                                        <div className="col-md-12">
                                            <p className={`list-format`} onClick={() => this.props.setName("Formato")}><i className="fas fa-database"></i> Formato</p>
                                        </div>
                                    )}


                                    <div className="col-md-12">
                                        <p className={`list-format mb-0`} onClick={() => this.props.setName("Casillas de verificación")}><i className="fas fa-check-square"></i> Casillas de verificación</p>
                                    </div>

                                    {/* <div className="col-md-12">
                                        <p className={`list-format mb-0`} onClick={() => this.props.setName("Asociación entre campos")}><i className="fas fa-check-square"></i> Asociación entre campos </p>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </PopoverBody>
                </UncontrolledPopover>

            </React.Fragment>
        );
    }
}

export default FormCreateQuestion;
