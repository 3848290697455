import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Show from '../components/documentManagement/Document/Show'
import HeaderTitle from "../GeneralComponents/HeaderTitle"

class DocumentShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            document: {
                id: this.props.document.id,
                name: this.props.document.name,
                module_task_id: this.props.document.module_task_id,
    
                user_create: this.props.document.user_create,
                user_review: this.props.document.user_review,
                user_approve: this.props.document.user_approve,
    
                create_state: this.props.document.create_state,
                review_state: this.props.document.review_state,
                approve_state: this.props.document.approve_state,
    
                creation_date: this.props.document.creation_date,
                review_date: this.props.document.review_date,
                approve_date: this.props.document.approve_date,
    
                review_status: this.props.document.review_status,
                approve_status: this.props.document.approve_status,
            },
        }
    }

    componentDidMount(){
        this.loadDataTask();
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.document.module_task_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoadedTask: false,
            });
        });
    }

    loadDocument = () => {
        fetch(`/document_management/get_document/${this.props.document.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.updateDocument(data.document)
        });
    }

    updateDocument = (document) => {
        this.setState({
            document: {
                id: document.id,
                name: document.name,
                module_task_id: document.module_task_id,
    
                user_create: document.user_create,
                user_review: document.user_review,
                user_approve: document.user_approve,
    
                create_state: document.create_state,
                review_state: document.review_state,
                approve_state: document.approve_state,
    
                creation_date: document.creation_date,
                review_date: document.review_date,
                approve_date: document.approve_date,
    
                review_status: document.review_status,
                approve_status: document.approve_status,
            },
        })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
            isLoaded: false,
            isFiltering: false,
        })
    }

    //add update
    updateItem = task => {
        this.loadDocument();
        this.loadDataTask();
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                user_owner: task.user_owner,
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
                checklist: task.checklist,
                url_source: task.url_source,
                task_source: task.task_source,
              }
            }
            return item;
          })
        });
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Gestión del documento`}
                    subTitle={"Gestión documental"}
                    titleNew={"Nuevo documento"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={() => this.child.current.toogle("new")}
                    showNew={false}
                    showFilter={false}
                />
                
                <Show
                    loadData={this.loadDataTask}
                    loadDocument={this.loadDocument}
                    estados={this.props.estados}

                    users={this.props.users}
                    user={this.props.user}
                    
                    labels={this.props.labels}
                    tasks={this.props.tasks} 
                    procesos={this.props.procesos}
                    task_sources={this.props.task_sources}
                    data={this.state.data}
                    document={this.props.document}
                    document_state={this.state.document}


                    //test data 

                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    updateDocument={this.updateDocument}
                />
            </React.Fragment>
        );
    }
}


export default DocumentShow;
WebpackerReact.setup({ DocumentShow });

