const initialData = {
  
    tasks: {
      'task-1': { id: 'task-1', content: 'Cuando el documneto esta en estado creado cuando le doy editar solo me debe permitir editar cargos que pueden acceder' },
      'task-2': { id: 'task-2', content: 'Cuando filtro y elimino una tarea no me debe recargar todas las tareas si no dejarme filtrado' },
      'task-3': { id: 'task-3', content: 'En las etiquetas en configuracion de tareas no me permite borrar los check list asociados' },
      'task-4': { id: 'task-4', content: 'Cuando creo una pregunta en decimales poner 2 por defecto' },
    },
    
    columns: {

      'column-1': {
        id: 'column-1',
        title: 'PENDIENTES',
        taskIds: ['task-1', 'task-2', 'task-3', 'task-4']
      },

      'column-2': {
        id: 'column-2',
        title: 'EN PROCESO',
        taskIds: []
      },

      'column-3': {
        id: 'column-3',
        title: 'FINALIZADAS',
        taskIds: []
      },

      'column-4': {
        id: 'column-4',
        title: 'FINALIZADAS',
        taskIds: []
      }

    },

    // orden de la colunas
    columnOrder: ['column-1', 'column-2', 'column-3']
  }
  
  export default initialData
  