import React, { Component } from 'react';

class Index extends Component {
    render() {
        const sytleFalse = {
            fontSize: "32px",
            color: "#ff0000",
            cursor: "pointer"
        }

        const sytleTrue = {
            fontSize: "32px",
            color: "#589000",
            cursor: "pointer"
        }

        return (
            <React.Fragment>
                {this.props.dataUsers.length >= 1 ? (
                    this.props.dataUsers.map((user, index) => (
                        <div className={`card mb-3`} key={user.id}>
                            <div className="card-body">
                                <div className="row">

                                    <div className={`col-${this.props.estados.change_state ? "10" : "12"} mb-2`}>
                                        <b>Nombre de usuario</b>
                                        <p>{user.username}</p>
                                    </div>

                                    {this.props.estados.change_state && (
                                        <div className="col-2">
                                            {user.user_state ? <i className="fas fa-check" onClick={() => this.props.updateState(user.id, false)} style={sytleTrue}></i> : <i className="fas fa-times" onClick={() => this.props.updateState(user.id, (this.props.usersTrue >= this.props.current_user.countUsers ? false : true))} style={sytleFalse}></i>}
                                        </div>
                                    )}

                                    <div className="col-md-3 mb-2">
                                        <b>Correo electrónico</b>
                                        <p>{user.second_email}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Nombre</b>
                                        <p>{user.first_name} {user.first_last_name}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Cargos</b>
                                        <p>{user.position != undefined ? user.position.name : "Sin Cargo"}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Adjuntos</b>
                                        <p>{this.props.estados.uploadFile == true ? <span className="link-line" onClick={() => this.props.addFiles(user)}>{user.general_files.length} Archivos adjuntos </span> : <span className="link-line">Ver archivos </span>   }</p>
                                    </div>

                                </div>
                            </div>

                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                <div className="card-footer">
                                    {this.props.estados.editar && (
                                        <button
                                            onClick={() => this.props.edit(user)}
                                            className="dropdown-item"
                                        >
                                            Editar
                                        </button>
                                    )}

                                    {this.props.estados.eliminar == true && !user.is_tenant  && (  
                                        <button
                                            onClick={() => this.props.delete(user.id)}
                                            className="dropdown-item"
                                        >
                                            Eliminar
                                        </button>
                                    )}
                                </div>
                            )}

                        </div>
                    ))

                ) : (
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>No hay registros</h4>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;