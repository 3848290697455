import React, { Component } from 'react';
import Show from '../components/risk/Risk/Show'
import ShowMejora from '../components/risk/Risk/Mejora/ShowMejora'
import WebpackerReact from 'webpacker-react';

class RiskShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataTask: [],
            isLoadedTask: true,
        }
    }

    componentDidMount(){
        this.loadDataTask();
    }
    

    updateStateLoadTask = (state) => {
        this.setState({ isLoadedTask: state })
    }

    //add items
    updateData = (data) => {
        this.setState({
            dataTask: [...this.state.dataTask, data],
            isLoadedTask: false,
        })
    }

    //add update
    updateItem = task => {
        this.setState({
            dataTask: this.state.dataTask.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                //user_owner: {document: "1037660678", document_type: "Cédula de"},
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
              }
            }
            return item;
          })
        });
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.risk.module_task_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataTask: data.data,
                isLoadedTask: false,
            });
        });
    }


    render() {

        if (this.props.user_c.app_type == "Mejora")
        {
        return (
            <React.Fragment>
                <ShowMejora
                    // task module
                    users={this.props.users}
                    user={this.props.user}
                    procesos={this.props.procesos}
                    isLoadedTask={this.state.isLoadedTask}
                    updateStateLoad={this.updateStateLoadTask}
                    dataTask={this.state.dataTask}
                    loadDataTask={this.loadDataTask}

                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    risk_matrix={this.props.risk_matrix}
                    risk={this.props.risk}
                    task_source={this.props.task_source}
                    url={this.props.url}
                />
            </React.Fragment>
        );

        }else{

            return (
                <React.Fragment>
                    <Show
                        // task module
                        users={this.props.users}
                        user={this.props.user}
                        procesos={this.props.procesos}
                        isLoadedTask={this.state.isLoadedTask}
                        updateStateLoad={this.updateStateLoadTask}
                        dataTask={this.state.dataTask}
                        loadDataTask={this.loadDataTask}
    
                        updateItem={this.updateItem}
                        updateData={this.updateData}
                        risk_matrix={this.props.risk_matrix}
                        risk={this.props.risk}
                        task_source={this.props.task_source}
                    />
                </React.Fragment>
            );
        }
    }
}


export default RiskShow;
WebpackerReact.setup({ RiskShow });
