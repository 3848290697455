import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import IndexLabels from './Labels/IndexLabel'
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props){
        super(props)
        this.state = {
            count: 0,
            countMax: 3,
            title: "",
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Configuración de tareas`}
                    subTitle={"Gestión de tareas"}
                    titleNew={"Nuevo label"}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={""}
                    showNew={false}
                    showFilter={false}
                />

                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a 
                            className="nav-item nav-link active" 
                            id="nav-home-tab" 
                            data-toggle="tab" 
                            href="#nav-home" 
                            role="tab" 
                            aria-controls="nav-home" 
                            aria-selected="true">
                            Etiquetas
                        </a>
                    </div>  
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div className="row mb-4">

                            <div className="col-md-12">
                                <IndexLabels
                                    dataLabels={this.props.dataLabels}
                                    isLoadedLabels={this.props.isLoadedLabels}
                                    updateDataLabels={this.props.updateDataLabels}
                                    messageSuccess={this.messageSuccess}
                                    loadDataLabels={this.props.loadDataLabels}
                                    updateStateLoadLabels={this.props.updateStateLoadLabels}
                                    updateItemLabel={this.props.updateItemLabel}
                                    dataColors={this.props.dataColors}
                                    users={this.props.users}
                                    user={this.props.user}

                                    //pagination

                                    activePage={this.props.activePage}
                                    countPage={this.props.countPage}
                                    handlePageChange={this.props.handlePageChange}
                                    labels_count={this.props.labels_count}

                                    //filter labels

                                    clearValuesLabel={this.props.clearValuesLabel}
                                    handleChangeFilterLabel={this.props.handleChangeFilterLabel}
                                    handleClickFilterLabel={this.props.handleClickFilterLabel}
                                    showbtnSubmit={this.props.showbtnSubmit}
                                    formFilterLabel={this.props.formFilterLabel}
                                />
                            </div>

                        </div>                        
                    </div>
                </div>          

            </React.Fragment>
        );
    }
}

export default Index;
