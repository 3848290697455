import React, { Component } from 'react';
import TaskIndex from '../../tasks/tasksCard/Index'

class Show extends Component {
    constructor(props){
        super(props)
        this.url_source = `/health/event_notification/${this.props.event_notification.id}`
    }

    render() {
        return (
            <React.Fragment>
                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-3">
                                        <p><b>Fecha del Evento</b></p>
                                        <p>{this.props.event_notification.event_date}</p>
                                    </div>

                                    <div className="col-md-3">
                                        <p><b>nombre del Paciente</b></p>
                                        <p>{this.props.event_notification.patient_name}</p>
                                    </div>

                                    <div className="col-md-3">
                                        <p><b>Fecha de Nacimiento</b></p>
                                        <p>{this.props.event_notification.birthdate}</p>
                                    </div>

                                    <div className="col-md-3">
                                        <p><b>Evento Relacionado</b></p>
                                        <p>{this.props.event_notification.related_event}</p>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                    </div>

                                    <div className="col-md-3">
                                        <p><b>Evento relacionado con la seguridad</b></p>
                                        <p>{this.props.event_notification.specific_area}</p>
                                    </div>

                                    <div className="col-md-3">
                                        <p><b>Área específica</b></p>
                                        <p>{this.props.event_notification.safety_related_event}</p>
                                    </div>

                                    <div className="col-md-6">
                                        <p><b>Descripción</b></p>
                                        <p>{this.props.event_notification.description}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <TaskIndex
                    data={this.props.data}
                    estados={this.props.estados}
                    user={this.props.user}
                    users={this.props.users}
                    module_task={this.props.event_notification.module_task_id}
                    updateStateLoad={this.props.updateStateLoad}
                    task_source_id={this.props.task_source.id}
                    url_source={this.url_source}
                    loadData={this.props.loadTasks}

                    updateData={this.props.updateData}
                    updateItem={this.props.updateItem}
                    procesos={this.props.procesos}
                />
            </React.Fragment>
        );
    }
}

export default Show;
