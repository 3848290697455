import React, { Component } from 'react';

class LoadingComponen extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="loading-component">
                    <div className="loader-wrapper d-flex justify-content-center align-items-center">
                        <div className="loader">
                            <div className="ball-spin-fade-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LoadingComponen;