import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class TableIndexWeb extends Component {
    constructor(props) {
        super(props);
        this.chart_title = `Gestión de normatividad para ${this.getNameTitle(this.props.type)}`
        this.categories = this.props.data.map(normativity => normativity.name);
        this.data = this.props.data.map(normativity => {
            if (normativity.normativity_scores.length > 0) {
                return (normativity.normativity_scores[0].score * 10).toFixed(1);
            } else {
                return 0;
            }
        });
        this.colors = this.props.data.map(normativity => {
            if (normativity.normativity_scores.length > 0) {
                if (normativity.normativity_scores[0].score < 6) {
                    return '#ea7470';
                } else if (normativity.normativity_scores[0].score >= 6 && normativity.normativity_scores[0].score < 8) {
                    return '#ffa600';
                } else if (normativity.normativity_scores[0].score >= 8) {
                    return '#22c488';
                };
            } else {
                return '#ea7470';
            }
        });
        this.series = []
        for (let i = 0; i < this.data.length; i++) {
            this.series.push({
                name: this.categories[i],
                data: [this.data[i]],
                colors: [this.colors[i]]
            })
        };
        console.log(this.series)

        this.state = {

            series: [{
                name: 'Última calificación',
                data: this.data
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                        distributed: true,
                    },
                },
                colors: this.colors,
                legend: {
                    show: false,
                    // formatter: function (value) {
                    //     const max_length = 20;
                    //     if (typeof value !== 'undefined' && value !== null) {
                    //         if (value.length > max_length) {
                    //             return value.substring(0, max_length-3) + "...";
                    //         }
                    //     }
                    //     return value;
                    // },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val + "%";
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },

                xaxis: {
                    categories: this.categories,
                    // position: 'top',
                    // RECORTAR LAS ETIQUETAS DE LAS CATEGORIAS DLE EJE X
                    labels: {
                        formatter: function (value) {
                            const max_length = 20;
                            if (typeof value !== 'undefined' && value !== null) {
                                if (value.length > max_length) {
                                    return value.substring(0, max_length - 3) + "...";
                                }
                            }
                            return value;
                        },
                        style: {
                            fontSize: '16px',
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val + "%";
                        }
                    }
                },
                title: {
                    text: this.chart_title,
                    position: "top",
                    floating: true,
                    offsetY: 0,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
        };
    };

    getNameTitle = (name) => {
        if (name == "PCH" || name == "NMH") {
            return "Hospitales"
        } else if (name == "PCCAP") {
            return "Modelo Ambulatorio"
        } else if (name == "PCISO9001") {
            return "ISO 9001:2015 Salud"
        } else if (name == "PCISO15189") {
            return "ISO 7101:2023 HQM"
        } else if (name == "PCHEM") {
            return "Acreditación"
        } else if (name == "NCON") {
            return "Servicios Auxiliares"
        } else if (name == "NFAR") {
            return "Expediente Clínico"
        } else if (name == "NNOM") {
            return "Marco Legal"
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body normativity-body">
                        <table className="table table-hover table-striped table-bordered normativity-body-table" id="sampleTable">
                            <thead>
                                <tr>
                                    <th style={{ width: "45%" }}>Nombre</th>
                                    {this.props.type != "NNOM" && (
                                        <th className="text-center" style={{ width: "20%" }}>Última Calificación</th>
                                    )}

                                    {this.props.estados.evaluations && (
                                        <th style={{ width: "15%" }}>
                                            {this.props.type == "NNOM" ? "Fecha de Calificación" : ""}
                                        </th>
                                    )}

                                    <th style={{ width: "15%" }}>{this.props.type == "NNOM" ? "Calificación" : ""}</th>
                                    {this.props.type == "NNOM" && (
                                        <th style={{ width: "15%" }}>Archivos</th>
                                    )}
                                    {this.props.estados.gestionar && (
                                        <th style={{ width: "8%" }}></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(normativity => (
                                        <tr key={normativity.id}>
                                            <td>
                                                {normativity.id == this.props.id ? (
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={this.props.formUpdate.name}
                                                        onChange={this.props.HandleChangeUpdate}
                                                        className="form form-control"
                                                    />
                                                ) : (
                                                    <p>{normativity.name}</p>
                                                )}
                                            </td>

                                            {this.props.type != "NNOM" && (
                                                <td className="last-score">
                                                    {this.props.getScore(normativity.normativity_scores)}
                                                </td>
                                            )}

                                            <td className="text-center">
                                                {this.props.type != "NNOM" ? (
                                                    <React.Fragment>
                                                        {this.props.estados.evaluations && (
                                                            <a
                                                                className="link-item"
                                                                data-turbolinks="false"
                                                                href={`/health/normativity/${normativity.id}`}
                                                            >
                                                                Implementación
                                                            </a>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {normativity.id == this.props.id ? (
                                                            <input
                                                                type="date"
                                                                name="date_score"
                                                                value={this.props.formUpdate.date_score}
                                                                onChange={this.props.HandleChangeUpdate}
                                                                className="form form-control"
                                                            />
                                                        ) : (
                                                            <p>{normativity.date_score}</p>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </td>

                                            <td className="text-center">
                                                {this.props.type != "NNOM" ? (
                                                    <a
                                                        className="link-item"
                                                        data-turbolinks="false"
                                                        href={`/health/normativity/${normativity.id}/numeral_parents`}
                                                    >
                                                        Estándares y guías
                                                    </a>
                                                ) : (
                                                    <React.Fragment>
                                                        {normativity.id == this.props.id ? (
                                                            <input
                                                                type="number"
                                                                name="score"
                                                                value={this.props.formUpdate.score}
                                                                onChange={this.props.HandleChangeUpdate}
                                                                placeholder="Calificación"
                                                                className="form form-control"
                                                            />
                                                        ) : (
                                                            <p>{normativity.score}</p>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </td>

                                            {this.props.type == "NNOM" && (
                                                <td className="text-center">
                                                    <span className="link-line" onClick={() => this.props.addFiles(normativity)}>Archivos adjuntos </span>
                                                </td>
                                            )}

                                            {(true || true) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    {this.props.id != "" ? (
                                                        <React.Fragment>
                                                            {normativity.id == this.props.id && (
                                                                <React.Fragment>
                                                                    <i className="fas fa-check-circle update-section" onClick={() => this.props.HandleClickUpdate()}></i>
                                                                    <i className="fas fa-times-circle update-false" onClick={() => this.props.updateId()}></i>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button
                                                                    className="btn-shadow btn btn-info"
                                                                    id="btnGroupDrop1"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="fas fa-bars"></i>
                                                                </button>

                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                    {this.props.estados.editar && (
                                                                        <button
                                                                            onClick={() => this.props.edit(normativity)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </button>
                                                                    )}

                                                                    {this.props.estados.eliminar && (
                                                                        <button
                                                                            onClick={() => this.props.delete(normativity.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </button>
                                                                    )}

                                                                    {this.props.type != "NNOM" && (
                                                                        <a
                                                                            className="dropdown-item"
                                                                            data-turbolinks="false"
                                                                            href={`/health/normativity/${normativity.id}/numeral_parents`}
                                                                        >
                                                                            Gestionar
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="content main-card mb-3 card">
                    <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        height={350}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;