import React, { Component } from 'react';
import TaskIndex from '../../tasks/tasksCard/Index'
import { Progress } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import GoogleDocsViewer from 'react-google-docs-viewer';
import answers from './answers'
import Answers from './answers';
import GeneralComments from '../../../GeneralComponents/ChatBoxModuleComment'

class Show extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleClickUpdate = (status) => {
        fetch(`/document_management/update_status_document/${this.props.document.id}/${status}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("data.registerdata.registerdata.register", data.register)
                this.props.updateDocument(data.register);
                this.props.loadData();
            });
    }

    getFooterUser = () => {
        if (this.props.document.user_review.id == this.props.user.id && this.props.document_state.create_state && !this.props.document_state.review_state) {

            return (
                <div className="card-footer">
                    <div className="btn-group" role="group" aria-label="Basic example">

                        {this.props.document_state.review_status == "aprobado" && (
                            <button
                                type="button"
                                className="btn-shadow btn btn-info"
                                disabled={true}
                            >
                                Documento aprobado
                            </button>
                        )}

                        {this.props.document_state.review_status == "no aprobado" && (
                            <button
                                type="button"
                                className="btn-shadow btn btn-danger"
                                disabled={true}
                            >
                                Documento no aprobado
                            </button>
                        )}

                        {(this.props.document_state.review_status == "" && this.props.document_state.create_state) && (
                            <React.Fragment>
                                <button type="button" className="btn-shadow btn btn-info" onClick={() => this.handleClickUpdate("aprobado")}>Apruebo el documento</button>
                                <button type="button" className="btn-shadow btn btn-danger" onClick={() => this.handleClickUpdate("no aprobado")}>No apruebo el documento</button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )

        } else if (this.props.document.user_approve.id == this.props.user.id && this.props.document_state.review_state && !this.props.document_state.approve_state) {

            return (
                <div className="card-footer">
                    <div className="btn-group" role="group" aria-label="Basic example">

                        {this.props.document_state.approve_status == "aprobado" && (
                            <button
                                type="button"
                                className="btn-shadow btn btn-info"
                                disabled={true}
                            >
                                Documento aprobado
                            </button>
                        )}

                        {this.props.document_state.approve_status == "no aprobado" && (
                            <button
                                type="button"
                                className="btn-shadow btn btn-danger"
                                disabled={true}
                            >
                                Documento no aprobado
                            </button>
                        )}

                        {(this.props.document_state.approve_status == "" && this.props.document_state.review_state) && (
                            <React.Fragment>
                                <button type="button" className="btn-shadow btn btn-info" onClick={() => this.handleClickUpdate("aprobado")}>Apruebo el documento</button>
                                <button type="button" className="btn-shadow btn btn-danger" onClick={() => this.handleClickUpdate("no aprobado")}>No apruebo el documento</button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )

        }
    }


    render() {
        return (
            <React.Fragment>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Documento</a>
                    </li>

                    {this.props.document.state != "aprobado" && (
                        <React.Fragment>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Estado del documento</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" id="comments-tab" data-toggle="tab" href="#comments" role="tab" aria-controls="comments" aria-selected="false">Comentarios</a>
                            </li>
                        </React.Fragment>
                    )}
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <div className="">
                            <div className="card mb-3 document-show-container">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12" >
                                            {(this.props.document.approve_state != true && this.props.document.suervey_id != "na") && (
                                                <a href={`/formatos/format_categories/${this.props.document.format_category_id}/formats/${this.props.document.format_id}/surveys/${this.props.document.suervey_id}`} className="btn btn-primary"><i className="fas fa-pencil-alt"></i> </a>
                                            )}
                                        </div>
                                        <div className="col-md-12" >
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {(!this.props.document.is_attached) && (
                                            <div className="col-md-12 text-center document-header" >
                                                {ReactHtmlParser(this.props.document.header.replace("{NOMBRE}", this.props.document.name).replace("{VERSION}", this.props.document.version))}
                                            </div>
                                        )}
                                        
                                        {(this.props.document.is_attached || this.props.document.suervey_id === "na") && (
                                            <div className="col-md-12 text-center ">
                                                <GoogleDocsViewer
                                                    width="900px"
                                                    height="780px"
                                                    fileUrl={this.props.document.attached.url}
                                                />
                                            </div>
                                        )}
                                        {this.props.document.suervey_id != "na" && (

                                            this.props.document.survey.answers.map((data, index) => (
                                                <div className="col-md-12">
                                                    <Answers data={data} index={index} />
                                                </div>
                                            ))

                                        )}
                                        {(!this.props.document.is_attached) && (
                                            <div className="col-md-12 text-center document-footer" width="900px">
                                                {ReactHtmlParser(this.props.document.footer)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                        <div className="card mb-3">
                            <div className="card-body">

                                <div className="col-md-6 ml-0">
                                    <div className="card">
                                        <div className="card-header">
                                            Estado del documento
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 progress-container" >
                                                    <Progress multi>
                                                        <Progress animated striped bar color={this.props.document_state.create_state ? "info" : "secondary"} value="34">CREADO ({this.props.document_state.creation_date != null ? this.props.document_state.creation_date : "Pendiente"}) </Progress>
                                                        <Progress animated striped bar color={this.props.document_state.review_status == "aprobado" ? "info" : "secondary"} value="34"> REVISADO ({this.props.document_state.review_date != null ? this.props.document_state.review_date : "Pendiente"}) </Progress>
                                                        <Progress animated striped bar color={this.props.document_state.approve_status == "aprobado" ? "info" : "secondary"} value="34"> APROBADO ({this.props.document_state.approve_date != null ? this.props.document_state.approve_date : "Pendiente"}) </Progress>
                                                    </Progress>
                                                    <div className="display-flex mt-2">
                                                        <p className="mr-2"><i class="fas fa-circle" style={{ color: "#16aaff" }}></i> <b>Completado</b></p>
                                                        <p><i class="fas fa-circle"></i> <b>No completado</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {(this.props.document.user_approve.id == this.props.user.id || this.props.document.user_review.id == this.props.user.id) && (
                                            <React.Fragment>
                                                {this.getFooterUser()}
                                            </React.Fragment>
                                        )}

                                    </div>
                                </div>

                            </div>
                        </div>

                        <TaskIndex
                            data={this.props.data}
                            estados={this.props.estados}
                            users={this.props.users}
                            user={this.props.user}
                            procesos={this.props.procesos}
                            updateData={this.props.updateData}
                            updateItem={this.props.updateItem}
                        />
                    </div>


                    <div class="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                        <GeneralComments
                            usuario={this.props.user}
                            module_name={"Gestión documental"}
                            users={this.props.users}
                            module_comment={this.props.document.module_comment_id}
                            url={this.props.url}
                        />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Show;
