import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ModalVideo from '../components/health/NumeralParents/ShowModalVideo';

class HeaderTitle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalVideo: false,
        }
    }

    toogle = () => {
        this.setState({ modalVideo: !this.state.modalVideo })
    }

    render() {
        return (
            <React.Fragment>
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className={`${this.props.icon != undefined ? this.props.icon : "fas fa-user-cog"} icon-gradient bg-mean-fruit`}>
                                </i>
                            </div>
                            <div>
                                {this.props.title}
                                <div className="page-title-subheading">{this.props.subTitle}
                                </div>
                            </div>
                        </div>


                        {this.state.modalVideo && (
                            <ModalVideo
                                modal={this.state.modalVideo}
                                video_url={this.props.video_url}
                                toggle={this.toogle}
                            />
                        )}

                        <div className="page-title-actions">

                            {this.props.showFilter && (
                                <button type="button" onClick={() => this.props.onClickFilter()} className="btn-shadow mr-3 btn btn-primary">
                                    <i className="fas fa-filter"></i>
                                </button>
                            )}

                            {this.props.showVideo && (
                                <button type="button" onClick={() => this.setState({ modalVideo: !this.state.modalVideo })} className="btn-shadow mr-3 btn btn-primary">
                                    <span className="pr-2 opacity-7">
                                        <i className="fas fa-video"></i>
                                    </span>
                                    {"Video"}
                                </button>
                            )}

                            {this.props.titleNew == "Nuevo numeral" && (
                                <a
                                    href={`/health/normativity/${this.props.normativity.id}`}
                                    className="btn-shadow mr-3 btn btn-primary"
                                    data-turbolinks="false"
                                >
                                    Implementación
                                </a>
                            )}

                            {this.props.showNew && (
                                <button type="button" onClick={() => this.props.onClickNew("new")} className="btn-shadow  btn btn-info">
                                    <span className="pr-2 opacity-7">
                                        <i className="fa fa-user"></i>
                                    </span>
                                    {this.props.titleNew}
                                </button>
                            )}

                            {this.props.taskHistory != undefined && (
                                <a
                                    href={`/task/history/${this.props.taskHistory}`}
                                    className="btn-shadow btn btn-info"
                                    style={{ display: this.props.taskHistory == "gestor" ? "none" : "" }}
                                    data-turbolinks="false"
                                >
                                    <i className="fas fa-clock"></i> Historial
                                </a>
                            )}

                            {this.props.taskHistory != undefined && (
                                <a
                                    href={`/task/calendar/${this.props.taskHistory}`}
                                    className="btn-shadow btn btn-info ml-2"
                                    style={{ display: this.props.taskHistory == "gestor" ? "none" : "" }}
                                    data-turbolinks="false"
                                >
                                    <i className="fas fa-calendar-alt"></i> Calendario
                                </a>
                            )}


                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HeaderTitle;