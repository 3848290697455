import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import FormCreate from '../components/settings/Positions/FormCreate'

class FormCreatePosition extends Component {
    render() {
        return (
            <FormCreate 
                usuario={this.props.current_user} 
                estados={this.props.estados} 
                editMode={this.props.editMode} 
                position={this.props.position}
                procesos={this.props.procesos}
                tenant={this.props.tenant}
                section_accions={this.props.section_accions}
            />
        );
    }
}

export default FormCreatePosition;
WebpackerReact.setup({ FormCreatePosition });