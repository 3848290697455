import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateFiles from './FormGeneralFile'
import { isMobile } from 'react-device-detect';
import FormCreate from "../components/settings/GeneralComments/FormCreate";
import { array } from 'prop-types';

class ChatBoxCommentModules extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            module_file_id: "",
            data: [],   
            editValuesUsers: [],
            users: [],
            modaFiles: false,
            modal: false,
            modeEdit: false,
            
            form: {
                comment: "",
                module_name: this.props.module_name,
            },

            selectedOptionComment: {
                user_ids: [],
                label: "Responsables"
            },

            formUpdate: {
                comment: "",
            }
        }
    }
    
    componentDidMount(){
        this.loadData();
        this.configSelect();
    }

    configSelect = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
        })
    }


    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
              ...this.state.form,
              comment: ""
            }
        });
    }
    

    HandleChange = (e) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    comment: e.target.value
                }
            });
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    comment: e.target.value
                }
            });
        }
    }

    editComment = (comment) => {
        console.log("comment", comment);
        let arrayUsersEdit = []
        let arrayIds =[]

        comment.users.map((item) => (
            arrayUsersEdit.push({ label: `${item.first_name} ${item.first_last_name}`, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modeEdit: true,
            modal: true,
            id: comment.id,
            editValuesUsers: arrayUsersEdit,

            formUpdate: {
                comment: comment.comment,
                users_ids: arrayIds,
            }
        })
    
        
    }

    handleChangeAutocompleteMulti = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    user_ids: array
                }
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    user_ids: array
                }
            })
        }

    }

    HandleClick = () => {
        if(this.state.modeEdit){
            fetch(`/settings/comment_modules/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateItem(data.comment)
                this.setState({ id: "", modal: false, modeEdit: false })
            });
        }else{
            fetch(`/settings/comment_modules`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateData(data.comment)
                this.setState({ modal: false })
                this.clearValues()
                this.messageSuccess(data);
            });
        }
    }

    loadData = () => {
        fetch(`/settings/get_comment_modules/${this.props.module_name}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
        console.log("cargando comentarios")
          this.setState({
            data: data.data,
          });
        });
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si"
        }).then(result => {
          if (result.value) {
            fetch(`/settings/comment_modules/${id}`, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.loadData()
                this.messageSuccess(response);
              });
          }
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })

    }

    //add update
    updateItem = comment => {
        this.setState({
            data: this.state.data.map(item => {
            if (comment.id === item.id) {
              return { ...item, 
                comment: comment.comment,
                users: comment.users,
              }
            }
            return item;
          })
        });
    }

    dateTime = (fecha) => {
        var d = new Date(fecha),
          months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear()
    }

    //files 

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            isLoadedFiles: false,
            nameFile: "",
            file: null
        })
    }

    addFiles = (comment) => {
        this.setState({
            module_file_id: comment.module_file_id,
            modaFiles: true,
        })
    }

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
              ...this.state.formAddFiles,
              [e.target.name]: e.target.value
            }
        });
    }

    toogleFiles = (from) => {
        if(from == "new"){
            this.setState({ modaFiles: true})
            this.clearValues();
        }else{
            this.setState({ modaFiles: false})
        }
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true})
        }else{
            this.setState({ modal: false, modeEdit: false, editValuesUsers: [] })
        }
    }
    

    render() {
        return (

            <div className="card-hover-shadow-2x mb-3 card card-list">
                <div className="card-header-tab card-header">
                    <div className="w-100">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <i className="header-icon far fa-comments"> </i>Comentarios
                                </div>
                            </div>

                            <div className="col-md-4 text-right">
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                >
                                    Nuevo comentario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props

                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}    
                        title={"Agregar Archivos"}

                        //form props
                        delete_file={true}
                        edit_file={true}
                        module_file_id={this.state.module_file_id}
                        formValues={this.state.formAddFiles}
                        onChangeForm={this.HandleChangeFiles}
                        submitForm={this.HandleClickFiles}

                        //table files 

                        dataFiles={this.state.dataUsersFiles}
                        handleFileChange={this.handleFile}

                        //load info

                        loadDataTable={this.loadData}

                        module_id={"user_id"}
                        idGeneralFile={this.state.idGeneralFile}     
                        
                        isLoadedFiles={this.state.isLoadedFiles}

                        nameFile={this.state.nameFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar comentario" : "Crear comentario"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.modeEdit ? this.state.formUpdate : this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}

                        //select multi

                        editValuesUsers={this.state.editValuesUsers}
                        users={this.state.users}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        selectedOptionComment={this.state.selectedOptionComment}
                    />
                )}

                <div className="scroll-area-lg">
                    <div className="scrollbar-container">
                        <div className="p-2">
                            <div className="chat-wrapper p-1">
                                <div className="chat-box-wrapper">
                                    <div className="col-md-12">
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map(comment => (
                                                <React.Fragment>

                                                        <div className="row mb-4">
                                                            <div className="col-md-1 pl-0">
                                                                <div className="avatar-icon-wrapper mr-1">
                                                                    {/*<div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />*/}
                                                                    <div className="avatar-icon avatar-icon-lg rounded">
                                                                        <img src={comment.user.avatar} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-8">
                                                                <div className="chat-box">
                                                                    <React.Fragment>
                                                                        {comment.comment}
                                                                    </React.Fragment>
                                                                </div>
                                                                <small className="opacity-6">
                                                                    <i className="fas fa-user"></i> <span className="mr-2">{comment.user.first_name} {comment.user.first_last_name}</span>
                                                                    <i className="fa fa-calendar-alt mr-1" />
                                                                    {this.dateTime(comment.created_at)} <i className="fas fa-paperclip ml-2" style={{cursor: "pointer"}} onClick={() => this.addFiles(comment)}></i> <span>{comment.general_files}</span>
                                                                                <React.Fragment>
                                                                                    <i className="far fa-trash-alt float-right ml-2" style={{cursor: "pointer"}} onClick={() => this.delete(comment.id)}></i>
                                                                                    <i className="fas fa-pencil-alt float-right" style={{cursor: "pointer"}} onClick={() => this.editComment(comment)}></i>
                                                                                </React.Fragment>
                                                                </small>
                                                            </div>
                                                        </div>

                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <React.Fragment>
                                                <div className="no-comments">
                                                    <i className="far fa-comments"></i><br/>
                                                    <h1>No hay comentarios</h1>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}

export default ChatBoxCommentModules;


