import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'

const Container = styled.div`

`

export default class Task extends React.Component {
  render() {
    return (
      <Draggable
        draggableId={this.props.task.id}
        index={this.props.index}
      >
        {(provided, snapshot) => (
          <React.Fragment>     
              <Container
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                innerRef={provided.innerRef}
                isDragging={snapshot.isDragging}
              >
                <ul 
                    className="todo-list-wrapper list-group list-group-flush"
                  >
                    <li className="list-group-item">
                        <div className="todo-indicator " style={{ background: "green" }}></div>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                    <div className="widget-heading task-count">
                                      <span>T723</span>
                                      <div 
                                        className="widget-heading" 
                                        style={{ 
                                          wordBreak: "break-all",
                                          color: "#172b4d",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {this.props.task.content}
                                      </div>
                                      
                                    </div>
                                </div>

                                <div className="widget-content-right">
                                    <div className="d-inline-block dropdown">
                                        <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                            <i className="fa fa-ellipsis-h"></i>
                                        </button>

                                        <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right" style={{ paddingTop: "0px" }}>
                                            {true && (
                                                <React.Fragment>
                                                    <div className="col-md-12 text-center background-blue-dark-app">
                                                        Acciones de tarea
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            
                                            <button type="button" disabled="" tabIndex="-1" className="dropdown-item">Gestionar</button>
                                            <button type="button" tabIndex="0" className="dropdown-item">Eliminar Tarea</button>
                                          

                                            {true && (
                                                <React.Fragment>
                                                    <div className="col-md-12 text-center  background-blue-dark-app">
                                                        Estados
                                                    </div>

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            Pendientes
                                                        </button>
                                                    )}

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            En proceso
                                                        </button>
                                                    )}

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            Finalizadas
                                                        </button>
                                                    )}

                                                    {true && (
                                                        <button
                                                            className="dropdown-item"
                                                        >
                                                            Archivar
                                                        </button>
                                                    )}
                                                </React.Fragment>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                

                            </div>
                        </div>

                    </li>
                </ul >
              </Container>
          </React.Fragment>

        
        )}
      </Draggable>
    )
  }
}


/*


<Container
{...provided.draggableProps}
{...provided.dragHandleProps}
innerRef={provided.innerRef}
isDragging={snapshot.isDragging}
>
{this.props.task.content}
</Container>

*/