import React, { Component } from 'react';
import Select from "react-select";

class inputProcess extends Component {
    render() {
        return (
            <div className="col-md-4">
            <input
              type="hidden"
              name="proceso_ids"
              value={this.props.procesoIds}
            />
            <label>Procesos <small className="validate-label">*</small></label>
            <Select
              onChange={this.props.handleChangeAutocomplete}
              isMulti
              defaultValue={this.props.editValues}
              closeMenuOnSelect={false}
              name="proceso_ids"
              options={this.props.dataProcesos}
              className="basic-multi-select"
              classNamePrefix="select"
            />

          </div>
        );
    }
}

export default inputProcess;