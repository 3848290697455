import React, { Component } from 'react';

class index extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.length >= 1 ? (
                    this.props.data.map((normativity, index) => (
                        <div className={`card mb-3`} key={normativity.id}>
                            <div className="card-body">
                                <div className="row">

                                    <div className={`col-md-12 ${normativity.id == this.props.id ? "mb-3" : ""}`}>
                                        {normativity.id == this.props.id ? (
                                            <input
                                                type="text"
                                                name="name"
                                                value={this.props.formUpdate.name}
                                                onChange={this.props.HandleChangeUpdate}
                                                className="form form-control"
                                            />
                                        ) : (
                                            <p className="text-center">{normativity.name}</p>
                                        )}
                                    </div>

                                    {this.props.type != "NNOM" && (
                                        <div className="col-md-12">
                                            <div className="last-score">
                                                {this.props.getScore(normativity.normativity_scores)}
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-12 mobile-nor-card-links" >
                                        <div className={`${this.props.type == "NNOM" ? "mb-3" : ""}`}>
                                            <b>{this.props.type == "NNOM" ? "Fecha de Calificación" : ""}</b>
                                            {this.props.type != "NNOM" ? (
                                                <a
                                                    className="link-item"
                                                    data-turbolinks="false"
                                                    href={`/health/normativity/${normativity.id}`}
                                                >
                                                    Implementación
                                                </a>
                                            ) : (
                                                <React.Fragment>
                                                    {normativity.id == this.props.id ? (
                                                        <input
                                                            type="date"
                                                            name="date_score"
                                                            value={this.props.formUpdate.date_score}
                                                            onChange={this.props.HandleChangeUpdate}
                                                            className="form form-control"
                                                        />
                                                    ) : (
                                                        <p>{normativity.date_score}</p>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className={`${this.props.type == "NNOM" ? "mb-3" : ""}`}>
                                            <b>{this.props.type == "NNOM" ? "Calificación" : ""}</b>
                                            {this.props.type != "NNOM" ? (
                                                <a
                                                    className="link-item"
                                                    data-turbolinks="false"
                                                    href={`/health/normativity/${normativity.id}/numeral_parents`}
                                                >
                                                    Estándares y guías
                                                </a>
                                            ) : (
                                                <React.Fragment>
                                                    {normativity.id == this.props.id ? (
                                                        <input
                                                            type="number"
                                                            name="score"
                                                            value={this.props.formUpdate.score}
                                                            onChange={this.props.HandleChangeUpdate}
                                                            placeholder="Calificación"
                                                            className="form form-control"
                                                        />
                                                    ) : (
                                                        <p>{normativity.score}</p>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>

                                    {this.props.type == "NNOM" && (
                                        <div className="col-md-3 mb-2">
                                            <b>Archivos</b>
                                            <p>
                                                <span className="link-line" onClick={() => this.props.addFiles(normativity)}>Archivos adjuntos </span>
                                            </p>
                                        </div>
                                    )}

                                </div>
                            </div>

                            {true && (
                                <div className="card-footer">

                                    {this.props.id != "" ? (
                                        <React.Fragment>
                                            {normativity.id == this.props.id && (
                                                <React.Fragment>
                                                    <i className="fas fa-check-circle update-section" onClick={() => this.props.HandleClickUpdate()}></i>
                                                    <i className="fas fa-times-circle update-false" onClick={() => this.props.updateId()}></i>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ) : (

                                        <button
                                            onClick={() => this.props.edit(normativity)}
                                            className="dropdown-item"
                                        >
                                            Editar
                                        </button>

                                    )}

                                    {true && (
                                        <button
                                            onClick={() => this.props.delete(normativity.id)}
                                            className="dropdown-item"
                                        >
                                            Eliminar
                                        </button>
                                    )}

                                    {this.props.type != "NNOM" && (
                                        <a
                                            className="dropdown-item"
                                            data-turbolinks="false"
                                            href={`/health/normativity/${normativity.id}/numeral_parents`}
                                        >
                                            Gestionar
                                        </a>
                                    )}
                                </div>
                            )}

                        </div>
                    ))

                ) : (
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>No hay registros</h4>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default index;