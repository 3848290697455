import React, { Component } from 'react';
import Index from '../components/tasks/taskBoard/Index'
import WebpackerReact from 'webpacker-react';


class IndexTaskBoard extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = icon => {
        this.setState({
            data: this.state.data.map(item => {
            if (icon.id === item.id) {
              return { ...item, 
                name: icon.name,
                positions: icon.positions,
                task_labels: icon.task_labels,
                users: icon.users,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/task/get_task_boards`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Index
                    data={this.state.data}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    labels={this.props.labels}
                    positions={this.props.positions}
                    users={this.props.users}
                    estados={this.props.estados}
                />
            </React.Fragment>
        );
    }
}


export default IndexTaskBoard;
WebpackerReact.setup({ IndexTaskBoard });


