import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import TaskAndComments from '../components/formatos/Surveys/TaskAndComments'
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import TaskIndex from '../components/tasks/tasksCard/Index'
import GeneralComments from '../GeneralComponents/ChatBoxModuleComment'
import NumberFormat from 'react-number-format';
import SurveyIndex from '../components/formatos/Surveys/SurveyIndex'
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import ModalShowInfo from '../components/formatos/Surveys/ModalShowInfo'



class SurveyShowTask extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataTask: [],
            dataSurvey: [],
            countComment: 0,
            modal: false,
            question: {},
            isLoadedTask: true,
            showParrafo: 0,
            showSurveys: false,
            format_association: {},
            id_question_header: this.props.module.answers.filter(value => value.is_header)[0] != undefined ? this.props.module.answers.filter(value => value.is_header)[0].value : "",

            changeFormat: false,
            showHeader: this.props.module.answers.filter(value => value.is_header).length >= 1,
            name: "",
            register_true: {},

            selectedOption: {
                question_id: "",
                label: "Selecciona la pregunta"
            },

            form: {
                question_id: "",
            },

            questions: [],
            format_association_id: "",
            tableWidth: 0,
            survey_total: 0,

            pdfs: [],

        }
    }

    copyLink = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(this.props.survey.id)
    }

    componentDidMount() {
        this.loadDataTask();
        this.selectConfiguration();
    }

    selectConfiguration = () => {
        let array = []
        let arrayPdfs = [];

        this.props.questions.map((item) => (
            array.push({ label: item.question, value: item.id })
        ))

        this.props.format_pdfs.map((item) => (
            arrayPdfs.push({ label: item.name, value: item.id })
        ))

        this.props.module.answers.filter(value => value.is_header).map((item) => (
            this.setState({
                register_true: item,
                name: item.name,
            })
        ))

        this.setState({
            questions: array,
            pdfs: arrayPdfs,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                question_id: selectedOption.value
            }
        });
    };

    updateTitle = () => {
        fetch(`/formatos/update_question_header`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    changeFormat: false,
                    showHeader: true,
                    name: data.register.question,
                    id_question_header: this.props.module.answers.filter(value => value.id == data.register.id)[0].value,

                    selectedOption: {
                        question_id: "",
                        label: "Pregunta"
                    },

                    form: {
                        question_id: "",
                    },
                })
            });
    }




    updateStateLoad = (state) => {
        this.setState({ isLoadedTask: state })
    }

    updateStateCountComment = (count) => {
        this.setState({ countComment: count })
    }

    //add items
    updateData = (data) => {
        this.setState({
            dataTask: [...this.state.dataTask, data],
            isLoadedTask: false,
        })
    }

    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            dataTask: this.state.dataTask.map(item => {
                if (task.id === item.id) {
                    return {
                        ...item,
                        description: task.description,
                        due_date: task.due_date,
                        name: task.name,
                        state: task.state,
                        user_owner: task.user_owner,
                        general_comments: task.general_comments.length,
                        general_files: task.general_files.length,
                        task_labels: task.task_labels,
                        users: task.task_labels,
                        checklist: task.checklist,
                    }
                }
                return item;
            })
        });
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.module.module_task_id}?survey=${this.props.survey.id} `, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataTask: data.data,
                    countComment: data.comments,
                    isLoadedTask: false,
                });
            });
    }

    cancelSelect = () => {
        this.setState({
            showSurveys: !this.state.showSurveys,
            format_association: {},
        })
    }

    getFormatInfo = (format_association) => {
        fetch(`/formatos/get_survey_formats/${format_association.format_select.id}/${this.props.survey.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_association: format_association,
                    dataQuestionnvm: data.dataQuestion,
                    dataQdataAnswersnvm: data.dataAnswers,
                    showSurveys: true,
                    tableWidth: data.tableWidth,
                    dataSurvey: data.data,
                    format_association_id: format_association,
                    survey_total:data.survey_total
                });
            });
    }

    dateDate = (fecha) => {

        console.log(fecha)
        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));
        console.log(d)
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear();

    }
    dateTime = (fecha) => {

        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));

        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());


    }

    showParrafoF = (val) => {
        this.setState({
            showParrafo: val
        })
    }

    hideParrafoF = () => {
        this.setState({
            showParrafo: 0
        })
    }

    toogle = (from, question) => {
        if (from == "new") {
            this.setState({ modal: true, question: question })
        } else {
            this.setState({ modal: false, question: {} })
        }
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.format.name} `}
                    subTitle={""}
                    titleNew={"Nuevo riesgo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">

                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id_format}/surveys?view=ACTIVE`}
                                data-turbolinks="false"
                            >
                                {"Volver al listado"}
                            </a>
                        </li>
                    </ol>
                </nav>

                {(!this.state.showHeader && this.props.permit) && (
                    <div className="alert alert-warning" role="alert">
                        <p>Te invitamos a que pongas una pregunta título<b style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => this.setState({ changeFormat: !this.state.changeFormat })}> para cambiarlo haz click aqui </b></p>
                        {this.state.changeFormat && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_id"
                                            value={this.state.selectedOption.question_id}
                                        />
                                        <Select
                                            onChange={this.handleChangeAutocomplete}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOption}
                                        />
                                    </div>

                                    {this.state.form.question_id != "" && (
                                        <React.Fragment>
                                            <div className="col-md-2">
                                                <i className="fas fa-check icon-check" style={{ cursor: "pointer" }} onClick={() => this.updateTitle()}></i>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}


                <div className="card">
                    <div className="card-header">

                        <div className="col-md-12 mt-2">

                            {this.state.showHeader && (
                                <div className="col-md-12">
                                    {this.state.changeFormat ? (
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="hidden"
                                                        name="question_id"
                                                        value={this.state.selectedOption.question_id}
                                                    />
                                                    <Select
                                                        onChange={this.handleChangeAutocomplete}
                                                        options={this.state.questions}
                                                        autoFocus={false}
                                                        className={`link-form`}
                                                        value={this.state.selectedOption}
                                                    />
                                                </div>
                                                <div className="col-md-4 pl-0 text-left">
                                                    <i className="fas fa-times icon-false" style={{ cursor: "pointer" }} onClick={() => this.setState({ changeFormat: !this.state.changeFormat })}></i>
                                                    {this.state.form.question_id != "" && (
                                                        <i className="fas fa-check icon-check ml-3" style={{ cursor: "pointer" }} onClick={() => this.updateTitle()}></i>
                                                    )}
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <p className="title-show-survey" style={{ display: "flex", justifyContent: "center" }}>
                                                    {ReactHtmlParser(this.state.id_question_header)} {this.props.permit ? <i className="fas fa-pencil-alt" style={{ cursor: "pointer", fontSize: "20px", marginRight: "10px", marginLeft: "20px" }} onClick={() => this.setState({ changeFormat: true })}></i> : ""}
                                                </p>
                                            </React.Fragment>
                                        )}
                                </div>
                            )}

                        </div>
                        <div className="options-survey-show text-right" >
                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button
                                        className="btn-shadow btn btn-info mr-2"
                                        id="btnGroupDrop1"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-bars"></i>
                                    </button>

                                    <div className="dropdown-menu dropdown-menu-right dp-pdf">
                                        {true && (
                                            <a
                                                target="_blank"
                                                className="dropdown-item"
                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=grid`}
                                            >
                                                PDF grilla
                                                </a>
                                        )}

                                        {true && (
                                            <a
                                                className="dropdown-item"
                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=list`}
                                                target="_blank"
                                            >
                                                PDF lista
                                            </a>
                                        )}

                                        {this.props.format_pdfs.map(pdf => (
                                            <a
                                                className="dropdown-item"
                                                key={pdf.id}
                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=custom&pdf_id=${pdf.id}`}
                                                target="_blank"
                                            >
                                                {pdf.name}
                                            </a>
                                        ))}



                                        {false && (
                                            <button
                                                className="dropdown-item"
                                                onClick={() => console.log("asdasdddddddddddddddddddddddddddddd")}
                                            >
                                                Copiar link de proyecto
                                            </button>
                                        )}

                                    </div>
                                </div>
                            </div>

                            {true && (
                                <button
                                    className="btn-shadow mr-3 btn btn-primary float-right"
                                    onClick={() => this.copyLink()}
                                >
                                    <i className="far fa-copy"></i>
                                </button>
                            )}

                            {false && (
                                <a
                                    className="btn-shadow mr-3 btn btn-primary float-right"
                                    style={{ textTransform: "initial"}}
                                    href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id_format}/surveys/${this.props.survey.id}/gantt_diagram`}
                                    target="_blank"
                                >
                                    <i className="far fa-folder-open"></i> Diagrama de gantt
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="card-body">
                        {this.state.modal && (
                            <ModalShowInfo
                                backdrop={"static"}
                                modal={this.state.modal}
                                question={this.state.question}
                                toggle={this.toogle}
                                title={this.state.question.name}
                            />
                        )}

                        {!this.props.module.show_custom && (
                            <div className="row">
                                <React.Fragment>
                                    {this.props.module.answers.filter(value => value.show_in_gestion).map((data, index) => (

                                        <React.Fragment>
                                            {data.type == "answer_file" && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0">
                                                        <strong>{data.name}</strong>
                                                    </p>
                                                    <p className="m-0">
                                                        <a href={data.value}>Ver archivo</a>
                                                    </p>

                                                </div>
                                            )
                                            }


                                            {(data.type != "answer_integer" && data.type != "url" && data.type != "answer_formula" && data.type != "answer_file" && data.type != "answer_datetime" && data.type != "answer_date" && data.type != "answer_text" && data.type != "answer_checkbox" && data.type != "answer_localization") && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}>  {typeof data.value !== 'object' ? data.value : ""}</p>
                                                </div>
                                            )
                                            }
                                            {((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency) && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}> <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.value} /></p>
                                                </div>
                                            )
                                            }
                                            {((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency != true) && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}>{typeof data.value !== 'object' ? data.value : ""}</p>
                                                </div>
                                            )
                                            }
                                            {data.type == "answer_date" && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}> {data.value != null & data.value != "" ? data.value : ""}</p>
                                                </div>
                                            )
                                            }
                                            {data.type == "answer_datetime" && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}> {data.value != null & data.value != "" ? this.dateTime(data.value) : ""}</p>
                                                </div>
                                            )
                                            }
                                            {data.type == "answer_text" && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    {/*<p className="m-0 gm-hover" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", display: this.state.showParrafo == data.id ? "block" : "none" }} > {ReactHtmlParser(data.value)}</p>*/}
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0 gm-ellipsis" style={{ color: "#909090", maxWidth: "300px", cursor: "pointer", maxHeight: "60px", overflow: "hidden", wordBreak: "break-all" }} onClick={() => this.toogle("new", data)}>
                                                        {ReactHtmlParser(data.value)}
                                                    </p>
                                                    {/*<p className="m-0 gm-ellipsis" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", maxWidth: "300px" }} > {ReactHtmlParser(data.value)}</p>*/}
                                                </div>
                                            )
                                            }
                                            {data.type == "url" && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                                                </div>
                                            )
                                            }
                                            {(data.type == "answer_checkbox") && (
                                                <div className="col-md-3" style={{ padding: "15px" }}>
                                                    <p className="m-0" ><strong>{data.name}</strong></p>
                                                    <p className="m-0" style={{ color: "#909090" }}>
                                                        {
                                                            data.value.map((value, index) => (

                                                                <span>{index == 0 ? "" : ", "} {value}</span>

                                                            ))
                                                        }
                                                    </p>
                                                </div>
                                            )
                                            }
                                            {(data.type == "answer_localization") && (
                                                <div className="col-md-3" style={{ padding: "15px"}}>
                                                    <p className="m-0 pb-2" ><strong>{data.name}</strong></p>
                                                    <button onClick={() => this.toogle("new", data)} className="btn btn-primary">Ver Mapa</button>
                                                        
                                        
                                                </div>
                                            )
                                            }


                                            {(((index + 1) % 4) == 0 || this.props.module.answers.filter(value => value.show_in_gestion).length == (index + 1)) && (
                                                <div className="col-md-12"><hr className="mt-0 mb-0" /></div>
                                            )
                                            }
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                                {(this.props.module.answers.filter(value => !value.show_in_gestion).length > 0) && (
                                    <div className="col-md-12 mt-3" > <a className="btn btn-primary btn-actions-true" data-toggle="collapse" href={`#collapseExampleNoshow`} role="button" aria-expanded="false" aria-controls={`collapseExampleNoshow`}>
                                        Ver mas</a>
                                    </div>
                                )}

                                <div className="collapse col-md-12 " style={{ padding: "15px" }} id={`collapseExampleNoshow`}>
                                    <div className="row mt-3">
                                        <React.Fragment>
                                            {this.props.module.answers.filter(value => !value.show_in_gestion).map((data, index) => (

                                                <React.Fragment>
                                                    {data.type == "answer_file" && (
                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                            <p className="m-0">
                                                                <strong>{data.name}</strong>
                                                            </p>
                                                            <p className="m-0">
                                                                <a href={data.value}>Ver archivo</a>
                                                            </p>

                                                        </div>
                                                    )
                                                    }
                                                    {(data.type != "answer_file" && data.type != "url" && data.type != "answer_datetime" && data.type != "answer_text" && data.type != "answer_checkbox") && (
                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                            <p className="m-0" style={{ color: "#909090" }}> {data.value}</p>
                                                        </div>
                                                    )
                                                    }
                                                    {data.type == "answer_datetime" && (
                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                            <p className="m-0" style={{ color: "#909090" }}> {this.dateTime(data.value)}</p>
                                                        </div>
                                                    )
                                                    }
                                                    {data.type == "answer_text" && (
                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                            <p className="m-0 gm-hover" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", display: this.state.showParrafo == data.id ? "block" : "none" }} > {ReactHtmlParser(data.value)}</p>
                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                            <p className="m-0 gm-ellipsis" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", maxWidth: "300px" }} > {ReactHtmlParser(data.value)}</p>
                                                        </div>
                                                    )
                                                    }
                                                    {data.type == "url" && (
                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                            <p className="m-0" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                                                        </div>
                                                    )
                                                    }
                                                    {(data.type == "answer_checkbox") && (
                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                            <p className="m-0" style={{ color: "#909090" }}>
                                                                {
                                                                    data.value.map((value, index) => (

                                                                        <span>{index == 0 ? "" : ", "} {value}</span>

                                                                    ))
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                    }


                                                    {(((index + 1) % 4) == 0) && (
                                                        <div className="col-md-12"><hr className="mt-0 mb-0" /></div>
                                                    )
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            {this.props.module.show_custom && (
                                <div >
                                    {ReactHtmlParser(this.props.module.custom_view)}
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {this.props.format.format_associations.length >= 1 && (
                                <React.Fragment>
                                    <div className="col-md-12 p-2 text-center mt-3">

                                        {this.props.format.format_associations.map(format_association => (
                                            <button
                                                className={`${this.state.format_association.id == format_association.id ? "btn btn-primary" : "btn btn-outline-primary"} mr-3`}
                                                key={format_association.id}
                                                onClick={() => this.getFormatInfo(format_association)}
                                                style={{ minWidth: "150px" }}
                                            >
                                                {format_association.format_select.name}
                                            </button>
                                        ))}

                                        {(this.state.showSurveys && this.props.format.has_taks && this.props.format.has_comments) && (
                                            <button
                                                className={"btn btn-outline-primary mr-3"}
                                                onClick={() => this.cancelSelect()}
                                                style={{ minWidth: "150px" }}
                                            >
                                                <i class="fas fa-calendar-check mr-1" ></i>
                                                Tareas y comentarios
                                            </button>
                                        )}

                                        {/*(this.state.showSurveys && this.props.format.has_taks && this.props.format.has_comments) ? (
                                            <button
                                                className={"btn btn-outline-primary mr-3"}
                                                onClick={() => this.cancelSelect()}
                                                style={{ minWidth: "150px" }}
                                            >
                                                <i class="fas fa-calendar-check mr-1" ></i>
                                                Tareas y comentarios
                                            </button>
                                        ) : (
                                            <button
                                                className={"btn btn-outline-primary mr-3"}
                                                onClick={() => this.cancelSelect()}
                                                style={{ minWidth: "150px" }}
                                            >
                                                <i class="fas fa-calendar-check mr-1" ></i>
                                                Volver
                                            </button>
                                        )*/}
                                    </div>
                                </React.Fragment>
                            )}
                            {/*<a
                                    className="btn-shadow btn btn-info mr-3"
                                    href={`/formatos/survey_pdf/${this.props.survey.id}.pdf`}
                                >
                                    Pdf <i className="fas fa-file-pdf"></i>
                                </a>*/}


                        </div>
                    </div>
                </div>

                {this.state.showSurveys ? (
                    <React.Fragment>
                        <SurveyIndex
                            data={this.state.dataSurvey}
                            format={this.state.format_association.format_select}
                            format_category={this.props.format_category}
                            formato={this.props.format}
                            survey={this.props.survey}
                            dataQuestionnvm={this.state.dataQuestionnvm}
                            tableWidth={this.state.tableWidth}
                            dataQdataAnswersnvm={this.state.dataQdataAnswersnvm}
                            loadData={this.getFormatInfo}
                            format_association={this.state.format_association_id}
                            showSurveys={this.state.showSurveys}
                            cancelSelect={this.cancelSelect}
                            domain={this.props.domain}
                            survey_total={this.state.survey_total}
                        />
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            {(this.props.format.has_taks || this.props.format.has_comments) && (
                                <TaskAndComments
                                    modulo={this.props.module}
                                    user={this.props.user}
                                    users={this.props.users}
                                    dataTask={this.state.dataTask}
                                    name_module_task={this.props.name_module_task}
                                    nameComment={this.props.name_module_task}
                                    updateData={this.updateData}
                                    updateItem={this.updateItem}
                                    updateStateLoad={this.updateStateLoad}
                                    procesos={this.props.procesos}
                                    format={this.props.format}
                                    estados={this.props.estados}

                                    specific_areas={this.props.specific_areas}
                                    tenant={this.props.tenant}
                                    procesos={this.props.procesos}
                                    survey={this.props.survey}
                                    task_source={this.props.task_source}
                                    format_category={this.props.format_category}
                                    loadDataTask={this.loadDataTask}
                                    url={this.props.url}
                                    countComment={this.state.countComment}
                                    updateStateCountComment={this.updateStateCountComment}
                                    domain={this.props.domain}
                                    pdfs={this.state.pdfs}
                                />
                            )}
                        </React.Fragment>
                    )}


            </React.Fragment >
        );
    }
}


export default SurveyShowTask;
WebpackerReact.setup({ SurveyShowTask });
