import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <input 
                                        type="text" 
                                        name="name" 
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className="form form-control"
                                        placeholder="Nombre"
                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <Dropzone onDrop={(files) => this.props.handleFile(files)}>
                                        {({getRootProps, getInputProps}) => (
                                            <div
                                                {...getRootProps({
                                                    className: 'dropzone',
                                                })}
                                            >
                                                <input {...getInputProps()} />
                                                <p>{`${(this.props.nameFile != "" ? this.props.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>

                                {/*<div className="col-md-12">
                                    <textarea
                                        name="description"
                                        rows="5"
                                        value={this.props.formValues.description}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Descripción.."
                                    />
                                </div>*/}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={() => this.props.submitForm()}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;