import React, { Component } from 'react';
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            ErrorValues: true,
        }
    }

    validationForm = () => {
        if (this.props.form.name != "" &&  
            this.props.form.due_date != "" && 
            this.props.form.user_owner_id != "" 
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClick = () => {
        if(this.validationForm() == true){
            this.props.updateStateLoad(true)
            fetch(`/task/tasks`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.props.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.clearValues();
                this.props.updateData(data.task);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="card mb-3">
                    <div className="card-body p-2">
                        <div className="row">

                            <div className="col-md-12 mb-4">
                                <input
                                    type="hidden"
                                    name="user_owner_id"
                                    value={this.props.selectedOption.user_owner_id}
                                />

                                <Select
                                    onChange={this.props.handleChangeAutocomplete}
                                    options={this.props.users}
                                    autoFocus={false}
                                    className={`link-form ${this.state.ErrorValues == false && this.props.form.user_owner_id == "" ? "error-class" : ""}`}
                                    value={this.props.selectedOption}
                                />
                            </div>

                            <div className="col-md-12 mb-4">
                                <input 
                                    type="date"
                                    name="due_date"
                                    onChange={this.props.HandleChange}
                                    value={this.props.form.due_date}
                                    className={`form form-control ${this.state.ErrorValues == false && this.props.form.due_date == "" ? "error-class" : ""}`}
                                />
                            </div>
                            
                            <div className="col-md-12">
                                <textarea 
                                    className={`form form-control style-text ${this.state.ErrorValues == false && this.props.form.name == "" ? "error-class" : ""}`}
                                    name="name"
                                    onChange={this.props.HandleChange}
                                    value={this.props.form.name}
                                    placeholder="Introduzca un título para esta tarjeta..."
                                >
                                </textarea>
                                <span className="span-count-form" style={{ color: (this.props.form.name.length + 0 >= 200 ? "red" : "#3f6ad8") }}>
                                    {200 - this.props.form.name.length}
                                </span>
                            </div>

                            {this.props.form.name.length >= 200 && (
                                <div className="col-md-12 mt-3">
                                    <div className="alert alert-primary" role="alert">
                                        <b>Si quieres ampliar la información debes hacerlo en la descripción</b>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="card-footer">
                        <button 
                            className="btn-shadow btn btn-primary mr-3" 
                            onClick={() => this.HandleClick()}
                        >
                            {this.state.ErrorValues == false ? "Complete los campos" : "Crear" }
                        </button>
                        <button className="btn-shadow btn btn-light" onClick={() => this.props.clearValues()}>Cancelar</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormCreate;
