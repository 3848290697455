import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Show from '../components/health/NormativityScores/Show'

class NormativitiesScoresShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataTasks: [],
        }
    }

    render() {
        return (
            <React.Fragment>
                <Show
                    normativityScore={this.props.normativityScore}
                    estados={this.props.estados}
                    user={this.props.user}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    task_source={this.props.task_source}
                />
            </React.Fragment>
        );
    }
}

export default NormativitiesScoresShow;
WebpackerReact.setup({ NormativitiesScoresShow });