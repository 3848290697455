import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CheckboxContainer from './CheckboxContainer'
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            sub_categories: []
        }
    }

    componentDidMount = () => {
        if (this.props.category.format_sub_categories.length >= 1) {
            const array = [];

            this.props.category.format_sub_categories.map((item) => (
                array.push({ label: item.name, value: item.id })
            ))

            this.setState({
                sub_categories: array
            })
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };



    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <label>Nombre <small className="validate-label">*</small></label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        placeholder="Nombre"
                                    />
                                </div>

                                {(this.props.modeEditFormat || this.props.categories.length >= 1) && (
                                    <div className="col-md-12 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_category_id"
                                            value={this.props.selectedOptionFormatCategory.format_category_id}
                                        />
                                        <label>Categoría</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteFormatCategory}
                                            options={this.props.categories}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionFormatCategory}
                                        />
                                    </div>
                                )}

                                {(this.props.format_sub_categories.length >= 1) && (
                                    <div className="col-md-12 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_sub_category_id"
                                            value={this.props.selectedOptionSubCategory.format_sub_category_id}
                                        />
                                        <label>Sub Módulo</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteSubCategory}
                                            options={this.props.format_sub_categories}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionSubCategory}
                                        />
                                    </div>
                                )}

                                <div className="col-md-12 mt-4">
                                    <CheckboxContainer
                                        checkedItems={this.props.checkedItems}
                                        handleChangeAccions={this.props.handleChangeAccions}
                                        checkboxes={this.props.checkboxes}
                                        modules={this.props.modules}
                                        handleChangeSelectAll={this.props.handleChangeSelectAll}
                                        modulo_ids={this.props.modulo_ids}
                                        formValues={this.props.formValues}
                                    />
                                </div>

                                {this.props.errorValues == false && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <hr />
                            <div>
                                <p><b>Ver registros propios:</b> Permite ver los propios registros, que el mismo usuario crea</p>
                                <p><b>Ver todos los registros:</b> Permite ver todos los registros creados en este formato.</p>
                                <p><b>Editar registros:</b> Permite editar los registros creados en este formato.</p>
                                <p><b>Eliminar registros:</b> Permite eliminar los registros creados en este formato.</p>
                                <p><b>Gestionar formato:</b> Permite crear, editar preguntas y configuraciones del formato.</p>
                                <p><b>Editar formato:</b> Permite editar la información básica del formato como nombre y permisos.</p>
                                <p><b>Eliminar formato:</b> Permite eliminar el formato.</p>


                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;