import React from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormUser from "../users/formUser";
import HeaderTitle from '../../GeneralComponents/HeaderTitle'
import Pagination from "react-js-pagination";
import Filter from './filter'
import FormCreateFiles from '../../GeneralComponents/FormConfigurationFile'
import Loading from '../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import IndexMobile from './Mobile/Index'
import IndexWeb from './TableIndexWeb'

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      title: "Nuevo usuario",
      modal: false,
      backdrop: "static",
      modeEdit: false,
      passwor: true,
      password_confir: true,
      isLoadedFiles: false,

      modaFiles: false,

      formAddFiles: {
        modulo: "Usuarios",
        file: "",  
        fileName: "",
        user_id: "",
      },

      idGeneralFile: "",
      file: null,

      dataUsersFiles: [],

      form: {
        username: "",
        email: "",
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        is_tenant: false,
        tenant_id:this.props.current_user.tenant,
        position_id: "",
        name: "",
        avatar: {},
        password: "",
        password_confirmation: "",
      },

      ErrorValues: true,
      errorName: "",
      sizeFile: 0,

      selectedOption: {
        position_id: "",
        label: "Selecionar"
      },

      nameFile: "",
      module_id: "",
      roles: []
    };
    this.toggle = this.toggle.bind(this);
  }

  handleFileUpload = e => {
    this.setState({
      avatar: e.target.files[0]
    });
  };


  clearValues = (form) => {
    this.setState({
      form: {
        username: "",
        second_email: "",
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        position_id: "",
        is_tenant: false,
        tenant_id:this.props.current_user.tenant,
        avatar: {},
        password: "",
        password_confirmation: "",
      },

      errorName: "",

      selectedOption: {
        position_id: "",
        label: "Selecionar"
      },

      modal: false,
      modeEdit: false,
    })
  }

  passwordConfirmationValidate = () => {
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/
    if (regex.test(this.state.form.password_confirmation)){
      this.setState({password_confir: true})
    }else{
      this.setState({password_confir: false})
    }         
  }

  validatePasswords () {
    if (this.state.form.password === this.state.form.password_confirmation) {
      return true
    }else{
      return false
    }
  }


  delete = id => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡El registro será eliminado para siempre!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si"
    }).then(result => {
      if (result.value) {
        fetch("/user/" + id, {
          method: "delete"
        })
          .then(response => response.json())
          .then(response => {
            this.props.loadDataTable();

            Swal.fire(
              "Borrado!",
              "¡El registro fue eliminado con éxito!",
              "success"
            );
          });
      }
    });
  };

  componentDidMount(){
    let array = []

    this.props.rols.map((item) => (
      array.push({label: item.name, value: item.id})
    ))

    this.setState({
      roles: array
    })
  }

  edit = user => {
    if(this.state.modeEdit === true){
      this.setState({modeEdit: false})
    }else{
      this.setState({modeEdit: true})
    }

    this.toggle("edit")
    
    this.setState({

      user: user,
      title: "Editar Registro",
      form: {
        username: user.username,
        second_email: user.second_email,
        first_name: user.first_name,
        second_name: user.second_name,
        first_last_name: user.first_last_name,
        second_last_name: user.second_last_name,
        position_id: user.position != undefined ? user.position.id : "",
        is_tenant: user.is_tenant,
        avatar: {},
        password: "",
        password_confirmation: "",
      },

      selectedOption: {
        label: `${user.position != undefined ? user.position.name : "Sin rol"}`,
        value: user.position != undefined ? user.position.id : ""
      }


    });
  };

  messageSuccess = (response) => {
    Swal.fire({
        position: "center",
        type: `${response.type}`,
        title: `${response.success}`,
        showConfirmButton: false,
        timer: 1500,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = e => {
    const date = new Date();
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
        password: this.state.modeEdit == false ? this.state.form.username + date.getFullYear() : "",
        password_confirmation: this.state.modeEdit == false ? this.state.form.username + date.getFullYear() : "",
      }
    });
  };
  

  handleChangePassword = e => {
    if (e.target.name == "password" && this.state.modeEdit) {
      var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/
      //(?=.*[@$!%*_?&])
      if (regex.test(e.target.value)){
        console.log("el campo de contraseña es correcto" + e.target.value)   
        this.setState({passwor: true})
      }else{
        console.log("el campo de contraseña no es correcto" + e.target.value)
        this.setState({passwor: false})
      }  
    }


    if (e.target.name == "password_confirmation" && this.state.modeEdit) {
      this.validatePasswords()
    }

    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      }
    });
  };



  validationForm = () => {
    if (this.state.form.username != "" &&  
        this.state.form.second_email != "" && 
        this.state.form.first_name != "" && 
        this.state.form.position_id != "" 
        ) {
    console.log("los campos estan llenos")
      this.setState({ ErrorValues: true })
      return true
    }else{
      console.log("los campos no se han llenado")
      this.setState({ ErrorValues: false })
      return false
      
    }
  }

  HandleClick = e => {
    const usersTrue = this.props.dataUsers.filter(user => user.user_state == true).length
    if (this.validatePasswords() == true && this.validationForm() == true)  {
    const formData = new FormData();
    formData.append("username", this.state.form.username != null ? this.state.form.username : "");
    formData.append("second_email", this.state.form.second_email != null ? this.state.form.second_email : "");
    formData.append("first_name", this.state.form.first_name != null ? this.state.form.first_name : "");
    formData.append("second_name", this.state.form.second_name != null ? this.state.form.second_name : "");
    formData.append("first_last_name", this.state.form.first_last_name != null ? this.state.form.first_last_name : "");
    formData.append("second_last_name", this.state.form.second_last_name != null ? this.state.form.second_last_name : "");
    formData.append("is_tenant", false);
    formData.append("tenant_id", this.props.current_user.tenant);
    formData.append("position_id", this.state.form.position_id);
    formData.append("avatar", this.state.avatar);
    formData.append("user_state", usersTrue >= this.props.current_user.countUsers ? false : true)
    formData.append("password", this.state.form.password);
    formData.append("password_confirmation",this.state.form.password_confirmation);
    const token = document.querySelector('[name=csrf-token]').content;

    if (this.state.modeEdit) {
      fetch("/update_user/" + this.state.user.id, {
        method: "PATCH", // or 'PUT'
        body: formData, // data can be `string` or {object}!
        headers: {}
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(response => {
          if (response.type != "error"){
            this.props.updateItem(response.register)
            //this.props.loadDataTable();
            this.messageSuccess(response);
            this.clearValues();
          }else{
            this.setState({ errorName: "El nombre de usuario es incorrecto" })
          }
        });
    } else {
      fetch("/create_user", {
        method: "POST", // or 'PUT'
        body: formData, // data can be `string` or {object}!
        headers: {}
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
          if (data.type != "error"){
            //this.props.loadDataTable();
            this.props.updateData(data.register)
            this.messageSuccess(data);
            this.setState({passwor: true});
            this.clearValues();
          }else{
            this.setState({ errorName: data.error[0].replace("Username", "") })
          }
        });
    }

  }
  };

  toggle(from) {
    if(from == "edit"){
      this.setState({modeEdit: true})
      
    }else if(from == "new"){

      this.setState({
        title: "Nuevo usuario",
      })

      this.clearValues("files");

    }else{
      if(this.state.modeEdit === true){
        this.setState({modeEdit: false})
      }else{
        this.setState({modeEdit: true})
      }

      this.setState({
        user: {},
        ErrorValues: true,
        passwor: true
      })

    }

    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }


  handleChangeAutocomplete = selectedOption => {
    this.setState({
      selectedOption,
      form: {
        ...this.state.form,
        position_id: selectedOption.value
      }
    });
  };

      //files 

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            isLoadedFiles: false,
            nameFile: "",
            file: null
        })
    }

    addFiles = (position) => {
      this.setState({
          module_id: position.id,
          modaFiles: true,
      })
    }

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
              ...this.state.formAddFiles,
              [e.target.name]: e.target.value
            }
        });
    }

    toogleFiles = (from) => {
        if(from == "new"){
            this.setState({ modaFiles: true})
            this.clearValues();
        }else{
            this.setState({ modaFiles: false})
        }
    }

    loadDataFiles = (id) => {
        fetch(`/settings/get_general_files_users/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataUsersFiles: data.data,
            isLoadedFiles: false
          });
        });
    }

    handleFile = (archivo) => {
      archivo.map(file => (
          this.setState({
              formAddFiles: {
                  ...this.state.formAddFiles,
                  file: file.size >= 10000000 ? "" : file
              },

              nameFile: file.size >= 10000000 ? "El archivo supera el máximo permitido intenta con otro" : file.path,
              sizeFile: file.size
          })
      ));
    };

    HandleClickFiles = () => {
        this.setState({ isLoadedFiles: true })
        const formData = new FormData();
        formData.append("modulo", this.state.formAddFiles.modulo)
        formData.append("file", this.state.formAddFiles.file)
        formData.append("fileName", this.state.formAddFiles.fileName)
        formData.append("user_id", this.state.formAddFiles.user_id)

        fetch(`/settings/general_files`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadDataTable();
            this.clearValuesFiles()
            this.loadDataFiles(this.state.formAddFiles.user_id)
            this.messageSuccess(data);
        });
    }

    updateState = (id, state) => {
      fetch(`/update_state/${id}/${state}`, {
          method: 'PATCH', // or 'PUT'
          headers: {}
      })
      .then(res => res.json())
      .catch(error => console.error("Error:", error))
      .then(data => {
        this.props.updateItem(data.register)
      });
    }

    handleChangeUserName = (e) => {
      this.setState({
        form: {
          ...this.state.form,
          username: e.target.value.toLowerCase()
        }
      })
    }

  render() {
    const usersTrue = this.props.dataUsers.filter(user => user.user_state == true).length

    return (
      <React.Fragment>
        <HeaderTitle 
          title={"Usuarios"} 
          subTitle={"Gestión de Usuarios"}
          titleNew={"Nuevo"}
          titleFilter={"Filtros"}
          icon={"fas fa-users"}
          onClickFilter={this.props.funShowFilter}
          onClickNew={this.toggle}
          showNew={this.props.estados.crear}
          showFilter={true}
        />

        {this.state.modaFiles && (
            <FormCreateFiles
                //modal props
                backdrop={"static"}
                modal={this.state.modaFiles}
                toggle={this.toogleFiles}
                title={"Agregar Archivos"}
                        delete_file={true}
                        edit_file={true}
                loadDataTable={this.props.loadData}
                nameModule={"Usuarios"}
                module_id={this.state.module_id}
            />
        )}

        <div className="col-md-12 pr-0 mb-3">
          <div className="row">

              {this.state.modal && (
                <FormUser

                  toggle={this.toggle}
                  backdrop={this.state.backdrop}
                  modal={this.state.modal}

                  onChangeForm={this.handleChange}
                  formValues={this.state.form}
                  submit={this.HandleClick}
                  FormSubmit={this.handleSubmit}

                  titulo={this.state.title}
                  nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}
                  handleChangeUserName={this.handleChangeUserName}



                  loadDataTable={this.props.loadDataTable}
                  handleFileUpload={this.handleFileUpload}
                  editState={this.state.modeEdit}
                  formAutocomplete={this.state.selectedOption}
                  roles={this.state.roles}
                  onChangeAutocomplete={this.handleChangeAutocomplete}
                  errorValues={this.state.ErrorValues}
                  onBlurPasswordConfirmation={this.passwordConfirmationValidate}

                  passworState={this.state.passwor}
                  passworConfirmationState={this.validatePasswords()}

                  errorName={this.state.errorName}
                  handleChangePassword={this.handleChangePassword}

                />
              )}

          </div>
        </div>

        <div style={{ display: `${this.props.showFilter == true ? "block" : "none"}` }}>
          <Filter
              onChangeFilter={this.props.onChangeFilter}
              formValuesFilter={this.props.FormFilter}
              onClickFilter={this.props.onClickFilter}
              cancelFilter={this.props.cancelFilter}
              closeFilter={this.props.showFilter}
              rols={this.props.rols}
          />
        </div>

        {usersTrue >= this.props.current_user.countUsers && (
            <div className="row">
                <div className="col-md-12">
                    <div className="alert alert-info" role="alert">
                      <b>El número máximo de usuarios activos es {this.props.current_user.countUsers} </b>
                    </div>
                </div>
            </div>
        )}

        {!this.props.isLoaded ? (
          <React.Fragment>
            {isMobile ? (
              <IndexMobile 
                dataUsers={this.props.dataUsers} 
                estados={this.props.estados}
                edit={this.edit}
                delete={this.delete}
                addFiles={this.addFiles}
                updateState={this.updateState}
                usersTrue={usersTrue}
                current_user={this.props.current_user}
              />
            ) : (
              <IndexWeb 
                dataUsers={this.props.dataUsers} 
                estados={this.props.estados}
                edit={this.edit}
                delete={this.delete}
                addFiles={this.addFiles}
                updateState={this.updateState}
                usersTrue={usersTrue}
                current_user={this.props.current_user}
              />
            )}
          </React.Fragment>
        ) : (
          <Loading/>
        )}

      </React.Fragment>
    );
  }
}

export default Table;


{/*

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-10 text-left pl-0">
                  <p>
                      Mostrando {this.props.dataUsers.length} de {this.props.users_total}
                  </p>
                </div>

                <div className="col-md-2 text-right">
                  <Pagination
                    hideNavigation
                    activePage={this.props.activePage}
                    itemsCountPerPage={this.props.countPage}
                    itemClass="page-item"
                    innerClass="pagination"
                    linkClass="page-link"
                    totalItemsCount={this.props.users_total}
                    pageRangeDisplayed={this.props.countPage}
                    onChange={this.props.handlePageChange}
                  />
                </div>

              </div>
            </div>


*/}