import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Show from '../components/health/Normativities/Show'

class NormativitiesShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }   

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    loadData = () => {
        fetch(`/health/get_normativity_scores/${this.props.normativity.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Show
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    normativity={this.props.normativity}
                    estados={this.props.estados}
                    users={this.props.users}
                    califications_date={this.props.califications_date}
                    califications_value={this.props.califications_value}
                    isLoaded={this.state.isLoaded}
                    current_user={this.props.current_user}
                />
            </React.Fragment>
        );
    }
}

export default NormativitiesShow;
WebpackerReact.setup({ NormativitiesShow });