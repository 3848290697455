import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import Loading from '../../../GeneralComponents/LoadingComponen'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            isLoaded: true,
            id: "",
            form: {
                name: "",
                users_plan: 20, 
                size_plan: 20,
            },

            formUpdate: {
                name: "",
                records_plan: "",
                users_plan: "", 
                size_plan: "",
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch("/settings/plans/" + id, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            form: {
                name: "",
                users_plan: 20, 
                size_plan: 20,
            }
        })
    }

    HandleClick = () => {
        fetch("/settings/plans", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateData(data.register)
                this.setState({ modal: false })
                this.messageSuccess(data);
            });
    }

    HandleClickUpdate = () => {
        fetch(`/settings/plans/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateItem(data.register)
            });
    }

    edit = (plan) => {
        this.setState({
            id: plan.id,
            formUpdate: {
                name: plan.name,
                users_plan: plan.users_plan, 
                size_plan: plan.size_plan,
                records_plan: plan.records_plan,
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                [e.target.name]: e.target.value
            }
        })
    }

    
    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Planes"}
                    subTitle={"Gestión de encuestas"}
                    titleNew={"Nuevo plan"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear plan"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Cantidad de usuarios activos</th>
                                            <th>Tamaño de archivos (MG)</th>
                                            <th>Registros del plan</th>
                                           
                                            <th style={{ width: "82px" }} >Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.length >= 1 ? (
                                            this.props.data.map(plan => (
                                                <tr key={plan.id}>

                                                    <td>
                                                        {plan.id == this.state.id ? (
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                value={this.state.formUpdate.name}
                                                                onChange={this.HandleChangeUpdate}
                                                                className="form form-control"
                                                            />
                                                        ) : (
                                                            <p>{plan.name}</p>
                                                        )}
                                                    </td>

                                                    <td>
                                                        {plan.id == this.state.id ? (
                                                            <input
                                                                type="number"
                                                                name="users_plan"
                                                                value={this.state.formUpdate.users_plan}
                                                                onChange={this.HandleChangeUpdate}
                                                                className="form form-control"
                                                            />
                                                        ) : (
                                                            <p>{plan.users_plan}</p>
                                                        )}
                                                    </td>

                                                    <td>
                                                        {plan.id == this.state.id ? (
                                                            <input
                                                                type="number"
                                                                name="size_plan"
                                                                value={this.state.formUpdate.size_plan}
                                                                onChange={this.HandleChangeUpdate}
                                                                className="form form-control"
                                                            />
                                                        ) : (
                                                            <p>{plan.size_plan}</p>
                                                        )}
                                                    </td>

                                                    <td>
                                                        {plan.id == this.state.id ? (
                                                            <input
                                                                type="number"
                                                                name="records_plan"
                                                                value={this.state.formUpdate.records_plan}
                                                                onChange={this.HandleChangeUpdate}
                                                                className="form form-control"
                                                            />
                                                        ) : (
                                                            <p>{plan.records_plan}</p>
                                                        )}
                                                    </td>

                                                   {/*  <td>
                                                        <ul>
                                                            {plan.tenants.map(tenant => (
                                                                <li>{tenant.company_name}</li>
                                                            ))}
                                                        </ul>
                                                    </td> */}

                                                    {(true || true) && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            {this.state.id != "" ? (
                                                                <React.Fragment>
                                                                    {plan.id == this.state.id &&(
                                                                        <React.Fragment>
                                                                            <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                            <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                                        </React.Fragment>
                                                                    )} 
                                                                </React.Fragment>
                                                            ) : (
                                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                    <div className="btn-group" role="group">
                                                                        <button
                                                                            className="btn-shadow btn btn-info"
                                                                            id="btnGroupDrop1"
                                                                            type="button"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="fas fa-bars"></i>
                                                                        </button>
                                        
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <button
                                                                                    onClick={() => this.edit(plan)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </button>
                                                                            )}
                                        
                                                                            {true && (  
                                                                                <button
                                                                                    onClick={() => this.delete(plan.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;