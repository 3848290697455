import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import {UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import TaskFormCreate from '../../tasks/tasksNumeralChildScore/FormCreate'
import TasksItems from '../../tasks/tasksNumeralChildScore/TasksItems'

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            idTask: "",
            showPopoverTask: false,
            loadCalification: false,
            formUpdate: {
                calification: 0,
            }
        }
    }

    componentDidMount = () => {
        const valores = this.props.data
        setTimeout(() => {
            console.log(valores)
        }, 3000);
    }

    getSelect = (valores) => {

    }

    clearData = () => {
        this.setState({
            id: "",
            formUpdate: {
                calification: 0,
            }
        })
    }

    HandleClickUpdate = (id) => {
        this.props.changeStateCalification(true)
        fetch(`/settings/update_values_proceso_child/${id}/${this.state.formUpdate.calification}`, {
            method: "POST", // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            },
        })
            .then(response => response.json())
            .then(data => {
                this.clearData()
                this.props.loadData()
            });
    }

    onChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }

    getColor = (calification) => {
        if (calification < 60) {
            return "#ff7d74"
        } else if (calification >= 60 && calification < 80) {
            return "#f9bb57"
        } else if (calification >= 80) {
            return "#5cd89b"
        }
    }



    updateValues = (id, value) => {
        this.setState({
            id: id,
            formUpdate: {
                calification: value
            }
        })
    }

    getBack=(value)=>{
        window.open(value,"_self")
    }

    closeTask = () => {
        this.setState({
          idTask: "",
          showPopoverTask: false,
        })
    }

    render() {
        return (
            <React.Fragment>


                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={()=>this.getBack("/settings/clinic_process/index")}>Proceso Clinicos</a></li>
                        <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={()=>this.getBack(`/settings/procesos/${this.props.procesoScore.proceso_id}`)}>Evaluaciones</a></li>
                        <li className="active breadcrumb-item" aria-current="page">{this.props.procesoScore.proceso}</li>
                    </ol>
                </nav>



                <div className="container mb-5">
                    <div className="row">

                        <div className="card card-normas col-md-12">
                            <div className="card-body">
                                <div className="row item-proceso-total">
                                    <div className="col-md-7 text-left ">
                                        <h2 className="mb-0 mt-0">{this.props.procesoScore.proceso}</h2>
                                    </div>
                                    <div className="col-md-3 text-right label-total">
                                        Total
                                     </div>
                                    <div className="col-md-2 value-total" style={{ background: this.getColor(this.props.totalValue) }}>
                                        <NumberFormat value={this.props.totalValue} displayType={"text"} thousandSeparator={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="card card-normas col-md-12">
                            <div className="card-body ">
                                {this.props.data.sort((a,b)=>a.numeral_parent.id - b.numeral_parent.id).map((accion, index) => (
                                    <React.Fragment>
                                        <div key={accion.id} className="row item-proceso">

                                            <div className="col-md-10">
                                                {accion.numeral_parent.patient_safety_standar}
                                                <i 
                                                    id={`PopoverTask${accion.numeral_parent.id}`} 
                                                    onClick={() => this.setState({ showPopoverTask: true, idTask: accion.id })}
                                                    className="fas fa-thumbtack float-right icon-add"
                                                ></i>
                                                <UncontrolledPopover isOpen={(this.state.showPopoverTask && this.state.idTask == accion.id) ? true : false} placement="bottom" target={`PopoverTask${accion.numeral_parent.id}`}>
                                                <PopoverHeader>Crear tarea <a className="close-popover" onClick={() => this.setState({ showTask: false, idTask: ""})}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                    <PopoverBody>
                                                        <TaskFormCreate 
                                                            numeral_child_score={accion.module_task_id} 
                                                            loadTasks={this.props.loadData}
                                                            users={this.props.users} 
                                                            closeTask={this.closeTask}
                                                            nameModule={'Evaluaciones de proceso'}
                                                        />
                                                        
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                                {accion.tasks.length >= 1 ? <hr/> : ""}
                                                {accion.tasks.length >= 1 ? <span className="task-title">Tareas:</span>    : ""}
                                                {accion.tasks.map((task, index) => (
                                                    <TasksItems 
                                                        task={task} 
                                                        user={this.props.user} 
                                                        users={this.props.users} 
                                                        loadData={this.props.loadData}
                                                    />
                                                ))}
                                            </div>
                                            <div className="col-md-2 value-total" >
                                                {this.state.id == accion.id ? (
                                                    <React.Fragment>

                                                        {this.props.loadCalification == false &&(
                                                            <React.Fragment>
                                                                <select
                                                                    name="calification"
                                                                    className="form form-control"
                                                                    onChange={this.onChangeUpdate}
                                                                    value={this.state.formUpdate.calification}
                                                                >
                                                                    <option value="0">0</option>
                                                                    <option value="20">20</option>
                                                                    <option value="40">40</option>
                                                                    <option value="60">60</option>
                                                                    <option value="80">80</option>
                                                                    <option value="100">100</option>
                                                                </select>

                                                                <div>
                                                                    <i onClick={() => this.HandleClickUpdate(accion.id)} className="fas fa-check-circle true-btn"></i>

                                                                    <i onClick={() => this.setState({ id: "" })}
                                                                        className="fas fa-times-circle false-btn"
                                                                    >
                                                                    </i>
                                                                </div>
                                                            </React.Fragment>
                                                        )}

                                                        {this.props.loadCalification &&(
                                                            <div className="loader-wrapper d-flex justify-content-center align-items-center">
                                                                <div className="loader">
                                                                    <div className="ball-clip-rotate">
                                                                        <div style={{backgroundColor: "rgb(68, 64, 84)"}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </React.Fragment>
                                                ) : (
                                                        <span style={{ background: this.getColor(accion.score) }} onClick={() => this.updateValues(accion.id, accion.score)}>{accion.score}</span>
                                                    )}
                                            </div>

                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Show;