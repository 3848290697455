import React, { Component } from 'react';
import GeneralComments from '../../../../GeneralComponents/ChatBoxModuleComment'
import Preloader from '../../../../GeneralComponents/LoadingComponen'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from '../../../tasks/tasksCard/FormCreate'
import TaskList from '../../../tasks/tasksCard/TaskList'
import HeaderTitle from '../../../../GeneralComponents/HeaderTitle'
import ReactHtmlParser from 'react-html-parser';

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.url_source = `/failure/causes/${this.props.cause.id}`,
        this.state = {
            showForm: false,

            //states task

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            users: [],

            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.cause.module_task_id,
                task_source_id: this.props.task_source.id,
                url_source: this.url_source,
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //tasks 

    componentDidMount = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                user_owner_id: selectedOption.value
            }
        });
    };

    clearValues = () => {
        this.setState({
            showForm: false,
            form: {
                ...this.state.form,
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.cause.module_task_id,
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
        })
    }

    HandleChangeTask = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }


    getColor = (number) => {
        if (number == 0 || number >= 12) {
            return ["#FF0000", "white"]
        } else if (number >= 6 && number <= 11) {
            return ["#FFFF00", "black"]
        } else if (number == 5 || number < 5) {
            return ["#008000", "white"]
        }
    }

    getColorImp = (number) => {
        if (number == 1) {
            return ["white", "black"]
        } else if (number == 2) {
            return ["#00B050", "black"]
        } else if (number == 3) {
            return ["#FFC000", "black"]
        } else if (number == 4) {
            return ["#FF1300", "white"]
        } else if (number == 5) {
            return ["#921503", "white"]
        }


    }




    render() {
        const estados = {
            gestionar: true,
            eliminar: true,
        }


        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Causa: ${this.props.cause.cause}`}
                    subTitle={"Gesión de riesgos"}
                    titleNew={"Nuevo riesgo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/failure/efects`}
                                data-turbolinks="false"
                            >
                                {"AMEF"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            <a
                                href={`/failure/causes/${this.props.cause.id}`}
                                data-turbolinks="false"
                            >
                                {`Causa: ${this.props.cause.cause}`}
                            </a>
                        </li>
                    </ol>
                </nav>
                <div className="row mt-4 mb-4">
                    <div className="col-md-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row cause-show-container">
                                    <div className="col-md-6">
                                        Control: {this.props.cause.control}
                                    </div>
                                    <div className="col-md-2">
                                        Ocurrencia: {this.props.cause.ocurrency_score_first}
                                    </div>
                                    <div className="col-md-2">
                                        Detectabilidad: {this.props.cause.detectability_score_first}
                                    </div>
                                    <div className="col-md-2">
                                        NPR: {this.props.cause.npr_score_first}
                                    </div>
                                    <div className="col-md-12">
                                        Información del Fallo al que pertenece: <br/>
                                         Modo: {this.props.cause.failure.mode } Efecto:{this.props.cause.failure.effect }
                                    </div>
                                    <div className="col-md-12">
                                        Actividad: ({this.props.cause.activity.name }) 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 mb-4">
                    <div className="col-md-5">
                        <div className="card card-list" style={{ height: "772px" }}>
                            <div className="card-header">
                                <div className="col-md-12 pl-0">
                                    Tareas
                                    {true ? <React.Fragment> {this.state.showForm == false ? <i className="fas fa-plus float-right icon-add" onClick={() => this.setState({ showForm: true })}></i> : <i className="fas fa-times float-right icon-add" onClick={() => this.setState({ showForm: false })}></i>} </React.Fragment> : ""}
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="scroll-area-lg scroll-tasks">
                                    <div className="scrollbar-container">
                                        {this.state.showForm == true && (
                                            <FormCreate
                                                HandleChange={this.HandleChangeTask}
                                                form={this.state.form}
                                                clearValues={this.clearValues}
                                                loadData={this.props.loadDataTask}
                                                updateStateLoad={this.props.updateStateLoad}

                                                //select autocomplete
                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                selectedOption={this.state.selectedOption}
                                                users={this.state.users}

                                                updateData={this.props.updateData}
                                                type_count={this.props.type_count}

                                            />
                                        )}

                                        {this.props.isLoadedTask ? (
                                            <div className="col-md-12 text-center mt-4">
                                                <Preloader />
                                            </div>
                                        ) : (
                                                <React.Fragment>
                                                    {this.props.dataTask.length >= 1 ? (
                                                        this.props.dataTask.map(task => (
                                                            <TaskList
                                                                task={task}
                                                                user={this.props.user}
                                                                users={this.props.users}
                                                                showOptions={true}
                                                                loadData={this.props.loadDataTask}
                                                                estados={estados}
                                                                updateStateLoad={this.props.updateStateLoad}
                                                                updateItem={this.props.updateItem}
                                                            />
                                                        ))
                                                    ) : (
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h6>No hay tareas</h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                </React.Fragment>
                                            )}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-7">
                        <GeneralComments
                            usuario={this.props.user}
                            module_name={"Causas de riesgos"}
                            users={this.state.users}
                            module_comment={this.props.cause.module_comment_id}
                            url={this.props.url}
                        />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Show;