import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Ckaditor from '../Formats/Ckeditor';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <label>Pregunta (selecciona la pregunta en la cual asociaste al responsable)</label>
                                    <select
                                        name="question_id"
                                        className="form form-control"
                                        value={this.props.formValues.question_id}
                                        onChange={this.props.onChangeForm}
                                    >
                                        <option value="">Preguntas</option>
                                        {this.props.questions_users.map(question => (
                                            <React.Fragment>
                                                <option value={question.id}>{question.question}</option>
                                            </React.Fragment>
                                        ))}
                                    </select>
                                </div>


{/*                                 <div className="col-md-6">
                                    <label>Tipo de responsabilidad</label>
                                    <select
                                        name="type_responsabilty"
                                        className="form form-control"
                                        value={this.props.formValues.type_responsabilty}
                                        onChange={this.props.onChangeForm}
                                    >
                                        <option value="">Seleccione el tipo</option>
                                        <option value="Responsable">Responsable</option>
                                        <option value="Gestor">Gestor</option>
                                    </select>
                                </div> */}

                                {this.props.modeEdit && (
                                    <div className="col-md-12 mt-3">
                                        <label htmlFor="">Contenido del email</label>
                                        <Ckaditor
                                            HandleChange={this.props.HandleChangeCkaditor}
                                            formValues={this.props.formValues.content_email}
                                        />
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;