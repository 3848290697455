import React, { Component } from 'react';
import ConfigurationSection from '../components/settings/Sections/ConfigurationSection'

class SectionIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            dataCategory: [],
            isLoaded: false,
        }
    }

    componentDidMount(){
        this.loadData();
        this.loadDataCategory();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    loadData = () => {
        fetch("/settings/get_section", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: true
          });
        });
    }

    //category sections settings

    loadDataCategory = () => {
        fetch("/settings/get_section_category", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataCategory: data.data,
          });
        });
    }

    //add items
    updateDataCategory = (data) => {
        this.setState({
            dataCategory: [...this.state.dataCategory, data],
        })
    }

    //add update
    updateItemCategory = section_category => {
        this.setState({
            dataCategory: this.state.dataCategory.map(item => {
            if (section_category.id === item.id) {
              return { ...item, 
                name: section_category.name,
                description: section_category.description,
                position: section_category.position,
              }
            }
            return item;
          })
        });
    }


    render() {
        return (
            <React.Fragment>
                <ConfigurationSection
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    data={this.state.data}


                    //category sections

                    dataCategory={this.state.dataCategory}
                    updateDataCategory={this.updateDataCategory}
                    updateItemCategory={this.updateItemCategory}
                    loadDataCategory={this.loadDataCategory}
                />
            </React.Fragment>
        );
    }
}

export default SectionIndex;
