import React, { Component } from 'react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import WebpackerReact from 'webpacker-react';
import FishboneChart from '../components/Causes/CauseAnalysis/FishboneChart'
import FormCreate from '../components/formatos/Formats/FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loading from '../GeneralComponents/LoadingComponen'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import TasksItems from '../components/tasks/tasksNumeralChildScore/TasksItems'
import TaskFormCreate from '../components/tasks/tasksNumeralChildScore/FormCreate'
import GeneralComments from '../GeneralComponents/ChatBoxModuleComment'
import FormUpdate from '../components/formatos/Formats/FormCreate'

class CauseAnalysisShow extends Component {
    constructor(props) {
        super(props);
        this.url_source = `/cause/cause_analyses/${this.props.cause_analysis.id}`
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            dataTable: [],
            users: [],
            isLoaded: true,
            showTask: false,
            modal: false,
            modalEdit: false,
            idTask: "",
            register: {},
            form: {
                name: "",
                cause_analysis_id: this.props.cause_analysis,
            },

            formUpdate: {
                id: "",
                title: "",
            }
        }
    }

    componentDidMount() {
        this.loadData();
        this.setDefaultValues();
    }

    setDefaultValues = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array
        })
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        name: format.name,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            form: {
                ...this.state.form,
                name: "",
            }
        });
    }

    get_name = (name) => {
        if (name == "FP") {
            return "FACTORES DE PACIENTE"
        } else if (name == "FT") {
            return "Factores de Tarea"
        } else if (name == "FFE") {
            return "FACTORES DE FORMACIÓN Y ENTRENAMIENTO"
        } else if (name == "FC") {
            return "FACTORES DE COMUNICACIÓN"
        } else if (name == "FES") {
            return "FACTORES SOCIALES Y DE EQUIPO"
        } else if (name == "FER") {
            return "FACTORES DE EQUIPAMIENTO Y RECURSOS"
        } else if (name == "CT") {
            return "CONDICIONES DE TRABAJO"
        } else if (name == "FOE") {
            return "FACTORES ORGANIZATIVOS Y ESTRATÉGICOS"
        } else if (name == "FI") {
            return "FACTORES INDIVIDUALES"
        }
    }

    loadData = () => {
        fetch(`/cause/get_show_data/${this.props.cause_analysis.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    dataTable: data.dataTable,
                    isLoaded: false
                });
            });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleMejora = (from) => {
        if (from == "new") {
            this.setState({ modalEdit: true })
        } else {
            this.setState({ modalEdit: false })
        }
    }

    HandleClick = () => {
        fetch("/cause/factors", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.loadData();
                this.setState({ modal: false })
            });
    }

    HandleClickUpdate = () => {
        fetch(`/cause/sub_factor_items/${this.state.formUpdate.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.loadData();
                this.messageSuccess(data);
                this.setState({ modalEdit: false })
            });
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }


    delete = (sub_factor_item, factor) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/cause/remove/${sub_factor_item}/${factor}`, {
                    method: "POST",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    editSubCausas = (sub_factor_item) => {
        this.setState({
            formUpdate: {
                id: sub_factor_item.id,
                name: sub_factor_item.title
            },

            modalEdit: true,
        })
    }

    HandleChangeMejora = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                name: e.target.value,
                title: e.target.value,
            }
        });
    }

    closeTask = () => {
        this.setState({
            idTask: "",
            showTask: false,
        })
    }


    render() {
        console.log("organizando popver")
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.tenant.app_type == "Mejora" ? "Investigación de causas" : "Análisis causa – raíz"}`} subTitle={"Se aconseja utilizar para encontrar las causas de lo que nos sucede, cuando se trata de un problema, pero también se puede plantear de un modo positivo: causas que influyen en la mejora de algo que pretendemos conseguir"}
                    titleNew={"Nueva espina"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Nueva espina"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                    />
                )}

                {this.state.modalEdit && (
                    <FormUpdate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalEdit}
                        toggle={this.toogleMejora}
                        title={`Actalizar Item`}

                        //form props
                        formValues={this.state.formUpdate}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChangeMejora}
                        submitForm={this.HandleClickUpdate}
                        nameBnt={"Actualizar"}
                    />
                )}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={"/cause/cause_analyses"}
                                data-turbolinks="false"
                            >
                                {"Análisis causa – raíz"}
                            </a>
                        </li>

                        {this.props.cause_analysis.have_survey && (
                            <li className="breadcrumb-item">
                                <a
                                    href={`/formatos/format_categories/${this.props.cause_analysis.survey.format_category_id}/formats/${this.props.cause_analysis.survey.format_id}/gestion/${this.props.cause_analysis.survey.id}`}
                                    data-turbolinks="false"
                                >
                                    {this.props.cause_analysis.format_name}
                                </a>
                            </li>
                        )}
                    </ol>
                </nav>

                <div className="content main-card mb-3 card">
                    <div className="card-body normativity-body">
                        <FishboneChart
                            data={this.state.data}
                            loadData={this.loadData}
                            cause_analysis={this.props.cause_analysis}
                            get_name={this.get_name}
                            tenant={this.props.tenant}
                        />
                    </div>
                </div>

                <div className="content main-card mb-3 card">
                    <div className="card-body normativity-body">
                        <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            Comentarios
                        </a>

                        <div className="collapse" id="collapseExample">
                            <div className="col-md-12 mt-4">
                                <GeneralComments
                                    usuario={this.props.user}
                                    module_name={"Analisis de causa"}
                                    module_comment={this.props.cause_analysis.module_comment_id}
                                    users={this.state.users}
                                    url={this.props.url}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content main-card mb-3 card">
                    <div className="card-body normativity-body">
                        {!this.state.isLoaded ? (
                            <React.Fragment>
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%" }} className="text-center">FACTOR</th>
                                            <th style={{ width: "50%" }}>COMPONENTES</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.dataTable.sort((a, b) => a.sub_factors_count - b.sub_factors_count).sort((a, b) => a.id - b.id).reverse().map((sub_factor, index) => (
                                            <React.Fragment>
                                                {sub_factor.sub_factor_items.length > 0 && (
                                                    <React.Fragment>
                                                        <tr key={sub_factor.id} rowSpan={sub_factor.count}>
                                                            <td style={{ background: index < 3 ? "#f9dbdb" : "white" }}>
                                                                <a href="/" className="text-center">{sub_factor.name}: </a>{this.get_name(sub_factor.name)}
                                                            </td>

                                                            {sub_factor.sub_factor_items.map(items => (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td style={{ width: this.props.tenant.app_type == "Mejora" ? "83%" : "100%" }}>
                                                                            {this.props.tenant.app_type == "Mejora" ? (
                                                                                <React.Fragment>
                                                                                    {items.title}
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                    <React.Fragment>
                                                                                        <a href="/">{items.code}</a> {items.description}
                                                                                    </React.Fragment>
                                                                                )}

                                                                            {items.tasks.length > 0 && (
                                                                                <div className="col-md-12">
                                                                                    <hr />
                                                                                </div>
                                                                            )}

                                                                            <ul>
                                                                                {items.tasks.map((task, index) => (
                                                                                    <TasksItems
                                                                                        task={task}
                                                                                        user={this.props.user}
                                                                                        users={this.props.users}
                                                                                        loadData={this.loadData}
                                                                                        procesos={this.props.procesos}
                                                                                    />
                                                                                ))}
                                                                            </ul>
                                                                        </td>
                                                                        <td>
                                                                            {this.props.tenant.app_type == "Mejora" ? (
                                                                                <React.Fragment>
                                                                                    <button
                                                                                        className="btn"
                                                                                        onClick={() => this.editSubCausas(items)}
                                                                                    >
                                                                                        <i className="fas fa-pencil-alt"></i>
                                                                                    </button>

                                                                                    <button
                                                                                        className="btn"
                                                                                        onClick={() => this.delete(items.id, sub_factor.id)}
                                                                                    >
                                                                                        <i className="fas fa-trash-alt"></i>
                                                                                    </button>
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                    <React.Fragment>
                                                                                        <button
                                                                                            className="btn"
                                                                                            onClick={() => this.delete(items.id, sub_factor.id)}
                                                                                        >
                                                                                            <i className="fas fa-trash-alt"></i>
                                                                                        </button>
                                                                                    </React.Fragment>
                                                                                )}

                                                                            <i
                                                                                id={`PopoverLabels${items.id}`}
                                                                                className="fas fa-thumbtack float-right icon-add icon-task-popover"
                                                                                onClick={() => this.setState({ idTask: items.id })}
                                                                            >
                                                                            </i>


                                                                            <UncontrolledPopover isOpen={this.state.idTask == items.id} placement="bottom" target={`PopoverLabels${items.id}`}>
                                                                                <PopoverHeader>Crear tarea <a className="close-popover" onClick={() => this.setState({ showTask: false, idTask: "", register: {} })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                                <PopoverBody>
                                                                                    <TaskFormCreate
                                                                                        numeral_child_score={items.module_task_id}
                                                                                        loadTasks={this.loadData}
                                                                                        users={this.props.users}
                                                                                        closeTask={this.closeTask}
                                                                                        task_source_id={this.props.task_source.id}
                                                                                        url_source={this.url_source}
                                                                                    />
                                                                                </PopoverBody>
                                                                            </UncontrolledPopover>

                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        ) : (
                                <Loading />
                            )}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


export default CauseAnalysisShow;
WebpackerReact.setup({ CauseAnalysisShow });
