import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ChromePicker } from 'react-color'
import ReactHtmlParser from 'react-html-parser';
import Ckaditor from './Ckeditor';
import SelectPosition from './SelecPosition'
import Select from "react-select";

class ModalConfigurationDate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            configuration_question_date: {
                has_traffic_light: this.props.question.configuration_question_date.has_traffic_light,
                first_color: this.props.question.configuration_question_date.first_color,
                second_color: this.props.question.configuration_question_date.second_color,
                third_color: this.props.question.configuration_question_date.third_color,
                email_next_to_end: this.props.question.configuration_question_date.email_next_to_end,
                email_ended: this.props.question.configuration_question_date.email_ended,
                next_to_end_day: this.props.question.configuration_question_date.next_to_end_day,
                question_compare_id: this.props.question.configuration_question_date.question_compare_id,
                question_compare: this.props.question.configuration_question_date.question_compare,
                value_to_compare: this.props.question.configuration_question_date.value_to_compare,

                position_ids: [],
            },

            selectedOptionCargo: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionQuestion: {
                question_compare_id: "",
                label: "Pregunta"
            },

            showPikerFirstColor: false,
            showPikerSecondColor: false,
            showPikerThirdColor: false,

            showEmailNex: false,
            showEmailEnd: false,

            ErrorValues: true,

            cargos: [],
            editValues: [],
            questions: [],
            positions: this.props.question.configuration_question_date.positions            
        }
    }

    //select position

    componentDidMount = () => {
        this.setDefaultValues()
    }

    setDefaultValues = () => {
        let arrayCargos = [];
        let arrayPositonEdit = [];
        let arrayQuestions = [];

        this.props.positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.question.configuration_question_date.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        this.props.steps_questions.map((item) => (
            arrayQuestions.push({ label: item.question, value: item.id })
        ))

        this.setState({
            cargos: arrayCargos,
            editValues: arrayPositonEdit,
            questions: arrayQuestions,
        })

    }

    handleChangeAutocompleteQuestion = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
                configuration_question_date: {
                    ...this.state.configuration_question_date,
                    question_compare_id: selectedOptionQuestion.value
                }
          });  
    }

    handleChangeAutocompleteCargo = selectedOption => {
        let array = []

        if (selectedOption != null) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))

            this.setState({
                configuration_question_date: {
                    ...this.state.configuration_question_date,
                    position_ids: array
                }
            })

        } else {

            this.setState({
                configuration_question_date: {
                    ...this.state.configuration_question_date,
                    position_ids: []
                }
            })
        }

    }

    //Primer color

    handleClickFirstColor = () => {
        this.setState({ showPikerFirstColor: !this.state.showPikerFirstColor })
    };

    handleCloseFirstColor = () => {
        this.setState({ showPikerFirstColor: false })
        this.updateStateConfigurationColor(this.state.configuration_question_date.first_color, "first_color")
    };

    handleChangeFirstColor = (color) => {
        this.setState({
            configuration_question_date: {
                ...this.state.configuration_question_date,
                first_color: color.hex,
            }
        })
    }

    //Segundo color 

    handleClickSecondColor = () => {
        this.setState({ showPikerSecondColor: !this.state.showPikerSecondColor })
    };

    handleCloseSecondColor = () => {
        this.setState({ showPikerSecondColor: false })
        this.updateStateConfigurationColor(this.state.configuration_question_date.second_color, "second_color")
    };

    handleChangeSecondColor = (color) => {
        this.setState({
            configuration_question_date: {
                ...this.state.configuration_question_date,
                second_color: color.hex,
            }
        })
    }

    //Tercer color

    handleClickThirdColor = () => {
        this.setState({ showPikerThirdColor: !this.state.showPikerThirdColor })
    };

    handleCloseThirdColor = () => {
        this.setState({ showPikerThirdColor: false })
        this.updateStateConfigurationColor(this.state.configuration_question_date.third_color, "third_color")
    };

    handleChangeThirdColor = (color) => {
        this.setState({
            configuration_question_date: {
                ...this.state.configuration_question_date,
                third_color: color.hex,
            }
        })
    }


    updateStateConfiguration = (state, countryside) => {
        fetch(`/formatos/update_state_configuration/${this.props.question.configuration_question_date.id}/${state}/${countryside}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({
                configuration_question_date: {
                    ...this.state.configuration_question_date,
                    has_traffic_light: data.configuration_question_date.has_traffic_light,
                }
            })
        });
    }

    updateStateConfigurationColor = (color, campo) => {
        color = color.replace("#", "")
        fetch(`/formatos/update_color_configuration/${this.props.question.configuration_question_date.id}/${color}/${campo}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {

        });
    }

    validationForm = () => {
        if (this.state.configuration_question_date.next_to_end_day != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }


    updateText = () => {
        if (this.validationForm()){
            fetch(`/formatos/update_state_config_question/${this.props.question.configuration_question_date.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.configuration_question_date), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {

                const arrayPositonEdit = []

                data.configuration_question_date.positions.map((item) => (
                    arrayPositonEdit.push({ label: item.name, value: item.id })
                ))

                this.setState({
                    configuration_question_date: {
                        ...this.state.configuration_question_date,
                        email_next_to_end: data.configuration_question_date.email_next_to_end,
                        email_ended: data.configuration_question_date.email_ended,
                        next_to_end_day: data.configuration_question_date.next_to_end_day,
                        question_compare: data.configuration_question_date.question_compare,
                        value_to_compare: data.configuration_question_date.value_to_compare,
                    },

                    selectedOptionQuestion: {
                        question_compare_id: "",
                        label: "Pregunta"
                    },

                    positions: data.configuration_question_date.positions,
                    editValues: arrayPositonEdit,
                    showEmailEnd: false,
                    showEmailNex: false,
                })
            });
        }
    }

    HandleChangeUpdateConfigurationEmailNex = (e) => {        
        this.setState({
            configuration_question_date: {
                ...this.state.configuration_question_date,
                email_next_to_end: e,
            }
        })
    }

    HandleChangeUpdateConfigurationEmailEnded = (e) => {        
        this.setState({
            configuration_question_date: {
                ...this.state.configuration_question_date,
                email_ended: e,
            }
        })
    }

    HandleChangeUpdateConfigurationNexDay = (e) => {
        this.setState({
            configuration_question_date: {
                ...this.state.configuration_question_date,
                [e.target.name]: e.target.value,
            }
        })
    }
    

    clearValues = () => {
        this.setState({
            /*
            configuration_question_date: {
                ...this.state.configuration_question_date,
                email_ended: this.props.question.configuration_question_date.email_ended,
                email_next_to_end: this.props.question.configuration_question_date.email_next_to_end,
            },
            */


            selectedOptionCargo: {
                position_ids: "",
                label: "Cargos"
            },

            showEmailEnd: false,
            showEmailNex: false,
        })
    }
                                                                                                                                             
    render() {

        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="custom-control custom-switch">
                                         <input type="checkbox" onChange={(e) => this.updateStateConfiguration(!this.state.configuration_question_date.has_traffic_light, "show_traffic_light")} className="custom-control-input" id={`customStateTraffic`} checked={this.state.configuration_question_date.has_traffic_light} />
                                        <label className="custom-control-label" htmlFor={`customStateTraffic`}>¿Tiene semáforo?</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <hr/>
                                </div>

                                {this.state.configuration_question_date.has_traffic_light && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    Configuración de colores del semáforo
                                                </div>
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-4 text-center">
                                                            <button 
                                                                onClick={this.handleClickFirstColor} 
                                                                style={{ backgroundColor: this.state.configuration_question_date.first_color }}
                                                                className="btn-traffic">
                                                                    Vigente
                                                            </button>
                                                            { this.state.showPikerFirstColor ? <div style={ popover }>
                                                            <div style={ cover } onClick={ this.handleCloseFirstColor }/>
                                                            <ChromePicker 
                                                                color={this.state.configuration_question_date.first_color} 
                                                                onChange={this.handleChangeFirstColor} 
                                                            />
                                                            </div> : null }
                                                        </div>


                                                        <div className="col-md-4 text-center">
                                                            <button 
                                                                onClick={this.handleClickSecondColor} 
                                                                style={{ backgroundColor: this.state.configuration_question_date.second_color }}
                                                                className="btn-traffic">
                                                                    Próximo
                                                            </button>
                                                            { this.state.showPikerSecondColor ? <div style={ popover }>
                                                            <div style={ cover } onClick={ this.handleCloseSecondColor }/>
                                                            <ChromePicker 
                                                                color={this.state.configuration_question_date.second_color} 
                                                                onChange={this.handleChangeSecondColor} 
                                                            />
                                                            </div> : null }
                                                        </div>

                                                        <div className="col-md-4 text-center">
                                                            <button 
                                                                onClick={this.handleClickThirdColor} 
                                                                style={{ backgroundColor: this.state.configuration_question_date.third_color }}
                                                                className="btn-traffic">
                                                                    Vencido
                                                            </button>
                                                            { this.state.showPikerThirdColor ? <div style={ popover }>
                                                            <div style={ cover } onClick={ this.handleCloseThirdColor }/>
                                                            <ChromePicker 
                                                                color={this.state.configuration_question_date.third_color} 
                                                                onChange={this.handleChangeThirdColor} 
                                                            />
                                                            </div> : null }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                <div className="col-md-12 mt-3 mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            Configuración de los email
                                        </div>
                                        <div className="card-body">
                                            {this.state.showEmailNex ? (
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <b>Cagos a los que seles va a enviar los emails </b>
                                                            <SelectPosition
                                                                handleChangeAutocomplete={this.handleChangeAutocompleteCargo}
                                                                selectedOptionCargo={this.state.selectedOptionCargo}
                                                                cargos={this.state.cargos}
                                                                editValues={this.state.editValues}
                                                            />
                                                        </div>

                                                        <div className="col-md-6">
                                                            <b>Dias</b>
                                                            <input 
                                                                type="number" 
                                                                name="next_to_end_day"
                                                                className={`form form-control ${this.state.ErrorValues == false && this.state.configuration_question_date.next_to_end_day == "" ? "error-class" : ""}`}
                                                                onChange={this.HandleChangeUpdateConfigurationNexDay}
                                                                value={this.state.configuration_question_date.next_to_end_day}
                                                            />
                                                        </div>


                                                        <div className="col-md-6 mt-3">
                                                            <div className="">
                                                                <h6>Contenido del correo de próximo a vencer</h6>
                                                            </div>

                                                            <Ckaditor
                                                                HandleChange={this.HandleChangeUpdateConfigurationEmailNex}
                                                                formValues={this.state.configuration_question_date.email_next_to_end}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 mt-3">
                                                            <div className="">
                                                                <h6>Contenido del correo vencido</h6>
                                                            </div>

                                                            <Ckaditor
                                                                HandleChange={this.HandleChangeUpdateConfigurationEmailEnded}
                                                                formValues={this.state.configuration_question_date.email_ended}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 mt-3">
                                                            <input
                                                                type="hidden"
                                                                name="question_compare_id"
                                                                value={this.state.selectedOptionQuestion.question_compare_id}
                                                            />
                                                            <label>Pregunta</label>
                                                            <Select
                                                                onChange={this.handleChangeAutocompleteQuestion}
                                                                options={this.state.questions}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                value={this.state.selectedOptionQuestion}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 mt-3">
                                                            <label>Valor a comparar</label>
                                                            <input 
                                                                type="text" 
                                                                name="value_to_compare"
                                                                className={`form form-control`}
                                                                onChange={this.HandleChangeUpdateConfigurationNexDay}
                                                                value={this.state.configuration_question_date.value_to_compare}
                                                            />
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <b>Cagos a los que seles va a enviar los emails </b>

                                                            <ul>
                                                                {this.state.positions.map((cargo, index) => (
                                                                    <React.Fragment>
                                                                        <li>{cargo.name}</li>
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <b>Dias (restantes para que el semáforo cambie a estado próximo a vencer)</b>
                                                            <p style={{fontSize:"20px"}}>{this.state.configuration_question_date.next_to_end_day}</p>
                                                        </div>
                                                        <div className="col-md-12"><hr/></div>              
                                                        <div className="col-md-6">
                                                            <b>Contenido del correo de próximo a vencer</b>
                                                            <div className="send-format-email mb-3 mt-2">
                                                                {ReactHtmlParser(this.state.configuration_question_date.email_next_to_end)}
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-6">
                                                            <b>Contenido del correo vencido</b>
                                                            <div className="send-format-email mt-2">
                                                                {ReactHtmlParser(this.state.configuration_question_date.email_ended)}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <b>Pregunta</b>
                                                            <p>{this.state.configuration_question_date.question_compare != null ? this.state.configuration_question_date.question_compare.question : ""}</p>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <b>Valor a comparar</b>
                                                            <p>{this.state.configuration_question_date.value_to_compare}</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        
                                        <div className="card-footer">
                                            {this.state.showEmailNex ? (
                                                <React.Fragment>
                                                    <button
                                                        className="btn-shadow btn btn-info mr-3" 
                                                        onClick={() => this.updateText()}
                                                    >
                                                        Aplicar cambios
                                                    </button>

                                                    <button
                                                        className="btn-shadow btn btn-danger" 
                                                        onClick={() => this.clearValues()}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </React.Fragment>
                                            ) : (
                                                <button
                                                    className="btn-shadow btn btn-info mr-3" 
                                                    onClick={() => this.setState({ showEmailNex: true, showEmailEnd: false })}
                                                >
                                                    Editar <i className="fas fa-pencil-alt"></i>
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalConfigurationDate;