import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Index from '../components/tasks/tasksCard/DashboardIndex'
import FormFilter from '../components/tasks/tasksCard/FormFilter';
import HeaderTitle from '../GeneralComponents/HeaderTitle'


class DashboardTask extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            cities: [],
            labels: [], //
            users: [],
            state_tasks: [],
            date_tasks: [],
            isLoaded: true,
            showFilter: false,
            isFiltering: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
            isLoaded: false,
            isFiltering: false,
        })
    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            data: this.state.data.map(item => {
                if (task.id === item.id) {
                    return {
                        ...item,
                        description: task.description,
                        due_date: task.due_date,
                        name: task.name,
                        state: task.state,
                        user_owner: task.user_owner,
                        general_comments: task.general_comments.length,
                        general_files: task.general_files.length,
                        task_labels: task.task_labels,
                        users: task.task_labels,
                        checklist: task.checklist,
                        url_source: task.url_source,
                        task_source: task.task_source,
                    }
                }
                return item;
            })
        });
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    handleClickShowFilter = () => {
        this.setState({
            showFilter: (this.state.showFilter == false ? true : false)
        })
    }

    handleClickCancel = () => {
        this.setState({ showFilter: false, isFiltering: false })
        this.loadData();
    }


    loadData = () => {
        fetch(`/task/get_dashboard_data`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    labels: data.labels,
                    users: data.users,
                    state_tasks: data.state_tasks,
                    date_tasks: data.date_tasks,
                    isLoaded: false
                });
            });
    }

    updateDataFilter = (data) => {
        this.setState({
            labels: data.labels,
            users: data.users,
            state_tasks: data.state_tasks,
            date_tasks: data.date_tasks,
            isLoaded: false
        })
    }

    updateStates = (state_is_filtering, state_is_loaded, url) => {
        this.setState({
            urlFilter: url,
            isFiltering: state_is_filtering,
            isLoaded: state_is_loaded,
        })
    }


    loadDataFilter = () => {
        fetch(this.state.urlFilter, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data.reverse(),
                isLoaded: false
            });
        });
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Dashboard de tareas"}
                    subTitle={"Informes de tareas"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={false}
                    showFilter={true}
                    taskHistory={undefined}
                />

                {this.state.showFilter && (
                    <FormFilter
                        handleClickFilter={this.HandleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        handleChangeFilter={this.handleChangeFilter}
                        from={this.props.from}
                        dashboard

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateStates={this.updateStates}
                        updateDataFilter={this.updateDataFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                    />
                )}

                <Index
                    labels={this.state.labels}
                    state_tasks={this.state.state_tasks}
                    users={this.state.users}
                    usuarios={this.props.users}
                    date_tasks={this.state.date_tasks}
                    isLoaded={this.state.isLoaded}
                />


            </React.Fragment>
        );
    }
}


export default DashboardTask;
WebpackerReact.setup({ DashboardTask });

