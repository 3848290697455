import React from "react";
import Index from "../components/health/NotificationEvents/index";


class NotificationEventIndex extends React.Component {
  
  render() {
  
    return (
      <Index 
        usuario={this.props.usuario} 
        from={this.props.from} 
        areas={this.props.areas} 
        events={this.props.events} 
        sources={this.props.sources} 
        nameModule={this.props.nameModule}
        estados={this.props.estados}
      />
      );
  }
  
}

export default NotificationEventIndex;

