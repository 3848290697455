import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input
                                        type="hidden"
                                        name="question_target_id"
                                        value={this.props.selectedOptionQuestion.question_target_id}
                                    />
                                    <label>Pregunta objetivo (Pregunta de este formulario que quieres actulizar)</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestions}
                                        options={this.props.questions}
                                        autoFocus={false}
                                        className={`link-form ${this.props.errorValues == false && this.props.formValues.question_target_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionQuestion}
                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <input
                                        type="hidden"
                                        name="format_select_id"
                                        value={this.props.selectedOption.format_select_id}
                                    />
                                    <label>Formato fuente (Formato del cual quieres que se actulice el campo anteriormente seleccionado)</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete}
                                        options={this.props.formatos}
                                        autoFocus={false}
                                        className={`link-form ${this.props.errorValues == false && this.props.formValues.format_select_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOption}
                                    />
                                </div>

                                {this.props.questions_values.length >= 1 && (
                                    <div className="col-md-12 mb-3">
                                        <input
                                            type="hidden"
                                            name="question_format_id"
                                            value={this.props.selectedOptionQuestionValues.question_format_id}
                                        />
                                        <label htmlFor="">Pregunta fuente de <b>{this.props.selectedOption.label} (Pregunta de la cual quieres actulizar)</b></label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteQuestionsValues}
                                            options={this.props.questions_values}
                                            autoFocus={false}
                                            className={`link-form ${this.props.errorValues == false && this.props.formValues.question_asociation_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOptionQuestionValues}
                                        />
                                    </div>
                                )}
                                <div className="col-md-12 mt-2 mb-3">
                                    <label>Operacion(Que tipo de información quieres actualizar)</label>
                                    <select
                                        name="operation"
                                        className="form form-control"
                                        value={this.props.formValues.operation}
                                        onChange={this.props.onChangeForm}
                                    >
                                         <option value="">Seleccione una opción</option>
                                        <option value="Suma">Suma</option>
                                        <option value="Máximo">Máximo</option>
                                        <option value="Mínimo">Mínimo</option>
                                        <option value="Contador">Cantidad de registros</option>
                                        <option value="Valor">Mismo Valor(Ingra el mismo valor del campo que agregaste)</option>
                                    </select>
                                </div>
                                <div className="col-md-12 mb-3" style={{ fontSize: "16px" }}>
                                    <hr />
                                    ¡LLena esta información si quieres condicionar cuando actualizar la pregunta objetivo!
                                                            </div>


                                <div className="col-md-12 mb-3">
                                    <input
                                        type="hidden"
                                        name="question_condition_id"
                                        value={this.props.selectedOptionQuestionCondition.question_condition_id}
                                    />
                                    <label>Pregunta condicional</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestionCondition}
                                        options={this.props.questions_values}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionQuestionCondition}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <label>Condicion (Para que me lleve información la pregunta condicional debe ser igual a:)</label>
                                    <input
                                        name="comparation_value"
                                        className="form form-control"
                                        value={this.props.formValues.comparation_value}
                                        onChange={this.props.onChangeForm}
                                        placeholder=""
                                    />
                                </div>


                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={() => this.props.submitForm()}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;