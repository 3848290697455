import React, { Component } from 'react';
import Table from '../components/dofa/Dofa/Index'
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import CKEditor from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-build-classic';

class IndexFormatPdf extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showMessage: false,
            modeEdit: this.props.modeEdit,
            format_pdf_id: this.props.modeEdit ? this.props.format_pdf.id : "",

            form: {
                format_id: this.props.format.id,
                name: this.props.modeEdit ? this.props.format_pdf.name : "",
                orientation: this.props.modeEdit ? this.props.format_pdf.orientation : "Portrait",
                content: this.props.modeEdit ? this.props.format_pdf.content : "",
            },
        }
    }


    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeCkaditor = (e) => {
        this.setState({
            form:{
                ...this.state.form,
                content: e
            }
        })
    }

    HandleClick = () => {
        if (this.state.modeEdit) {
            fetch(`/formatos/format_pdfs/${this.state.format_pdf_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}`);
                });
        } else {
            fetch(`/formatos/format_pdfs`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}`);
                });
        }
    }

    copyQuestion = (question) => {
        
        this.setState({ showMessage: true })
        setTimeout(()=> {
            this.setState({ showMessage: false })
        },2000)
        navigator.clipboard.writeText(`{S${question.step_position}P${question.position}}`)
    }

    render() {
        const editorConfiguration = {
            language: 'es',
            toolbar: {
                items: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo',  'fontfamily', 'fontsize' ],
                viewportTopOffset: 30,
                shouldNotGroupWhenFull: true
            }
        };

        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Configuración de PDFs"}
                    subTitle={"Fordata"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    showNew={false}
                    showFilter={false}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}`}
                                data-turbolinks="false"
                            >
                                {"Volver al formato"}
                            </a>
                        </li>
                    </ol>
                </nav>
                
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input 
                                            type="text" 
                                            name="name"
                                            value={this.state.form.name}
                                            onChange={this.HandleChange}
                                            className="form form-control"
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input type="radio" id="male" name="orientation" onChange={this.HandleChange} value="Portrait" checked={this.state.form.orientation == "Portrait" ? true : false}/>
                                        <label for="male" className="mr-3 ml-1">Vertical</label>
                                        <input type="radio" id="female" name="orientation" onChange={this.HandleChange} value="Landscape" checked={this.state.form.orientation == "Landscape" ? true : false}/>
                                        <label for="female" className="ml-1">Horizontal</label>
                                    </div>

                                    <div className="col-md-12">
                                        <CKEditor
                                            style={{height:"500px"}}
                                            editor={ ClassicEditor }
                                            data={this.state.form.content}
                                            config={ editorConfiguration }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                this.HandleChangeCkaditor(data)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer">
                                <button className="btn-shadow btn btn-info" onClick={() => this.HandleClick()}>{this.state.modeEdit ? "Actualizar" : "Crear"}</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                Preguntas
                            </div>
                            <div className="card-body">
                                {this.props.steps_questions.length >= 1 ? (
                                    this.props.steps_questions.map(question => (
                                        <React.Fragment>
                                            <p style={{cursor:"pointer"}} onClick={() => this.copyQuestion(question)} key={question.id}>{"{"}S{question.step_position}P{question.position}{"}"} - {question.question}</p>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <p>No hay preguntas</p>
                                )}

                                {this.state.showMessage && (
                                    <div className="alert alert-warning alert-dismissible fade show" style={{position:"fixed", bottom:"20px", right:"0px", zIndex:"10"}} role="alert">
                                        <strong>Pregunta copiada</strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ showMessage: false })}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>  
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default IndexFormatPdf;
WebpackerReact.setup({ IndexFormatPdf });
