import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import GoogleDocsViewer from 'react-google-docs-viewer';

class ShowFile extends Component {
    render() {
        return (
            <React.Fragment>
                <a
                    href="/settings/positions"
                    data-turbolinks="false"
                    className="btn"
                >
                    Volver
                </a>
                <div style={{ position:"relative" }}>
                     <div  className="hideButton" style={{position:"absolute", width:"40px", height:"70px", background:"#d1d1d1", right:"12px"}}></div>
              <GoogleDocsViewer
                        width="100%"
                        height="780px"
                        fileUrl={this.props.file.file.url}
                    />
                </div>

            </React.Fragment>
        );
    }
}


export default ShowFile;
WebpackerReact.setup({ ShowFile });
