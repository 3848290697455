import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input
                                        type="hidden"
                                        name="format_select_id"
                                        value={this.props.selectedOption.format_select_id}
                                    />
                                    <label>Formato</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete}
                                        options={this.props.formatos}
                                        autoFocus={false}
                                        className={`link-form ${this.props.errorValues == false && this.props.formValues.format_select_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOption}
                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <input
                                        type="hidden"
                                        name="question_asociation_id"
                                        value={this.props.selectedOptionQuestion.question_asociation_id}
                                    />
                                    <label>Pregunta que quieres asociar a este formato</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestions}
                                        options={this.props.questions}
                                        autoFocus={false}
                                        className={`link-form ${this.props.errorValues == false && this.props.formValues.question_asociation_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionQuestion}
                                    />
                                </div>

                                {/*<div className="col-md-12">
                                    <textarea
                                        name="description"
                                        rows="5"
                                        value={this.props.formValues.description}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Descripción.."
                                    />
                                </div>*/}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={() => this.props.submitForm()}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;