import React, { Component } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import Preloader from '../../../GeneralComponents/LoadingComponen'
import ChatBoxCommentModules from '../../../GeneralComponents/ChatBoxCommentModules'

class DashboardIndex extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-7">
                        <div className="content main-card mb-3 card">
                        <div className="card-header">TAREAS POR ETIQUETA</div>
                            <div className="card-body">
                                {this.props.isLoaded ? (
                                    <Preloader />
                                ) : (
                                        <Bar
                                            data={this.props.labels}
                                            width={1189}
                                            height={350}
                                            options={{ maintainAspectRatio: false }}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="content main-card mb-3 card">
                            <div className="card-header">TAREAS POR ESTADO</div>
                            <div className="card-body">
                                {this.props.isLoaded ? (
                                    <Preloader />
                                ) : (
                                        <Doughnut
                                            data={this.props.state_tasks}
                                            width={350}
                                            height={350}
                                            options={{ maintainAspectRatio: false }}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <div className="content main-card mb-3 card">
                            <div className="card-header">TAREAS POR RESPONSABLE</div>
                            <div className="card-body">
                                {this.props.isLoaded ? (
                                    <Preloader />
                                ) : (
                                        <Bar
                                            data={this.props.users}
                                            width={1189}
                                            height={300}
                                            options={{ maintainAspectRatio: false }}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="content main-card mb-3 card">
                            <div className="card-header">TAREAS POR CUMPLIMIENTO</div>
                            <div className="card-body">
                                {this.props.isLoaded ? (
                                    <Preloader />
                                ) : (
                                        <Doughnut
                                            data={this.props.date_tasks}
                                            width={350}
                                            height={300}
                                            options={{ maintainAspectRatio: false }}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12">
                        <ChatBoxCommentModules
                            users={this.props.usuarios}
                            module_name={"Dashboard"}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DashboardIndex;
