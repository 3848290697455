import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",

            formSectionAccionUpdate: {
                name: "",
                state: ""
            },
        }
    }

    HandleClick = () => {
        fetch(`/settings/section_accion_apps/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formSectionAccionUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadSectionAccions(this.props.formValues.section_app_id)
            this.props.loadSection()
            this.setState({ id: "" })
            this.props.messageSuccess(data);
        });
    }

    HandleChangeSectionAccion = (e) => {
        this.setState({
            formSectionAccionUpdate: {
              ...this.state.formSectionAccionUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    edit = (section) => {
        this.setState({
            id: section.id,
            formSectionAccionUpdate: {
                name: section.name,
                state: section.state
            },
        })
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si"
        }).then(result => {
          if (result.value) {
            fetch(`/settings/section_accion_apps/${id}`, {
              method: "DELETE",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadSectionAccions(this.props.formValues.section_app_id)
                this.props.loadSection()
                this.props.messageSuccess(response);
              });
          }
        });
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-12">
                                        <label>Nombre <small className="validate-label">*</small></label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    {this.props.ShowFormModule == false && (
                                       <div className="col-md-12 mt-4">
                                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th className="text-center">Acciones</th>
                                                </tr>
                                            </thead>
                
                                            <tbody>
                                                {this.props.dataSectionAccions.length >= 1 ? (
                                                    this.props.dataSectionAccions.map(section => (
                                                        <tr key={section.id}>
                                                            <td>
                                                                {section.id == this.state.id ? (
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        value={this.state.formSectionAccionUpdate.name}
                                                                        onChange={this.HandleChangeSectionAccion}
                                                                        className="form form-control"
                                                                    />
                                                                ) : (
                                                                    <p>{section.name}</p>
                                                                )}
                                                            </td>
                    
                                                            <td className="text-right" style={{ width: "10px" }}>
                                                                {this.state.id != "" ? (
                                                                    <React.Fragment>
                                                                        {section.id == this.state.id &&(
                                                                            <React.Fragment>
                                                                                <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i> 
                                                                                <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                                            </React.Fragment>
                                                                        )} 
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                        <div className="btn-group" role="group">
                                                                            <button
                                                                                className="btn-shadow btn btn-info"
                                                                                id="btnGroupDrop1"
                                                                                type="button"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <i className="fas fa-bars"></i>
                                                                            </button>
                        
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                {true && (
                                                                                    <button
                                                                                        onClick={() => this.edit(section)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Editar
                                                                                    </button>
                                                                                )}
                        
                                                                                {true && (  
                                                                                    <button
                                                                                        onClick={() => this.delete(section.id)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Eliminar
                                                                                    </button>
                                                                                )}
                                                                            </div>
                        
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                ))
                                                    ) : (
                                                    <tr>
                                                        <td colSpan="8" className="text-center">
                                                            <div className="text-center mt-4 mb-4">
                                                                <h4>No hay acciones de módulo para este módulo</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                       </div>
                                    )}

                                    
                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                                <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>Crear</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;