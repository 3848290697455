import React, { useState, useEffect } from 'react';
import ImportFile from './ImportFile';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Index = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [modal, setModal] = useState(false);
    const [file, setFile] = useState({});
    const [form, setForm] = useState({ file: {} });
    const [isLoaded, setIsLoaded] = useState(false);
    const [nameFile, setNameFile] = useState("Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo");
  
    useEffect(() => {

    }, []);

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const toogle = () => {
        setModal(!modal);
        setForm({ file: {} });
    }

    const handleFile = (archivo) => {
        archivo.map(file => (
            setForm({ file: file.size >= 11485760 ? {} : file }),
            setNameFile(file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path)
        ));
    }

    const clearValues = () => {
        setIsLoaded(false);
        setModal(false);
        setForm({ file: {} });
    }

    const uploadFile = () => {
        setIsLoaded(true);
        const formData = new FormData();
        formData.append("file", form.file)
    
        fetch(props.urlUploadFile, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            messageSuccess(data);
            clearValues();
            props.updateData(data.data);
        });
    }

    return (
        <React.Fragment>
            {modal && (
                <ImportFile
                    //modal props
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}    
                    title={`Subir archivo`}

                    handleFile={handleFile}
                    nameFile={nameFile}
                    isLoaded={isLoaded}
                    uploadFile={uploadFile}
                />
            )}

            <div className="">
                <a
                    className=" mr-2"
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="Descagar plantilla"
                    href={props.urlDowloadTemplate}
                    target="_blank"
                >
                  <img src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/244/file_formats_4_csv-512.png" alt="" style={{ height: "35px" }} />
                </a>

                <button
                    className='btn-shadow btn btn-info'
                    onClick={() => toogle()}
                >
                    Subir exel
                </button>
            </div>

        </React.Fragment>
    );
}

export default Index;
