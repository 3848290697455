import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Items from "../../settings/Icons/Items";

class FormCreateCategories extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label>Nombre <small className="validate-label">*</small></label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    
                                    <div className="col-md-6">
                                        <label>Cargos que pueden acceder <small className="validate-label">*</small></label>
                                        <input
                                            type="hidden"
                                            name="position_ids"
                                            value={this.props.selectedOption.position_ids}
                                        />
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            name="position_ids"
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.cargos}
                                            defaultValue={[
                                                this.props.editValuesProcesos[0],
                                                this.props.editValuesProcesos[1],
                                                this.props.editValuesProcesos[2],
                                                this.props.editValuesProcesos[3],
                                                this.props.editValuesProcesos[4],
                                                this.props.editValuesProcesos[5],
                                                this.props.editValuesProcesos[6],
                                                this.props.editValuesProcesos[7],
                                                this.props.editValuesProcesos[8],
                                                this.props.editValuesProcesos[9],
                                                this.props.editValuesProcesos[10],
                                                this.props.editValuesProcesos[11],
                                                this.props.editValuesProcesos[12],
                                                this.props.editValuesProcesos[13],
                                                this.props.editValuesProcesos[14],
                                                this.props.editValuesProcesos[15],
                                                this.props.editValuesProcesos[16],
                                                this.props.editValuesProcesos[17],
                                                this.props.editValuesProcesos[18],
                                                this.props.editValuesProcesos[19],
                                                this.props.editValuesProcesos[20]
                                            ]}
                                            
                                        />
                                    </div>
                                </div>

                                <Items
                                    icons={this.props.icons}
                                    modeEdit={this.props.modeEdit}
                                    setIconForm={this.props.setIconForm}
                                    icon_id={this.props.formValues.icon_id}
                                />
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                    <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateCategories;