import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import Loading from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            id: "",

            form: {
                title: "",
                date: "",
                responsable_id: "",
                description: "",
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            users: [],
        }
    }

    clearValues = () => {
        this.setState({
            ErrorValues: true,
            modeEdit: false,
            
            form: {
                title: "",
                date: "",
                responsable_id: "",
                description: "",
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.title != "" &&  
                this.state.form.date != "" && 
                this.state.form.responsable_id != ""
            ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    //selects Config

    componentDidMount = () => {
        this.configSelect();
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                responsable_id: selectedOption.value
            }
        });
    };


    configSelect = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }    

    HandleClick = () => {
        if(this.validationForm() == true){
            if (this.state.modeEdit == true) {
                fetch(`/dofa/dofas/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false})
                    this.props.updateItem(data.register)
                    this.messageSuccess(data);
                });
            }else{
                fetch(`/dofa/dofas`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false})
                    this.props.updateData(data.register)
                    this.messageSuccess(data);
                });
            }
        }
    }

    edit = (foda) => {
        this.setState({
            modal: true,
            modeEdit: true, 
            id: foda.id,

            form: {
                title: foda.title,
                date: foda.date,
                responsable_id: foda.responsable_id,
                description: foda.description,
            },

            selectedOption: {
                responsable_id: foda.responsable_id,
                label: `${foda.responsable.first_name} ${foda.responsable.first_last_name}`
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/dofa/dofas/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Lista de análisis FODA"}
                    subTitle={""}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Editar Dofa" : "Nuevo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}
                        
                        //select values
                        
                        //select user
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        users={this.state.users}
                    
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Título</th>
                                            <th>Fecha</th>
                                            <th>Responsable</th>
                                            <th>Descripción</th>
                                            {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                                <th className="text-center"></th>
                                            )}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.length >= 1 ? (
                                            this.props.data.map(foda => (
                                                <tr key={foda.id}>
                                                    <td>{foda.title}</td>
                                                    <td>{foda.date}</td>
                                                    <td>{foda.responsable != undefined ? foda.responsable.first_name : ""} {foda.responsable != undefined ? foda.responsable.first_last_name : ""}</td>
                                                    <td>{foda.description}</td>

                                                    {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {this.props.estados.gestionar && (
                                                                            <a
                                                                                href={`/dofa/dofas/${foda.id}`}
                                                                                data-turbolinks="false"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Gestionar
                                                                            </a>
                                                                        )}

                                                                        {this.props.estados.editar && (
                                                                            <button
                                                                                onClick={() => this.edit(foda)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                            </button>
                                                                        )}

                                                                        {this.props.estados.eliminar && (
                                                                            <button
                                                                                onClick={() => this.delete(foda.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </button>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;