import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    getMessageFactorType = (factor_type) => {
        if (factor_type === 'Fortalezas') {
            let message = (
                <React.Fragment>
                    Las {factor_type} son <b>factores internos</b> de su empresa o proyecto que ponen en <b>ventaja</b> respecto a su competencia
                </React.Fragment>
            );
            return message;
        }

        if (factor_type === 'Debilidades') {
            let message = (
                <React.Fragment>
                    Las {factor_type} son <b>factores internos</b> de su empresa o proyecto que ponen en <b>desventaja</b> respecto a su competencia
                </React.Fragment>
            );
            return message;
        }

        if (factor_type === 'Oportunidades') {
            let message = (
                <React.Fragment>
                    Las {factor_type} son <b>factores externos</b> de su empresa o proyecto que ponen en <b>ventaja</b> a la empresa
                </React.Fragment>
            );
            return message;
        }

        if (factor_type === 'Amenazas') {
            let message = (
                <React.Fragment>
                    Las {factor_type} son <b>factores externos</b> de su empresa o proyecto que pueden <b>afectar</b> a la empresa
                </React.Fragment>
            );
            return message;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader style={{ color: "#ffff", backgroundColor: this.props.getColorFactors(this.props.formValues.factor_type) }} toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.formValues.factor_type}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-12">
                                        <label>{this.getMessageFactorType(this.props.formValues.factor_type)}</label>
                                        <textarea
                                            name="description"
                                            value={this.props.formValues.description}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.ErrorValuesFortaleza == false && this.props.formValues.description == "" ? "error-class" : ""}`}
                                            rows="6"
                                        />
                                    </div>


                                    {this.props.ErrorValuesFortaleza == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                                <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn"
                                    onClick={this.props.submitForm}
                                    style={{ color: "#ffff", backgroundColor: this.props.getColorFactors(this.props.formValues.factor_type) }}
                                >
                                    {this.props.nameSubmit}
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;