import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Nombre</label>
                                        <input 
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Variable</label>
                                        <input 
                                            type="text"
                                            name="variable"
                                            value={this.props.formValues.variable}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                            placeholder="Variable"
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3 mb-2">
                                        <label>Tipo de imput</label>
                                        <select 
                                            name="unit_type"
                                            value={this.props.formValues.unit_type}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                        >   
                                            <option value="">Seleccione el tipo</option>
                                            <option value="numero">Número</option>
                                            <option value="moneda">Moneda</option>

                                        </select>
                                    </div>

                                    <div className="col-md-8 mt-3">
                                        <label>Empresas</label>
                                        <input 
                                            type="text"
                                            name="nameEmpresa"
                                            value={this.props.formValues.nameEmpresa}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                            placeholder="Nombre"
                                        />
                                        {/*<textarea
                                            type="text"
                                            name="data"
                                            value={this.props.formValues.data}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                            placeholder="Empresas"
                                            style={{ resize: "none" }}
                                            rows="8"
                                        />*/}
                                    </div>

                                    <div className="col-md-4">
                                        <button className="btn-shadow btn btn-info  mt-5" onClick={this.props.AddNames}>
                                            Agregar Empresa
                                        </button>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <ul style={{ paddingLeft: "17px" }}>
                                            {this.props.dataNames.map(indicator => (
                                                <li>{indicator}</li>
                                            ))}
                                        </ul>
                                    </div>


                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;


/*


                name: "",
                start_date: "",
                end_date: "",
                variable_1: "",
                variable_2: "",
                target: "",
*/