import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Index from '../components/tasks/tasksCard/Index';
import FormFilter from '../components/tasks/tasksCard/FormFilter';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import TaskIndexTrello from '../components/taskTrello/index'

class IndexTasks extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [], //
            urlFilter: "",
            url: (this.props.from != "TABLERO" ? `/task/get_tasks?task=${this.props.from}` : `/task/get_task_table/${this.props.task_board.id}`),
            isLoaded: true,
            showFilter: false,
            isFiltering: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
            isLoaded: false,
            isFiltering: false,
        })
    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                user_owner: task.user_owner,
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
                checklist: task.checklist,
                url_source: task.url_source,
                task_source: task.task_source,
              }
            }
            return item;
          })
        });
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    handleClickShowFilter = () => {
        this.setState({
            showFilter: (this.state.showFilter == false ? true : false)
        })
    }

    handleClickCancel = () => {
        this.setState({ showFilter: false, isFiltering: false })
        this.loadData();
    }
    

    loadData = () => {
        const date = new Date();
        fetch(this.state.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data.reverse(),
                isLoaded: false
            });
        });
    }


    loadDataFilter = () => {
        fetch(this.state.urlFilter, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data.reverse(),
                isLoaded: false
            });
        });
    }

    getNameTitle = (name) => {
        if (name == "ALL") {
            return `Todas las tareas`
        } else if (name == "ASSIGNED") {
            return `Tareas asignadas por mi`
        } else if (name == "MY") {
            return `Todas mis tareas`
        } else if (name == "GESTOR") {
            return `Tareas compartidas para mi`
        } else if (name == "TABLERO"){
            return `Tareas`
        }
    }

    updateDataFilter = (data) => {
        this.setState({
            data: data.data
        })
    }

    updateStates = (state_is_filtering, state_is_loaded, url) => {
        this.setState({
            urlFilter: url,
            isFiltering: state_is_filtering,
            isLoaded: state_is_loaded,
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.getNameTitle(this.props.from)}
                    subTitle={"Gestión de tareas"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={false}
                    showFilter={true}
                    taskHistory={this.props.from != "TABLERO" ? this.props.from : undefined}
                />

                {this.state.showFilter && (
                    <FormFilter 
                        handleClickFilter={this.HandleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        handleChangeFilter={this.handleChangeFilter}
                        from={this.props.from}
                        index={true}
                        url={this.state.url}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateStates={this.updateStates}
                        updateDataFilter={this.updateDataFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                    />
                )}

                {this.props.from == "TABLERO" && (
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a
                                    href={`/task/task_boards`}
                                    data-turbolinks="false"
                                >
                                    {"Volver a los tableros"}
                                </a>
                            </li>
                        </ol>
                    </nav>
                )}


                {/*<TaskIndexTrello
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    user={this.props.user} 
                    users={this.props.users}
                    from={this.props.from}
                    estados={this.props.estados}

                    //test data 

                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    task_board={this.props.task_board}

                    //parametros 

                    task_id={this.props.task_id}
                    procesos={this.props.procesos}
                    handleClickFilter={this.loadDataFilter}
                    isFiltering={this.state.isFiltering}
                />*/}

                <Index
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    user={this.props.user} 
                    users={this.props.users}
                    from={this.props.from}
                    estados={this.props.estados}

                    //test data 

                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    task_board={this.props.task_board}

                    //parametros 

                    task_id={this.props.task_id}
                    procesos={this.props.procesos}
                    handleClickFilter={this.loadDataFilter}
                    isFiltering={this.state.isFiltering}
                />
            </React.Fragment>
        );
    }
}


export default IndexTasks;
WebpackerReact.setup({ IndexTasks });
