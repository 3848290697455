import React, { Component } from 'react';
import Table from '../components/documentManagement/Document/Index'
import FormFilter from '../components/documentManagement/Document/FormFilter';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import Preloader from '../GeneralComponents/LoadingComponen'

class DocumentIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [],
            isLoaded: true,
            showFilter: false,
            filtering: false,

            formFilter: {
                name: "",
                document_type_id: "",
                proceso_id: "",
                user_create_id: "",
                user_review_id: "",
                user_approve_id: "",
                due_date: "",
                version: "",
            },

            selectedOptionUserCreate: {
                user_create_id: "",
                label: "Usuario que crea"
            },

            selectedOptionUserReview: {
                user_review_id: "",
                label: "Usuario que revisa"
            },

            selectedOptionUserApprove: {
                user_approve_id: "",
                label: "Usuario que aprueba"
            },

            selectedOptionDocumentType: {
                document_type_id: "",
                label: "Tipo de documento"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            positions: [],
            document_types: [],
            procesos: [],
            users: [],

            //pagination 

            activePage: 1,
            countPage: 10,
            documents_total: 0
        }
    }

    componentDidMount(){
        this.loadData();
        this.configSelect();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            documents_total: this.state.documents_total + 1
        })
    }

    configSelect = () => {
        let arrayPositions = [];
        let arrayProcesos = [];
        let arrayDocumentType = [];
        let arrayUsers = [];

        this.props.positions.map((item) => (
            arrayPositions.push({label: `${item.name}`, value: item.id})
        ))

        this.props.document_types.map((item) => (
            arrayDocumentType.push({label: `${item.name}`, has_process: item.has_process, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({label: `${item.name}`, value: item.id})
        ))

        this.props.users.map((item) => (
            arrayUsers.push({label: `${item.name}`, value: item.id})
        ))

        this.setState({
            positions: arrayPositions,
            document_types: arrayDocumentType,
            procesos: arrayProcesos,
            users: arrayUsers,
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
            if (format.id === item.id) {
              return { ...item, 
                approve_date: format.approve_date,
                code: format.code,
                creation_date: format.creation_date,
                due_date: format.due_date,
                is_attached: format.is_attached,
                is_last: format.is_last,
                name: format.name,
                document_type: format.document_type,
                document_type_id: format.document_type_id,
                position_approve: format.position_approve,
                position_approve_id: format.position_approve_id,
                position_create: format.position_create,
                position_create_id: format.position_create_id,
                position_review: format.position_review,
                position_review_id: format.position_review_id,
                proceso_id: format.proceso_id,
                review: format.review,
                state: format.state,
                validity: format.validity,
                version: format.version,
                positions: format.positions,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(this.props.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            documents_total: data.documents_total,
            isLoaded: false
          });
        });
    }


    HandleClickFilter = e => {
        this.setState({ isLoaded: true })
        fetch(`${this.props.url}?name=${this.state.formFilter.name}&document_type_id=${this.state.formFilter.document_type_id}&proceso_id=${this.state.formFilter.proceso_id}&user_create_id=${this.state.formFilter.user_create_id}&user_review_id=${this.state.formFilter.user_review_id}&user_approve_id=${this.state.formFilter.user_approve_id}&due_date=${this.state.formFilter.due_date}&version=${this.state.formFilter.version}&filtering=${this.state.filtering}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({
              data: data.data,
              documents_total: data.documents_total,
              isLoaded: false,
            });
        });
    };

    clearValues = () => {
        this.setState({
            formFilter: {
                name: "",
                document_type_id: "",
                proceso_id: "",
                user_create_id: "",
                user_review_id: "",
                user_approve_id: "",
                due_date: "",
                version: "",
            },

            selectedOptionPositionCreate: {
                position_create_id: "",
                label: "Cargo que crea"
            },

            selectedOptionPositionReview: {
                position_review_id: "",
                label: "Cargo que revisa"
            },

            selectedOptionPositionApprove: {
                position_approve_id: "",
                label: "Cargo que aprueba"
            },

            selectedOptionDocumentType: {
                document_type_id: "",
                label: "Tipo de documento"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

        })
    }

    filter = (state) => {
        this.setState({ showFilter: state })
        if (!state){
            this.loadData();
            this.clearValues();
        }
    }

    HandleChange = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeAutocompleteUserCreate = selectedOptionUserCreate => {
        this.setState({
            selectedOptionUserCreate,
            formFilter: {
                ...this.state.formFilter,
                user_create_id: selectedOptionUserCreate.value
            }
        });
    };

    handleChangeAutocompleteUserReview = selectedOptionUserReview => {
        this.setState({
            selectedOptionUserReview,
            formFilter: {
                ...this.state.formFilter,
                user_review_id: selectedOptionUserReview.value
            }
        });
    };

    handleChangeAutocompleteUserApprove = selectedOptionUserApprove => {
        this.setState({
            selectedOptionUserApprove,
            formFilter: {
                ...this.state.formFilter,
                user_approve_id: selectedOptionUserApprove.value
            }
        });
    };

    handleChangeAutocompleteDocumentType = selectedOptionDocumentType => {
        this.setState({
            selectedOptionDocumentType,
            formFilter: {
                ...this.state.formFilter,
                document_type_id: selectedOptionDocumentType.value
            }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProceso => {
        this.setState({
            selectedOptionProceso,
            formFilter: {
                ...this.state.formFilter,
                proceso_id: selectedOptionProceso.value
            }
        });
    };

    //pagination 

    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber }); 
        fetch(`${this.url}?page=${pageNumber}&filter=${this.state.countPage}&filtering=${this.state.filtering}&work_description=${this.state.filtering == true ? this.state.formFilter.work_description : "" }&report_execute_id=${this.state.filtering == true ? this.state.formFilter.report_execute_id : ""}&date_ejecution=${this.state.filtering == true ? this.state.formFilter.date_ejecution : ""}&report_sate=${this.state.filtering == true ? this.state.formFilter.report_sate : ""}&cost_center_id=${this.state.filtering == true ? this.state.formFilter.cost_center_id : ""}&customer_id=${this.state.filtering == true && this.state.formFilter.customer_id != undefined  ? this.state.formFilter.customer_id : ""}&date_desde=${this.state.formFilter.date_desde}&date_hasta=${this.state.formFilter.date_hasta}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({ 
              data: data.data,
              documents_total: data.documents_total,
            });
        });     
    };  

    titleView = () => {
        if(this.props.view == "document_view"){
            return "Documentos"
        }else if(this.props.view == "obsolete_view"){
            return "Documentos obsoletos"
        }else if(this.props.view == "create_view"){
            return "Documentos en creación"
        }
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.titleView()}
                    subTitle={"Gestión documental"}
                    titleNew={"Nuevo documento"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={() => this.child.current.toogle("new")}
                    onClickFilter={() => this.filter(true)}
                    showNew={this.props.estados.create}
                    showFilter={true}
                />

                {this.state.showFilter && (
                    <FormFilter
                        HandleClickFilter={this.HandleClickFilter}
                        formValues={this.state.formFilter}
                        onChangeForm={this.HandleChange}
                        filter={this.filter}

                        //select values

                        //select UserCreate
                        selectedOptionUserCreate={this.state.selectedOptionUserCreate}
                        handleChangeAutocompleteUserCreate={this.handleChangeAutocompleteUserCreate}

                        //select UserReview
                        selectedOptionUserReview={this.state.selectedOptionUserReview}
                        handleChangeAutocompleteUserReview={this.handleChangeAutocompleteUserReview}

                        //select UserApprove
                        selectedOptionUserApprove={this.state.selectedOptionUserApprove}
                        handleChangeAutocompleteUserApprove={this.handleChangeAutocompleteUserApprove}

                        //select DocumentType
                        selectedOptionDocumentType={this.state.selectedOptionDocumentType}
                        handleChangeAutocompleteDocumentType={this.handleChangeAutocompleteDocumentType}

                        //select Proceso
                        selectedOptionProceso={this.state.selectedOptionProceso}
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}

                        positions={this.state.positions}
                        document_types={this.state.document_types}
                        procesos={this.state.procesos}
                        users={this.state.users}
                    />
                )}
                
                {!this.state.isLoaded ? (
                    <Table
                        ref={this.child}
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        updateItem={this.updateItem}
                        updateData={this.updateData}
                        estados={this.props.estados}

                        positions={this.state.positions}
                        document_types={this.state.document_types}
                        procesos={this.state.procesos}
                        users={this.state.users}

                        //pagination
                        totalData={this.state.documents_total}
                        activePage={this.state.activePage}
                        countPage={this.state.countPage}
                        handlePageChange={this.handlePageChange}
                        url={this.props.url}
                        view={this.props.view}
                    />
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader />
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}


export default DocumentIndex;
WebpackerReact.setup({ DocumentIndex });
