import React, { Component } from 'react';
import Table from '../components/settings/Procesos/Table'


class ProcesoIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            showbtnSubmit: false,
            formFilter: {
                name: ""
            }
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateNewData = (data) => {
        this.setState({ 
            data: data
        })
    }

    //filter

    clearValues = () => {
        this.setState({
            formFilter: {
                name: ""
            },
            showbtnSubmit: false,
        })
        
        this.loadData();
    }

    handleChangeFilter = e => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        });
    };

    handleClickFilter = () => {
        fetch(`/settings/get_procesos/${'procesos administrativos'}?name=${this.state.formFilter.name}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                showbtnSubmit: true
            });
        });
    };

    loadData = () => {
        fetch(`/settings/get_procesos/${'procesos administrativos'}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    estados={this.props.estados}
                    isLoaded={this.state.isLoaded}

                    //filter
                    handleClickFilter={this.handleClickFilter}
                    handleChangeFilter={this.handleChangeFilter}
                    showbtnSubmit={this.state.showbtnSubmit}
                    formFilter={this.state.formFilter}
                    clearValues={this.clearValues}
                    tenant={this.props.tenant}
                    updateNewData={this.updateNewData}
                />
            </React.Fragment>
        );
    }
}


export default ProcesoIndex;