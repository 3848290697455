import React, { Component } from 'react';
import IndexImportFile from '../ImportFile/Index';

class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="row">
                        <div className="col-md-10">
                            <p className="mt-3 pl-4 mb-0" >{this.props.data.length} Registros</p>    
                        </div>

                        <div className="col-md-2 mt-3">
                            <IndexImportFile
                                urlUploadFile={"/settings/import_proceso_file"}
                                urlDowloadTemplate={"/settings/download_procesos_template.xls"}
                                updateData={this.props.updateNewData}
                            />
                        </div>
                    </div>
                    
                    <div className="card-body">

                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Prefijo</th>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Tipo de proceso</th>
                                    {this.props.estados.uploadFile && (
                                        <th>Adjuntos</th>
                                    )}
                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                        <th className="text-center"></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(procesos => (
                                        <tr key={procesos.id}>
                                            <td>{procesos.prefix}</td>
                                            <td>{procesos.name}</td>
                                            <td>{procesos.description}</td>
                                            <td>{procesos.proceso_type}</td>
                                            {this.props.estados.uploadFile && (
                                                <td>
                                                    <p> <span className="link-line" onClick={() => this.props.addFiles(procesos)}>{procesos.general_files.length} Archivos adjuntos </span></p>
                                                </td>
                                            )}
                                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-info"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {this.props.estados.editar && (
                                                                    <button
                                                                        onClick={() => this.props.edit(procesos)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}

                                                                {this.props.estados.eliminar && (
                                                                    <button
                                                                        onClick={() => this.props.delete(procesos.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;