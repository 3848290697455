import React, { Component } from 'react';
import Select from "react-select";

class FormFilter extends Component {
    constructor(props){
        super(props)
        this.state = {
            isFiltering: false,
            url: "",
            
            formFilter: {
                fechaDesde: "",
                fechaHasta: "",
                name: "",
                state: "",
                description: "",
                gestor_user_id: "",
                user_id: "",
                task_label_id: [],
                name_module: [],
                user_owner_id: "",
                count_task: "",
                state_task: "",
                procesos_ids: [],
            },

            selectedOption: {
                gestor_user_id: "",
                label: "Gestores"
            },

            selectedOptionEtiquetas: {
                task_label_id: "",
                label: "Etiqueta"
            },

            selectedOptionUserOwner: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionUser: {
                user_id: "",
                label: "Dueño de la tarea"
            },

            selectedOptionTasks: {
                count_task: "",
                label: "Número de tarea"
            },

            selectedOptionCargos: {
                procesos_ids: "",
                label: "Cargo"
            },

            selectedOptionTaskSources: {
                task_source_ids: "",
                label: "Módulo"
            },

            labels: [],
            users: [],
            tasks: [],
            cargos: [],
            task_sources: [],
        }
    }

    clearValues = () => {
        this.setState({
            formFilter: {
                fechaDesde: "",
                fechaHasta: "",
                name: "",
                state: "",
                description: "",
                gestor_user_id: "",
                user_id: "",
                task_label_id: [],
                name_module: [],
                user_owner_id: "",
                count_task: "",
                state_task: "",
                procesos_ids: [],
            },

            selectedOption: {
                user_id: "",
                label: "Gestores"
            },

            selectedOptionUserOwner: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionEtiquetas: {
                task_label_id: "",
                label: "Etiqueta"
            },

            selectedOptionTasks: {
                count_task: "",
                label: "Número de tarea"
            },

            selectedOptionCargos: {
                procesos_ids: "",
                label: "Cargo"
            },

            selectedOptionUser: {
                user_id: "",
                label: "Dueño de la tarea"
            },
        })
    }

    handleChangeAutocompleteTaskSources = selectedOptionTaskSources => {
        let array = []

        selectedOptionTaskSources.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
          formFilter: {
            ...this.state.formFilter,
            name_module: array
          }
        })
    }


    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
                formFilter: {
                    ...this.state.formFilter,
                    gestor_user_id: selectedOption.value
                }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    };

    handleChangeAutocompleteUserOwner = selectedOptionUserOwner => {
        this.setState({
            selectedOptionUserOwner,
              formFilter: {
                  ...this.state.formFilter,
                  user_owner_id: selectedOptionUserOwner.value
              }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteUser = selectedOptionUser => {
        this.setState({
            selectedOptionUser,
              formFilter: {
                  ...this.state.formFilter,
                  user_id: selectedOptionUser.value
              }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteSeachTask = selectedOptionTasks => {
        this.setState({
            selectedOptionTasks,
              formFilter: {
                  ...this.state.formFilter,
                  count_task: selectedOptionTasks.value
              }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteEtiquetas = selectedOptionEtiquetas => {
        let array = []

        selectedOptionEtiquetas.map((item) => (
          array.push(item.value)
        ))

        this.setState({
            formFilter: {
                ...this.state.formFilter,
                task_label_id: array
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteCargos = selectedOptionCargos => {
        let array = []

        selectedOptionCargos.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                procesos_ids: array
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    usersSelect = () => {
        let array = [];
        let arrayLabels = [];
        let arrayTasks = [];
        let arrayCargos = [];
        let arrayTaskSources = [];

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.labels.map((item) => (
            arrayLabels.push({label: item.name, value: item.id})
        ))

        this.props.tasks.map((item) => (
            arrayTasks.push({label: item.count, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.props.task_sources.map((item) => (
            arrayTaskSources.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
            labels: arrayLabels,
            tasks: arrayTasks,
            cargos: arrayCargos,
            task_sources: arrayTaskSources,
        })
    }

    HandleClickFilter = () => {
        let array = []

        if(this.props.history){
            array.push([`/task/get_history/${this.props.from}?filtering=${this.props.isFiltering}&fechaDesde=${this.state.formFilter.fechaDesde}&fechaHasta=${this.state.formFilter.fechaHasta}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&gestor_user_id=${this.state.formFilter.gestor_user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}&user_id=${this.state.formFilter.user_id}&state=${this.state.formFilter.state}&state_task=${this.state.formFilter.state_task}`])
        }else if(this.props.index){
            array.push([`${this.props.url}&fechaDesde=${this.state.formFilter.fechaDesde}&fechaHasta=${this.state.formFilter.fechaHasta}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&gestor_user_id=${this.state.formFilter.gestor_user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}&user_id=${this.state.formFilter.user_id}&state=${this.state.formFilter.state}&state_task=${this.state.formFilter.state_task}`])
        }else if(this.props.calendar){
            array.push([`/task/get_tasks_test?task=${this.props.from}&fechaDesde=${this.state.formFilter.fechaDesde}&fechaHasta=${this.state.formFilter.fechaHasta}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&gestor_user_id=${this.state.formFilter.gestor_user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}&user_id=${this.state.formFilter.user_id}&state=${this.state.formFilter.state}&state_task=${this.state.formFilter.state_task}`])
        }else if(this.props.dashboard){
            array.push([`/task/get_dashboard_data?fechaDesde=${this.state.formFilter.fechaDesde}&fechaHasta=${this.state.formFilter.fechaHasta}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&gestor_user_id=${this.state.formFilter.gestor_user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}&user_id=${this.state.formFilter.user_id}&state=${this.state.formFilter.state}&state_task=${this.state.formFilter.state_task}`])
        }

        let url = array[0].toString()
        this.props.updateStates(true, true, url)
        
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.props.updateDataFilter(data)
            this.props.updateStateIsLoaded(false)
        });
    };    

    componentDidMount(){
        this.usersSelect();
    }

    handleChangeFilter = e => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="card mb-5">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-3">
                                    <label>Fecha desde</label>
                                    <input 
                                        type="date"
                                        name="fechaDesde"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.fechaDesde}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label>Fecha hasta</label>
                                    <input 
                                        type="date"
                                        name="fechaHasta"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.fechaHasta}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <input
                                        type="hidden"
                                        name="gestor_user_id"
                                        value={this.state.selectedOption.gestor_user_id}
                                    />
                                    <label>Gestor</label>
                                    <Select
                                        onChange={this.handleChangeAutocomplete}
                                        options={this.state.users}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.state.selectedOption}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label>Título</label>
                                    <input 
                                        type="text"
                                        name="name"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.name}
                                        placeholder="Título"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Descripción</label>
                                    <input 
                                        type="text"
                                        name="description"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.description}
                                        placeholder="Descripción"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="task_label_id"
                                        value={this.state.selectedOptionEtiquetas.task_label_id}
                                    />
                                    <label>Etiquetas</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteEtiquetas}
                                        options={this.state.labels}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        name="task_label_id"
                                    />

                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="task_source_ids"
                                        value={this.state.selectedOptionTaskSources.task_source_ids}
                                    />
                                    <label>Módulo</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteTaskSources}
                                        options={this.state.task_sources}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        name="task_source_ids"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="user_owner_id"
                                        value={this.state.selectedOptionUserOwner.user_owner_id}
                                    />
                                    <label>Responsable</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteUserOwner}
                                        options={this.state.users}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.state.selectedOptionUserOwner}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="count_task"
                                        value={this.state.selectedOptionTasks.count_task}
                                    />
                                    <label>Número de tarea</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteSeachTask}
                                        options={this.state.tasks}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.state.selectedOptionTasks}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="procesos_ids"
                                        value={this.state.selectedOptionCargos.procesos_ids}
                                    />
                                    <label>Procesos</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteCargos}
                                        options={this.state.cargos}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        name="procesos_ids"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        value={this.state.selectedOptionUser.user_id}
                                    />
                                    <label>Dueño de tarea</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteUser}
                                        options={this.state.users}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.state.selectedOptionUser}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Estados de la tarea</label>
                                    <select 
                                        type="date"
                                        name="state_task"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.state_task}
                                    >
                                        <option value="">Seleccione un estado</option>
                                        <option value="vencidas">Vencidas</option> 
                                        <option value="próximas">Próximas a vencer</option> 
                                        <option value="vigentes">Vigentes</option> 
                                    </select>
                                </div>

                                {this.props.dashboard && (
                                    <div className="col-md-3 mt-3">
                                        <label>Estados</label>
                                        <select 
                                            type="date"
                                            name="state"
                                            className="form form-control"
                                            onChange={this.handleChangeFilter}
                                            value={this.state.formFilter.state}
                                        >
                                            <option value="">Seleccione un estado</option>
                                            <option value="create">Pendientes</option>    
                                            <option value="doing">En proceso</option>    
                                            <option value="made">Finalizadas</option>    
                                        </select>
                                    </div>
                                )}
                                
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-primary  mr-3"
                                onClick={() => this.HandleClickFilter()}
                            >
                                Aplicar Filtro
                            </button>

                            <a
                                className="btn-shadow btn btn-light"
                                style={{ }}
                                onClick={() => this.props.handleClickCancel()}
                            >
                                Cancelar
                            </a>

                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;