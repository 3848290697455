import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import Filter from '../Surveys/Filter'
import Pagination from "react-js-pagination";
import UploadFile from '../Surveys/UploadFile'
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { array } from 'prop-types';
import Ckaditor from '../Formats/Ckeditor';
import ReactDOM from 'react-dom'
import { Bar, Doughnut, Line } from 'react-chartjs-2';

class IndexIndicator extends Component {
    constructor(props) {
        super(props);

        this.token = document.querySelector("[name='csrf-token']").content;
        this.url = `${this.props.domain}/formatos/generate_questions/${this.props.format.id}`
        this.state = {
            formUpdateRepy: {

            },
            formUpdateRepySelect: {

            },

            formatValues: {

            },

            formUpdateWidth: {
                column_width: "",
            },

            showFilter: false,
            question_id: "",
            modal: false,
            showPopover: false,
            sort: false,
            showIcons: false,
            selectItems: [],
            checked: false,
            options: [],

            answer_id: "",

            formUpdateAnswer: {
                answer_type: "",
                value: "",
            }
        }
    }

    HandleChangeRepy = (e) => {
        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: e.target.value + "-" + (this.state.formUpdateRepySelect[e.target.dataset.id] != undefined ? this.state.formUpdateRepySelect[e.target.dataset.id] : "contiene")
            }
        });
    }


    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                [e.target.name]: e.target.value,
            }
        });
    }

    selectValue = (answer) => {
        this.loadOptions(answer.survey_id, answer.question_id)
        this.setState({
            answer_id: answer.survey_id,
            question_id: answer.question_id,

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: answer.type,
                value: answer.value,
            }
        })
    }

    loadOptions = (survey_id, question_id) => {
        fetch(`/formatos/get_options_surveys/${survey_id}/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    options: data.register,
                })
            });
    }

    cancelValues = () => {
        this.setState({
            answer_id: "",
            question_id: "",
            options: [],

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: "",
                value: "",
            }
        })
    }


    HandleChangeCkaditor = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                value: e
            }
        })
    }

    updateValue = () => {
        fetch(`/formatos/update_survey_values/${this.state.answer_id}/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateAnswer), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.cancelValues();
            });
    }


    HandleChangeRepySelect = (e) => {
        var value = this.state.formUpdateRepy[e.target.dataset.id].split("-")[0];
        this.setState({
            formUpdateRepySelect: {
                ...this.state.formUpdateRepySelect,
                [e.target.dataset.id]: e.target.value
            },

            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: value + "-" + e.target.value
            }
        });
    }

    HandleChangeWidth = (e) => {
        this.setState({
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    setValues = (question) => {
        this.setState({
            question_id: question.id,
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                column_width: question.column_width,
            }
        })
    }

    HandleChangeRepyDate1 = (e) => {

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${e.target.value},${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[1] : null}`
            }
        });
    }
    HandleChangeRepyDate2 = (e) => {

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[0] : null},${e.target.value}`
            }
        });
    }



    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    componentWillReceiveProps = (nexProps) => {
        this.loadValues(nexProps)
        let filterPareameters = nexProps.filters

        /*        nexProps.dataQuestionnvm.map(value=>{
                   filterPareameters[value.id] = ""
               }); */

        this.setState({
            formUpdateRepy: filterPareameters,
        })
    }


    componentDidMount = () => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: '338057327568708',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v8.0'
            });
        };
    }

    onClickFacebook = () => {
        FB.ui({
            method: 'feed',
            link: this.url
        }, function (response) { });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    getWidth = (value) => {
        console.log(isMobile)
        console.log(value)
        if (value > 4 || isMobile) {

            return (((value * 300) + 300).toString()) + "px";

        } else {
            return "100%";
        }

    }



    generateQuestions = () => {
        window.open(`${this.props.domain}/formatos/generate_questions/${this.props.format.id}`, '_self');
    }

    dateDate = (fecha) => {
        console.log(fecha)
        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));
        console.log(d)
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear();

    }
    dateTime = (fecha) => {

        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));

        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());


    }
    showFilter = () => {
        this.setState({
            showFilter: true
        })

    }
    cancelFilter = () => {
        this.props.cleanFilters();
        this.props.loadData();
        this.setState({
            showFilter: false

        })

    }

    sortby = (question, order) => {
        console.log(question)
        console.log(order)
        var order_value = order ? "ASC" : "DESC"
        fetch(`/formatos/sort_list/${this.props.format.id}/${question}/${order_value}`, {
            method: "PATCH",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                this.props.loadData();
                this.setState({
                    sort: !this.state.sort
                })

            });
    }

    queryExcel = (data) => {
        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');
        console.log("------ob-------")
        console.log(data)
        return query
    }


    updateWidth = () => {
        fetch(`/formatos/update_width/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateWidth), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    question_id: "",
                    formUpdateWidth: {
                        column_width: "",
                    }
                })

                this.props.loadData()
            });
    }


    getFormatsValues = (datas) => {
        fetch(`/formatos/get_formats_values/${datas}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [datas]: data
                    }
                })
            });
    }

    loadValues = (nexProps) => {
        nexProps.dataQuestionnvm.filter(value => value.question_type == "format_id").map((question, index) => {
            this.getFormatsValues(question.id)
        })
    }

    addAllValues = (e) => {
        if (e.target.checked) {
            const array = []

            this.props.dataQdataAnswersnvm.map((survey) => (
                array.push(survey.survey)
            ))

            this.setState({
                selectItems: array,
                checked: e.target.checked,
            })
        } else {
            this.setState({
                selectItems: [],
                checked: e.target.checked,
            })
        }
    }


    addItem = (survey, state) => {
        const selectItems = this.state.selectItems

        if (!state) {
            selectItems.push(survey.survey)

            this.setState({
                selectItems: selectItems,
            })
        } else {
            var arr = selectItems
            var index = selectItems.indexOf(parseInt(survey.survey))
            delete arr[index]

            this.setState({
                selectItems: arr.filter(item => item != null),
            })
        }
    }

    deleteAll = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/delete_surveys/${this.state.selectItems}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ selectItems: [], checked: false })
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    updateView = () => {
        fetch(`/formatos/update_suveys_view/${this.state.selectItems}/${this.props.view}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.setState({ selectItems: [], checked: false })
            });
    }

    setTitle = () => {
        if (this.props.view === "ACTIVE") {
            return "Desactivar"
        } else if (this.props.view === "INACTIVE") {
            return "Activar"
        }
    }

    barChart = () => {


        return {
            labels: this.props.dataAnswersIndicator[0],
            datasets: [{
                label: '',
                data: this.props.dataAnswersIndicator[1],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },{
                label: '',
                data: this.props.dataAnswersIndicator[1],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            }]
        };
    }


    render() {

        return (
            <React.Fragment>

                <HeaderTitle
                    title={`${this.props.format.name}`}
                    titleNew={"Crear"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    showNew={this.props.dataQuestionnvm.length >= 1 ? true : false}
                    onClickNew={this.generateQuestions}
                    showFilter={this.props.dataQuestionnvm.length >= 1 ? true : false}
                    onClickFilter={this.showFilter}
                />

                {this.state.modal && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Subir archivo"}
                        format={this.props.format}
                        format_category={this.props.format_category}
                        loadData={this.props.loadData}
                    />
                )}

                {!this.props.isLoaded ? (
                    this.props.dataQuestionnvm.length >= 1 ? (
                        <React.Fragment>
                            <div className="mb-2" style={{ display: this.state.showFilter ? "block" : "none" }} >
                                <div className="col-md-12 card">
                                    <div className="row pt-2 pb-3">


                                        {this.props.dataQuestionnvm.length >= 1 ? (
                                            this.props.dataQuestionnvm.filter(value => value.show_filter).map(question => (
                                                (question.question_type != "answer_file" && question.question_type != "dynamic_field") && (
                                                    <div className={question.question_type == "answer_date" || question.question_type == "answer_time" ? "col-md-3 mb-3 filter-inputs-wp" : "col-md-3 mb-3 filter-inputs-wp"}>
                                                        <div className="filter-inputs">
                                                            <label htmlFor="">{question.question} {question.question_type == "answer_date" ? "(Desde-Hasta)" : ""}</label>
                                                            <Filter question={question}
                                                                users={this.props.users}
                                                                procesos={this.props.procesos}
                                                                positions={this.props.positions}
                                                                HandleChangeRepy={this.HandleChangeRepy}
                                                                HandleChangeRepySelect={this.HandleChangeRepySelect}
                                                                formUpdateRepy={this.state.formUpdateRepy}
                                                                formUpdateRepySelect={this.state.formUpdateRepySelect}
                                                                HandleChangeRepyDate1={this.HandleChangeRepyDate1}
                                                                HandleChangeRepyDate2={this.HandleChangeRepyDate2}
                                                                formValues={this.state.formatValues}
                                                            /></div>
                                                    </div>
                                                )

                                            ))
                                        ) : (<div></div>
                                            )}
                                        <div className="col-md-12" > <hr /></div>
                                        <div className="col-md-12" ><button className="btn btn-primary" onClick={() => this.props.loadDataFilter(this.state.formUpdateRepy)}>Filtrar</button>< button className="btn btn-danger ml-3" onClick={() => this.cancelFilter()}>X</button></div>

                                    </div>

                                </div>
                            </div>

                            <div className="mh-400" >
                                <div className="col-md-7 p-0">
                                    <div className="content main-card mb-3 card">
                                        <div className="card-header">TAREAS POR ETIQUETA</div>
                                        <div className="card-body">
                                            {this.props.isLoaded ? (
                                                <Preloader />
                                            ) : (

                                                    <Line
                                                        data={this.barChart()}
                                                        width={1189}
                                                        height={350}
                                                        options={{ maintainAspectRatio: false ,  elements: {
                                                            line: {
                                                                tension: 0
                                                            }
                                                        }}}
                                                    />

                                                )}
                                        </div>
                                    </div>
                                </div>


                                {!this.props.isFilter ? (
                                    <div className="col-md-2 text-right">
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage}
                                            itemsCountPerPage={this.props.countPage}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.survey_total}
                                            pageRangeDisplayed={this.props.countPage}
                                            onChange={this.props.handlePageChange}
                                        />
                                    </div>
                                ) : (
                                        <div className="col-md-2 text-right">
                                            <Pagination
                                                hideNavigation
                                                activePage={this.props.activePage_filter}
                                                itemsCountPerPage={this.props.countPage_filter}
                                                itemClass="page-item"
                                                innerClass="pagination"
                                                linkClass="page-link"
                                                totalItemsCount={this.props.survey_total_filter}
                                                pageRangeDisplayed={this.props.countPage_filter}
                                                onChange={this.props.handlePageChange_filter}
                                            />
                                        </div>
                                    )}


                            </div>
                        </React.Fragment >
                    ) : (

                            <div className="no-fordata"><i className="far fa-list-alt"></i> <br />
                                ¡Aún no has completado tu Formato! <br /> crea almenos una entrada</div>

                        )
                ) : (
                        <div className="col-md-12 text-center">
                            <Preloader />
                        </div>
                    )}
            </React.Fragment >
        );
    }
}

export default IndexIndicator;
