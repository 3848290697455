import React, { Component } from "react";
import NumberFormat from "react-number-format";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import TasksItems from '../../tasks/tasksNumeralChildScore/TasksItems'
import TaskFormCreate from '../../tasks/tasksNumeralChildScore/FormCreate'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import ReactHtmlParser from 'react-html-parser';

class Show extends Component {
  constructor(props) {
    super(props);
    this.token = document.querySelector("[name='csrf-token']").content;
    this.url_source = `/health/normativity_scores/${this.props.normativityScore.id}`,
      this.state = {
        id: "",
        idTask: "",
        register: {},
        idSubnumeral: "",
        dataNumerals: [],
        formUpdate: {
          calification: 0,
        },

        modeEdit: false,
        modal: false,
        loadFile: true,
        showTask: false,

        data: [],
        isLoaded: false,
        loadCalification: false,
        valueTotal: 0,
      };
  }

  componentDidMount = () => {
    this.loadData();
  }

  //add items
  updateData = (data) => {
    this.setState({
      data: this.state.data.map(data_normativity => {
        data_normativity.numeral_child_scores.filter(numeral => numeral.id == this.state.register.id).map(item => {
          return (
            [...item.tasks, data],
            console.log("actualizadno estadoooooooooooooooooooooooo"),
            console.log(item)
          )
        })

        return data_normativity;
      })
    });
  }

  updateData = (data) => {
    this.setState({
      data: [...this.state.data, data],
    })
  }

  //add update
  updateItem = task => {
    console.log("updateItemupdateItemupdateItem")
    this.setState({
      data: this.state.data.map(item => {
        if (task.id === item.id) {
          return {
            ...item,
            description: task.description,
            due_date: task.due_date,
            name: task.name,
            state: task.state,
            //user_owner: {document: "1037660678", document_type: "Cédula de"},
            general_comments: taskitem.general_comments.length,
            general_files: task.general_files.length,
            task_labels: task.task_labels,
            users: task.task_labels,
          }
        }
        return item;
      })
    });
  }


  loadData = () => {
    fetch(`/health/get_numeral_parents_score/${this.props.normativityScore.id}`, {
      method: 'GET', // or 'PUT'
      headers: {
        "X-CSRF-Token": this.token,
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data.data,
          valueTotal: data.valueTotal,
          loadCalification: false,
          isLoaded: true,
          id: "",
          formUpdate: {
            calification: 0,
          },
        });
      });
  }

  messageSuccess = (response) => {
    Swal.fire({
      position: "center",
      type: "success",
      title: `${response}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  HandleClickUpdate = (id) => {
    this.setState({ loadCalification: true })
    fetch(`/health/update_value/${id}/${this.state.formUpdate.calification}`, {
      method: "POST", // or 'PUT'
      headers: {
        "X-CSRF-Token": this.token,
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.loadData();
      });
  };

  clearValues = () => {
    this.setState({
      formNumeralFiles: {
        ...this.state.formNumeralFiles,
        description: "",
      },
      file_subnumeral: null,
    });

    console.log("limpiando datos");
  };



  onChangeUpdate = (e) => {
    this.setState({
      formUpdate: {
        ...this.state.formUpdate,
        [e.target.name]: e.target.value,
      },
    });
  };

  updateValues = (id, value) => {
    this.setState({
      id: id,
      formUpdate: {
        calification: value,
      },
    });
  };

  closeTask = () => {
    this.setState({
      idTask: "",
      showTask: false,
    })
  }



  getColor(value) {
    let color = []
    if (value == 11) {
      color = ["gray", "white"];
    }
    else if (value < 6) {
      color = ["#ea4612", "white"];
    } else if (value >= 6 && value < 8) {
      color = ["orange", "white"];
    }
    else {
      color = ["#30af28", "white"];
    }

    var style = { color: color[1], background: color[0] }

    return style
  }

  getBack = (value) => {
    window.open(value, "_self")
  }


  getNameTitle = (name) => {
    if (name == "PCH" || name == "NMH") {
      return "Hospitales"
    } else if (name == "PCCAP") {
      return "Modelo Ambulatorio"
    } else if (name == "PCISO9001") {
      return "ISO 9001:2015 Salud"
    } else if (name == "PCISO15189") {
      return "ISO 7101:2023 HQM"
    } else if (name == "PCHEM") {
      return "Acreditación"
    } else if (name == "NCON") {
      return "Servicios Auxiliares"
    } else if (name == "NFAR") {
      return "Expediente Clínico"
    } else if (name == "NNOM") {
      return "Marco Legal"
    }
  }

  render() {
    return (
      <React.Fragment>
        <nav className="" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack(`/health/normativity?type=${this.props.normativityScore.normativity.type_normativity}`)}>{this.getNameTitle(this.props.normativityScore.normativity.type_normativity)}</a></li>
            <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack(`/health/normativity/${this.props.normativityScore.normativity.id}/numeral_parents`)}>Estándares y Guías</a></li>
            <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack(`/health/normativity/${this.props.normativityScore.normativity.id}/`)}>Implementación</a></li>
            <li className="active breadcrumb-item" aria-current="page">Implementación de {this.props.normativityScore.normativity.name}</li>
          </ol>
        </nav>

        {this.state.isLoaded ? (
          <div className="container-calification">
            <div className="row ">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body text-center">
                    <h3 className="mt-0">{this.props.normativityScore.normativity.name}</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4 mb-5">
                <div className="card">
                  <div className="card-body">
                    {this.state.data.map((accion, index) => (
                      <div className="row content-numeral-parent" key={accion.id}>
                        <div className="col-md-12">
                          <div className="row border-bottom-gray">

                            <div className="col-md-12 standar-title">
                              <b> Estándar de Seguridad del Paciente: </b> <span>   {accion.numeral_parent.patient_safety_standar}</span>
                            </div>

                          </div>
                        </div>

                        <div className="col-md-12 content-numeral-childs">

                          {accion.numeral_child_scores.map((son, index) => (



                            <div className="row content-numeral-child">
                              <div className="col-md-10 elemento">
                                <i
                                  id="PopoverLabels"
                                  onClick={() => this.setState({ showTask: true, idTask: son.id, register: son })}
                                  className="fas fa-thumbtack float-right icon-add icon-task-popover"
                                >

                                </i>
                                <b>Elemento medible (Requisito): </b> <p>{ReactHtmlParser(son.measurable_element_child)}</p>


                                <UncontrolledPopover isOpen={(this.state.showTask && this.state.idTask == son.id) ? true : false} placement="bottom" target="PopoverLabels">
                                  <PopoverHeader>Crear tarea <a className="close-popover" onClick={() => this.setState({ showTask: false, idTask: "", register: {} })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                  <PopoverBody>
                                    <TaskFormCreate
                                      numeral_child_score={son.module_task_id}
                                      loadTasks={this.loadData}
                                      users={this.props.users}
                                      closeTask={this.closeTask}
                                      url_source={this.url_source}
                                      task_source_id={this.props.task_source.id}
                                    />
                                  </PopoverBody>
                                </UncontrolledPopover>

                                {son.tasks.length >= 1 ? <hr /> : ""}
                                {son.tasks.length >= 1 ? <span className="task-title"></span> : ""}
                                {son.tasks.map((task, index) => (

                                  <TasksItems
                                    task={task}
                                    user={this.props.user}
                                    users={this.props.users}
                                    loadData={this.loadData}
                                    procesos={this.props.procesos}
                                  />
                                ))}
                              </div>

                              {true /* this.props.normativityScore.responsable_user_id == this.props.user.id */ && (
                                <div className="col-md-2 elemento">

                                  {this.state.id == son.id ? (
                                    <React.Fragment>

                                      {this.state.loadCalification == false && (
                                        <React.Fragment>
                                          <select
                                            name="calification"
                                            className="form form-control"
                                            onChange={this.onChangeUpdate}
                                            value={
                                              this.state.formUpdate.calification
                                            }
                                          >
                                            <option value="0">0</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="11">N/A</option>

                                          </select>

                                          <div className="col-md-12 mt-2 text-center">
                                            <i className="fas fa-check-circle true-btn mt-1 ml-2" onClick={() => this.HandleClickUpdate(son.id)}></i>
                                            <i className="fas fa-times-circle false-btn " onClick={() => this.setState({ id: "" })}></i>
                                          </div>
                                        </React.Fragment>
                                      )}

                                      {this.state.loadCalification && (
                                        <div className="loader-wrapper d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
                                          <div className="loader">
                                            <div className="ball-clip-rotate">
                                              <div style={{ backgroundColor: "rgb(68, 64, 84)" }}></div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    </React.Fragment>
                                  ) : (
                                    <p className="child-score"
                                      onClick={() =>
                                        this.updateValues(
                                          son.id,
                                          son.score
                                        )
                                      }
                                    >
                                      {son.score != null ? (son.score != 11 ? son.score : "N/A") : 0}
                                    </p>
                                  )}
                                </div>
                              )}

                            </div>



                          ))}


                        </div>
                        <div className="col-md-12">
                          <div className="row row-total">

                            <div className="col-md-10 total-title">
                              Total
                            </div>

                            <div className="col-md-2 patient-safety-score">

                              <span style={this.getColor(accion.score != null ? accion.score : 0)}> {accion.score != null ? (accion.score != 11 ? accion.score : "N/A") : 0}</span>


                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="row total-score">
                      <div className="col-md-12"><span className="title">Total</span> <span className="total-score-number" style={this.getColor(this.state.valueTotal)}>{this.state.valueTotal}</span></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12 text-center">
            <Preloader />
          </div>
        )}


      </React.Fragment>
    );
  }
}

export default Show;
