import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from '../../../GeneralComponents/FormCreate'
import FormCreateFiles from '../../../GeneralComponents/FormGeneralFile'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import IndexWeb from './TableIndexWeb'
import IndexMobile from './Mobile/Index'

class Table extends Component {
    constructor(props) {
        super(props)
        const date = new Date();
        this.fecha = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            idGeneralFile: "",
            modaFiles: false,

            ErrorValues: true,
            submitBtnFile: false,
            file: null,
            id: "",

            form: {
                name: "",
                description: "",
                type_normativity: this.props.type
            },

            formUpdate: {
                name: "",
                description: "",
                date_score: this.props.type == "NNOM" ? this.fecha : "",
                score: "",
            },

            formAddFiles: {
                modulo: "Normatividad",
                file: "",
                fileName: "",
                module_file_id: "",
            },

            dataFiles: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: "",
                type_normativity: this.props.type
            }
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    HandleClick = () => {
        if (this.validationForm() == true) {
            fetch("/health/normativity", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/health/normativity/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    //Update section

    edit = (rol) => {
        this.setState({
            id: rol.id,
            formUpdate: {
                name: rol.name,
                description: rol.description,
                date_score: this.props.type == "NNOM" ? this.fecha : "",
                score: this.props.type == "NNOM" ? rol.score : "",
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleClickUpdate = () => {
        fetch(`/health/normativity/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.clearValues();
                this.setState({ id: "" })
                this.messageSuccess(data);
            });
    }

    getNameTitle = (name) => {
        if (name == "PCH" || name == "NMH") {
            return "Hospitales"
        } else if (name == "PCCAP") {
            return "Modelo Ambulatorio"
        } else if (name == "PCISO9001") {
            return "ISO 9001:2015 Salud"
        } else if (name == "PCISO15189") {
            return "ISO 7101:2023 HQM"
        } else if (name == "PCHEM") {
            return "Acreditación"
        } else if (name == "NCON") {
            return "Servicios Auxiliares"
        } else if (name == "NFAR") {
            return "Expediente Clínico"
        } else if (name == "NNOM") {
            return "Marco Legal"
        }
    }

    getColor = (calification) => {
        if (calification < 6) {
            return "color-red"
        } else if (calification >= 6 && calification < 8) {
            return "color-orange"
        } else if (calification >= 8) {
            return "color-green"
        }
    }

    getScore = (value) => {


        if (value.length > 0 && value != undefined) {
            return <div> <span className={this.getColor(value[0].score)}> {(value[0].score * 10).toFixed(1)}%</span> <span>{value[0].date_score}</span></div>;

        }
        else {
            return <span className="no-data-small">Sin calificación</span>;
        }
    }

    //subir exel 

    uploadExel = (e) => {
        this.setState({
            file: e.target.files[0],
            submitBtnFile: (this.state.submitBtnFile == false ? true : false)
        });
    }

    handleClickUpload = () => {
        const formData = new FormData();
        formData.append("file", this.state.file)
        fetch(`/health/import_normativity/${this.props.type}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({ file: null, submitBtnFile: false })
                this.messageSuccess(data);
            });
    }

    //files 

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            }
        })
    }

    addFiles = (normativity) => {
        this.loadDataFiles(normativity.module_file_id);
        this.setState({
            formAddFiles: {
                modulo: "Normatividad",
                file: {},
                fileName: "",
                module_file_id: normativity.module_file_id,
            },

            idGeneralFile: normativity.module_file_id,
            modaFiles: true,
        })
    }


    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                [e.target.name]: e.target.value
            }
        });
    }

    toogleFiles = (from) => {
        if (from == "new") {
            this.setState({ modaFiles: true })
            this.clearValuesFiles();
        } else {
            this.setState({ modaFiles: false })
        }
    }

    loadDataFiles = (id) => {
        fetch(`/settings/get_general_files_normativity/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataFiles: data.data,
                    isLoadedFiles: false
                });
            });
    }

    handleFile = e => {
        this.setState({
            file: e.target.files[0]
        });
    };

    HandleClickFiles = () => {
        const formData = new FormData();
        formData.append("modulo", this.state.formAddFiles.modulo)
        formData.append("file", this.state.file)
        formData.append("fileName", this.state.formAddFiles.fileName)
        formData.append("module_file_id", this.state.formAddFiles.module_file_id)

        fetch(`/settings/general_files`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValuesFiles()
                this.setState({ file: null })
                this.loadDataFiles(this.state.formAddFiles.module_file_id)
                this.messageSuccess(data);
            });
    }

    updateId = () => {
        this.setState({
            id: ""
        })
    }



    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={`${this.getNameTitle(this.props.type)}`}
                    subTitle={`Gestión de normatividad para ${this.getNameTitle(this.props.type)}`}
                    titleNew={"Nuevo requisito"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}
                    title={"Agregar nuevo requisito"}

                    //form props
                    formValues={this.state.form}
                    errorValues={this.state.ErrorValues}
                    onChangeForm={this.HandleChange}
                    submitForm={this.HandleClick}
                />

                {/* FormCreateFiles */}

                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}
                        title={"Agregar Archivos"}
                        delete_file={true}
                        edit_file={true}
                        //form props
                        formValues={this.state.formAddFiles}
                        onChangeForm={this.HandleChangeFiles}
                        submitForm={this.HandleClickFiles}

                        //table files 

                        dataFiles={this.state.dataFiles}
                        handleFileChange={this.handleFile}

                        //load info

                        loadDataTable={this.test}
                        loadDataFiles={this.loadDataFiles}
                        idGeneralFile={this.state.idGeneralFile}
                        module_file_id={this.state.formAddFiles.module_file_id}
                    />
                )}

                {true && (
                    <div className="row">
                        <div className="col-md-12 text-left">
                            {this.props.estados.importFiles && (
                                <React.Fragment>
                                    <label
                                        htmlFor="fathers"
                                        className="btn-shadow btn btn-info mt-2 mr-2"
                                    >
                                        Cargar excel
                                    </label>

                                    <input
                                        type="file"
                                        id="fathers"
                                        onChange={(e) => this.uploadExel(e)}
                                        style={{ display: "none" }}
                                    />

                                    {this.state.submitBtnFile && (
                                        <button
                                            onClick={() => this.handleClickUpload()}
                                            className="btn-shadow btn btn-primary"
                                        >
                                            Subir <i className="fas fa-file-upload"></i>
                                        </button>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}

                {this.props.isLoaded == true ? (
                    <React.Fragment>
                        {isMobile ? (
                            <IndexMobile
                                data={this.props.data}
                                type={this.props.type}
                                getScore={this.getScore}
                                addFiles={this.addFiles}

                                //form update

                                id={this.state.id}
                                updateId={this.updateId}
                                HandleChangeUpdate={this.HandleChangeUpdate}
                                formUpdate={this.state.formUpdate}

                                edit={this.edit}
                                delete={this.delete}

                                HandleClickUpdate={this.HandleClickUpdate}
                                estados={this.props.estados}
                            />
                        ) : (
                            <IndexWeb
                                data={this.props.data}
                                type={this.props.type}
                                getScore={this.getScore}
                                addFiles={this.addFiles}

                                //form update

                                id={this.state.id}
                                updateId={this.updateId}
                                HandleChangeUpdate={this.HandleChangeUpdate}
                                formUpdate={this.state.formUpdate}

                                edit={this.edit}
                                delete={this.delete}

                                HandleClickUpdate={this.HandleClickUpdate}
                                estados={this.props.estados}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <div className="col-md-12 text-center">
                        <Preloader />
                    </div>
                )
                }

            </React.Fragment>
        );
    }
}

export default Table;

/*
                    <div className="card-header text-right">

                        <label
                            htmlFor="fathers"
                            className="btn-shadow btn btn-info mt-2 mr-2"
                        >
                            Cargar excel
                        </label>

                        <input
                            type="file"
                            id="fathers"
                            onChange={(e) => this.uploadExel(e)}
                            style={{ display: "none" }}
                        />

                        {this.state.submitBtnFile && (
                            <button
                                onClick={() => this.handleClickUpload()}
                                className="btn-shadow btn btn-primary"
                            >
                                Subir <i className="fas fa-file-upload"></i>
                            </button>
                        )}

                    </div>
*/