import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class NumeralsModal extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row numerals-modal-content">
                                   

   
                                       
                                                {this.props.dataNumerals.length >= 1 ? (
                                                    this.props.dataNumerals.map(numeral => (
                                                     
                                                        <div className="card col-md-12 mt-4" key={numeral.id}>
                                                            <div className="card-body">
                                                                {numeral.patient_safety_standar}
                                                            </div>
                                                        </div>
                                                  
                                                ))
                                                    ) : (
                                                        <div style={{width:"100%"}}>
                                                          
                                                                <div className="text-center mt-4 mb-4">
                                                                    <h4 >No hay Numerales para este proceso</h4>
                                                                </div>
                                                        
                                                        </div>
                                                    )}
                                               
                                       </div>


                            
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default NumeralsModal;
