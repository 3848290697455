import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CheckList from '../../taskCheckList/ChekList'

class FormCreateCkeckList extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: this.props.label.check_lists,

            formCreateChecklist: {
                name: "Checklist",
                task_label_id: this.props.label.id,
            },
        }
    }

    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    HandleClickCheckList = () => {
        fetch(`/task/check_lists`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formCreateChecklist), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateData(data.register);
            this.props.loadDataLabels();
        });
    }

    HandleChangeChecklist = (e) => {
        this.setState({
            formCreateChecklist: {
                ...this.state.formCreateChecklist,
                [e.target.name]: e.target.value,
            }
        });
    }

    updateItemChecklist = ckeck => {
        this.setState({
            data: this.state.data.map(item => {
            if (ckeck.id === item.id) {
              return { ...item, 
                name: ckeck.name, 
              }
            }
            return item;
          })
        });
    }

    loadCheckList = () => {
        fetch(`/task/get_check_list_labels/${this.props.label.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
            });
        });
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="card">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="card-body">
                                                <label>Nombre <small className="validate-label">*</small></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.state.formCreateChecklist.name}
                                                    onChange={this.HandleChangeChecklist}
                                                    className={`form form-control`}
                                                    placeholder="Nombre"
                                                />
                                            </div>

                                            <div className="card-footer">
                                                <button 
                                                    className="btn-shadow btn btn-info" 
                                                    onClick={() => this.HandleClickCheckList()}
                                                >
                                                    Añadir
                                                </button>
                                            </div>

                                        </form>
                                        
                                    </div>
                                </div>

                                {this.state.data.length >= 1 && (
                                    <CheckList
                                        dataChecklist={this.state.data}
                                        loadCheckList={this.loadCheckList}
                                        messageSuccess={this.messageSuccess}
                                        updateData={this.props.updateData}
                                        users={this.props.users}
                                        user={this.props.user}
                                        task={this.props.task}
                                        updateItemChecklist={this.updateItemChecklist}
                                        updateItem={this.props.updateItem}
                                        showFields={false}
                                    />
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateCkeckList;