import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import Loading from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            showCompany: false,
            showCategories: false,

            formCreate: {
                tenant_source_id: "",
                format_source_id: "",
                tenant_target_id: "",
                format_category_id: "",
            },

            selectedOptionCompany: {
                format_sub_category_id: "",
                label: "Empresas",
            },

            selectedOptionFormat: {
                format_source_id: "",
                label: "Formato",
            },

            selectedOptionTenant: {
                tenant_target_id: "",
                label: "Empresa a duplicar",
            },

            selectedOptionCategory: {
                format_category_id: "",
                label: "Categoría",
            },

            companies: [],
            formats: [],
            tenants: [],
            categories: [],
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    componentDidMount = () => {
        this.defaultValues();
    }

    defaultValues = () => {
        let array = []

        this.props.companies.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            companies: array,
        })
    }

    // select tenant 

    handleChangeAutocompleteFormat = selectedOptionFormat => {
        this.setState({
            showCompany: true,
            selectedOptionFormat,
            formCreate: {
                ...this.state.formCreate,
                format_source_id: selectedOptionFormat.value
            }
        });
    }

    handleChangeAutocompleteCategory = selectedOptionCategory => {
        this.setState({
            selectedOptionCategory,
            formCreate: {
                ...this.state.formCreate,
                format_category_id: selectedOptionCategory.value
            }
        });
    }

    handleChangeAutocompleteTenant = selectedOptionTenant => {
        fetch(`/formatos/get_format_category_tenant/${selectedOptionTenant.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = []

            data.data.map((item) => (
                array.push({ label: item.name, value: item.id })
            ))

            this.setState({
                showCategories: true,
                categories: array,
                selectedOptionTenant,
                formCreate: {
                    ...this.state.formCreate,
                    tenant_target_id: selectedOptionTenant.value
                }
            });
        });
    }

    handleChangeAutocompleteCompany = selectedOptionCompany => {
        fetch(`/formatos/get_formats_tenant/${selectedOptionCompany.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = []
            let arrayTenant = []

            data.tenants.map((item) => (
                arrayTenant.push({ label: item.company_name, value: item.id })
            ))

            data.data.map((item) => (
                array.push({ label: item.name, value: item.id })
            ))

            this.setState({
                formats: array,
                tenants: arrayTenant,
                selectedOptionCompany,
                formCreate: {
                    ...this.state.formCreate,
                    tenant_source_id: selectedOptionCompany.value
                },
            });
        });

    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/duplicate_forms/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.props.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            showCompany: false,
            showCategories: false,

            formCreate: {
                tenant_source_id: "",
                format_source_id: "",
                tenant_target_id: "",
                format_category_id: "",
            },

            selectedOptionCompany: {
                format_sub_category_id: "",
                label: "Empresas",
            },

            selectedOptionFormat: {
                format_source_id: "",
                label: "Formato",
            },

            selectedOptionTenant: {
                tenant_target_id: "",
                label: "Empresa a duplicar",
            },

            selectedOptionCategory: {
                format_category_id: "",
                label: "Categoría",
            },

            companies: [],
            formats: [],
            tenants: [],
            categories: [],
        })
    }

    HandleClick = () => {
        if (!this.state.modeEdit)
            fetch(`/formatos/duplicate_forms`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.messageSuccess(data);
                this.props.loadData();
                this.clearValues();
            });
        else {
            fetch(`/formatos/format_sub_categories/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.messageSuccess(data);
                this.clearValues();
            });
        }
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Duplicador de formatos`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo duplicado"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar duplicado" : "Crear duplicado"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreate}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}

                        showCompany={this.state.showCompany}
                        showCategories={this.state.showCategories}

                        //select company

                        handleChangeAutocompleteCompany={this.handleChangeAutocompleteCompany}
                        selectedOptionCompany={this.state.selectedOptionCompany}
                        companies={this.state.companies}

                        //select formats

                        handleChangeAutocompleteFormat={this.handleChangeAutocompleteFormat}
                        selectedOptionFormat={this.state.selectedOptionFormat}
                        formats={this.state.formats}

                        //select duplicate tenant
                        handleChangeAutocompleteTenant={this.handleChangeAutocompleteTenant}
                        selectedOptionTenant={this.state.selectedOptionTenant}
                        tenants={this.state.tenants}

                        //select categories

                        handleChangeAutocompleteCategory={this.handleChangeAutocompleteCategory}
                        selectedOptionCategory={this.state.selectedOptionCategory}
                        categories={this.state.categories}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card ls">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Empresa</th>
                                            <th>Formato</th>
                                            <th>Empresa a duplicar el formato</th>
                                            <th>Categoría a duplicar</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.length >= 1 ? (
                                            this.props.data.map(duplicate => (
                                                <tr key={duplicate.id}>
                                                    <td>{duplicate.tenant_source.name}</td>
                                                    <td>{duplicate.format_source.name}</td>
                                                    <td>{duplicate.tenant_target.name}</td>
                                                    <td>{duplicate.format_category.name}</td>

                                                    {(true || true) && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <button
                                                                                onClick={() => this.edit(duplicate)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                            </button>
                                                                        )}

                                                                        {true && (
                                                                            <button
                                                                                onClick={() => this.delete(duplicate.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </button>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;
