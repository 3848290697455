import React, { Component } from 'react';
import Select from "react-select";

class FormFilter extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="card mb-5">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-3">
                                    <label>Descripcion</label>
                                    <input 
                                        type="text"
                                        name="description"
                                        className="form form-control"
                                        onChange={this.props.handleChange}
                                        value={this.props.formValues.description}
                                    />
                                </div>
                            
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-primary  mr-3"
                                onClick={() => this.props.HandleClickFilter()}
                            >
                                Aplicar Filtro
                            </button>

                            <a
                                className="btn-shadow btn btn-light"
                                style={{ }}
                                onClick={() => this.props.handleClickCancel(false)}
                            >
                                Cancelar
                            </a>

                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;