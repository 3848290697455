import React from "react";
import Chart from "react-google-charts";


class ColumnChartQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();


    }
    componentDidMount() {
        console.log(this.chartReference); // returns a Chart.js instance reference
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={
                            this.props.values
                        } 
                        options={{
                            
                            hAxis: {
                                title: this.props.name,
                            }, vAxis: {
                                title: 'Votos',
                            },
                        }}
                       
                    />


                </div>
            </React.Fragment>
        );
    }
}

export default ColumnChartQuestion;
