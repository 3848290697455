import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import FormCreateCategories from './FormCreateCategories'
import FormCreateSubCategories from './SubModules/FormCreate'
import ModalShowCategory from '../FormatCategories/ModalShowCategory'
import Preloader from '../../../GeneralComponents/LoadingComponen'

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modalCategories: false,
            modalSubCategories: false,
            modalCategoriesShow: false,
            modeEdit: false,
            modeEditSubCategories: false,
            modeEditFormat: false,
            title: "",
            formato_id: "",
            format_category: {},
            category: {},
            format_sub_category_id: "",
            modulo_ids: [],
            created_format: false,

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },

            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                icon_id: "",
                position_ids: [],
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },

            checkedItems: new Map(),
            oldcheckedItems: new Map(),

            checkboxes: [],
            dataProcesosEdit: [],
            cargos: [],
            sub_categories: [],
            modulos: [],

            categories: [],
            format_sub_categories: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            created_format: false,
            modeEdit: false,
            modeEditFormat: false,
            modeEditSubCategories: false,
            modulo_ids: [],
            modulos: [],

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },

            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                position_ids: [],
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            checkedItems: new Map(),
            checkboxes: [],
            dataProcesosEdit: [],
            format_sub_categories: [],
        })
    }

    clearValuesSubCategory = () => {
        this.setState({
            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    subCategories = (from, category) => {
        if (from == "new") {
            this.setState({
                formCreateSubCategories: {
                    ...this.state.formCreateSubCategories,
                    format_category_id: category.id,
                },

                modalSubCategories: true,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        } else {
            this.clearValuesSubCategory();
            this.setState({
                modalSubCategories: false,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        }
    }

    toogleCategories = (from) => {
        if (from == "new") {
            this.setState({ modalCategories: true })
            this.clearValues();
        } else {
            this.setState({ modalCategories: false })
        }
    }

    toogleCategoriesShow = (from, format_category) => {
        if (from == "new") {
            this.setState({ modalCategoriesShow: true, format_category: format_category })
        } else {
            this.setState({ modalCategoriesShow: false, format_category: {} })
        }
    }

    componentDidMount = () => {
        this.defaultValues();
    }

    defaultValues = () => {
        let array = [];
        let arraySubCategory = [];
        let arrayCategories = [];

        this.props.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.format_sub_categories.map((item) => (
            arraySubCategory.push({ label: item.name, value: item.id })
        ))

        this.props.categories.map((item) => (
            arrayCategories.push({ label: item.name, format_sub_categories: item.format_sub_categories, value: item.id })
        ))
    
        this.setState({
            cargos: array,
            sub_categories: arraySubCategory,
            categories: arrayCategories,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteSubCategoryMulti = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        this.setState({
            selectedOptionSubCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_sub_category_id: selectedOptionSubCategory.value
            }
        });
    }


    validationForm = () => {
        if (this.state.form.name != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.state.modeEdit == false)
            fetch(`/formatos/format_categories`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modalCategories: false })
                    this.messageSuccess(data);
                    window.location.replace(`/formatos/format_categories`);
                });
        else {
            fetch(`/formatos/format_categories/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalCategories: false })
                    this.messageSuccess(data);
                    this.clearValues();
                    window.location.replace(`/formatos/format_categories`);
                });
        }
    }

    HandleClickSubCategory = () => {
        if (this.state.modeEditSubCategories == false)
            fetch(`/formatos/format_sub_categories`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_categories`);
                    this.clearValuesSubCategory();
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/formatos/format_sub_categories/${this.state.format_sub_category_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                    this.clearValuesSubCategory();
                    window.location.replace(`/formatos/format_categories`);
                });
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreateFormat: {
                ...this.state.formCreateFormat,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeCategory = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeSubCategory = (e) => {
        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                [e.target.name]: e.target.value
            }
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        window.location.replace(`/formatos/format_categories`);
                        this.messageSuccess(response)
                    });
            }
        });
    };

    //formatos 

    HandleClickFormat = () => {
        if (!this.state.modeEditFormat) {
            fetch(`/formatos/format_categories/${this.state.formCreateFormat.format_category_id}/formats`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_categories`);
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });

        } else {

            fetch(`/formatos/format_categories/${this.state.formCreateFormat.format_category_id}/formats/${this.state.formato_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    window.location.replace(`/formatos/format_categories`);
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }

    formatAdd = (category) => {
        this.setState({
            modal: true,
            modeEditFormat: false,
            title: "Añadir formato",
            checkedItems: new Map(),
            category: category,

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: category.id,
            }
        })
    }

    editForm = (formato, category) => {
        const arrayPermissions = formato.formats_permissions;
        const arrayNewPermissions = [];
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.formats_permissions_all.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })


        category.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            modal: true,
            created_format: true,
            modeEditFormat: true,
            category: category,
            title: "Editar formato",
            formato_id: formato.id,
            checkedItems: map,

            selectedOptionSubCategory: {
                format_sub_category_id: formato.format_sub_category != null ? formato.format_sub_category.id : "",
                label: `${formato.format_sub_category != null ? formato.format_sub_category.name : "Sub Categorías"}`
            },

            selectedOptionFormatCategory: {
                format_category_id: category.id,
                label: category.name,
            },

            formCreateFormat: {
                name: formato.name,
                formats_permission_ids: arrayNewPermissions,
                format_category_id: category.id,
            },

            format_sub_categories: array,
        })
    }

    edit = (config) => {
        let arrayPositonEdit = []

        config.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        this.setState({
            id: config.id,
            modeEdit: true,
            dataProcesosEdit: arrayPositonEdit,
            modalCategories: true,

            form: {
                name: config.name,
                icon_id: config.icon_id,
            }
        })
    }

    editSubCategory = (format_category, format_sub_category) => {
        let arrayPositonEdit = []
        let arrayIds = []

        format_sub_category.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            format_sub_category_id: format_sub_category.id,
            modeEditSubCategories: true,
            dataProcesosEdit: arrayPositonEdit,
            modalSubCategories: true,

            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: arrayIds,
                name: format_sub_category.name,
                order_number: format_sub_category.order_number,
                format_category_id: format_category.id,
            }
        })
    }

    deleteFormat = (category_id, id, formato) => {
        Swal.fire({
            title: 'Escribe el nombre del formato para poder eliminarlo',
            input: 'text',
            footer: `<p>El nombre del formato es (${formato.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == formato.name.trim()) {
                    fetch(`/formatos/format_categories/${category_id}/formats/${id}`, {
                        method: "delete",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.messageSuccess(response)
                            window.location.replace(`/formatos/format_categories`);
                        });
                } else {
                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    /*deleteFormat = (category_id, id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${category_id}/formats/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.messageSuccess(response)
                        window.location.replace(`/formatos/format_categories`);
                    });
            }
        });

            preConfirm: (login) => {
              return fetch(`/delete_agreement/${accion.id}/${login}`, {
                method: 'delete'
            })
                .then(response => {
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }
                  return response.json()
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `${error == "Internal Server Error" ? "" : "Error el nombre no concuerda"}`
                  )
                })
            },
    };

    */

    deleteSubCategory = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_sub_categories/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        window.location.replace(`/formatos/format_categories`);
                        this.messageSuccess(response)
                    });
            }
        });
    };

    handleChangeAccions = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormat.formats_permission_ids

        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAll = (e, modulo) => {
        const checkedItems = this.state.checkedItems;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_ids;
        const defaultValues = this.state.formCreateFormat.formats_permission_ids

        if (isChecked) {

            this.setState(
                {
   
                    modulo_ids: [...this.state.modulo_ids, modulo.id],
                    modulos: [...this.state.modulos.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems
        
                        }, function(){
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true){
                                    array_final.push(Number(key));
                                }
                              }
                              this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },
                
                                })
                        }
                    )
                });

        


        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos
            var indexModulos = 0
     
/* 
            this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                    newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                ))
            )) */

            this.setState(
                {
                    
                    modulo_ids: arr.filter(item => item != null),
                    modulos: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems
        
                        }, function(){
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true){
                                    array_final.push(key);
                                }
                              }
                              this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },
                
                                })
                        }
                    )

                });

        }
    };

    handleChangeAutocompleteFormatCategory = selectedOptionFormatCategory => {
        const array = [];

        selectedOptionFormatCategory.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))
    

        this.setState({
            selectedOptionFormatCategory,
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    format_category_id: selectedOptionFormatCategory.value
                },

                format_sub_categories: array,
        });
    }

    setIconForm = (icon_id) => {
        this.setState({
            form: {
                ...this.state.form,
                icon_id: icon_id,
            },
        })
    }


    render() {
        const styleIcons = {
            cursor: "pointer",
            color: "#3f69d8",
        }

        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Módulos`}
                    subTitle={"Crea y gestiona tus modulos y formatos"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogleCategories}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEditFormat ? "Actualizar formato" : "Añadir formato"}
                        nameBnt={this.state.modeEditFormat ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreateFormat}
                        submitForm={this.HandleClickFormat}
                        onChangeForm={this.HandleChange}
                        category={this.state.category}
                        modeEditFormat={this.state.modeEditFormat}

                        //ckeckcontainer

                        checkedItems={this.state.checkedItems}
                        handleChangeAccions={this.handleChangeAccions}
                        checkboxes={this.state.checkboxes}
                        modules={this.props.permissions}
                        handleChangeSelectAll={this.handleChangeSelectAll}
                        modulo_ids={this.state.modulo_ids}

                        //select format category
                        selectedOptionFormatCategory={this.state.selectedOptionFormatCategory}
                        handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCategory}
                        categories={this.state.categories}

                        //select sub categorias
                        handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                        selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                        format_sub_categories={this.state.format_sub_categories}
                    />
                )}

                {this.state.modalCategories && (
                    <FormCreateCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCategories}
                        toggle={this.toogleCategories}
                        title={this.state.modeEdit ? "Actualizar categoría" : "Crear Módulo"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChangeCategory}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                        icons={this.props.icons}
                        modeEdit={this.state.modeEdit}
                        setIconForm={this.setIconForm}
                    />
                )}

                {this.state.modalSubCategories && (
                    <FormCreateSubCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalSubCategories}
                        toggle={this.subCategories}
                        title={this.state.modeEditSubCategories ? "Actualizar Sub Módulo" : "Crear Sub Módulo"}

                        //form props
                        formValues={this.state.formCreateSubCategories}
                        submitForm={this.HandleClickSubCategory}
                        onChangeForm={this.HandleChangeSubCategory}
                        nameSubmit={this.state.modeEditSubCategories ? "Actualizar" : "Crear"}
                        modeEdit={this.state.modeEditSubCategories}
                        
                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocompleteSubCategoryMulti}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                    />
                )}

                {this.state.modalCategoriesShow && (
                    <ModalShowCategory
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCategoriesShow}
                        toggle={this.toogleCategoriesShow}
                        title={"información de la categoría"}
                        format_category={this.state.format_category}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="alert alert-warning"><b>¡ Información Importante ! </b> los formatos que estan en la columna Formatos son los que no pertenecen a ningun Sub Modulo, para ver los que pertenecen a un submodulo debes darle click al submodulo, esto lo hicimos con el fin de mejorar la experiencia.</div>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "15%" }}>Nombre</th>
                                            <th style={{ width: "23%" }}>Sub Módulo</th>
                                            <th>Formatos</th>
                                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                <th style={{ width: "8%" }}></th>
                                            )}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.length >= 1 ? (
                                            this.props.data.map(config => (
                                                <tr key={config.id}>
                                                    <td>

                                                        <p>{config.name}</p>
                                                    </td>

                                                    <td style={{ verticalAlign: "baseline" }}>
                                                        <p
                                                            className="link-line-dark"
                                                            onClick={() => this.subCategories("new", config)}
                                                        >
                                                            + Nuevo Sub Módulo
                                                        </p>
                                                        <hr />
                                                        <div>
                                                            {config.format_sub_categories.map(format_sub_category => (
                                                                <React.Fragment>
                                                                    <div className="gm-link-formatos">
                                                                        <a href={`/formatos/format_sub_categories/${format_sub_category.id}`} data-turbolinks="false" >{format_sub_category.name}</a> <i className="ml-2 fas fa-pencil-alt" style={styleIcons} onClick={() => this.editSubCategory(config, format_sub_category)}></i> <i className="fas fa-trash-alt" onClick={() => this.deleteSubCategory(format_sub_category.id)} style={styleIcons}></i>
                                                                    </div>
                                                                {/*     <ul>
                                                                        {format_sub_category.formatos.map(formato => (
                                                                            <li>{formato.name}</li>
                                                                        ))}
                                                                    </ul> */}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <p
                                                            className="link-line-dark"
                                                            onClick={() => this.formatAdd(config)}
                                                        >
                                                            + Nuevo formato
                                                        </p>
                                                        <hr />
                                                        <div className="col-md-12 pl-0 pb-2">
                                                            {config.formats.map((format, index) => (
                                                                <React.Fragment>
                                                                    <div className="gm-link-formatos">
                                                                        <a
                                                                            href={(this.props.estados.is_admin || format.gestionar) ? `/formatos/format_categories/${config.id}/formats/${format.id}` : ""}
                                                                            className="link-line mr-2"
                                                                            data-turbolinks="false"
                                                                        >
                                                                            {format.name} ({format.questions_count})
                                                                        </a>

                                                                        {(this.props.estados.is_admin || format.editar) && (
                                                                            <i
                                                                                className="fas fa-pencil-alt mr-1"
                                                                                style={styleIcons}
                                                                                onClick={() => this.editForm(format, config)}
                                                                            >
                                                                            </i>
                                                                        )}

                                                                        {format.eliminar && (
                                                                            <i className="fas fa-trash-alt mr-4" onClick={() => this.deleteFormat(config.id, format.id, format)} style={styleIcons}></i>
                                                                        )}

                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>

                                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                <div className="btn-group" role="group">
                                                                    <button
                                                                        className="btn-shadow btn btn-info"
                                                                        id="btnGroupDrop1"
                                                                        type="button"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-bars"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <button
                                                                                onClick={() => this.toogleCategoriesShow("new", config)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Ver mas información
                                                                                    </button>
                                                                        )}


                                                                        {this.props.estados.editar && (
                                                                            <button
                                                                                onClick={() => this.edit(config)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                                </button>
                                                                        )}

                                                                        {this.props.estados.eliminar && (
                                                                            <button
                                                                                onClick={() => this.delete(config.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                                </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    )}

            </React.Fragment>
        );
    }
}

export default Index;