import { Map, GoogleApiWrapper ,Marker} from 'google-maps-react';
import React, { Component } from 'react';

const mapStyles = {
  width: '95%',
  height: '100%',
};

export class MapContainer extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          stores: this.props.markers,
          hola:"dfdas"
        }
      }


    displayMarkers = () => {
        return this.state.stores.map((store, index) => {
          return <Marker key={index} id={index} position={{
           lat: store.lat,
           lng: store.lng
         }}
         onClick={() => console.log("You clicked me!")} />
         {this.displayMarkers()}
        })
      }

    
  render() {
    return (
        <Map
        google={this.props.google}
        zoom={15}
        style={mapStyles}
        initialCenter={this.state.stores[0]}
     
      >
            {this.displayMarkers()}
        
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCID5cH4nPd5GYJ981eS3pIb6PIHNDn5FU'
})(MapContainer);