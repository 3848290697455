import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from "../../../GeneralComponents/LoadingComponen"
import { isMobile } from 'react-device-detect';
import { SwatchesPicker } from 'react-color';

class IndexLabels extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modeEdit: "",
            id: "",
            ErrorValues: true,
            isLoaded: false,

            formValues: {
                name: "",
                color: "",
                task_id: "",
            },

            formFilter: {
                name: "",
            },

            dataLabels: this.props.dataLabels,
            colorLabel: [],
        }
    }

    loadLabels = () =>{
        fetch(`/task/get_label_tasks`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataLabels: data.data,
            isLoaded: false,
          });
        });
    }

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeFilter = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        });
    }

    clearValues = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                name: "",
                color: "",
            },

            modeEdit: "",
            id: "",
            ErrorValues: true,
        });
    }

    validationForm = () => {
        if (this.state.formValues.name != "" &&  
            this.state.formValues.color != ""
            ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    componentDidMount(){

    }

    addColor = (labelColor) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                color: labelColor.color
            }
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClick = () => {
        if(this.validationForm() == true){
            this.setState({ isLoaded: true })
            if(this.state.modeEdit == "new")
                fetch(`/task/task_labels`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modeEdit: "" })
                    this.loadLabels();
                });
            else{
                fetch(`/task/task_labels/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modeEdit: "", id: "" })
                    this.props.loadDataTaskShow(this.props.task.id);
                    this.loadLabels();
                });
            }
        }
    }

    updateValues = () => {
        this.setState({
            modeEdit: "new",
            formValues: {
                ...this.state.formValues,
                name: "",
                color: "",
            }
        })
    }

    editLabel = (label) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                name: label.name,
                color: label.color,
            },

            modeEdit: "edit",
            id: label.id
        })
    }

    updateState = (label, state) => {
        fetch(`/task/label_update_state/${label.id}/${this.props.task.id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            console.log(data)
            if(this.props.updateItem != undefined){
                this.props.updateItem(data.task);
            }
            this.props.updateDataLabels(data.task.task_labels)
            this.props.loadCheckList()
            this.setState({ modeEdit: "", id: "" })
        });
    };


    delete = id => {
        this.setState({ isLoaded: true })
        fetch(`/task/task_labels/${id}`, {
            method: 'delete', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadDataTaskShow(this.props.task.id);
            this.loadLabels();
            this.setState({ modeEdit: "", id: "" })
        });
    };

    handleChangeComplete = (color) => {
        this.setState({ 
            formValues: {
                ...this.state.formValues,
                color: color.hex 
            }
        });
    };

    cancelFilter = () => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                name: ""
            }
        })
    }

    getIcon = (label) => {
        /*<i className="fas fa-check icon-show" style={{ position: "absolute",  right: "11px", top: "6px" }}></i> */
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoaded ?(
                    <div className="col-md-12 text-center" style={{ width: "314px", height: "143px"}}>
                        <Preloader />
                        <p>Cargando información...</p>
                    </div>
                ):(
                    <React.Fragment>
                        {(this.state.modeEdit == "new" || this.state.modeEdit == "edit" )  ?(
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12" style={{ width: isMobile ? "" : "289px" }}>
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.state.formValues.name}
                                            onChange={this.HandleChange}
                                            className={`form form-control ${this.state.ErrorValues == false && this.state.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
        
                                        <hr/>
                                    </div>
        
                                    <div className="col-md-12 mb-4">
                                        <SwatchesPicker
                                            className="w-100"
                                            color={ this.state.formValues.color }
                                            onChangeComplete={ this.handleChangeComplete }
                                        />
                                    </div>
        
                                    <div className="col-md-12 text-center">
                                        <a
                                            className="btn-shadow btn btn-success mr-3"
                                            style={{ color: "#ffff"}}
                                            onClick={() => this.HandleClick()}
                                        >
                                            {this.state.modeEdit == "new" ? "Agregar" : "Actualizar"} 
                                        </a>
        
                                        <a
                                            className="btn-shadow btn btn-danger"
                                            style={{ color: "#ffff"}}
                                            onClick={() => this.clearValues()}
                                        >
                                            Cancelar 
                                        </a>
                                    </div>
                                </div>
                            </React.Fragment>
                            
                        ) : (
        
                            <React.Fragment>
                                <div className="mb-2 display-flex">
                                    <input 
                                        type="text" 
                                        name="name" 
                                        value={this.state.formFilter.name}
                                        onChange={this.HandleChangeFilter}
                                        autoComplete="off"
                                        className={`form form-control ${this.state.formFilter.name.length >= 1 ? "width-filter" : ""}`} 
                                    />
                                    
                                    {this.state.formFilter.name.length >= 1 && (
                                        <i class="fas fa-times cancel-filter" onClick={() => this.cancelFilter()}></i>
                                    )}
                                </div>
                                <div className="scroll-area-lg scroll-labels">
                                    <div className="scrollbar-container mr-3">
        
                                        {this.state.dataLabels.filter(value => value.name.toLowerCase().includes(this.state.formFilter.name.toLowerCase())).map((label, index) => (
                                            <div className={`row mb-2`} key={label.id}>
                                                <div className={`col-10`} style={{ width: isMobile ? "" : "232px" }}>
                                                    <span 
                                                        className={`badge badge-secondary text-left ${index >= 0 ? "mr-2" : ""}`}
                                                        style={{ cursor: "pointer", background: label.color, width: "100%", height: "31px" }} 
                                                        onClick={() => this.updateState(label, (label.state == true ? false : true) )}
                                                    >
                                                        {label.name}
                                                        {this.getIcon(label)}
                                                    </span>
                                                </div>

                                                <div className={`col-2 ${isMobile ? "" : "pl-0"}`}>
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Button group with nested dropdown"
                                                    >
                                                        <div className="btn-group" role="group">
                                                            <button
                                                                className="btn-shadow btn btn-primary"
                                                                id="btnGroupDrop1"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fas fa-bars"></i>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {true && (
                                                                    <button
                                                                        onClick={() => this.editLabel(label)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                )}
                
                                                                {true && (  
                                                                    <button
                                                                        onClick={() => this.delete(label.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                         ))}
                                    </div>
                                </div>
        
                                <div className="col-md-12 mt-2 p-0">
                                    <button className="btn-shadow btn btn-light btn-block" style={{ color: "#1ba8fa"}} onClick={() => this.updateValues()}>
                                        Nuevo label
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default IndexLabels;
{/*

*/}