import React, { Component } from 'react';

class Index extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.length >= 1 ? (
                    this.props.data.map((risk_matrix, index) => (
                        <div className={`card mb-3`} key={risk_matrix.id}>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-3 mb-2">
                                        <b>Proceso</b>
                                        <p>{risk_matrix.proceso.name}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Fecha de análisis</b>
                                        <p>{risk_matrix.date_of_analysis}</p>
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <b>Responsable</b>
                                        <p>{risk_matrix.responsable.first_name} {risk_matrix.responsable.first_last_name}</p>
                                    </div>

                                </div>
                            </div>

                            {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                <div className="card-footer">

                                    {this.props.estados.editar && (
                                        <button
                                            onClick={() => this.props.edit(risk_matrix)}
                                            className="dropdown-item"
                                        >
                                            Editar
                                        </button>
                                    )}

                                    {this.props.estados.eliminar && (  
                                        <button
                                            onClick={() => this.props.delete(risk_matrix.id)}
                                            className="dropdown-item"
                                        >
                                            Eliminar
                                        </button>
                                    )}

                                    {this.props.estados.gestionar && (
                                        <a
                                            href={`/risk/matrices/${risk_matrix.id}/risks`}
                                            data-turbolinks="false"
                                            className="dropdown-item"
                                        >
                                            Gestionar
                                        </a>
                                    )}

                                </div>
                            )}

                        </div>
                    ))

                ) : (
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>No hay registros</h4>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;