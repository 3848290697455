import React, { Component } from 'react';
import Index from '../components/formatos/FormatIndicators/IndexIndicator'  
import WebpackerReact from 'webpacker-react';

class FormatIndicatorIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            dataQuestion: [],
            dataAnswers: [],
            dataAnswersIndicator:[],
            surveys:[],
            isLoaded: true,
            paramas:{},
            filters:{},
            tableWidth: 0,

            activePage: 1,
            countPage: 30,
            survey_total: 0,

            activePage_filter: 1,
            countPage_filter: 30,
            survey_total_filter: 0,
            formUpdateRepy:{},
            isFilter:false
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }
    
    cleanFilters=()=>{
        this.setState({
            filters: {}
        })
    }

    loadData = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let view = urlParams.get('view')
        fetch(`/formatos/get_surveys_index_indicators/${this.props.formato.id}?view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            dataQuestion: data.dataQuestion,
            dataAnswers: data.dataAnswers,
            tableWidth: data.tableWidth,
            isLoaded: false,
            survey_total: data.survey_total,
            filters: data.filters,
            isFilter:false,
            surveys: data.surveys,
            activePage: 1,
            dataAnswersIndicator: data.dataAnswersIndicator
          });
        });
    }
    handlePageChange = (pageNumber) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let view = urlParams.get('view')
        this.setState({ activePage: pageNumber });
        fetch(`/formatos/get_surveys_index/${this.props.formato.id}?page=${pageNumber}&filter=${this.state.countPage}&view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            dataQuestion: data.dataQuestion,
            dataAnswers: data.dataAnswers,
            tableWidth: data.tableWidth,
            isLoaded: false,
            survey_total: data.survey_total,
            filters: data.filters,
            isFilter:false,
            surveys: data.surveys,
           
          });
        });
    }


    loadDataFilter = (data) => {
        console.log("estoy en el data filter:",  data)
        this.setState({
            formUpdateRepy: data
        })
        console.log("Object keys:",Object.keys(data))
        console.log("Object keys:",data[Object.keys(data)[0]])
        let query = Object.keys(data)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
             .join('&');

        fetch(`/formatos/get_surveys_index_indicators_filter/${this.props.formato.id}/?${query}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            dataQuestion: data.dataQuestion,
            dataAnswers: data.dataAnswers,
            tableWidth: data.tableWidth,
            isLoaded: false,
            filters: data.filters,
            survey_total_filter: data.survey_total,
            isFilter:true,
            activePage_filter: 1,
            dataAnswersIndicator: data.dataAnswersIndicator
          });
        });
    }


    handlePageChange_filter = (pageNumber) => {
        var data = this.state.formUpdateRepy
        let query = Object.keys(data)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
             .join('&');
        this.setState({ activePage_filter: pageNumber });
        fetch(`/formatos/get_surveys_index_filter/${this.props.formato.id}?${query}&page=${pageNumber}&filter=${this.state.countPage}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            dataQuestion: data.dataQuestion,
            dataAnswers: data.dataAnswers,
            tableWidth: data.tableWidth,
            isLoaded: false,
            filters: data.filters,
            isFilter:true
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Index
                    updateStateLoad={this.updateStateLoad}
                    data={this.state.data}
                    dataQuestionnvm={this.state.dataQuestion}
                    dataQdataAnswersnvm={this.state.dataAnswers}
                    dataAnswersIndicator={this.state.dataAnswersIndicator}
                    surveys={this.state.surveys}
                    format={this.props.formato}
                    updateData={this.updateData}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    positions={this.props.positions}
                    loadDataFilter={this.loadDataFilter}
                    filters={this.state.filters}
                    format_category={this.props.format_category}
                    tableWidth={this.state.tableWidth}
                    
                    domain={this.props.domain}

                    survey_total={this.state.survey_total}
                    countPage={this.state.countPage}
                    handlePageChange={this.handlePageChange}
                    activePage={this.state.activePage}

                    survey_total_filter={this.state.survey_total_filter}
                    countPage_filter={this.state.countPage_filter}
                    handlePageChange_filter={this.handlePageChange_filter}
                    activePage_filter={this.state.activePage_filter}

                    isFilter={this.state.isFilter}
                    cleanFilters={this.cleanFilters}
                    estados={this.props.estados}
                    format_exels={this.props.format_exels}
                    view={this.props.view}
                    format_indicators={this.props.format_indicators}
                />
            </React.Fragment>
        );
    }
}


export default FormatIndicatorIndex;
WebpackerReact.setup({ FormatIndicatorIndex });

