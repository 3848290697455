import React, { Component } from 'react';
import HeaderTitle from "./HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from '../components/formatos/Formats/FormCreate'
import IndexImportFile from '../components/settings/ImportFile/Index';

class RiskConfigIndex extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            form: {
                name: ""
            },

            formUpdate: {
                name: ""
            }
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            form: {
                name: ""
            },

            formUpdate: {
                name: ""
            }
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.name != ""
            ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch(`${this.props.urlCreate}`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateData(data.register)
                this.setState({ modal: false })
                this.messageSuccess(data);
            });
        }
    }

    HandleClickUpdate = () => {
        fetch(`${this.props.urlUpdate}${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.clearValues();
            this.props.updateItem(data.register)
        });
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                name: e.target.value
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                name: e.target.value
            }
        })
    }

    edit = (config) => {
        this.setState({
            id: config.id,

            formUpdate: {
                name: config.name
            }
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`${this.props.urlDelete}${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.headerTitleName}`}
                    subTitle={"Configuración de riesgos"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Nuevo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="row">
                        <div className="col-md-10">
                            <p className="mt-3 pl-4 mb-0" >{this.props.data.length} Registros</p>    
                        </div>

                        {this.props.estados.import && (
                            <div className="col-md-2 mt-3">
                                <IndexImportFile
                                    urlUploadFile={"/risk/import_activities_file"}
                                    urlDowloadTemplate={"/risk/download_activities_template.xls"}
                                    updateData={this.props.updateNewData}
                                />
                            </div>
                        )}
                    </div>

                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                        <th style={{ width: "8%" }}></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(config => (
                                        <tr key={config.id}>
                                            <td>
                                                {config.id == this.state.id ? (
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={this.state.formUpdate.name}
                                                        onChange={this.HandleChangeUpdate}
                                                        className="form form-control"
                                                    />
                                                ) : (
                                                    <p>{config.name}</p>
                                                )}
                                            </td>
                                            {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    {this.state.id != "" ? (
                                                        <React.Fragment>
                                                            {config.id == this.state.id &&(
                                                                <React.Fragment>
                                                                    <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                    <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                                </React.Fragment>
                                                            )} 
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button
                                                                    className="btn-shadow btn btn-info"
                                                                    id="btnGroupDrop1"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="fas fa-bars"></i>
                                                                </button>
                                
                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                    {this.props.estados.gestionar && (  
                                                                        <a
                                                                            href={`configuration/${config.id}/${this.props.headerTitleName == "Criterios" ? "criterios" : "actividad"}`}
                                                                            data-turbolinks="false"
                                                                            target="_blank"
                                                                            className="dropdown-item"
                                                                        >
                                                                            Matriz de Riesgos
                                                                        </a>
                                                                    )}

                                                                    {this.props.estados.editar && (
                                                                        <button
                                                                            onClick={() => this.edit(config)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </button>
                                                                    )}
                                
                                                                    {this.props.estados.eliminar && (  
                                                                        <button
                                                                            onClick={() => this.delete(config.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default RiskConfigIndex;