import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Table from '../components/health/Normativities/Table'

class NormativitiesIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    loadData = () => {
        fetch(`/health/get_normativities/${this.props.typeNormativity}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: true
          });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    data={this.state.data}
                    procesos={this.props.procesos}
                    estados={this.props.estados}
                    type={this.props.typeNormativity}
                />
            </React.Fragment>
        );
    }
}

export default NormativitiesIndex;
WebpackerReact.setup({ NormativitiesIndex });

/*
                {this.state.isLoaded == true ? (

                  ) : (

                  )
                }
*/