import React, { Component } from 'react';
import FormUpdate from './FormUpdate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import IndexAttached from '../../tasks/tasksAttached/IndexAttached';
import IndexImportFile from '../../settings/ImportFile/Index';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import is_imagen from 'is-image'

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEditFile: false,
            showPopoverFile: false,
            editComment: false,
            id: "",
            nameFile: "",
            form: {
                company_name: "",
                nit: "",
                logo: {},
            },

            file: {}
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                company_name: this.props.data.company_name,
                nit: this.props.data.nit,
                logo: {},
            },
            nameFile: "",
            file: {}
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    handleFile = archivo => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    logo: file
                },

                nameFile: file.path,
                file: archivo,
            })
        ));
    };

    HandleClick = () => {
        const formData = new FormData();
        formData.append("company_name", this.state.form.company_name)
        formData.append("nit", this.state.form.nit)
        formData.append("logo", this.state.form.logo)

        fetch(`/settings/tenants/${this.props.user}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.clearValues()
                this.messageSuccess(data);
                this.setState({ modal: false })
                window.location.replace(`/settings/tenants/${this.props.data.id}`);
            });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //files

    closePopovers = () => {
        this.setState({
            showPopoverFile: false,
            editComment: false,
            id: ""
        })
    }

    deleteFiles = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/general_files/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData();
                    });
            }
        });
    };

    removeLogo = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/remove_img`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.messageSuccess(response)
                        this.props.loadData();
                        window.location.replace(`/settings/tenants/${this.props.data.id}`);
                    });
            }
        });
    };


    render() {
        return (
            <React.Fragment>
                <FormUpdate
                    //modal props
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}
                    title={"Actualizar Registro"}

                    //form props
                    formValues={this.state.form}
                    onChangeForm={this.HandleChange}
                    submitForm={this.HandleClick}

                    //file
                    handleFile={this.handleFile}
                    nameFile={this.state.nameFile}
                    file={this.state.file}
                    tenant={this.props.tenant}
                />

                <div className="row">

                    <div className="col-md-4">
                        <div className="card tenant-info tenant-info-name__container">
                            {(this.props.data.logo != undefined && this.props.data.logo.url != null) && (
                                <div><p><i style={{ fontSize: "20px !important" }} className="far fa-times-circle icon-remove-img" onClick={() => this.removeLogo()}></i></p> </div>
                            )}
                            <div className="tenant-info-img ui">

                                <img className="tenant-img" src={this.props.data.logo != undefined ? this.props.data.logo.url : "http://karinlifoods.com/wp-content/uploads/2017/09/imagen-no-disponible.jpg"} alt="" />
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <b>Nombre de la empresa</b>
                                        <p>{this.props.data.company_name}</p>
                                    </div>

                                    {this.props.tenant.app_type == "Salud" && (
                                        <div className="col-md-12">
                                            <b>Licencia Sanitaria</b>
                                            <p>{this.props.data.nit}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {this.props.estados.editar && (
                                <div className="card-footer">
                                    <button className="btn-shadow btn btn-primary ui" onClick={() => this.toogle("new")}>
                                        Actualizar <i className="far fa-edit"></i>
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="col-md-4">
                        <div className="card tenant-info">

                            <div className="tenant-info-img">
                                <span>
                                    <i className="tenant-icon fas fa-users"></i>
                                </span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="info users">{this.props.data.users}</h4>
                                        <h5 className="tenant-subtitle">Usuarios Activos</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="col-md-4">
                        <div className="card tenant-info">

                            <div className="tenant-info-img">
                                <span>
                                <i className="tenant-icon far fa-file-alt"></i>
                                </span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="info users">{this.props.data.formats}</h4>
                                        <h5 className="tenant-subtitle">Formatos</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Card 4 */}
                    <div className="col-md-4 card-register-count">
                        <div className="card tenant-info">
                            <div className="tenant-info-img">
                                <span>
                                    <i class="tenant-icon fas fa-file-signature"></i>
                                </span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="info formats">{this.props.data.records_count}</h4>
                                        <h5 className="tenant-subtitle">Registros</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* Table */}
                    <div className="col-md-8">
                        <div className="card card-ui card-ui-tenant">

                            {true && (
                                <div className="card-header">
                                    {this.props.estados.inportFile && (
                                        <IndexImportFile
                                            urlUploadFile={"/settings/import_general_files"}
                                            urlDowloadTemplate={"/risk/download_activities_template.xls"}
                                            updateData={this.props.loadData}
                                        />
                                    )}

                                    <button
                                        id="PopoverLegacy"
                                        type="button"
                                        className="btn-shadow btn btn-primary ml-3"
                                        onClick={() => this.setState({ showPopoverFile: true, modeEditFile: false, editComment: false })}
                                    >

                                        Adjuntar documento <i className="fas fa-paperclip ml-2"></i>
                                    </button>

                                    <UncontrolledPopover isOpen={this.state.showPopoverFile} placement="bottom" target="PopoverLegacy">
                                        <PopoverHeader>{"Adjuntar archivo"} <a className="close-popover" onClick={() => this.setState({ showPopoverFile: false, modeEditFile: false })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                        <PopoverBody>
                                            <IndexAttached
                                                task={this.props.tenant}
                                                loadFiles={this.props.loadData}
                                                updateItem={undefined}
                                                editFileState={this.state.modeEditFile}
                                                moduleName={"Tenant"}
                                                valuesFiles={this.state.modeEditFile == false ? {} : {}}
                                                closePopovers={this.closePopovers}
                                                updateDataFiles={this.props.loadData}
                                            />
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            )}

                            <div className="card-body">

                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Tipo</th>
                                            <th>Adjuntos</th>

                                            {this.props.estados.documents && (
                                                <th style={{ width: "19%" }} className="text-center">Acciones</th>
                                            )}

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.general_files != undefined && (
                                            <React.Fragment>
                                                {this.props.data.general_files.length >= 1 ? (
                                                    this.props.data.general_files.map(file => (
                                                        <tr key={file.id}>
                                                            <td>{file.fileName}</td>
                                                            <td>{is_imagen(file.file.url) ? "Imagen" : "Documento"}</td>
                                                            <td>
                                                                {file.type_file == "url" ? (
                                                                    <a href={file.url} target="_blank">Ver archivo </a>
                                                                ) : (
                                                                        <React.Fragment>
                                                                            {file.file.url != null && (
                                                                                <a className="link-line" target="_blank" href={file.file.url}>
                                                                                    {is_imagen(file.file.url) ? "Ver Archivo" : "Descargar Archivo"} <i className="fas fa-file-download"></i>
                                                                                </a>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                            </td>

                                                            {this.props.estados.documents && (
                                                                <td className="text-right">
                                                                    {true && (
                                                                        <button
                                                                            id={`PopoverLegacy${file.id}`}
                                                                            className="btn"
                                                                            onClick={() => this.setState({ modeEditFile: true, editComment: true, id: file.id, showPopoverFile: false })}
                                                                        >
                                                                            <i class="fas fa-edit"></i>
                                                                        </button>
                                                                    )}

                                                                    <UncontrolledPopover isOpen={(this.state.editComment == true && this.state.id == file.id) ? true : false} placement="bottom" target={`PopoverLegacy${file.id}`}>
                                                                        <PopoverHeader>{"Editar Archivo"} <a className="close-popover" onClick={() => this.setState({ modeEditFile: false, editComment: false, id: "" })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                        <PopoverBody>
                                                                            <IndexAttached
                                                                                task={this.props.tenant}
                                                                                loadFiles={this.props.loadData}
                                                                                moduleName={"Tenant"}
                                                                                editFileState={this.state.modeEditFile}
                                                                                valuesFiles={this.state.modeEditFile == true ? file : {}}
                                                                                closePopovers={this.closePopovers}
                                                                                updateDataFiles={this.props.loadData}
                                                                            />
                                                                        </PopoverBody>
                                                                    </UncontrolledPopover>


                                                                    {true && (
                                                                        <button
                                                                            onClick={() => this.deleteFiles(file.id)}
                                                                            className="btn"
                                                                        >
                                                                            <i className="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    )}

                                                                </td>
                                                            )}


                                                        </tr>
                                                    ))
                                                ) : (
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <div className="text-center mt-4 mb-4">
                                                                    <h4>No hay Archivos</h4>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Show;
