import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

const Index = (props) => {
    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                <ModalBody>
                    <div className="col-md-12">
                        <Dropzone 
                            onDrop={(files) => props.handleFile(files)}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div
                                    {...getRootProps({
                                        className: 'dropzone',
                                    })}
                                >
                                <input {...getInputProps()} />
                                    <p>{props.nameFile}</p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <button 
                        className="btn btn-light" 
                        onClick={() => props.toggle()}
                        disabled={props.isLoaded}
                    >
                        Cancelar
                    </button>

                    <button 
                        className="btn-shadow btn btn-info" 
                        onClick={() => props.uploadFile()}
                        disabled={props.isLoaded}
                    >
                        {props.isLoaded ? "Subiendo archivo..." : "Subir archivo"}
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default Index;

