import React from "react";

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };

  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 form-checks" style={{ marginBottom: "10px" }}>
            <div className="form-checks-descriptions"></div>
            <div className="form-checks-titles">Ver registros propios</div>
            <div className="form-checks-titles">Ver todos los registros</div>
            <div className="form-checks-titles">Eliminar regitros</div>
            <div className="form-checks-titles">Editar registros</div>

            <div className="form-checks-titles">Gestionar formato</div>
            <div className="form-checks-titles">Editar formato</div>
            <div className="form-checks-titles">Eliminar formato</div>
            <div className="form-checks-titles">Todos</div>
            
            
          </div>
          {this.props.modules.map(modulex => (
            <div className="col-md-12 form-checks" style={{ marginBottom: "10px" }}>
              <h5 className="form-checks-descriptions" key={modulex.id}>{modulex.name} {/*<input type="checkbox" className="float-right" onChange={(e) => this.props.handleChangeAll(e, this.props.modules)}/>*/}</h5>

              {modulex.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item, index) => (
                <React.Fragment>
                  <div className="form-checks-titles">
                    <label key={item.id}>
                      <input
                        style={{ marginRight: "10px" }}
                        type="checkbox"
                        value={item.id}
                        checked={this.props.checkedItems.get(item.id.toString())}
                        onChange={(e) => this.props.handleChangeAccions(e)}
                      />
                    </label>
                  </div>

                  {modulex.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").length == index + 1 && (
                    <div className="form-checks-titles">
                        <label>
                          <input
                            style={{ marginRight: "10px" }}
                            type="checkbox"
                            checked={this.props.modulo_ids.includes(modulex.id)}
                            onChange={(e) => this.props.handleChangeSelectAll(e, modulex)}
                          />
                        </label>
                    </div>
                  )}

                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default CheckboxContainer;
