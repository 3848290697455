import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LineChartIndicator from "../../../GeneralComponents/Charts/LineChartIndicator"
import LineChartIndicatorMonth from "../../../GeneralComponents/Charts/LineChartIndicatorMonth"
import LayoutGerencialVentas from '../indicator/layouts/layoutGerencialVentas'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import FormCreate from '../../tasks/tasksCard/FormCreate'
import TaskList from '../../tasks/tasksCard/TaskList'
import GeneralComments from '../../../GeneralComponents/ChatBoxModuleComment'


class ShowVentas extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formValues: {
                start_month: new Date().getMonth()
            },

            formUpdate: {
                real: 0,
                target: 0,
            },

            showForm: false,

            idIndex: 260,
            id: "",
            postion: 0,
            dataLine: [],
            dataLineMonth: [],
            typeLayout: "false",
            typeShow: "Day",
            formMeses: {
                enero: 0,
                febrero: 0,
                marzo: 0,
                abril: 0,
                mayo: 0,
                junio: 0,
                julio: 0,
                agosto: 0,
                septiembre: 0,
                octubre: 0,
                noviembre: 0,
                diciembre: 0,

            },

            updateMonthState: false,

            //states task

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            users: [],

            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.indicator_all.module_task_id,
                name_module: "Indicadores",
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: e.target.value

            },
        });
        this.props.loadData();
    }


    HandleChangeMonth = (e) => {
        this.setState({
            formMeses: {
                ...this.state.formMeses,
                [e.target.name]: e.target.value

            },
        });

    }

    updateMonthTarget = () => {
        let data = { data: this.state.formMeses }
        fetch(`/indicator/update_month_target/${this.props.data.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({
                    updateMonthState: false
                })                /*               this.messageSuccess(data); */
            });
    }



    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps && nextProps.dataNew.length > 0) {
            this.setState({
                typeLayout: "true",
                formMeses: {
                    enero: nextProps.dataNew[2].data_indicators[0].target,
                    febrero: nextProps.dataNew[2].data_indicators[1].target,
                    marzo: nextProps.dataNew[2].data_indicators[2].target,
                    abril: nextProps.dataNew[2].data_indicators[3].target,
                    mayo: nextProps.dataNew[2].data_indicators[4].target,
                    junio: nextProps.dataNew[2].data_indicators[5].target,
                    julio: nextProps.dataNew[2].data_indicators[6].target,
                    agosto: nextProps.dataNew[2].data_indicators[7].target,
                    septiembre: nextProps.dataNew[2].data_indicators[8].target,
                    octubre: nextProps.dataNew[2].data_indicators[9].target,
                    noviembre: nextProps.dataNew[2].data_indicators[10].target,
                    diciembre: nextProps.dataNew[2].data_indicators[11].target,



                }
            })
        }

    }

    changeTypeSHow = (value) => {
        this.setState({
            typeShow: value
        })
    }

    componentDidMount = () => {

        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array
        })
    }

    showUpdateMonth = () => {
        this.setState({
            updateMonthState: true
        })
    }

    showUpdateMonthCancel = () => {
        this.setState({
            updateMonthState: false
        })
    }

    getMonthText = (fecha) => {
        let months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
        return months[fecha]
    }

    //tasks 


    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                user_owner_id: selectedOption.value
            }
        });
    };

    clearValues = () => {
        this.setState({
            showForm: false,
            form: {
                ...this.state.form,
                name: "",
                due_date: "",
                user_owner_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
        })
    }

    HandleChangeTask = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }


    render() {
        const value = this.state.formValues.start_month;
        const estados = {
            gestionar: true,
            eliminar: true,
        }

        return (
            <React.Fragment>

                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/indicator/category_indicators/${this.props.category_indicator.id}/indicators?type=${this.props.indicator_all.indicator_type}`}
                                data-turbolinks="false"
                            >
                                Indicadores
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);" >
                                Indicador de {this.props.data.name}
                            </a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-12">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5> INDICADOR DE {this.props.data.name} <span style={{ color: "#3e68d8", fontWeight: "bold" }}>{this.state.typeShow == "Day" ? this.getMonthText(this.state.formValues.start_month) : ""}</span></h5>
                                                <p>{this.props.data.description}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <p className="m-0"><b>Integra</b></p>
                                                        <p className="m-0">{this.props.position[0]}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p className="m-0"><b>Analiza</b></p>
                                                        <p className="m-0">{this.props.position[1]}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p className="m-0"><b>Aprueba</b></p>
                                                        <p className="m-0">{this.props.position[2]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12"><hr className="mt-1" /></div>
                                            <div className="col-md-9 mb-2">
                                                <button className={this.state.typeShow == "Day" ? "btn btn-primary" : "btn btn-light"} style={{ marginRight: "10px" }} onClick={() => this.changeTypeSHow("Day")}>Diario</button>
                                                <button className={this.state.typeShow == "Month" ? "btn btn-primary" : "btn btn-light"} onClick={() => this.changeTypeSHow("Month")}>Mensual</button>
                                            </div>
                                            {this.state.typeShow == "Day" && (
                                                <div className="col-md-3 text-right">

                                                    <select
                                                        name="start_month"
                                                        value={this.state.formValues.start_month}
                                                        onChange={this.HandleChange}
                                                        className="form form-control"
                                                    >
                                                        <option value="">Seleccione un mes</option>
                                                        <option value="0">Enero</option>
                                                        <option value="1">Febrero</option>
                                                        <option value="2">Marzo</option>
                                                        <option value="3">Abril</option>
                                                        <option value="4">Mayo</option>
                                                        <option value="5">Junio</option>
                                                        <option value="6">Julio</option>
                                                        <option value="7">Agosto</option>
                                                        <option value="8">Septiembre</option>
                                                        <option value="9">Octubre</option>
                                                        <option value="10">Noviembre</option>
                                                        <option value="11">Diciembre</option>
                                                    </select>
                                                </div>)}

                                            <div className="col-md-12 mt-1">
                                                <a className="link-line" href="javascript:void(0);" onClick={this.showUpdateMonth}>Actualizar {this.props.user.app_type == "Mejora" ? "Target" : "Presupuesto"} mensual</a>

                                            </div>
                                            {this.state.updateMonthState == true && (
                                                <div className="col-md-12 mt-2">
                                                    <div className="inputs-months">
                                                        <div className="form-group">
                                                            <label htmlFor="">Enero</label>
                                                            <input
                                                                name="enero"
                                                                value={this.state.formMeses.enero}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Febrero</label>
                                                            <input
                                                                name="febrero"
                                                                value={this.state.formMeses.febrero}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Marzo</label>
                                                            <input
                                                                name="marzo"
                                                                value={this.state.formMeses.marzo}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Abril</label>
                                                            <input
                                                                name="abril"
                                                                value={this.state.formMeses.abril}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Mayo</label>
                                                            <input
                                                                name="mayo"
                                                                value={this.state.formMeses.mayo}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />

                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Junio</label>
                                                            <input
                                                                name="junio"
                                                                value={this.state.formMeses.junio}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="inputs-months">
                                                        <div className="form-group">
                                                            <label htmlFor="">Julio</label>
                                                            <input
                                                                name="julio"
                                                                value={this.state.formMeses.julio}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Agosto</label>
                                                            <input
                                                                name="agosto"
                                                                value={this.state.formMeses.agosto}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Septiembre</label>
                                                            <input
                                                                name="septiembre"
                                                                value={this.state.formMeses.septiembre}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Octubre</label>
                                                            <input
                                                                name="octubre"
                                                                value={this.state.formMeses.octubre}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Noviembre</label>
                                                            <input
                                                                name="noviembre"
                                                                value={this.state.formMeses.noviembre}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Diciembre</label>
                                                            <input
                                                                name="diciembre"
                                                                value={this.state.formMeses.diciembre}
                                                                onChange={this.HandleChangeMonth}
                                                                className="form form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={this.updateMonthTarget}>Actualizar</button>
                                                    <button className="btn btn-light" onClick={this.showUpdateMonthCancel}>Cancelar</button>

                                                </div>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {(this.props.dataNew != undefined && this.props.dataNew.length > 0) ? (

                        <div className="col-md-12">
                            {console.log(this.props.dataNew)}
                            <LayoutGerencialVentas data={this.props.dataNew} typeShow={this.state.typeShow} indicator={this.props.data} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />

                        </div>
                    ) : (
                            <div></div>
                        )}

                </div>
                <hr />
                <div className="row mt-4 mb-4">

                    <div className="col-md-5">
                        {/* 510px 772px */}
                        <div className="card card-list" style={{ height: "772px" }}>
                            <div className="card-header">
                                <div className="col-md-12 pl-0">
                                    Tareas
                                    {true ? <React.Fragment> {this.state.showForm == false ? <i className="fas fa-plus float-right icon-add" onClick={() => this.setState({ showForm: true })}></i> : <i className="fas fa-times float-right icon-add" onClick={() => this.setState({ showForm: false })}></i>} </React.Fragment> : ""}
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="scroll-area-lg scroll-tasks">
                                    <div className="scrollbar-container">
                                        {this.state.showForm == true && (
                                            <FormCreate
                                                HandleChange={this.HandleChangeTask}
                                                form={this.state.form}
                                                clearValues={this.clearValues}
                                                loadData={this.props.loadDataTask}
                                                updateStateLoad={this.props.updateStateLoad}

                                                //select autocomplete
                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                selectedOption={this.state.selectedOption}
                                                users={this.state.users}

                                                updateData={this.props.updateData}
                                            />
                                        )}

                                        {this.props.isLoadedTask ? (
                                            <div className="col-md-12 text-center mt-4">
                                                <Preloader />
                                            </div>
                                        ) : (
                                                <React.Fragment>
                                                    {this.props.dataTask.length >= 1 ? (
                                                        this.props.dataTask.map(task => (
                                                            <TaskList
                                                                task={task}
                                                                user={this.props.user}
                                                                users={this.props.users}
                                                                showOptions={true}
                                                                loadData={this.props.loadDataTask}
                                                                estados={estados}
                                                                updateStateLoad={this.props.updateStateLoadTask}
                                                                updateItem={this.props.updateItem}
                                                                procesos={this.props.procesos}
                                                            />
                                                        ))
                                                    ) : (
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h6>No hay tareas</h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                </React.Fragment>
                                            )}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-7">
                        <GeneralComments
                            usuario={this.props.user}
                            module_name={"Indicadores"}
                            users={this.state.users}
                            module_comment={this.props.indicator_all.module_comment_id}
                            url={this.props.url}
                        />
                    </div>

                </div>

            </React.Fragment >
        );
    }
}

export default ShowVentas;
