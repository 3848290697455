import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            errorForm: false,
            form: {
                survey_id: "",
            }
        }
    }

    HandleClick = () => {
        fetch(`/task/update_survey_task/${this.props.task.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.type == "success"){
                this.props.messageSuccess(data);
                this.props.updateItem(data.task)
                this.props.closePopoversTaskSurvey();
                this.clearValues();
            }else{
                this.setState({ errorForm: true })
            }
        });
    }

    clearValues = () => {
        this.setState({
            errorForm: false,
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{ width: isMobile ? "" : "289px" }}>
                        <input
                            type="number"
                            className="form form-control"
                            name="survey_id"
                            value={this.state.form.survey_id}
                            onChange={this.HandleChange}
                            placeholder={"Añadir identificador de registro"}
                        />
                    </div>
                    
                    {this.state.errorForm && (
                        <div className="col-md-12 mt-4">
                            <div className="alert alert-danger" role="alert">
                                <b>Identificador incorrecto, ingresa uno diferente</b>
                            </div>
                        </div>
                    )}

                    <div className="col-md-12 text-center mt-3">
                        <button
                            className="btn-shadow btn btn-light btn-block mr-3"
                            style={{ color: "#1ba8fa" }}
                            onClick={() => this.HandleClick()}
                        >
                            Asociar
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
