import React, { Component } from 'react';
import Dropzone from "react-dropzone";
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import { Progress } from 'reactstrap';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import loading from '../../../GeneralComponents/LoadingComponen'
import LoadingComponen from '../../../GeneralComponents/LoadingComponen';
import CustomAutocomplete from '../../../GeneralComponents/CustomAutocomplete';
import FileTypeFormat from './FileTypeFormat'
import NumberFormat from 'react-number-format';
import Ckeditor from '../../formatos/Surveys/Ckeditor'
import ModalSubForm from './ModalSubForm'
import SignaturePad from 'react-signature-canvas'
import TimeField from "react-simple-timefield";
import FroalaEditor from 'react-froala-wysiwyg';


class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.sigPad = {}
        this.state = {
            sigPad: [],
            survey_answer_id: "",
            isLoaded: true,
            isSaved: true,
            modal: false,
            errorValues: true,
            countProgress: 0,
            nameFile: "",
            nexStep: 0,
            prevStep: 1,
            maxStep: 0,
            loading: false,
            loadingNextStep: false,
            completed: false,
            countPageValidation: 0,
            countPageInputs: 0,
            formulaError: false,
            dataAnswerSubForms: {},

            formUpdateRepy: {
                answer_string: "",
                answer_text: "",
                answer_date: "",
                answer_datetime: "",
                answer_file: "",
                answer_select: "",
                answer_radio: "",
                answer_checkbox: [],
                answer_user_id: "",
                answer_integer: "",
            },

            geolocalizacion: {
                Latitude: "",
                Longitude: "",
            },

            formFormat: {
                name: "",
                question_id: "",
            },

            formUpdateRepy1: {

            },

            formatValues: {

            },

            selectedOptionUser: {
                answer_user_id: "",
                label: "Selección de usuario",
            },

            selectCustomAutocomplete: {
                name: "",
                label: "Buscar de registro",
            },


            formTest: {
                name: "",
                data: [],
            },

            trimmedDataURL: null,
            surveySubform: {},
            dataSubForms: [],
            formatSubForm: "",
            users: [],
            options: [],
            cargos: [],
            procesos: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    updateState = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Estas preguntas serán finalizadas!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/formats/${this.props.format.id}/update_state/${this.props.survey.id}`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.updateRepy()

                        window.location.replace(`/formatos/congratulations_quiz`);

                    });
            }
        });
    };

    componentWillMount = () => {
        this.usersSelect();
    }

    getGeolocalizacion = (question) => {
        let app = this
        if (app.props.survey.state_complete != "saved") {
            console.log("entre aqui a las geo code")
            navigator.geolocation.getCurrentPosition(function (position) {
                app.setState({
                    formUpdateRepy1: {
                        ...app.state.formUpdateRepy1,
                        [question.id]: { value: [`${position.coords.latitude},${position.coords.longitude}`], type: question.question_type, step: app.state.formUpdateRepy1[question.id].step, is_mandatory: question.id.is_mandatory, is_active: question.id_active }
                    }
                });
            });
        }
    }

    usersSelect = () => {
        let array = [];

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array,
        })
    }

    componentDidMount = () => {
        let array = [];
        let arrayCargos = [];
        let arrayProcesos = [];



        this.props.users.map((item) => (
            array.push({ name: item.name, value: item.id })
        ))

        this.props.positions.map((item) => (
            arrayCargos.push({ name: item.name, value: item.id })
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({ name: item.name, value: item.id })
        ))

        this.setState({
            options: array,
            cargos: arrayCargos,
            procesos: arrayProcesos,
        })
    }

    componentWillReceiveProps = (nexProps) => {
        this.loadValues(nexProps);

        this.setState({
            maxStep: nexProps.data.length,
            formUpdateRepy1: nexProps.dataAnswer,
        })


        nexProps.data_all.filter(value => value.question_type == "answer_signature").map((item) => {
            console.log("jejejesiseñores")
            this.setState({
                sigPad: {
                    ...this.state.sigPad,
                    [item.id]: {}
                }
            })

        })



    }


    updateBackValue = (variable) => {
        this.setState({ nexStep: this.state.nexStep - 1, errorValues: true })
    }



    HandleChangeRepy = (e) => {
        const { target } = e;

        console.log("------------------------ haasdasd")
        console.log(e.target.value)

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);
        });
    }

    HandleChangeCkaditor = (e, question) => {
        let val = []
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [e], type: question.question_type, step: this.state.formUpdateRepy1[question.id].step, is_mandatory: question.is_mandatory, is_active: question.is_active }
            }
        })
    }

    HandleChangeRepyNumber = (e) => {
        const { target } = e;
        const value = e.target.value.replace("$", "").replace(",", "").replace(",", "").replace(",", "").replace(",", "")

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);

        });
    }


    changevalue = (target) => {

        if (target.dataset.type == "answer_integer" || target.dataset.type == "answer_radio" || target.dataset.type == "answer_select") {
            //this.hasFormule(target.value);
        }
    }


    //CALCULO DE FORMULA
    hasFormule = (value) => {


        try {
            //p ES EL OBEJTO QUE TENDRA LOS VALORES DE LA FORMULA
            var p = {}
            var result;
            var step;


            //answer_formulas ES EL OBEJTO QUE TENDRA EL VALOR FINAL DEL CAMPO TIPO FORMULA
            var answer_formulas = {};

            //SE RECORREN TODAS LAS PREGUNTAS QUE SON DEL TIPO FORMULA
            this.props.data_all.filter(valuee => valuee.question_type == "answer_formula").map((data, index) => {
                var answer_name = data.id;
                var xxx = data.step_position;
                var yyy = this.state.nexStep;
                // SE LLAMA LA FUNCTION QUE EXTRAE LAS PREGUNTAS DE LAS QUE DEPENTE LA FORMULA LA FORMULA 
                let values = this.hasValues(data.answer_formula);

                values.forEach((item) => {
                    // SE SEPARA LA SECCION Y LA POSISION DE LA PREGUNTA EJE: {p.S1_P1} => S1_P1
                    var y = item.substring(2, item.length);
                    // SE CREA UN ARRAY EN LA PRIMERA POSISION ESTA LA SECCION Y EN LA SEGUNA LA PREGUNTA => [S1, P1]
                    var value_split = y.split("_")
                    // SE ADQUIERE LA SECCION
                    var step_v = value_split[0];
                    // SE ADQUIERE LA SECCION
                    var answer_v = value_split[1];
                    // SE ADQUIERE LA SECCION SE ADQUIERE EL NUMERO DE LA SECCION S1 => 1
                    var xs = step_v.substring(1, item.length);
                    // SE ADQUIERE LA SECCION SE ADQUIERE EL NUMERO DE LA PREGUNTA P1 => 1
                    var x = answer_v.substring(1, item.length);

                    // SE ADQUIERE LA PREGUNTA QUE NECESITA LA FORMULA
                    var vale = this.props.data_all.filter(value => value.position == x && value.step_position == (xs - 1));
                    // SE ADQUIERE EL ID DE LA PREGUNTA
                    var answer_name_in = vale[0].id;
                    // SE VERIFICA SI LA FORMULA DEPENTE DE OTRA FORMULA PARA QUE TOME EL VALOR ACTUAL Y NO EL DE EL ESTADO
                    var has_formula = answer_formulas[answer_name_in]
                    if (has_formula != undefined) {
                        p[y] = answer_formulas[answer_name_in].value[0];
                    }
                    else {
                        p[y] = parseInt(this.state.formUpdateRepy1[answer_name_in].value[0]);
                    }
                    // SE ADQUIERE EL STEP DE LA PREGUNTA
                    step = parseInt(this.state.formUpdateRepy1[data.id].step);

                })
                // SE LE QUITAN LAS LLAVES A LOS VALORES
                let formula = data.answer_formula.replaceAll('{', '').replaceAll('}', '')
                // SE EVELUA LA FORMULA
                result = eval(formula)
                // SI EL RESULTADO ES NAN SE LE PASA UN CERO
                result = isNaN(result) ? 0 : result;
                // SE LE PASA AL OBJETO DE LAS FORMULAS EL NUEVO VALOR
                answer_formulas[answer_name] = { value: [Math.result], type: "answer_formula", step: step, is_mandatory: data.is_mandatory, is_active: data.is_active };
                /*    this.setState(state => ({
                       formUpdateRepy1: {
                           ...this.state.formUpdateRepy1,
                           [2242 + index + 1]: { value: [result], type: "answer_formula", step: step, is_mandatory: false, is_active: data.is_active }
                       }
                   })); */

            })

            // SE ACTUALIZAN LOS ESTADOS DE LAS FORMULAS
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    ...answer_formulas,
                }
            });



            return answer_formulas
        } catch (e) {
            console.log("hay un error un una formula");
            this.setState({
                formulaError: true
            })
        }
    }


    hasValues = (data) => {
        //Tu Texto
        //String match
        let data_array = [];
        data.match(/\{([.\w\s]*)\}/g)
            .forEach((item) => {
                //console.log( item );
                //Puedes limpiar el texto con:
                data_array.push(item.replace(/[\{\}]/g, ''));
            });
        return data_array
    }


    HandleChangeRepyRadio = (e, id) => {
        const { target } = e;
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value, ""], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            },
        }, () => {
            this.changevalue(target);

        });
    }


    HandleChangeRepyRadioInput = (e, question) => {
        const { target } = e;
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [this.state.formUpdateRepy1[question.id].value[0], e.target.value], type: question.question_type, step: this.state.formUpdateRepy1[question.id].step, is_mandatory: question.is_mandatory, is_active: question.is_active }
            },
        }, () => {
            this.changevalue(target);

        });
    }



    HandleChangeHour = (value) => {
        const showSecond = true;
        const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: value.format(str), question_type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        })
    }



    HandleChangeRepyArray = (e, question) => {
        let array = this.state.formUpdateRepy1[e.target.dataset.question].value[0];
        const value = e.target.value != "on" ? e.target.value : ""

        if (e.target.checked) {
            array.push(value)

            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [e.target.dataset.question]: { value: [array], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
                },


            })

        } else {


            this.setState({

                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [e.target.dataset.question]: { value: [array.filter(item => item != e.target.value)], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
                },
            })

        }
    }

    HandleChangeFile = (archivo, id, is_mandatory, is_active, question) => {
        question = question.id;
        archivo.map(file => (
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: { value: [file], type: "answer_file", step: this.state.formUpdateRepy1[question].step, is_mandatory: is_mandatory, is_active: is_active }
                },

                survey_answer_id: id,
                nameFile: file.path
            })
        ));
/*         this.updateRepy()
 */    }

    setValueSelect = (e, survey_answer) => {
        const { target } = e;
        var name = survey_answer.id
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [name]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[name].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);

        })
        /* 
                setTimeout(() => {
                    this.updateRepy()
                }, 1000); */
    }



    getFormatsValues = (datas) => {
        fetch(`/formatos/get_formats_values/${datas}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [datas]: data
                    }
                })
            });
    }

    loadValues = (nexProps) => {

        nexProps.data.map((form, index) => {
            form.survey_answers.filter(value => value.question_type == "format_id").map((question, index) => {
                this.getFormatsValues(question.id)
            })
        })
    }






    getBntUpdate = (question) => {
        return (
            <React.Fragment>
                {this.state.survey_answer_id == question.id && (
                    <div className="col-md-12 pr-0 mt-3">


                        <a
                            className="btn-shadow btn btn-info mr-2"
                            onClick={() => this.updateRepy()}
                            style={{ color: "#ffff" }}
                        >
                            Gurdar
                        </a>
                        <a
                            className="btn-shadow btn btn-danger "
                            onClick={() => this.cancelUpdate()}
                            style={{ color: "#ffff" }}
                        >
                            <i className="fas fa-times"></i>
                        </a>
                    </div>
                )}
            </React.Fragment>
        )
    }


    getValidation = (question) => {
        if (question.question_type == "text" && question.answer_string != null) {
            return true
        }
    }



    getValidationNew = (question) => {
        if (question.question_type == "text" && question.answer_string != null) {
            return true
        } else if (question.question_type == "textarea" && question.answer_text != null) {
            return true
        } else if (question.question_type == "date" && question.answer_date != null) {
            return true
        } else if (question.question_type == "time" && question.answer_datetime != null) {
            return true
        } else if (question.question_type == "file" && question.answer_file.url != null) {
            return true
        } else if (question.question_type == "select" && question.answer_select != null) {
            return true
        } else if (question.question_type == "radio" && question.answer_radio != null) {
            return true
        } else if (question.question_type == "checkbox" && question.answer_radio != null) {
            return true
        } else if (question.question_type == "select_user" && question.answer_user_id != null) {
            return true
        } else if (question.question_type == "number" && question.answer_integer != null) {
            return true
        } else {
            return false
        }
    }

    redirectSurvey = () => {

        if (this.props.user_signed) {
            if (this.props.survey.tenant_id == this.props.survey.tenant_user_id) {
                if (this.props.survey.have_indicator) {
                    window.location.replace(`/indicator/category_indicators/${this.props.survey.indicator.category_indicator_id}/indicators/${this.props.survey.indicator.id}`);
                } else if (this.props.survey.have_survey_tab && this.props.type == "parent") {
                    window.location.replace(`/formatos/format_categories/${this.props.survey.survey_tab.format_categories_id}/formats/${this.props.survey.survey_tab.format_id}/gestion/${this.props.survey.survey_tab.id}`);
                } else if (this.props.survey.have_survey_document) {
                    window.location.replace(`/document_management/documents/${this.props.survey.document_id}`);
                } else {
                    window.location.replace(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys`);
                }
            } else {
                window.location.replace(`/formatos/congratulations_quiz?format=${this.props.format.id}`);
            }
        } else {
            window.location.replace(`/formatos/congratulations_quiz?format=${this.props.format.id}`);
        }
    }

    // UPDATE FORMULARIO 
    updateValues = () => {

        //var formula = this.hasFormule("");
        let state = true;
        this.setState({
            errorValues: false,

            /*  formUpdateRepy1: {
                 ...this.state.formUpdateRepy1,
                 ...formula,
             } */

        })
        Object.keys(this.state.formUpdateRepy1).forEach(key => {
            console.log(this.state.formUpdateRepy1[key].value[0])
            console.log(this.state.formUpdateRepy1[key].value[0] === "")
            console.log(this.state.formUpdateRepy1[key].value[0] == undefined)
            console.log(this.state.formUpdateRepy1[key].value[0] == null)

            if (this.state.formUpdateRepy1[key].step == this.state.nexStep && (this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true) && (this.state.formUpdateRepy1[key].value[0] === "" || this.state.formUpdateRepy1[key].value[0] == null || this.state.formUpdateRepy1[key].value[0].length == 0 || (this.state.formUpdateRepy1[key].type == "answer_file" ? this.state.formUpdateRepy1[key].value[0].name == undefined : false))) {
                state = false
            }
        });

        if (state) {

            const formData = new FormData();


            let dataArray = [];

            if (this.props.data.filter(value => value.is_private == true).length > 0) {

                var step_data = this.props.data[this.state.nexStep];

                Object.keys(this.state.formUpdateRepy1).forEach(key => {


                    var rt = this.state.formUpdateRepy1;
                    var v1 = this.state.formUpdateRepy1[key].step;
                    var v2 = this.state.nexStep;

                    if (this.state.formUpdateRepy1[key].step == step_data.step_position) {
                        dataArray.push(this.state.formUpdateRepy1[key].type)

                    }

                });
            } else {
                Object.keys(this.state.formUpdateRepy1).forEach(key => {


                    var rt = this.state.formUpdateRepy1;
                    var v1 = this.state.formUpdateRepy1[key].step;
                    var v2 = this.state.nexStep;

                    if (this.state.formUpdateRepy1[key].step == this.state.nexStep) {
                        dataArray.push(this.state.formUpdateRepy1[key].type)

                    }

                });
            }


            if (this.props.data.filter(value => value.is_private == true).length > 0) {

                var step_data_1 = this.props.data[this.state.nexStep];

                Object.keys(this.state.formUpdateRepy1).forEach(key => {
                    if (this.state.formUpdateRepy1[key].step == step_data_1.step_position) {
                        if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                            let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                            let final_array = []

                            if (array.length >= 1) {
                                array.map((value) => {
                                    final_array.push(value.value)
                                })
                            } else {
                                console.log("lkeseasdasd")
                                final_array.push(array.value)
                            }


                            formData.append(key, final_array);
                        } else {
                            var test = this.state.formUpdateRepy1[key];

                            if (this.state.formUpdateRepy1[key].type.includes("answer_text")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            } else if (this.state.formUpdateRepy1[key].type.includes("answer_radio")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else {
                                formData.append(key, this.state.formUpdateRepy1[key].value[0]);

                            }

                        }
                    }
                });
            } else {
                Object.keys(this.state.formUpdateRepy1).forEach(key => {
                    if (this.state.formUpdateRepy1[key].step == this.state.nexStep) {
                        if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                            let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                            let final_array = []

                            if (array.length >= 1) {
                                array.map((value) => {
                                    final_array.push(value.value)
                                })
                            } else {
                                console.log("lkeseasdasd")
                                final_array.push(array.value)
                            }


                            formData.append(key, final_array);
                        } else {
                            var test = this.state.formUpdateRepy1[key];

                            if (this.state.formUpdateRepy1[key].type.includes("answer_text")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else if (this.state.formUpdateRepy1[key].type.includes("answer_radio")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else {
                                console.log(key, this.state.formUpdateRepy1[key].value[0])
                                formData.append(key, this.state.formUpdateRepy1[key].value[0]);

                            }
                        }
                    }
                });
            }

            let lastStep = 0;
            if ((this.state.nexStep + 1) == this.state.maxStep) {
                this.setState({
                    loading: true
                })
                lastStep = 1;
            }
            else {
                this.setState({
                    loadingNextStep: true
                })
            }

            console.log("Primer parametro")
            console.log(this.props.survey.id)
            console.log("Segundo parametro")
            console.log(dataArray)
            console.log("Tercer parametro")
            console.log(lastStep)

            console.log(formData)
            fetch(`/formatos/update_ansewers/${this.props.survey.id}/${dataArray}/${lastStep}/${this.props.data[this.state.nexStep].id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    if ((this.state.nexStep + 1) == this.state.maxStep) {
                        this.redirectSurvey()
                    } else {
                        window.scrollTo(0, 0)
                        this.setState((prevState, props) => ({
                            errorValues: true,
                            nexStep: prevState.nexStep + 1,
                            loading: false,
                            loadingNextStep: false,
                            completed: false
                        }));
                    }
                });


        } else {
            this.setState({
                completed: true
            })
        }


    }

    redirectSurvey = () => {
        if (this.props.user_signed) {
            if (this.props.survey.tenant_id == this.props.survey.tenant_user_id) {
                if (this.props.survey.have_indicator) {
                    window.location.replace(`/indicator/category_indicators/${this.props.survey.indicator.category_indicator_id}/indicators/${this.props.survey.indicator.id}`);
                } else if (this.props.survey.have_survey_tab && this.props.type == "parent") {
                    window.location.replace(`/formatos/format_categories/${this.props.survey.survey_tab.format_categories_id}/formats/${this.props.survey.survey_tab.format_id}/gestion/${this.props.survey.survey_tab.id}`);
                } else if (this.props.survey.have_survey_document) {
                    window.location.replace(`/document_management/documents/${this.props.survey.document_id}`);
                } else {
                    window.location.replace(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=ACTIVE`);
                }
            } else {
                window.location.replace(`/formatos/congratulations_quiz?format=${this.props.format.id}`);
            }
        } else {
            window.location.replace(`/formatos/congratulations_quiz?format=${this.props.format.id}`);
        }
    }


    updateRepy = () => {
        if (this.props.survey.state == false && this.state.survey_answer_id != "") {
            const formData = new FormData();
            formData.append("answer_string", this.state.formUpdateRepy.answer_string != null ? this.state.formUpdateRepy.answer_string : "");
            formData.append("answer_text", this.state.formUpdateRepy.answer_text != null ? this.state.formUpdateRepy.answer_text : "");
            formData.append("answer_date", this.state.formUpdateRepy.answer_date != null ? this.state.formUpdateRepy.answer_date : "");
            formData.append("answer_datetime", this.state.formUpdateRepy.answer_datetime != null ? this.state.formUpdateRepy.answer_datetime : "");
            formData.append("answer_file", this.state.formUpdateRepy.answer_file != null ? this.state.formUpdateRepy.answer_file : "");
            formData.append("answer_select", this.state.formUpdateRepy.answer_select != null ? this.state.formUpdateRepy.answer_select : "");
            formData.append("answer_radio", this.state.formUpdateRepy.answer_radio != null ? this.state.formUpdateRepy.answer_radio : "");
            formData.append("answer_user_id", this.state.formUpdateRepy.answer_user_id != null ? this.state.formUpdateRepy.answer_user_id : "");
            formData.append("answer_checkbox", this.state.formUpdateRepy.answer_checkbox != null ? this.state.formUpdateRepy.answer_checkbox : "");
            formData.append("answer_integer", this.state.formUpdateRepy.answer_integer != null ? this.state.formUpdateRepy.answer_integer : "");


            formData.append("state_finished", true)



            fetch(`/formatos/survey_answers/${this.state.survey_answer_id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {

                    this.updateNexValue(undefined, "loadData");
                    this.setState({
                        formUpdateRepy: {
                            ...this.state.formUpdateRepy,
                            answer_checkbox: [],
                        },
                        isLoaded: false,
                        errorValues: true,
                    })
                });
        }
    }

    dateTime = (fecha) => {
        var d = new Date(fecha)
        return d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());
    }


    handleChangeAutocompleteUser = (selectedOptionUser, id) => {
        this.setState({
            selectedOptionUser,
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.id]: { value: [selectedOptionUser.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.id].step }
            },
        });
    }


    getDateTime = (datetime) => {
        let time = new Date(datetime)
        let result = time.getFullYear() + "-" + (time.getMonth() < 10 ? `0${time.getMonth()}` : time.getMonth()) + "-" + (time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()) + "T" + (time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()) + ":" + (time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes())
        return result
    }

    //custom select


    handleChangeCustomAutocomplete = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question]: value
            }
        })
    }

    handleChangeCustomAutocompleteSingleFormat = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question.id]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        })
    }


    handleChangeCustomAutocompleteMultiple = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    handleChangeCustomAutocompleteFormat = (selectCustomAutocomplete, question) => {
        console.log(question);
        console.log(selectCustomAutocomplete)
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question.id]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question.id]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question.id]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question.id]: value
                    }
                })
            }
        }
    }

    handleChangeCustomAutocompleteCargos = (selectCustomAutocomplete, question) => {
        console.log(selectCustomAutocomplete);
        console.log(question)
        console.log("laura esta hablando mierda")
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    handleChangeCustomAutocompleteProcesos = (selectCustomAutocomplete, question) => {
        console.log(selectCustomAutocomplete);
        console.log(question)
        console.log("laura esta hablando mierda")
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }

    getSubform = (id) => {
        fetch(`/formatos/get_questions_subform/${id}/${this.props.survey.id}/0`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data, "adjfñlasfk")
                this.setState({
                    modal: true,
                    surveySubform: data.survey,
                    formatSubForm: data.format,
                    dataAnswerSubForms: data.dataAnswer,
                    dataSubForms: data.data,
                })
            });
    }

    editSubform = (question_id, surveyid) => {
        fetch(`/formatos/get_questions_subform/${question_id}/${this.props.survey.id}/${surveyid}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    modal: true,
                    surveySubform: data.survey,
                    formatSubForm: data.format,
                    dataAnswerSubForms: data.dataAnswer,
                    dataSubForms: data.data,
                })
            });
    }

    deleteSubform = (question_id, surveyid) => {
        fetch(`/formatos/delete_questions_subform/${question_id}/${this.props.survey.id}/${surveyid}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.props.loadData()
            });
    }




    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="answer_string"
                        className="form form-control imput-options-disabled"
                        data-id={question.id}
                        data-question={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        disabled={this.props.survey.state}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } if (question.question_type == "answer_hour") {
            return (
                <React.Fragment>
                    <TimeField
                        onChange={this.HandleChangeRepy}
                        value={this.state.formUpdateRepy1[question.id].value}
                        input={
                            <input
                                type="text"
                                name="answer_hour"
                                className="form-control"
                                data-id={question.id}
                                data-question={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                disabled={this.props.survey.state}
                                autoComplete="off"
                            />
                        }
                    />
                </React.Fragment>
            )

        }
        else if (question.question_type == "answer_integer") {

            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_integer"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}

                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            autoComplete="off"

                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}

                        />

                    ) : (

                            <input
                                type="number"
                                className="form form-control imput-options-disabled"
                                name="answer_integer"
                                data-id={question.id}
                                data-question={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                disabled={this.props.survey.state}
                                value={this.state.formUpdateRepy1[question.id].value}
                                onChange={this.HandleChangeRepy}
                                autoComplete="off"
                            />
                        )}
                </React.Fragment>
            )

        } else if (question.question_type == "code_format") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_user_id") {
            return (
                <React.Fragment>
                    <CustomAutocomplete
                        data={this.state.options}
                        handleChange={question.is_multiple ? this.handleChangeCustomAutocompleteMultiple : this.handleChangeCustomAutocomplete}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti={question.is_multiple}
                        question={question.id}
                        value={this.state.formUpdateRepy1[question.id].value}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "position_id") {
            return (
                <React.Fragment>
                    <CustomAutocomplete
                        data={this.state.cargos}
                        handleChange={this.handleChangeCustomAutocompleteCargos}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti
                        question={question.id}
                        value={this.state.formUpdateRepy1[question.id].value}
                        autoComplete="off"
                    />

                </React.Fragment>
            )

        } else if (question.question_type == "proceso_id") {
            return (
                <React.Fragment>
                    <CustomAutocomplete
                        data={this.state.procesos}
                        handleChange={this.handleChangeCustomAutocompleteProcesos}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti
                        question={question.id}
                        value={this.state.formUpdateRepy1[question.id].value}
                        autoComplete="off"
                    />

                </React.Fragment>
            )

        }

        else if (question.question_type == "answer_text") {
            return (
                <React.Fragment>
                    {question.is_multiple ? (
                        <FroalaEditor
                            model={this.state.formUpdateRepy1[question.id].value[0]}
                            config={{
                                language: 'es',
                                colorsHEXInput: true,
                                autoFocus: true,
                                toolbartop: true,
                                linkAlwaysBlank: true,
                                fontFamilySelection: true,
                                fontSizeSelection: true,
                                paragraphFormatSelection: true,
                                htmlExecuteScripts: true,
                                imageUploadToS3: this.props.imageUpload,
                                iframe: true,
                                tabSpaces: 4,
                                pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
                                toolbarButtons: {
                                    moreText: {
                                        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                                        // Alignment of the group in the toolbar.
                                        align: 'left',

                                        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                                        buttonsVisible: 12
                                    },


                                    moreParagraph: {
                                        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                        align: 'left',
                                        buttonsVisible: 11
                                    },

                                    moreRich: {
                                        buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                        align: 'left',
                                        buttonsVisible: 10
                                    },

                                    moreMisc: {
                                        buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                                        align: 'right',
                                        buttonsVisible: 9
                                    }
                                }
                            }}
                            question={question}
                            onModelChange={(e) => this.HandleChangeCkaditor(e, question)}
                        />
                    ) : (

                            <Ckeditor
                                HandleChange={this.HandleChangeCkaditor}
                                question={question}
                                formValues={this.state.formUpdateRepy1[question.id].value[0]}
                                autoComplete="off"
                            />
                        )}

                </React.Fragment>
            )

        } else if (question.question_type == "answer_date") {
            return (
                <React.Fragment>
                    <input
                        type="date"
                        className="form form-control imput-options-disabled"
                        name="answer_date"
                        data-id={question.id}
                        data-question={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        disabled={question.is_currency}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_datetime") {
            return (
                <React.Fragment>

                    <input
                        type="datetime-local"
                        className="form form-control imput-options-disabled"
                        name="answer_time"
                        data-id={question.id}
                        data-question={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.getDateTime(this.state.formUpdateRepy1[question.id].value)}
                        onChange={this.HandleChangeRepy}
                        disabled={this.props.survey.state}
                        autoComplete="off"
                    />

                </React.Fragment>
            )

        } else if (question.question_type == "answer_file") {
            return (
                <React.Fragment>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="mt-3">
                                <Dropzone
                                    onDrop={(files) => this.HandleChangeFile(files, question.id, question.is_mandatory, question.is_active, question)}
                                    disabled={this.props.survey.state}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps({
                                                className: 'dropzone',
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{ color: this.state.formUpdateRepy1[question.id].value[0].name != undefined ? "#0094ff" : "#a5a5a5" }}>{`${(this.state.formUpdateRepy1[question.id].value[0].name != undefined ? this.state.formUpdateRepy1[question.id].value[0].name : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                        </div>
                                    )}
                                </Dropzone>

                                {false != null && (
                                    <p className="mb-0 mt-2"><b>Archivo: </b>
                                        <a
                                            href={this.state.formUpdateRepy1[question.id].value[0].url}
                                            target="_blanck"
                                        >
                                            {this.state.formUpdateRepy1[question.id].value[0].name}


                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select") {

            return (
                <select
                    name="answer_select"
                    className="form form-control"
                    onChange={(e) => this.setValueSelect(e, question)}
                    disabled={this.props.survey.state}
                    data-id={question.id}
                    data-type={question.question_type}
                    data-mandatory={question.is_mandatory}
                    data-active={question.is_active}
                    data-question={question.id}
                    autoComplete="off"

                    value={this.state.formUpdateRepy1[question.id].value}
                >
                    <option value="">Seleccione una opcion</option>
                    {question.options.map((name, index) => (
                        <option value={name}>{name}</option>
                    ))}

                </select>

            )

        } else if (question.question_type == "answer_localization") {
            this.getGeolocalizacion(question)
            return (
                <div>
                    <input
                        type="text"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value.toString()}
                        className={`form form-control mt-4`}
                        autoComplete="off"
                    />
                </div>
            )

        } else if (question.question_type == "answer_autdatetime") {
            return (
                <input
                    type="text"
                    className="form form-control"
                    disabled={true}
                    value={this.state.formUpdateRepy1[question.id].value}
                    autoComplete="off"
                />
            )

        } else if (question.question_type == "answer_radio") {

            return (
                <div className="row mt-3">
                    {question.options.map((name, index) => (
                        <div className="col-md-12">
                            <input
                                id={name}
                                onChange={(e) => this.HandleChangeRepyRadio(e, question.id)}
                                type="radio"
                                value={name}
                                data-id={question.id}
                                data-question={question.id}

                                data-type={question.question_type}
                                disabled={this.props.survey.state}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                checked={this.state.formUpdateRepy1[question.id].value[0] == name ? true : false}
                                autoComplete="off"
                                className="mr-2"
                            />
                            <label htmlFor={name}> {name} </label>
                            {name == "Otra.." && (
                                <input
                                    type="text"
                                    className="imput-options remove-select ml-3"
                                    name=""
                                    style={{ width: "50%" }}
                                    value={this.state.formUpdateRepy1[question.id].value[1]}
                                    onChange={(e) => this.HandleChangeRepyRadioInput(e, question)}
                                    autoComplete="off"
                                />
                            )}
                        </div>
                    ))}
                </div>
            )

        } else if (question.question_type == "answer_checkbox") {
            return (
                <div className="row mt-3">
                    {question.options.map((name, index) => (
                        <div className="col-md-12">
                            <input
                                name="answer_checkbox"
                                onChange={(e) => this.HandleChangeRepyArray(e, question)}
                                value={name}
                                data-id={question.id}
                                data-type={question.question_type}
                                disabled={this.props.survey.state}
                                data-question={question.id}

                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                checked={this.state.formUpdateRepy1[question.id].value[0].includes(name)}
                                autoComplete="off"
                                type="checkbox"
                                className="mr-2"
                            />
                            <label htmlFor={name}> {name}</label>
                        </div>
                    ))}
                </div>
            )
        } else if (question.question_type == "answer_formula") {
            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_formula"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}

                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            autoComplete="off"
                            disabled={true}
                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}

                        />

                    ) : (
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                name="answer_formula"
                                data-id={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                disabled={true}
                                value={this.state.formUpdateRepy1[question.id].value}
                                onChange={this.HandleChangeRepy}
                                autoComplete="off"
                            />
                        )}
                </React.Fragment>
            )

        }
        else if (question.question_type == "format_id") {
            return (
                <React.Fragment>
                    <FileTypeFormat
                        question={question}
                        formUpdateRepy1={this.state.formUpdateRepy1[question.id]}
                        formatValues={this.state.formatValues[question.id]}
                        survey={this.props.survey}
                        setValueSelect={this.setValueSelect}

                        //autocomplete props

                        handleChange={question.is_multiple ? this.handleChangeCustomAutocompleteFormat : this.handleChangeCustomAutocompleteSingleFormat}
                        selectCustomAutocomplete={this.state.selectCustomAutocomplete}
                        classIconList="fas fa-users"
                        isMulti={question.is_multiple}
                        value={this.state.formUpdateRepy1[question.id].value}
                        autoComplete="off"
                    />
                </React.Fragment>
            )


        } else if (question.question_type == "dynamic_field") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "email") {
            return (
                <React.Fragment>
                    <input
                        type="email"
                        className="form form-control imput-options-disabled"
                        name="email"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "url") {
            return (
                <React.Fragment>
                    <input
                        type="url"
                        className="form form-control imput-options-disabled"
                        name="url"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_subform") {
            return (
                <React.Fragment>
                    <div className="row sub-form-input" style={{paddingRight:"20px"}}>
                        <div className="col-md-12 text-right" style={{paddingRight:"0px"}}>
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.getSubform(question.id)}
                            >
                                Nuevo
                            </button>

                            <hr />
                        </div>
                        {isMobile ? (
                            <div className="col-md-12">
                                {this.state.formUpdateRepy1[question.id].value[0].answers.map(answer => (
                                    <div className="sub-form-container" style={{ padding: "10px", marginTop: "10px", border: "1px solid #e2e2e2" }} >
                                        <div className="sub-form-container__options  text-right">
                                            <i onClick={() => this.editSubform(question.id, answer.id)} className="fa-pencil-alt fas mr-2"></i>
                                            <i onClick={() => this.deleteSubform(question.id, answer.id)} className="fa-trash-alt fas"></i>
                                        </div>
                                        {answer.value.map(answer_child => (

                                            <div className="sub-form-container__item"><b>{answer_child.question}</b> <br />
                                                {answer_child.type == "answer_file" && (
                                                    <a href={answer_child.value}>Archivo</a>
                                                )}
                                                {(answer_child.type == "answer_string" || answer_child.type == "answer_select" || answer_child.type == "answer_radio") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {answer_child.type == "answer_text" && (
                                                    <p>{ReactHtmlParser(answer_child.value)}</p>
                                                )}
                                                {(answer_child.type == "answer_integer" || answer_child.type == "answer_formula") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {(answer_child.type == "answer_date") && (
                                                    <p>{answer_child.value}</p>
                                                )}


                                            </div>
                                        ))}


                                    </div>
                                ))}

                            </div>
                        ) : (
                                <div className="col-md-12 table-sub-form_container">
                                    <table className="table-sub-form" style={{ padding: "10px", marginTop: "10px", width: (this.state.formUpdateRepy1[question.id].value[0].questions.length * 200) }} >
                                        <tr>
                                            <th className="text-center" style={{ width: "60px" }}></th>
                                            {this.state.formUpdateRepy1[question.id].value[0].questions.map(answer_th => (
                                                <th>{answer_th}</th>
                                            )
                                            )}
                                        </tr>
                                        {this.state.formUpdateRepy1[question.id].value[0].answers.map(answer => (
                                            < tr >
                                                <td className="sub-form-container__options text-center">
                                                    <i onClick={() => this.editSubform(question.id, answer.id)} className="fas fa-pencil-alt mr-2"></i>
                                                    <i onClick={() => this.deleteSubform(question.id, answer.id)} className="fas fa-trash-alt"></i>
                                                </td>
                                                {answer.value.map(answer_child => (

                                                    <td>
                                                        {answer_child.type == "answer_file" && (
                                                            <a href={answer_child.value}>Archivo</a>
                                                        )}
                                                        {(answer_child.type == "answer_string" || answer_child.type == "answer_select" || answer_child.type == "answer_radio") && (
                                                            <p>{answer_child.value}</p>
                                                        )}
                                                        {answer_child.type == "answer_text" && (
                                                            <p>{ReactHtmlParser(answer_child.value)}</p>
                                                        )}
                                                        {(answer_child.type == "answer_integer" || answer_child.type == "answer_formula") && (
                                                            <p>{answer_child.value}</p>
                                                        )}
                                                        {(answer_child.type == "answer_date") && (
                                                            <p>{answer_child.value}</p>
                                                        )}
                                                    </td>
                                                ))}

                                            </tr>
                                        ))}
                                    </table>

                                </div>
                            )

                        }


                    </div>
                </React.Fragment >
            )

        } else if (question.question_type == "answer_signature") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`${isMobile ? "canvas-container" : ""}`}>
                                <SignaturePad canvasProps={{ className: `sigPad_generate sigPad_generate_${isMobile ? "290" : "505"}`, width: isMobile ? 290 : 505, height: 150 }} ref={(ref) => { this.state.sigPad[question.id] = ref }} />

                                <div className="mt-2">
                                    <button className={"btn-shadow btn btn-info mr-2"} onClick={() => this.clear(question)}>
                                        <i class="fas fa-trash-alt"></i> Limpiar
                                      </button>

                                    <button className={"btn-shadow btn btn-info"} onClick={() => this.trim(question)}>
                                        <i class="fas fa-camera"></i> Confirmar
                                     </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4">
                            {this.state.formUpdateRepy1[question.id] != undefined && (
                                <React.Fragment>

                                    <img style={{ width: "100%" }} className={"sigImage"} src={this.state.formUpdateRepy1[question.id].value[0]} />
                                </React.Fragment>
                            )}
                        </div>
                    </div>



                </React.Fragment>
            )
        }
    }

    clear = (question) => {
        this.state.sigPad[question.id].clear()
    }

    trim = (question) => {
        const value = this.state.sigPad[question.id].getTrimmedCanvas().toDataURL('image/png')
        this.setState({
            trimmedDataURL: value,
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [value], type: "answer_signature", step: this.state.formUpdateRepy1[question.id].step, is_mandatory: false, is_active: true }
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false, dataSubForms: [] })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <ModalSubForm
                        //modal props
                        loadData={this.props.loadData}
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.formatSubForm}
                        data={this.state.dataSubForms}
                        survey={this.props.survey.id}
                        surveySubform={this.state.surveySubform}
                        dataAnswer={this.state.dataAnswerSubForms}

                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className={`container  ${isMobile ? "p-0" : ""}`}>
                            <div className="alert alert-danger" style={{ display: this.state.formulaError ? "block" : "none" }}>¡ Tienes un error en alguna de las formulas por favor revisar. !</div>
                            {this.props.showHeader && (
                                <div className={`col-md-12 text-right`}>
                                    <a
                                        className="btn btn-light"
                                        href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=ACTIVE`}
                                        data-turbolinks="false"
                                        onClick={() => this.updateRepy("countNex")}
                                    >
                                        Volver
                                    </a>
                                </div>
                            )}


                            {this.props.format.imagen_url != null && (
                                <div className="col-md-12 mb-3 mt-3">
                                    <div className="card text-center pt-3 pb-3" >
                                        <div>
                                            <img style={{ maxWidth: "200px" }} src={this.props.format.imagen_url} className="" />
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className={`col-md-12 mx-auto ${isMobile ? "p-0" : ""}`}>

                                <ul className="nav nav-tabs mb-0" id="myTab" role="tablist">
                                    {this.props.data.map((form, index) => (
                                        <li className="nav-item">
                                            {form.survey_step_state ? (
                                                <a
                                                    className={`nav-link ${this.state.nexStep == index ? "active" : ""}`}
                                                    id="home-tab"
                                                    data-toggle="tab"
                                                    href={`#home${form.id}`}
                                                    onClick={() => this.setState({ nexStep: index })}
                                                    role="tab"
                                                    aria-disabled={true}
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                >
                                                    {form.step.name == "Formulario sin título" ? "Sección" : form.step.name} {index + 1}/{this.props.data.length}
                                                </a>
                                            ) : (
                                                    <div className={`nav-link ${this.state.nexStep == index ? "active" : ""}`}>
                                                        {form.step.name == "Formulario sin título" ? "Sección" : form.step.name} {index + 1}/{this.props.data.length}
                                                    </div>
                                                )}

                                        </li>
                                    ))}
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    {this.props.data.map((form, index) => (
                                        <div className={`tab-pane fade show ${this.state.nexStep == index ? "active mt-4" : ""}`} id={`home${form.id}`} role="tabpanel" aria-labelledby="home-tab">
                                            <div className={`card ${index > 0 ? "mt-4" : ""}`} key={form.id}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <h4>{form.step.name}</h4>
                                                        </div>

                                                        <div className="col-md-12">
                                                            {ReactHtmlParser(form.step.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.format.columns_format_number == 1 ? (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {form.survey_answers.map((question, index) => (
                                                            <div
/*                                             className={`card mt-3 ${this.getValidation(question) ? "border-questions" : "border-questions-error"} `}
*/                                            className="card mt-3 border-questions"

                                                                key={question.id}
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className={`col-md-12`}>
                                                                            <h5>{index + 1}) {question.question}</h5>
                                                                            <p>{question.description}</p>

                                                                            {question.file.url && (
                                                                                <div className="mb-3">
                                                                                    <img src={question.file.url} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                                                </div>
                                                                            )}

                                                                            {this.getTypeQuestion(question)}
                                                                            <div>
                                                                                {this.state.formUpdateRepy1 != undefined ? ((this.state.formUpdateRepy1[question.id].value[0] == null || this.state.formUpdateRepy1[question.id].value[0].length == 0 || (this.state.formUpdateRepy1[question.id].type == "answer_file" ? this.state.formUpdateRepy1[question.id].value[0].name == undefined : false)) && this.state.errorValues == false && (this.state.formUpdateRepy1[question.id].is_mandatory == "true" || this.state.formUpdateRepy1[question.id].is_mandatory == true) ? <b style={{ color: "#ff0000" }}>Esta pregunta es obligatoria</b> : "") : ""}
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (

                                                    <div className="form-survey">
                                                        {form.survey_answers.map((question, index) => (
                                                            <div
/*                                             className={`card mt-3 ${this.getValidation(question) ? "border-questions" : "border-questions-error"} `}
*/                                            className="card mt-3 border-questions"

                                                                key={question.id}
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className={`col-md-12`}>
                                                                            <h5>{index + 1}) {question.question}</h5>
                                                                            <p>{question.description}</p>
                                                                            
                                                                            {question.file.url && (
                                                                                <div className="mb-3">
                                                                                    <img src={question.file.url} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                                                </div>
                                                                            )}


                                                                            {this.getTypeQuestion(question)}
                                                                            <div>
                                                                                {this.state.formUpdateRepy1 != undefined ? ((this.state.formUpdateRepy1[question.id].value[0] == null || this.state.formUpdateRepy1[question.id].value[0].length == 0 || (this.state.formUpdateRepy1[question.id].type == "answer_file" ? this.state.formUpdateRepy1[question.id].value[0].name == undefined : false)) && this.state.errorValues == false && (this.state.formUpdateRepy1[question.id].is_mandatory == "true" || this.state.formUpdateRepy1[question.id].is_mandatory == true) ? <b style={{ color: "#ff0000" }}>Esta pregunta es obligatoria</b> : "") : ""}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                )}
                                        </div>
                                    ))}
                                    {this.state.completed && (
                                        <div className="alert alert-danger mt-3">
                                            Debes completar todos los campos
                                        </div>
                                    )}
                                    <div className="card mt-3">
                                        <div className="card-body">

                                            {this.state.loading && (
                                                <div className="survey-loading-component text-center">

                                                    <div> Enviando formulario </div>
                                                    <LoadingComponen />
                                                </div>
                                            )}
                                            {this.state.loadingNextStep && (
                                                <div className="survey-loading-component text-center">

                                                    <div> Guardando sección </div>
                                                    <LoadingComponen />
                                                </div>
                                            )}
                                            <div className="survey-actions">
                                                <div>
                                                    {this.state.nexStep > 0 && (

                                                        <button
                                                            className="btn-shadow btn btn-light"
                                                            onClick={() => this.updateBackValue()}
                                                        >
                                                            Anterior
                                                        </button>

                                                    )}
                                                </div>
                                                <div>
                                                    <button
                                                        className={`btn-shadow btn btn-${this.state.nexStep + 1 >= this.state.maxStep ? "info" : "light"}`}
                                                        onClick={this.updateValues}
                                                    >  {this.state.nexStep + 1 >= this.state.maxStep ? "Guardar" : "Siguiente"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                        <div className="col-md-12 text-center">
                            <LoadingComponen />
                        </div>
                    )}

            </React.Fragment>
        );
    }
}

export default Show;
