import React, { Component } from 'react';
import Table from '../components/settings/Procesos/NewTable'
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'

class NewIndexProceso extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    loadData = () => {
        fetch(`/settings/get_procesos/${'procesos clinicos'}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }


    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={"Auditoría de Procesos"}
                    subTitle={"Gestiona el cumplimiento de los procesos clínicos"}
                    titleNew={"Nuevo cargos"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    showNew={false}
                    showFilter={false}
                />
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    estados={this.props.estados}
                    isLoaded={this.state.isLoaded}
                />
            </React.Fragment>
        );
    }
}


export default NewIndexProceso;
WebpackerReact.setup({ NewIndexProceso });